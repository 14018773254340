import axios from '../../axios'
const basePath = '/core/excel'
/*
 * 文件上传模块
 */
export const excelImport = (typeService, data) => {
  return axios({
    url: basePath + '/import/' + typeService,
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'multipart/form-data'
  }})
}
