/* eslint-disable quotes */

export default {
  /**
   * @description: 生成唯一uuid
   * @return {*}
   */

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0
      var v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },
  getTextByValue: function(value, list) {
    if (value || value === 0) {
      value = value + ''
      var item = null
      for (const index in list) {
        item = list[index]
        if (value + '' === item.value + '') {
          return item.label
        } else if (value + '' === item.contentCode + '') {
          return item.contentName
        }
      }
    }
    return null
  },
  getIndexByValue: function(value, list) {
    var item = null
    for (const index in list) {
      item = list[index]
      if (value === item.value) {
        return parseInt(index)
      }
    }
    return null
  },
  setValueChecked: function(value, list) {
    var item = null
    for (const index in list) {
      item = list[index]
      if (value === item.value) {
        item.checked = true
      } else {
        item.checked = false
      }
    }
  },
  timestampToTime: function(timestamp, state) {
    var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1).toString().padStart(2, 0) + '-'
    var D =
      date
        .getDate()
        .toString()
        .padStart(2, 0) + ' '
    var h =
      date
        .getHours()
        .toString()
        .padStart(2, 0) + ':'
    var m =
      date
        .getMinutes()
        .toString()
        .padStart(2, 0) + ':'
    var s = date
      .getSeconds()
      .toString()
      .padStart(2, 0)
    return state ? Y + M + D + h + m + s : Y + M + D
  },
  getQrCodeFilePath: function(ticket) {
    return 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + ticket
  },
  dateFormat(dateStr, fmt) {
    const date = new Date(dateStr)
    var o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return fmt
  },
  dateToStr: function(date) {
    if (!date) {
      return ''
    }
    if (typeof date === 'string') {
      return date
    } else if (typeof date === 'number') {
      date = new Date(date)
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    var monthStr = month < 10 ? '0' + month : month
    var dayStr = day < 10 ? '0' + day : day
    return year + '-' + monthStr + '-' + dayStr
  },
  /* 根据出生日期算出年龄*/
  getAgeByBirthday: function(strBirthday) {
    var returnAge = 0
    var strBirthdayArr = strBirthday.split('-')
    var birthYear = strBirthdayArr[0]
    var birthMonth = strBirthdayArr[1]
    var birthDay = strBirthdayArr[2]

    var d = new Date()
    var nowYear = d.getFullYear()
    var nowMonth = d.getMonth() + 1
    var nowDay = d.getDate()

    if (nowYear === birthYear) {
      returnAge = 0 // 同年 则为0岁
    } else {
      var ageDiff = nowYear - birthYear // 年之差
      if (ageDiff > 0) {
        if (nowMonth === birthMonth) {
          var dayDiff = nowDay - birthDay // 日之差
          if (dayDiff < 0) {
            returnAge = ageDiff - 1
          } else {
            returnAge = ageDiff
          }
        } else {
          var monthDiff = nowMonth - birthMonth // 月之差
          if (monthDiff < 0) {
            returnAge = ageDiff - 1
          } else {
            returnAge = ageDiff
          }
        }
      } else {
        returnAge = -1 // 返回-1 表示出生日期输入错误 晚于今天
      }
    }
    return returnAge // 返回周岁年龄
  },
  getBirthdayByAge: function(age) {
    const d = new Date()
    const year = d.getFullYear()
    const birthYear = year - parseInt(age)
    return new Date(birthYear, 0, 1)
  },
  addressChange: function(checkNodes, editInfo) {
    if (checkNodes && checkNodes.length === 1) {
      const editAddr = checkNodes[0].pathLabels
      editInfo.province = editAddr[0]
      editInfo.city = editAddr[1]
      if (editAddr && editAddr.length === 3) {
        editInfo.district = editAddr[2]
        editInfo.districtcode = checkNodes[0].data.value
      } else {
        editInfo.district = ''
      }
    }
  },
  timeToStr: function(date) {
    if (!date) {
      return ''
    }
    if (typeof date === 'number') {
      date = new Date(date)
    }
    var _format = function(number) {
      return number < 10 ? '0' + number : number
    }
    return (
      date.getFullYear() + '-' + _format(date.getMonth() + 1) + '-' + _format(date.getDate()) + ' ' + _format(date.getHours()) + ':' + _format(date.getMinutes()) + ':' + _format(date.getSeconds())
    )
  },
  addDays: function(srcDate, addDay) {
    if (!srcDate) {
      return ''
    }
    if (typeof srcDate === 'number') {
      srcDate = new Date(srcDate)
    }
    srcDate.setDate(srcDate.getDate() + addDay)
    var _format = function(number) {
      return number < 10 ? '0' + number : number
    }
    return srcDate.getFullYear() + '-' + _format(srcDate.getMonth() + 1) + '-' + _format(srcDate.getDate())
  },
  addMonths: function(srcDate, addMonth) {
    if (!srcDate) {
      return ''
    }
    if (typeof srcDate === 'number') {
      srcDate = new Date(srcDate)
    }
    srcDate.setMonth(srcDate.getMonth() + addMonth)
    var _format = function(number) {
      return number < 10 ? '0' + number : number
    }
    return srcDate.getFullYear() + '-' + _format(srcDate.getMonth() + 1) + '-' + _format(srcDate.getDate())
  },
  setCurTagTitle: function(path, title, visitedViews) {
    if (visitedViews && visitedViews.length > 0) {
      for (const item of visitedViews) {
        if (path === item.path) {
          item.title = title
          break
        }
      }
    }
  },
  concatAttVo(response, file, entityType) {
    const { success, resultData } = response
    const fileName = file.name
    const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
    let uuid = null
    if (success) {
      uuid = resultData.uuidName
    }
    const att = {
      name: fileName,
      url: file.url,
      docName: fileName,
      docSize: file.size,
      uid: file.uid,
      docType: fileType,
      entityType: entityType,
      uuidName: uuid
    }
    return att
  },
  extend(des, src, override) {
    for (const i in src) {
      if (override || !(i in des)) {
        des[i] = src[i]
      }
    }
    return des
  },
  toArrayStr(arr) {
    if (arr) {
      return arr.toString()
    } else {
      return ''
    }
  },
  convertNullStr(str) {
    return str || ''
  },
  convertPage(old = {}, src = {}) {
    old.pageSize = src.pageSize
    old.curPage = src.pageNum
    old.totalRows = src.totalSize
  },
  getTreeData(data) {
    for (var i = 0; i < data.length; i++) {
      if (!data[i].children || !data[i].children.length) {
        data[i].children = undefined
        continue
      }
      // children若不为空数组，则继续 递归调用 本方法
      this.getTreeData(data[i].children)
    }
    return data
  },
  objKeySort(obj) {
    // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    var newkey = Object.keys(obj).sort()
    var newObj = {} // 创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) {
      // 遍历newkey数组
      newObj[newkey[i]] = obj[newkey[i]] // 向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj // 返回排好序的新对象
  },
  isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]'
  },
  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  toInteger: function(floatNum) {
    var ret = { times: 1, num: 0 }
    if (this.isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    var strfi = floatNum + ''
    var dotPos = strfi.indexOf('.')
    var len = strfi.substr(dotPos + 1).length
    var times = Math.pow(10, len)
    var intNum = parseInt(floatNum * times + 0.5, 10)
    ret.times = times
    ret.num = intNum
    return ret
  },
  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   *
   * @param a {number} 运算数1
   * @param b {number} 运算数2
   * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
   *
   */
  floatOperation: function(a, b, op) {
    var o1 = this.toInteger(a)
    var o2 = this.toInteger(b)
    var n1 = o1.num
    var n2 = o2.num
    var t1 = o1.times
    var t2 = o2.times
    var max = t1 > t2 ? t1 : t2
    var result = null
    switch (op) {
      case 'add':
        if (t1 === t2) {
          // 两个小数位数相同
          result = n1 + n2
        } else if (t1 > t2) {
          // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2)
        } else {
          // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 'subtract':
        if (t1 === t2) {
          result = n1 - n2
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2)
        } else {
          result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 'multiply':
        result = (n1 * n2) / (t1 * t2)
        return result
      case 'divide':
        result = (n1 / n2) * (t2 / t1)
        return result
    }
  },
  // 判断obj是否为一个整数
  isInteger: function(obj) {
    return Math.floor(obj) === obj
  },
  // 全局开关参数标识
  enableFlags(item) {
    const _session = sessionStorage.getItem('systemInfo')
    const _info = JSON.parse(_session)
    const _enable = {
      headacheQuestionnaire: _info.func.headacheQuestionnaire, // 头痛问卷
      headacheDiary: _info.func.headacheDiary // 头痛日记
    }
    console.log(_enable)
    let _sty = null
    if (item) {
      _sty = _enable[item]
    }
    return _sty
  },
  getAge(strBirthday, currentTime) {
    // 格式为"1990/01/01"
    // console.log('getAge', strBirthday, currentTime)

    if (typeof strBirthday !== 'string') {
      return '未知'
    }
    let d = new Date()
    if (currentTime) {
      const d1 = new Date(strBirthday).getTime()
      const d2 = new Date(currentTime).getTime()
      console.log('getAge两个时间比较', d1, d2)
      if (d1 > d2) {
        return '-1天'
      } else {
        d = new Date(currentTime)
      }
    }
    let returnAge
    const strBirthdayArr = strBirthday.split('-')
    const birthYear = Number(strBirthdayArr[0])
    const birthMonth = Number(strBirthdayArr[1])
    const birthDay = Number(strBirthdayArr[2])
    const nowYear = d.getFullYear()
    const nowMonth = d.getMonth() + 1
    const nowDay = d.getDate()
    if (nowYear === birthYear) {
      // 0岁
      const monthDiff = nowMonth - birthMonth // 计算月之差
      if (monthDiff > 0) {
        returnAge = monthDiff + '个月'
      } else if (monthDiff === 0) {
        // 0月
        const dayDiff = nowDay - birthDay // 计算日之差
        if (dayDiff >= 0) {
          returnAge = dayDiff + '天'
        } else {
          console.log('错误日')
          returnAge = -1 // 错误年龄
        }
      } else {
        console.log('错误月')
        returnAge = -1 // 错误年龄
      }
    } else {
      // n岁
      const ageDiff = nowYear - birthYear // 年之差
      if (ageDiff > 0) {
        returnAge = ageDiff + '岁'
        console.log(ageDiff, 1)
      } else if (ageDiff === 0) {
        returnAge = '0天'
      } else {
        console.log('错误年')
        console.log(ageDiff, 2)
        returnAge = -1 // 错误年龄
      }
    }

    return returnAge
  },
  appendZero(obj) {
    if (obj < 10) {
      return '0' + obj
    }
    return obj
  },
  MillisecondToDate(mss) {
    /* eslint-disable */
    let date = ''
    const days = parseInt(mss / (1000 * 60 * 60 * 24))
    const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.ceil((mss % (1000 * 60)) / 1000)
    if (days > 0 && mss >= 1000) {
      date += `${days} 天`
    }
    if (hours > 0 && mss >= 1000) {
      date += `${hours} 小时`
    }
    if (minutes > 0 && mss >= 1000) {
      date += `${minutes} 分钟`
    }
    if (seconds > 0) {
      date += `${seconds} 秒`
    }
    /* eslint-enable */
    return date
  },
  //   格式化时间
  timeFormat(data, type) {
    if (!data) return
    const weekArr = ['日', '一', '二', '三', '四', '五', '六']
    const isStr = typeof data
    const time = isStr === 'string' ? new Date(data.replace(/\-/g, '/')) : isStr === 'number' ? new Date(data) : data
    const year = time.getFullYear()
    const month = this.appendZero(time.getMonth() + 1)
    const day = this.appendZero(time.getDate())
    const hours = this.appendZero(time.getHours())
    const min = this.appendZero(time.getMinutes())
    const seconds = this.appendZero(time.getSeconds())
    const weekIndex = time.getDay()
    const week = weekArr[weekIndex]
    if (type === 'MM月dd日(week) HH:mm') {
      const timeFormat = month + '月' + day + '日' + '(周' + week + ')' + '' + hours + ':' + min
      return timeFormat
    }

    if (type === 'YY年MM月dd日') {
      const timeFormat = year + '年' + month + '月' + day + '日'
      return timeFormat
    }
    if (type === 'MM月dd日 HH:mm') {
      const timeFormat = month + '月' + day + '日' + ' ' + hours + ':' + min
      return timeFormat
    }

    if (type === 'HH:mm') {
      const timeFormat = hours + ':' + min
      return timeFormat
    }
    if (type === 'HH:mm:ss') {
      const timeFormat = hours + ':' + min + ':' + seconds
      return timeFormat
    }
    if (type === 'mm:ss') {
      const timeFormat = min + ':' + seconds
      return timeFormat
    }

    if (type === 'YYYY-MM-dd HH:mm') {
      const timeFormat = year + '-' + month + '-' + day + ' ' + hours + ':' + min
      return timeFormat
    }
    if (type === 'YYYY-MM-dd HH:mm:ss') {
      const timeFormat = year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds
      return timeFormat
    }

    if (type === 'MM-dd HH:mm') {
      const timeFormat = month + '-' + day + ' ' + hours + ':' + min
      return timeFormat
    }

    if (type === 'YYYY-MM-dd') {
      const timeFormat = year + '-' + month + '-' + day
      return timeFormat
    }
    if (type === 'MM-dd') {
      const timeFormat = month + '-' + day
      return timeFormat
    }
  },
  getFloatStr(e) {
    if (e != null) {
      let num = e
      num += ''
      num = num.replace(/[^0-9|\.]/g, '') // 清除字符串中的非数字非.字符

      if (/^0+/.test(num)) {
        num = num.replace(/^0+/, '')
      } // 清除字符串开头的0
      if (!/\./.test(num)) {
        num += '.00'
      } // 为整数字符串在末尾添加.00
      if (/^\./.test(num)) {
        num = '0' + num
      } // 字符以.开头时,在开头添加0
      num += '00' // 在字符串末尾补零
      num = num.match(/\d+\.\d{2}/)[0]
      return '￥' + num
    } else {
      return '-'
    }
  },
  // 校验身份证信息
  isIdentityId(identityId) {
    const patrn = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/ // 长度或格式校验
    // 地区校验
    const aCity = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外'
    }
    // 出生日期验证
    const sBirthday = (identityId.substr(6, 4) + '-' + Number(identityId.substr(10, 2)) + '-' + Number(identityId.substr(12, 2))).replace(/-/g, '/')
    const d = new Date(sBirthday)
    // 身份证号码校验 最后4位  包括最后一位的数字/字母X
    let sum = 0
    const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    const codes = '10X98765432'
    for (let i = 0; i < identityId.length - 1; i++) {
      sum += identityId[i] * weights[i]
    }
    const last = codes[sum % 11] // 计算出来的最后一位身份证号码

    let errorMsg = ''
    if (identityId === '') {
      errorMsg = '身份证号不能为空'
    } else if (!patrn.exec(identityId)) {
      errorMsg = '你输入的身份证长度或格式错误'
    } else if (!aCity[parseInt(identityId.substr(0, 2))]) {
      errorMsg = '输入的身份证上的地区有误'
    } else if (sBirthday !== d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()) {
      errorMsg = '你输入的身份证上的出生日期有误'
    } else if (identityId[identityId.length - 1] !== last) {
      errorMsg = '你输入的身份证号有误'
    }
    return errorMsg
  }
}
