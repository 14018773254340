var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      ref: "cur_container",
      attrs: {
        title: "手写签名",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      nativeOn: {
        mousedown: function ($event) {
          return _vm.updateStatus($event)
        },
        mouseup: function ($event) {
          return _vm.updateStatus($event)
        },
        mousemove: function ($event) {
          return _vm.updateStatus($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "canvasHW",
          staticClass: "box",
          staticStyle: { width: "100%", height: "260px" },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.mouseDown($event)
            },
            mousemove: function ($event) {
              $event.stopPropagation()
              return _vm.mouseMove($event)
            },
            mouseup: function ($event) {
              $event.stopPropagation()
              return _vm.mouseUp($event)
            },
          },
        },
        [
          _c("canvas", { ref: "canvasF", attrs: { id: "canvas" } }, [
            _vm._v("签名"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleOverwrite } }, [
            _vm._v("重签"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitSign } },
            [_vm._v("提交签名")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }