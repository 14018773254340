var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.currentGroups, function (item, index) {
      return _c("Branch", {
        key: index,
        attrs: {
          branchFeature: {
            amp: _vm.feature[item.key + "_amp_max"],
            pres: _vm.feature[item.key + "_vital_pres"],
            width: _vm.feature[item.key + "_width"],
          },
          en: _vm.en,
          color: _vm.branchColor[item.key],
          rotate: _vm.rotate,
          title: item.title,
          mai: _vm.branch[item.key].mai,
          value: _vm.branch[item.key].value,
          measure: _vm.measure,
          widthBar: _vm.widthBar,
          width3d: _vm.width3d,
          showKong: _vm.showKong,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }