import axios from '../../axios'
const basePath = '/core/dictionary'
/*
 * 数据字典模块
 */

//
export const findCategoryTree = (data) => {
  return axios({
    url: basePath + '/findTree',
    method: 'get',
    params: data// data
  })
}

export const findContentPage = (data, pageVo) => {
  return axios({
    url: basePath + '/content/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}

export const checkCategoryExists = (data) => {
  return axios({
    url: basePath + '/checkCategoryExists',
    method: 'get',
    params: data// data
  })
}

export const checkContentExists = (data) => {
  return axios({
    url: basePath + '/checkContentExists',
    method: 'get',
    params: data// data
  })
}

export const saveCategory = (data) => {
  return axios({
    url: basePath + '/saveCategory',
    method: 'post',
    data
  })
}

export const editCategory = (data) => {
  return axios({
    url: basePath + '/editCategory',
    method: 'post',
    data
  })
}

export const batchDeleteCategory = (data) => {
  return axios({
    url: basePath + '/deleteCategory',
    method: 'post',
    data
  })
}

export const saveContent = (data) => {
  return axios({
    url: basePath + '/saveContent',
    method: 'post',
    data
  })
}

export const editContent = (data) => {
  return axios({
    url: basePath + '/editContent',
    method: 'post',
    data
  })
}

export const batchDeleteContent = (data) => {
  return axios({
    url: basePath + '/deleteContent',
    method: 'post',
    data
  })
}

export function dictionaryList(data) {
  return axios({
    url: basePath + `/findByType`,
    method: 'get',
    params: data// data
  })
}
