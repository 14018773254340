// import parseTime, formatTime and set to filter
export {
  parseTime,
  formatTime
}
  from '@/utils'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [{
    value: 1E18,
    symbol: 'E'
  },
  {
    value: 1E15,
    symbol: 'P'
  },
  {
    value: 1E12,
    symbol: 'T'
  },
  {
    value: 1E9,
    symbol: 'G'
  },
  {
    value: 1E6,
    symbol: 'M'
  },
  {
    value: 1E3,
    symbol: 'k'
  }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * 性别代码
 * @param {Number} num
 */
export function gender(num) {
  let res = ''
  num = Number(num)
  const data = {
    0: '未知的性别',
    1: '男性',
    2: '女性',
    9: '未说明的性别'
  }
  if (data[num]) {
    res = data[num]
  } else {
    res = '参数错误'
  }
  return res
}

/**
 * 时间戳格式化
 * @param {Number} timestamp
 * @param {String} type eg:yyyy-MM-dd,yyyy-MM-dd hh:mm:ss
 */
export function formatTimestamp(timestamp, type = 'yyyy-MM-dd') {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let weekday = date.getDate()
  let Hour = date.getHours()
  let Min = date.getMinutes()
  let Sec = date.getSeconds()

  if (month < 10) month = '0' + month
  if (weekday < 10) weekday = '0' + weekday
  if (Hour < 10) Hour = '0' + Hour
  if (Min < 10) Min = '0' + Min
  if (Sec < 10) Sec = '0' + Sec

  const format = {
    'yyyy-MM-dd': `${year}-${month}-${weekday}`,
    'yyyy-MM-dd hh:mm': `${year}-${month}-${weekday} ${Hour}:${Min}`,
    'yyyy-MM-dd hh:mm:ss': `${year}-${month}-${weekday} ${Hour}:${Min}:${Sec}`
  }
  let r = format[type]
  if (!r) {
    r = '错误参数'
  }
  return r
}

/**
 * 会诊状态代码
 * @param {Number} num
 */
export function formatConsultationtatus(num) {
  let res = ''
  num = Number(num)
  const data = {
    1: '待支付',
    2: '已支付',
    3: '待审批',
    4: '待开始',
    5: '进行中',
    6: '已完成',
    7: '不通过'
  }
  if (data[num]) {
    res = data[num]
  } else {
    res = '参数错误'
  }
  return res
}

/**
 * 参会方式代码
 * @param {Number} num
 */
export function formatWaysOfAttendingTheMeeting(num) {
  let res = ''
  num = Number(num)
  const data = {
    1: '线上',
    2: '线下'
  }
  if (data[num]) {
    res = data[num]
  } else {
    res = '参数错误'
  }
  return res
}
