import axios from '../../axios'
import { export_blob } from '@/utils/exportFile'
import commonMethod from '@/utils/commonMethod'

// ------------------------------------------- 卡密列表
// 管理员使用卡密列表
export function findPage(data) {
  return axios({
    url: '/mgmt/member/card/list',
    method: 'get',
    params: data
  })
}
// 机构使用卡密列表
export function agentFindPage(data) {
  return axios({
    url: '/mgmt/member/card/listByAgent',
    method: 'get',
    params: data
  })
}
// 批量生成
export function generateCard(data) {
  return axios({
    url: '/mgmt/member/card/generateCard',
    method: 'post',
    data
  })
}
// 批量禁用
export function disable(data) {
  return axios({
    url: '/mgmt/member/card/_batch-disable',
    method: 'post',
    data
  })
}
// 批量启用
export function enable(data) {
  return axios({
    url: '/mgmt/member/card/_batch-enable',
    method: 'post',
    data
  })
}
// 批量失效
export function invalid(data) {
  return axios({
    url: '/mgmt/member/card/_batch-invalid',
    method: 'post',
    data
  })
}
// 绑定机构
export function bindCardToAge(data) {
  return axios({
    url: '/mgmt/member/card/bindCardToAgent',
    method: 'post',
    data
  })
}
// 换绑机构
export function SwitchbindCardToAge(data) {
  return axios({
    url: '/mgmt/member/card/cardChangeAgent',
    method: 'post',
    data
  })
}
// 导入自动化激活
export function importExcel(data) {
  return axios({
    url: '/mgmt/member/card/importBindAgentTemplate2',
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'application/octet-stream'
  }})
}
// 下载模板
export const exportDrugsTemp = (data) => {
  return axios({
    url: '/mgmt/member/card/exportBindAgentTemplate',
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
export function exportExcel(data, loadingMessage, id) {
  const d = new Date().getTime()
  const time = commonMethod.timeFormat(d, 'YYYY-MM-dd HH:mm:ss')
  return axios({
    url: '/mgmt/member/card/export',
    method: 'post',
    responseType: 'blob',
    data
  })
    .then(response => {
      export_blob(response, `会员卡列表${time}.xls`)
      if (loadingMessage) {
        setTimeout(function() {
          loadingMessage.close()
        }, 100)
      }
    })
    .catch(function(error) {
      loadingMessage.close()
      console.log(error)
    })
}


// 关联用户
export function membershipAdd(data) {
  return axios({
    url: '/mgmt/member/card/membership/add',
    method: 'post',
    data
  })
}