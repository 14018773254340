<template>
  <div class="chart-channel" :style="`width: ${width}px; height: ${width}px;`">
    <div class="channel-wrp" :style="`transform: ${transform(width)}`">
      <ChannelBg :mais="mais()" :width="800" />

      <div class="channel">
        <div class="item">
          <div class="top"><span :class="['txt', { 'on': lj.xian_shaoyang }]">少阳</span></div>
          <div class="bottom"><span :class="['txt', { 'on': lj.ruan_taiyang }]">太阳</span><span
              :class="['txt', { 'on': lj.ruan_jueyin }]">厥阴</span></div>
        </div>
        <div class="item r-2">
          <div class="top"><span :class="['txt', { 'on': lj.fu_taiyang }]">太阳</span><span
              :class="['txt', { 'on': lj.fu_yangming }]">阳明</span></div>
          <div class="bottom">
            <div class="left"><span :class="['txt', { 'on': lj.chen_yangming }]">阳明</span></div>
            <div class="right"><span :class="['txt', { 'on': lj.chen2_shaoyang }]">少阳</span><span
                :class="['txt', { 'on': lj.chen2_jueyin }]">厥阴</span></div>
          </div>
        </div>
        <div class="item r-3">
          <div class="top"><span :class="['txt', { 'on': lj.hua_yangming }]">阳明</span><span
              :class="['txt', { 'on': lj.hua_taiyang }]">太阳</span></div>
          <div class="bottom"><span :class="['txt', { 'on': lj.xi_jueyin }]">厥阴</span><span
              :class="['txt', { 'on': lj.xi_shaoyin }]">少阴</span></div>
        </div>
        <div class="item r-4">
          <div class="top">
            <div class="left"><span :class="['txt', { 'on': lj.shu_shaoyin }]">少阴</span><span
                :class="['txt', { 'on': lj.shu_taiyin }]">太阴</span></div>
            <div class="right"><span :class="['txt', { 'on': lj.shu2_taiyang }]">太阳</span><span
                :class="['txt', { 'on': lj.shu2_yangming }]">阳明</span></div>
          </div>
          <div class="bottom"><span :class="['txt', { 'on': lj.chi_shaoyin }]">少阴</span><span
              :class="['txt', { 'on': lj.chi_jueyin }]">厥阴</span><span
              :class="['txt', { 'on': lj.chi_taiyang }]">太阳</span></div>
        </div>
      </div>


      <div class="mai">
        <Item :on="mai.xian" :titles="duadMaiTitle2.xian" />
        <Item class="r-2" :on="mai.fu" :titles="duadMaiTitle2.fu" />
        <Item class="r-3" :on="mai.hua" :titles="duadMaiTitle2.hua" />
        <Item class="r-4" :on="mai.shu" :titles="duadMaiTitle2.shu" />
      </div>

      <!-- <div class="mai">
        <div class="item ">
          <div style="display: none;">
            <div class="top1"><span class="txt ">弦</span></div>
            <div class="bottom1"><span class="txt on">软</span></div>
          </div>
          <div>
            <div class="top"><span class="txt ">弦</span></div>
            <div class="bottom"><span class="txt on">软</span></div>
          </div>
        </div>
        <div class="item r-2">
          <div style="display: none;">
            <div class="top1"><span class="txt ">浮</span></div>
            <div class="bottom1"><span class="txt on">沉</span></div>
          </div>
          <div>
            <div class="top"><span class="txt ">浮</span></div>
            <div class="bottom"><span class="txt on">沉</span></div>
          </div>
        </div>
        <div class="item r-3">
          <div style="display: none;">
            <div class="top1"><span class="txt ">滑</span></div>
            <div class="bottom1"><span class="txt on">细</span></div>
          </div>
          <div>
            <div class="top"><span class="txt ">滑</span></div>
            <div class="bottom"><span class="txt on">细</span></div>
          </div>
        </div>
        <div class="item r-4">
          <div style="display: none;">
            <div class="top1"><span class="txt ">数</span></div>
            <div class="bottom1"><span class="txt ">迟</span></div>
          </div>
          <div>
            <div class="top"><span class="txt ">数</span></div>
            <div class="bottom"><span class="txt ">迟</span></div>
          </div>
        </div>
      </div> -->
      <div class="bg" id="channelchartbg"></div>
    </div>
  </div>

</template>

<script>
import ChannelBg from './ChannelBg.vue'
import Item from './Item.vue'
import { getMais } from './maiChannel.js'
import { duadMaiDefaultValue, duadMaiTitle, duadMaiTitle1 } from './Branch/index.js'
export default {
  data() {
    return {
      mai: { ...duadMaiDefaultValue },
      duadMaiTitle2: { ...duadMaiTitle },
      lj: {}
    }
  },
  props: {
    channels: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 700
    },
    heartrate: {
      type: Number,
      default: 75
    },
    summary: {
      type: Object,
      default: () => null
    },
    en: {
      type: String,
      default: ''
    }
  },
  components: {
    ChannelBg, Item
  },
  methods: {
    transform(width) {
      const scale = width / 800
      const translate = ((1 - scale) * 50) / scale
      return `scale(${scale}) translate(-${translate}%, -${translate}%)`
    },
    mais() {
      if (!this.summary || !this.channels) return undefined
      const { mais, duadMai, lj: _lj } = getMais(this.summary, this.heartrate, this.channels)
      this.mai = { ...duadMai } // Vue 2 数据响应
      this.lj = { ..._lj } // Vue 2 数据响应
      console.log(mais)
      return mais
    }
  },

}
</script>

<style scoped lang="scss">
.chart-channel {
  overflow: hidden;
  margin: 0 auto 1.2rem
}

.channel-wrp {
  backface-visibility: hidden;
  width: 800px;
  height: 800px;
  position: relative
}

.channel-wrp .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/channel_bg.png);
  background-size: 100% 100%;
  top: 0;
  left: 0
}


.channel-wrp .result-canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

.channel-wrp .r-1 {
  height: 92% !important;
  top: 4% !important
}

.channel-wrp .r-2 {
  transform: rotate(45deg)
}

.channel-wrp .r-3 {
  transform: rotate(90deg)
}

.channel-wrp .r-5 {
  transform: rotate(90deg);
  height: 91% !important
}

.channel-wrp .r-4 {
  transform: rotate(-45deg)
}

.channel-wrp .channel {
  position: absolute;
  width: 86%;
  height: 86%;
  left: 7%;
  top: 7%
}

.channel-wrp .channel .item {
  width: 220px;
  position: absolute;
  height: 100%;
  left: 50%;
  margin-left: -110px;
  text-align: center
}

.channel-wrp .channel .top,
.channel-wrp .channel .bottom {
  position: absolute;
  width: 100%
}

.channel-wrp .channel .top {
  top: 0;
  transform: translateY(-100%)
}

.channel-wrp .channel .bottom {
  bottom: 0;
  transform: translateY(100%)
}

.channel-wrp .channel .txt {
  border: .5px solid #f4bdbd;
  color: #f4bdbd;
  display: block;
  width: 50px;
  font-size: 1.2rem;
  line-height: 2.4rem;
  margin: auto;
  border-bottom: none
}

.channel-wrp .channel .txt:last-child {
  border-bottom: .5px solid #f4bdbd
}

.channel-wrp .channel .r-4 .left,
.channel-wrp .channel .r-2 .right {
  transform: rotate(-12deg);
  margin-left: 10px
}

.channel-wrp .channel .left {
  float: left
}

.channel-wrp .channel .r-4 .right,
.channel-wrp .channel .r-2 .left {
  transform: rotate(12deg);
  margin-right: 10px
}

.channel-wrp .channel .right {
  float: right
}

.channel-wrp .channel .r-3 .top {
  transform: rotate(-90deg) translate(50px)
}

.channel-wrp .channel .r-3 .bottom {
  transform: rotate(-90deg) translate(-50px)
}

.channel-wrp .channel .on.txt {
  background-color: #d5454b;
  color: #fff;
  border-color: #d5454b
}

.channel-wrp .mai {
  position: absolute;
  width: 75%;
  height: 75%;
  left: 12.5%;
  top: 12.5%
}

.channel-wrp .mai .item {
  width: 40px;
  position: absolute;
  height: 100%;
  left: 50%;
  margin-left: -20px;
  text-align: center
}

.channel-wrp .mai .top {
  position: absolute;
  width: 100%;
  top: 0;
  transform: translateY(-100%)
}

.channel-wrp .mai .bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  transform: translateY(100%)
}

.channel-wrp .mai .top1 {
  position: absolute;
  width: 100%;
  top: -20px;
  transform: translateY(-100%)
}

.channel-wrp .mai .bottom1 {
  position: absolute;
  width: 100%;
  bottom: -20px;
  transform: translateY(100%)
}

.channel-wrp .mai .txt {
  font-size: 2rem
}

.channel-wrp .mai .on.txt {
  color: #d5454b
}

.channel-wrp ::v-deep .mai .r-3 .top {
  transform: rotate(-90deg) translate(27px)
}

.channel-wrp ::v-deep .mai .r-3 .bottom {
  transform: rotate(-90deg) translate(-27px)
}
</style>