import axios from '../../axios'

export function getList(data) {
  return axios({
    url: '/mgmt/medical/activity/getList',
    method: 'get',
    params: data
  })
}

export function getListByWhere(data) {
  return axios({
    url: '/mgmt/medical/activity/getListByWhere',
    method: 'get',
    params: data
  })
}

export function addTitle(data) {
  return axios({
    url: '/mgmt/medical/activity/add',
    method: 'post',
    data: data
  })
}

export function deleteTitle(data) {
  return axios({
    url: '/mgmt/medical/activity/delete',
    method: 'post',
    data: data
  })
}

export function editTitle(data) {
  return axios({
    url: '/mgmt/medical/activity/edit',
    method: 'post',
    data: data
  })
}
