import axios from '../../axios'

// 获取分类列表
export function getCategoryList(data) {
  return axios({
    url: '/mgmt/special/disease/category/getList',
    method: 'get',
    params: data
  })
}

// 新增分类
export function addCategory(data) {
  return axios({
    url: '/mgmt/special/disease/category/add',
    method: 'post',
    data: data
  })
}

// 删除分类
export function deleteCategory(data) {
  return axios({
    url: '/mgmt/special/disease/category/delete',
    method: 'post',
    data: data
  })
}

// 编辑分类
export function editCategory(data) {
  return axios({
    url: '/mgmt/special/disease/category/edit',
    method: 'post',
    data: data
  })
}
