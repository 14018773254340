export const groups = [
    {
      title: '寸部：',
      key: 'cun'
    },
    {
      title: '关部：',
      key: 'guan'
    },
    {
      title: '尺部：',
      key: 'chi'
    }
  ];
  
  export const groups1 = [
    {
      title: 'Cun part:',
      key: 'cun'
    },
    {
      title: 'Guan part：',
      key: 'guan'
    },
    {
      title: 'Chi part：',
      key: 'chi'
    }
  ];
  