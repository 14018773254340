/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-10-19 14:06:49
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-27 09:46:34
 * @FilePath: \drant-manager\src\http\moudules\device\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 添加活动
export const activityAdd = data => {
  return axios({
    url: '/mgmt/activity/popup/add',
    method: 'post',
    data
  })
}
// 活动详情
export const activityDetail = data=> {
  return axios({
    url: '/mgmt/activity/popup/detail/'+ data.id,
    method: 'get',
    params: data
  })
}
// 活动分页
export const activityGetPage = data => {
  return axios({
    url: '/mgmt/activity/popup/getPage',
    method: 'get',
    params: data
  })
}
// 活动编辑
export const activityEdit = data => {
  return axios({
    url: '/mgmt/activity/popup/edit',
    method: 'post',
    data
  })
}
// 活动删除
export const activityDelete = data => {
  return axios({
    url: '/mgmt/activity/popup/delete',
    method: 'post',
    data
  })
}

