import axios from '../../axios'
const basePath = '/core'

// 收入分页查询
export const inComeFindPage = (data, pageVo) => {
  return axios({
    url: basePath + '/financeBoard/income/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 收入总金额
export function inComeSumAmount(data) {
  return axios({
    url: basePath + '/financeBoard/income/sumamount',
    method: 'get',
    params: data
  })
}

// 支出分页查询
export const expendFindPage = (data) => {
  return axios({
    url: '/mgmt/order/refunds',
    method: 'get',
    params: data
  })
}

// 支出总金额
export function expendSumAmount(data) {
  return axios({
    url: basePath + '/financeBoard/expend/sumamount',
    method: 'get',
    params: data
  })
}

// 退款订单申请分页
export const orderRefundApplyFindPage = (data, pageVo) => {
  return axios({
    url: basePath + '/orderRefundApply/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 同意退款申请
export function approveRefundApply(data) {
  return axios({
    url: `/mgmt/order/refunds/_approve`,
    method: 'post',
    data
  })
}

// 拒绝退款申请
export function rejectRefundApply(data) {
  return axios({
    url: `/mgmt/order/refunds/_reject`,
    method: 'post',
    data
  })
}

// 交易数据
export function findEachMonthIncomeSumamountGroupByYear(data) {
  return axios({
    url: basePath + '/financeBoard/income/findEachMonthIncomeSumamountGroupByYear',
    method: 'get',
    params: data
  })
}

// 订单来源
export function findIncomeAppointSumamountGroupByYear(data) {
  return axios({
    url: basePath + '/financeBoard/income/findIncomeAppointSumamountGroupByYear',
    method: 'get',
    params: data
  })
}

// 医生收益列表
export const earningsfindPage = (data, pageVo) => {
  return axios({
    url: basePath + '/doctor/doctorpage/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 会员结算列表
export const endfindPage = (data, pageVo) => {
  return axios({
    url: basePath + '/memberIncomeInfo/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 结算收益
export const statistics = (data) => {
  return axios({
    url: basePath + '/memberIncomeInfo/emptyIncome',
    method: 'post',
    data
  })
}

// 结算详细
export const findInfoByUniqueKey = (id) => {
  return axios({
    url: `/mgmt/order/refunds/${id}`,
    method: 'get'
  })
}
