import axios from '../../axios'
const basePath = '/core'
/*
 * 用户管理模块
 */

// 保存
export const save = data => {
  return axios({
    url: basePath + '/user/save',
    method: 'post',
    data
  })
}

// 编辑
export const edit = data => {
  return axios({
    url: basePath + '/user/edit',
    method: 'post',
    data
  })
}
// 删除
export const batchDelete = data => {
  return axios({
    url: basePath + '/user/delete',
    method: 'post',
    data
  })
}
// 删除整个账户信息(仅超级管理员可用)
export const Delete = data => {
  return axios({
    url: 'mgmt/member/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/user/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
// 查找用户的角色标识集合
export const findUserRoles = params => {
  return axios({
    url: '/mgmt/user/findUserRoles',
    method: 'get',
    params
  })
}

// 切换角色
export const changeRole = data => {
  return axios({
    url: '/mgmt/user/changeRole',
    method: 'post',
    data
  })
}

// 唯一性校验
export const checkExists = data => {
  return axios({
    url: basePath + '/user/checkExists',
    method: 'get',
    params: data
  })
}

// 获取当前登陆账号信息
export const getCurrentUser = () => {
  return axios({
    url: basePath + '/user/getCurrentUser',
    method: 'get'
  })
}

// 修改密码
export const updPassword = data => {
  return axios({
    url: basePath + '/user/updPassword',
    method: 'post',
    data
  })
}

// 重置密码
export const resetPassword = id => {
  return axios({
    url: basePath + '/user/resetPassword/' + id,
    method: 'post'
  })
}

// 获取access-keys
export const accessKeys = data => {
  return axios({
    url: '/mgmt/user/access-keys',
    method: 'post',
    data
  })
}
// 分页获取用户消息通知
export const notifications = data => {
  return axios({
    url: '/mgmt/notifications',
    method: 'get',
    params: data
  })
}

// 消息通知
export const messageCount = () => {
  return axios({
    url: '/mgmt/notifications/message-count',
    method: 'get'
  })
}

// 消息通知已读
export const notificationsRead = id => {
  return axios({
    url: `/mgmt/notifications/${id}/_read`,
    method: 'post'
  })
}

// 获取省市区
export const mapAreadata = () => {
  return axios({
    url: `/mgmt/areadata`,
    method: 'get'
  })
}
