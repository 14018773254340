/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-04-06 10:12:41
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-25 10:53:54
 * @FilePath: \drant-manager\src\http\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * 接口统一集成模块
 */
import * as login from './moudules/login/login'
import * as user from './moudules/base/user'
import * as dept from './moudules/base/dept'
import * as role from './moudules/base/role'
import * as menu from './moudules/base/menu'
import * as perm from './moudules/base/perm'
import * as member from './moudules/base/member'
import * as overseasAudit from './moudules/base/overseasAudit'
import * as log from './moudules/base/log'
import * as uploadfile from './moudules/base/uploadfile'
import * as tDepartment from './moudules/hospitalmanager/tDepartment'
import * as tHispital from './moudules/hospitalmanager/tHispital'
import * as tInsServicePoint from './moudules/hospitalmanager/tInsServicePoint'
import * as tInsDevice from './moudules/hospitalmanager/tInsDevice'
import * as tDoctor from './moudules/hospitalmanager/tDoctor'
import * as tInsInstitutionBusiness from './moudules/hospitalmanager/tInsInstitutionBusiness'
import * as tInsContacts from './moudules/hospitalmanager/tInsContacts'
import * as tPatientTag from './moudules/hospitalmanager/tPatientTag'

import * as dictionary from './moudules/base/dictionary'
import * as medicine from './moudules/medicine/medicine'
import * as medicinecategory from './moudules/medicine/medicinecategory'
import * as medicinefactory from './moudules/medicine/medicinefactory'
import * as medicineshowcategory from './moudules/medicine/medicineshowcategory'
import * as tChannel from './moudules/medicine/tChannel'
import * as tanakaShow from './moudules/medicine/tanakaShow'
import * as wallet from './moudules/medicine/wallet'
import * as channelmedicine from './moudules/medicine/channelmedicine'
import * as tDoctorChannel from './moudules/medicine/tDoctorChannel'
// import * as tDoctorMedicine from './moudules/medicine/tDoctorMedicine'
import * as operationlog from './moudules/base/operationlog'
import * as treatmentorder from './moudules/dprelationship/treatmentorder'
import * as treatmentrecord from './moudules/dprelationship/treatmentrecord'
import * as business from './moudules/base/business'
import * as order from './moudules/order/order'
import * as assistantInfo from './moudules/base/assistantInfo'
import * as chat from './moudules/base/chat'
import * as doctorassist from './moudules/base/doctorassist'
import * as userassist from './moudules/base/userassist'
import * as internetHospitalBase from './moudules/base/internetHospitalBase'
import * as chatsetcategory from './moudules/base/chatsetcategory'
import * as chatsetcontent from './moudules/base/chatsetcontent'
import * as doctoradvicecategory from './moudules/base/doctoradvicecategory'
import * as excelimport from './moudules/base/excelimport'
import * as inquirer from './moudules/inquirer/inquirer'
import * as videoinfo from './moudules/base/videoinfo'
import * as consultation from './moudules/consultation/consultation'
import * as financeboard from './moudules/base/financeboard'
import * as sysCmsContent from './moudules/article/sysCmsContent'
import * as healthCmsContent from './moudules/article/healthCmsContent'
import * as sysBannelContent from './moudules/base/sysBannelContent'
import * as agreement from './moudules/article/agreement'
import * as wxminicode from './moudules/base/wxminicode'
import * as doctor from './moudules/base/doctor'
import * as redpacket from './moudules/base/redpacket'
import * as pandect from './moudules/finance/pandect'
import * as excelBase from './moudules/excel/base'

import * as pharmacy from './moudules/medicine/pharmacy' // 药品接口
import * as offlineorder from './moudules/order/offlineorder' // 线下订单接口
import * as consignor from './moudules/base/consignor' // 线下药房发货员
import * as prescriptions from './moudules/medicine/prescriptions' // 公用药方
import * as generalDrugs from './moudules/hospitalmanager/generalDrugs' // 通用药品模块
import * as general from './moudules/base/general' // 通用下载模板
import * as settings from './moudules/base/settings' // 获取配置项
import * as patient from './moudules/hospitalmanager/patient' // 患者接口
import * as captcha from './moudules/captcha/captchaImg' // 获取图形验证码
import * as pulse from './moudules/pulse/pulseDiagnosis' // 获取图形验证码
import * as blacklists from './moudules/drug/blacklists' // 药品黑名单
import * as organization from './moudules/organization/info' // 机构信息
import * as verifyIdCard from './moudules/base/verifyIdCard' // 校验身份信息
import * as telemedicinecheck from './moudules/telemedicine/check' // 会诊审核
import * as feesSetting from './moudules/telemedicine/feesSetting' // 诊费设置
import * as chronicdisease from './moudules/basicsetting/chronicdisease' // 门诊慢性疾病
import * as medicalinsurancedrug from './moudules/basicsetting/medicalinsurancedrug' // 医保药品
import * as medicineSpecialDecoct from './moudules/basicsetting/medicineSpecialDecoct' // 中药特殊煎煮方法
import * as diagnosis from './moudules/basicsetting/diagnosis' // 医疗诊别
import * as runningwater from './moudules/finance/runningwater' // 财务流水
import * as inspectionReport from './moudules/inspectionReport/inspectionReport' // 检查记录
import * as fastCheck from './moudules/inspectionReport/fastCheck' // 快检查
import * as fileLog from './moudules/base/fileLog' // 归档日志
import * as freightSet from './moudules/base/freightSet' // 运费设置
import * as drugSalesStatistics from './moudules/drugSalesStatistics/index' // 药品销售统计
import * as chineseMedicineRoutine from './moudules/basicsetting/chineseMedicineRoutine' // 中药常规用量
// import * as project from './moudules/serverProject/project' // 服务项目
// 商城模块
import * as classify from './moudules/Store/classify' // 分类管理
import * as auditList from './moudules/Store/auditList' // 商品审核
import * as storeSelection from './moudules/Store/storeSelection' // 商城甄选
import * as storeRecommend from './moudules/Store/storeRecommend' // 商品推荐
import * as referrer from './moudules/Store/referrer' // 种草官

// 卡密管理
import * as CarmiManagement from './moudules/CarmiManagement/CarmiManagementList' // 卡密列表
// 机构管理
import * as organizationManager from './moudules/organizationManager/organizationList' // 机构列表
// 微信官方接口
import * as wx from './moudules/wx/index' // 获取AccessToken
// 助理师
import * as cloudAssistant from './moudules/cloudAssistant'
// 店主
import * as shopkeeper from './moudules/shopkeeper'
// 对公管理
import * as corporate from './moudules/corporate'
// 服务费
import * as serviceCharge from './moudules/serviceCharge'
// 企业会员
import * as healthGuruList from './moudules/healthGuruList'
// 服务站
import * as serviceStation from './moudules/serviceStation'
// 在线开通  企业会员/助理大使 
import * as onlineOpened from './moudules/onlineOpened'
// 引流管理
import * as drainageManage from './moudules/drainageManage'

// 第三方小程序管理
import * as miniprogramManage from './moudules/miniprogramManage'
// obs资源管理
import * as obs from './moudules/obs'
// 会员管理
import * as memberManage from './moudules/memberManage'

// 动态管理
// 话题分类管理
import * as topicClass from './moudules/ram/topicClass'
// 话题管理
import * as topic from './moudules/ram/topic'
// 动态列表
import * as ramList from './moudules/ram/ramList'
// 举报列表
import * as report from './moudules/ram/report'
// 黑名单列表
import * as blackList from './moudules/ram/blackList'

// 华稷ai辅诊管理
import * as aiAssist from './moudules/aiAssist/index'
// 设备管理
import * as device from './moudules/device/index'
// 月付管理
import * as payMonthly from './moudules/payMonthly/index'
// 运费模版
import * as freightTemplate from './moudules/freightTemplate/index'
import * as supplyChain from './moudules/supplyChain/index'

// 活动管理
import * as activityList from './moudules/activityList/index'

import * as sysAboutUs from './moudules/article/sysAboutUs' // 关于我们
import * as noticeBoard from './moudules/article/noticeBoard' // 关于我们
import * as specialDisease from './moudules/specialDisease/index' // 专病专科
import * as specialList from './moudules/specialDisease/specialList' // 专病专科
import * as specialSelection from './moudules/specialDisease/specialSelection' // 专病专科

import * as agencyList from './moudules/regionalAgency/agencyList' // 区域代理商管理

import * as merchantList from './moudules/paymentManagement/merchantList' // 支付中心 商户列表
import * as manageApp from './moudules/manageApp/index' // 小程序管理


// 默认全部导出
export default {
    chatsetcategory,
    payMonthly,
    device,
    report,
    blackList,
    aiAssist,
    ramList,
    topic,
    topicClass,
    obs,
    miniprogramManage,
    drainageManage,
    memberManage,
    cloudAssistant,
    shopkeeper,
    corporate,
    serviceCharge,
    healthGuruList,
    serviceStation,
    onlineOpened,
    wx,
    CarmiManagement,
    organizationManager,
    chatsetcontent,
    doctoradvicecategory,
    classify,
    login,
    user,
    dept,
    role,
    menu,
    perm,
    log,
    member,
    overseasAudit,
    uploadfile,
    dictionary,
    medicine,
    medicinecategory,
    medicinefactory,
    medicineshowcategory,
    tDepartment,
    tHispital,
    tInsServicePoint,
    tInsDevice,
    tDoctor,
    tInsInstitutionBusiness,
    tInsContacts,
    tPatientTag,
    tChannel,
    tanakaShow,
    wallet,
    channelmedicine,
    tDoctorChannel,
    // tDoctorMedicine,
    operationlog,
    treatmentorder,
    treatmentrecord,
    business,
    order,
    assistantInfo,
    chat,
    doctorassist,
    userassist,
    internetHospitalBase,
    excelimport,
    inquirer,
    videoinfo,
    consultation,
    financeboard,
    sysCmsContent,
    healthCmsContent,
    sysBannelContent,
    wxminicode,
    doctor,
    redpacket,
    pandect,
    excelBase,
    agreement,
    pharmacy, // 药品接口
    offlineorder, // 线下订单列表
    consignor, // 线下发货员
    prescriptions, // 公用药方
    generalDrugs, // 通用药品模块
    general, // 通用下载模板
    settings, // 配置项
    patient, // 患者接口
    captcha, // 获取图形验证码
    pulse, // 获取脉诊报告
    blacklists, // 药品黑名单
    organization, // 机构信息
    verifyIdCard, // 校验身份信息
    telemedicinecheck, // 会诊审核
    feesSetting, // 诊费设置
    chronicdisease, // 门诊慢性疾病
    medicalinsurancedrug, // 医保药品
    diagnosis, // 医疗诊别
    runningwater, // 财务流水
    inspectionReport, // 检查报告
    fastCheck,
    activityList, //活动弹窗
    medicineSpecialDecoct,
    fileLog,
    drugSalesStatistics,
    freightSet,
    chineseMedicineRoutine,
    freightTemplate,
    supplyChain,
    auditList,
    storeSelection,
    storeRecommend,
    referrer,
    sysAboutUs,
    noticeBoard,
    specialDisease,
    specialList,
    specialSelection,
    // project
    agencyList,
    merchantList,
    manageApp
}