
const state = {
  freightChange: false,
  menuClick: false,
  nextPath: null
}

const mutations = {
  FREIGHT_CHANGE: (state, freightChange) => {
    state.freightChange = freightChange
  },
  MENU_CLICK: (state, menuClick) => {
    state.menuClick = menuClick
  },
  NEXT_PATH: (state, nextPath) => {
    state.nextPath = nextPath
  }
}
export default {
  namespaced: true,
  state,
  mutations
  // actions
}

