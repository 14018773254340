import axios from '../../axios'
/*
 * 系统登录模块
 */

// 登录
export const login = (data, header) => {
  return axios({
    url: '/mgmt/login',
    method: 'post',
    data
  }, { headers: {
    'X-Captcha-Type': 'image',
    'X-Captcha-Key': header.key,
    'X-Captcha-Value': header.captcha
  }})
}

// 登出
export const logout = () => {
  return axios({
    url: '/mgmt/login/logout',
    method: 'post'
  })
}

// 获取验证码

export const getCode = (data) => {
  return axios({
    url: '/mgmt/login/getCheckCode',
    method: 'get',
    params: data
  })
}

// 启动人脸核身URL

export const getFace = (data, token) => {
  return axios({
    url: '/mgmt/faceid/_startFaceidUrl',
    method: 'get',
    params: data
  }, { headers: {
    'Auth-token': token
  }})
}

// 获取人脸识别结果

export const getFaceResult = (data, token) => {
  return axios({
    url: '/mgmt/faceid/_validFaceidResult',
    method: 'get',
    params: data
  }, { headers: {
    'Auth-token': token
  }})
}

// 获取短信验证码

export const getNoteCode = (data) => {
  return axios({
    url: '/mgmt/tfa/sms/_send',
    method: 'post',
    data
  })
}

// 获取短信验证码后登陆

export const toLogin = (data) => {
  return axios({
    url: '/mgmt/tfa/sms/_verify',
    method: 'post',
    data
  })
}
