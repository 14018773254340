import axios from '../../axios'

// 添加区域代理商
export const agentAdd = data => {
    return axios({
        url: 'mgmt/region/agent/add',
        method: 'post',
        data
    })
}

// 编辑 区域代理商
export const editAgent = data => {
    return axios({
        url: 'mgmt/region/agent/update',
        method: 'post',
        data
    })
}

// 获取代理商列表
export const agentGetPage = data => {
    return axios({
        url: 'mgmt/region/agent/getPage',
        method: 'get',
        params: data
    })
}