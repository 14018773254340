var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile" }, [
    _c("div", {}, [
      _c("div", { staticClass: "mai-item stability" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("测量稳定指数：" + _vm._s(_vm.data.score) + "%"),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-top gray" }, [
        _c("div", { staticClass: "print-user" }, [
          _c("div", {
            staticClass: "portrait",
            staticStyle: {
              "background-image":
                "url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/wxmini/static/common/huanzhetx.png')",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.data.time))]),
            _vm._v(" "),
            _c("div", { staticClass: "hand" }, [
              _c("span", { staticStyle: { display: "none" } }, [
                _vm._v("Both hands"),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.data.hand == 2 ? "双手" : "单手")),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mai-item mai-desc" }, [
      _c(
        "div",
        { staticClass: "desc" },
        [
          _c("h1", { staticClass: "title" }, [
            _c("div", [_vm._v("总体脉象：" + _vm._s(_vm.talSummary))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "heart-rate img-heart-rate" }, [
            _vm._v("心率：" + _vm._s(_vm.data.heartrate) + "次/分钟"),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("注释:")]),
          _vm._v(" "),
          _vm._l(_vm.data.mais.summary_infos, function (item, index) {
            return _c("p", { key: index }, [
              _vm._v(_vm._s(item.explaination_cn)),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(4),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mai-item mai-3d" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("左手脉象：" + _vm._s(_vm.branchResult.left.total)),
        ]),
        _vm._v(" "),
        _c("Total3d", {
          attrs: {
            feature: _vm.feature.left,
            width: _vm.channelWidth,
            "total-mai": _vm.totalMai.title,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {}, [
      _c(
        "div",
        { staticClass: "mai-item mai-branch" },
        [
          _vm._m(5),
          _vm._v(" "),
          _c("BranchGroup", {
            attrs: {
              "branch-result": _vm.branchResult.left,
              feature: _vm.feature.left,
              "width-bar": 150,
              width3d: 150 * 2.3,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hole-body", staticStyle: { "margin-top": "3rem" } },
            [
              _c("div", {}, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "mai-all" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mai-img",
                        staticStyle: { position: "relative" },
                        attrs: {
                          src: _vm.data.data_left.heatMap.heatmap_img_cun_url,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            left: "8%",
                            color: "white",
                          },
                        },
                        [_vm._v("左寸")]
                      ),
                      _vm._v(" "),
                      _vm._m(8),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mai-all" }, [
                      _vm._m(9),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mai-img",
                        staticStyle: { position: "relative" },
                        attrs: {
                          src: _vm.data.data_left.heatMap.heatmap_img_guan_url,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            left: "8%",
                            color: "white",
                          },
                        },
                        [_vm._v("左关")]
                      ),
                      _vm._v(" "),
                      _vm._m(10),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mai-all" }, [
                      _vm._m(11),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mai-img",
                        staticStyle: { position: "relative" },
                        attrs: {
                          src: _vm.data.data_left.heatMap.heatmap_img_chi_url,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            left: "8%",
                            color: "white",
                          },
                        },
                        [_vm._v("左尺")]
                      ),
                      _vm._v(" "),
                      _vm._m(12),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "timeWidth",
                      staticStyle: { width: "88%" },
                      attrs: {
                        src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/waiya.png",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
                  _vm._m(13),
                  _vm._v(" "),
                  _vm.isgetLeftHeatmap
                    ? _c(
                        "div",
                        { staticClass: "chart-branch-3d2" },
                        [_c("Chart", { attrs: { x: _vm.xLeftValue } })],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mai-item mai-3d" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("右手脉象：" + _vm._s(_vm.branchResult.right.total)),
        ]),
        _vm._v(" "),
        _c("Total3dRight", {
          attrs: {
            feature: _vm.feature.right,
            width: _vm.channelWidth,
            "total-mai": _vm.totalMai.title,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {}, [
      _c(
        "div",
        { staticClass: "mai-item mai-branch" },
        [
          _vm._m(14),
          _vm._v(" "),
          _c("BranchGroup", {
            attrs: {
              "branch-result": _vm.branchResult.right,
              feature: _vm.feature.right,
              "width-bar": 150,
              width3d: 150 * 2.3,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hole-body", staticStyle: { "margin-top": "3rem" } },
            [
              _c("div", {}, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _vm._m(15),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "mai-all" }, [
                      _vm._m(16),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mai-img",
                        staticStyle: { position: "relative" },
                        attrs: {
                          src: _vm.data.data_right.heatMap.heatmap_img_cun_url,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            left: "8%",
                            color: "white",
                          },
                        },
                        [_vm._v("右寸")]
                      ),
                      _vm._v(" "),
                      _vm._m(17),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mai-all" }, [
                      _vm._m(18),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mai-img",
                        staticStyle: { position: "relative" },
                        attrs: {
                          src: _vm.data.data_right.heatMap.heatmap_img_guan_url,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            left: "8%",
                            color: "white",
                          },
                        },
                        [_vm._v("右关")]
                      ),
                      _vm._v(" "),
                      _vm._m(19),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mai-all" }, [
                      _vm._m(20),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mai-img",
                        staticStyle: { position: "relative" },
                        attrs: {
                          src: _vm.data.data_right.heatMap.heatmap_img_chi_url,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            left: "8%",
                            color: "white",
                          },
                        },
                        [_vm._v("右尺")]
                      ),
                      _vm._v(" "),
                      _vm._m(21),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "timeWidth",
                      staticStyle: { width: "88%" },
                      attrs: {
                        src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/waiya.png",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
                  _vm._m(22),
                  _vm._v(" "),
                  _vm.isgetRightHeatmap
                    ? _c(
                        "div",
                        { staticClass: "chart-branch-3d2" },
                        [_c("Chart", { attrs: { x: _vm.xRightValue } })],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mai-item mai-branch" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      六经辨证\n      "),
          _c(
            "span",
            { staticClass: "show-img", on: { click: _vm.showImgFn } },
            [_vm._v("查看大图")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: { "channel-big-img": _vm.isShowImg } },
          [
            _c("i", {
              staticClass: "icon-close",
              style: { display: _vm.isShowImg ? "initial" : "none" },
              on: { click: _vm.showImgFn },
            }),
            _vm._v(" "),
            _c("ChannelChart", {
              attrs: {
                channels: _vm.channelReault,
                heartrate: _vm.heartrate,
                summary: _vm.summary,
                width: _vm.isShowImg ? "700" : _vm.channelWidth,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("ChannelParagraph", {
          attrs: { channels: _vm.channelReault, symptoms: _vm.symptomsResult },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mai-item" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("气血津液")]),
        _vm._v(" "),
        _c("QxjyChart", { attrs: { value: _vm.qxjyResult.level } }),
        _vm._v(" "),
        _c("QxjyParagraph", { attrs: { qxjy: _vm.qxjyResult } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mai-item" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("邪气")]),
        _vm._v(" "),
        _c("HeresyIcon", { attrs: { value: _vm.heresyResult.value } }),
        _vm._v(" "),
        _c("HeresyParagraph", { attrs: { heresy: _vm.heresyResult } }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mai-item" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("养生建议")]),
      _vm._v(" "),
      _c("div", { staticClass: "paragraph-wrap" }, [
        _c("div", { staticClass: "paragraph" }, [
          _c("h2", { staticClass: "sub-title" }, [_vm._v("饮食")]),
          _vm._v(" "),
          _c("p", { staticClass: "paragraph-p" }, [
            _vm._v(_vm._s(_vm.healthAdvice.new_food_propose)),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "sub-title" }, [_vm._v("运动")]),
          _vm._v(" "),
          _c("p", { staticClass: "paragraph-p" }, [
            _vm._v(_vm._s(_vm.healthAdvice.new_sport_propose)),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "sub-title" }, [_vm._v("生活")]),
          _vm._v(" "),
          _c("p", { staticClass: "paragraph-p" }, [
            _vm._v(_vm._s(_vm.healthAdvice.other_propose)),
          ]),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "sub-title", staticStyle: { display: "none" } },
            [_vm._v("Nothing")]
          ),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "sub-title", staticStyle: { display: "none" } },
            [_vm._v("无")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "paragraph-wrap" }, [
      _c("div", { staticClass: "paragraph" }, [
        _c("p", { staticClass: "paragraph-p" }, [
          _vm._v("若稳定指数低于60%，脉象结果不建议采用，可重新测量。"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "paragraph-p" }, [
          _vm._v("注意测量过程中避免说话、身体晃动等。"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name" }, [
      _c("strong"),
      _vm._v(" "),
      _c("span"),
      _vm._v(" "),
      _c("span", { staticStyle: { display: "none" } }),
      _vm._v(" "),
      _c("span"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "title", staticStyle: { display: "none" } },
      [
        _c("h1", [_vm._v("Health report")]),
        _vm._v(" "),
        _c("h2", [_vm._v("Intelligent pulse diagnosis system")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c(
        "div",
        { staticStyle: { "font-size": "26px", "font-weight": "700" } },
        [_vm._v("健康报告")]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "text-align": "right" } }, [
        _vm._v("智能脉诊系统"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-hand" }, [
      _c("div", { staticClass: "img-hand right" }),
      _vm._v(" "),
      _c("div", { staticClass: "img-hand left" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "title" }, [
      _c("span", [_vm._v("左手分部脉象")]),
      _vm._v(" "),
      _c("div", { staticClass: "branch-legend" }, [
        _c("div", { staticClass: "unhealthy" }, [_vm._v("异常区")]),
        _vm._v(" "),
        _c("div", [_vm._v("健康区")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "handimg" }, [
      _c("img", {
        staticClass: "mypic",
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/lefthand.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-font" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/cun_top.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-color-image" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-font" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/gun_top.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-color-image" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-font" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/chi_top.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-color-image" }, [
      _c("img", {
        staticClass: "mai-color-img",
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "far" }, [
      _c("div", [
        _c("div", [_vm._v("外")]),
        _vm._v(" "),
        _c("div", [_vm._v("压")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "title" }, [
      _c("span", [_vm._v("右手分部脉象")]),
      _vm._v(" "),
      _c("div", { staticClass: "branch-legend" }, [
        _c("div", { staticClass: "unhealthy" }, [_vm._v("异常区")]),
        _vm._v(" "),
        _c("div", [_vm._v("健康区")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "handimg" }, [
      _c("img", {
        staticClass: "mypic",
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/lefthand.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-font" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/cun_top.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-color-image" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-font" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/gun_top.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-color-image" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-font" }, [
      _c("img", {
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/chi_top.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mai-color-image" }, [
      _c("img", {
        staticClass: "mai-color-img",
        attrs: {
          src: "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "far" }, [
      _c("div", [
        _c("div", [_vm._v("外")]),
        _vm._v(" "),
        _c("div", [_vm._v("压")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }