import axios from '../../axios'

// 动态-列表
export const getlist = data => {
  return axios({
    url: '/mgmt/trends/content/getList',
    method: 'get',
    params: data
  })
}
// 动态-详情
export const contentdetail = data => {
  return axios({
    url: '/mgmt/trends/content/detail',
    method: 'get',
    params: data
  })
}
// 动态-删除
export const contentdlet = data => {
  return axios({
    url: '/mgmt/trends/content/delete/' + data,
    method: 'post'
    // data
  })
}
// 动态-上架/下架
export const contentoff = data => {
  return axios({
    url: '/mgmt/trends/content/off',
    method: 'post',
    data
  })
}
// 审核文章
export const examineTrends = data => {
  return axios({
    url: '/mgmt/trends/content/audit',
    method: 'post',
    data
  })
}
