import axios from '../../axios'


// 后台管理员使用服务费列表
export function findPage(data) {
  return axios({
    url: '/mgmt/service/charge/settle/getPage',
    method: 'get',
    params: data
  })
}
// 机构使用服务费列表
export function findPageAgent(data) {
  return axios({
    url: '/mgmt/service/charge/settle/getPageByInsNo',
    method: 'get',
    params: data
  })
}
// 审核
export function Update(data) {
  return axios({
    url: '/mgmt/service/charge/settle/audit',
    method: 'post',
    data
  })
}

