<template>
  <div class="cont-de">
    <template v-if="data.consultationNo">
      <div class="cont-ee">
        <div v-if="data.flowStatus >= 6 && type !== '1'" class="img-yz">
          <div class="img-yz-i">
            <img src="/img/end.png" alt="" />
          </div>
        </div>
        <div class="cont-top">
          <div class="title-de">
            专家联合会诊
            <span v-if="type === '1'" :class="{ back10: data.approveStatus === 1, back20: data.approveStatus === 2, back30: data.approveStatus === 3 }">
              <template v-if="data.approveStatus === 1">
                待审核
              </template>
              <template v-if="data.approveStatus === 2">
                已通过
              </template>
              <template v-if="data.approveStatus === 3">
                已拒绝
              </template>
            </span>
            <span v-else class="back10">
              {{ data.flowStatus >= 6 ? '已备案' : '待备案' }}
            </span>
            <span class="t-text">会诊单编号：{{ data.consultationNo }}</span>
          </div>
          <div class="doctor-n">
            <div class="doctor-head">
              <img :src="data.doctorHeadPortrait" alt="" />
            </div>
            {{ data.doctorName }}医生申请
            <img :src="data.doctorSign" alt="" style="height: 38px;margin-top: -10px;" />
          </div>
        </div>
        <div class="cont-top">
          <el-descriptions :column="1">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-bank-card" />
                患者信息
              </div>
            </template>
            <el-descriptions-item label="患者">
              {{ data.patientName }}，{{ data.patientGender ? `${data.patientGender === 1 ? '男' : '女'}` : '-' }}，{{ data.patientAge ? `${data.patientAge}` : '-' }}
            </el-descriptions-item>
            <el-descriptions-item label="经治科室">{{ data.doctorDeptName || '-' }}</el-descriptions-item>
            <el-descriptions-item label="病情描述">{{ data.diseaseDescription || '-' }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="1" :colon="false">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-help" />
                当前诊断
              </div>
            </template>
            <el-descriptions-item>{{ data.diagnoseNames ? `${data.diagnoseNames.join()}` : '-' }}</el-descriptions-item>
          </el-descriptions>
          <div class="cont-tab">
            <span class="label">会诊类型：</span>
            普通会诊
          </div>
          <div class="cont-tab">
            <span class="label">申请会诊原因：</span>
            {{ data.applyReasonTexts ? `${data.applyReasonTexts.join()}` : '-' }}
          </div>
          <div class="cont-tab">
            <span class="label">会诊目的：</span>
            {{ data.consultationPurposeTexts ? `${data.consultationPurposeTexts.join()}` : '-' }}
          </div>
          <div v-if="data.approveStatus === 1 && groupDoctorList.length < 1" class="cont-tab">
            <span class="label">经治医生：</span>
            <div class="doctor-card">
              <div class="doctor-card-cont">
                <div class="doctor-card-head">
                  <img :src="data.doctorHeadPortrait" alt="" />
                </div>
                <div>
                  <div class="doctor-card-text">
                    <span>{{ data.doctorName || '-' }}</span>
                    {{ data.doctorPositionName || '-' }}
                  </div>
                  <div class="doctor-card-text">
                    <span>{{ data.doctorDeptName || '-' }}</span>
                    <span>|</span>
                    {{ data.doctorHisName || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-descriptions v-else :column="1" :colon="false">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-help" />
                会诊医生
              </div>
            </template>
            <el-descriptions-item>
              <div class="doctor-list">
                <div v-for="(item, index) in groupDoctorList" :key="index" class="doctor-card">
                  <div v-if="data.doctorId === item.doctorId" class="doctor-card-tab">经治医生</div>
                  <div class="doctor-card-cont">
                    <div class="doctor-card-head">
                      <img :src="item.doctorHeadPortrait" alt="" />
                    </div>
                    <div>
                      <div class="doctor-card-text">
                        <span>{{ item.doctorName || '-' }}</span>
                        {{ item.doctorPositionName || '-' }}
                      </div>
                      <div class="doctor-card-text">
                        <span>{{ item.doctorDeptName || '-' }}</span>
                        <span>|</span>
                        {{ item.doctorHisName || '-' }}
                      </div>
                    </div>
                  </div>
                  <div v-if="type !== '1'" class="doctor-card-but">
                    <el-button type="text" size="mini" @click="handleSeeReport(item)">{{ data.doctorId === item.doctorId ? '治疗方案' : '会诊报告' }}</el-button>
                  </div>
                </div>
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <div v-if="data.approveStatus !== 1 && (data.flowStatus < 6 || type === '1')" class="img-yz">
            <div v-if="data.approveStatus === 2" class="img-yz-i">
              <img src="/img/pass.png" alt="" />
            </div>
            <div v-if="data.approveStatus === 3" class="img-yz-i">
              <img src="/img/no-pass.png" alt="" />
            </div>
          </div>
          <el-descriptions :column="1" :colon="false">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-help" />
                会诊科室
              </div>
            </template>
            <el-descriptions-item>
              {{ data.consultationDeptNames ? `${data.consultationDeptNames.join()}` : '-' }}
              <el-button v-if="data.approveStatus === 1 && type === '1'" size="mini" type="primary" plain @click="handleChoice(data.consultationDeptNos)">选择会诊医生</el-button>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="1">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-help" />
                会诊时间
              </div>
            </template>
            <el-descriptions-item label="开始时间">{{ getLocalTime(data.meetingStartTime) }}</el-descriptions-item>
            <el-descriptions-item label="预计时长">{{ data.meetingDuration || '0' }}分钟</el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="1">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-help" />
                会诊费用
              </div>
            </template>
            <el-descriptions-item label="会诊费用">{{ data.consultationFee || 0 }}元</el-descriptions-item>
          </el-descriptions>
          <el-descriptions v-if="data.approveStatus > 1" :column="1" :colon="false">
            <template slot="title">
              <div class="des-t">
                <i class="el-icon-help" />
                患者签字远程会诊申请单
              </div>
            </template>
            <el-descriptions-item>
              <el-button v-if="data.approveStatus === 2" size="mini" type="primary" plain @click="handleInventory">远程会诊申请单</el-button>
              <template v-else>
                无
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions v-if="data.approveStatus === 2" :column="1" :colon="false">
            <template slot="title">
              <div class="des-t" style="display:flex">
                <i class="el-icon-help" />
                <span>审核人</span>
              </div>
            </template>
            <el-descriptions-item>
              <div v-if="data.approveStatus === 2" size="mini" type="primary" plain>
                {{ data.approveDoctorName }}
                <img :src="data.approveDoctorSign" alt="" style="height: 38px;margin-top: -10px;" />
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <el-descriptions :column="1" :colon="false">
          <template slot="title">
            <div class="des-t">
              <i class="el-icon-help" />
              会诊进程
            </div>
          </template>
          <el-descriptions-item>
            <el-timeline v-if="data.logList.length > 0">
              <el-timeline-item v-for="(item, index) in data.logList" :key="index" :timestamp="item.createTime" placement="top">
                <div class="timeline-card">
                  {{ item.operator }}
                  <span>{{ item.operateContent }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div v-if="data.approveStatus === 1 && type === '1'" class="check-but">
        <el-button @click="handleRefuse">不通过</el-button>
        <el-button type="primary" @click="handleSendRecords">备案并发送患者</el-button>
      </div>
    </template>

    <el-empty v-else />
    <canvasSign ref="canvassign" @cb="getSign" />
    <el-dialog title="选择医生" :visible.sync="isShowDoctorDialog" width="1124px" class="dzdialog" append-to-body>
      <div class="doctorlist">
        <div class="doctorlist-item">
          <el-input v-model="filterText" placeholder="输入医生名称搜索" />
          <div class="doctor-tree">
            <el-tree
              ref="tree"
              :data="doctorArr"
              :props="defaultProps"
              show-checkbox
              node-key="id"
              :filter-node-method="filterNode"
              :render-content="renderContent"
              default-expand-all
              @check="handleNodeClick"
            />
          </div>
        </div>
        <div class="doctorlist-item">
          <div class="doctorlist-select-top">已选择{{ selectArr.length }}/3</div>
          <ul v-if="selectArr.length > 0" class="doctorlist-select-list">
            <li v-for="(item, index) in selectArr" :key="index">
              <div class="doctorlist-select-list-i">{{ item.doctorName }}</div>
              <div class="doctorlist-select-list-i">{{ item.positionCategory_dictName }}</div>
              <div class="doctorlist-select-list-i">{{ item.departmentName }}</div>
              <div class="doctorlist-select-list-i">会诊费￥{{ item.consultationFee }}/次</div>
              <i class="doctorlist-select-list-icon el-icon-error" @click="selectDel(item.id)" />
            </li>
          </ul>
          <el-empty v-else description="请选择医生" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowDoctorDialog = false">取 消</el-button>
        <el-button type="primary" @click="headleConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核详情" :visible.sync="isShowCheckDialog" width="500px" append-to-body>
      <el-form label-width="100px" :model="formObj">
        <el-form-item label="审核结果:">
          不通过
        </el-form-item>
        <el-form-item label="驳回说明:" required>
          <el-input v-model="formObj.rejectReason" type="textarea" :rows="2" style="width: 300px" />
        </el-form-item>
      </el-form>
      <!-- <el-descriptions :column="1">
        <el-descriptions-item label="审核结果">
          不通过
        </el-descriptions-item>
        <el-descriptions-item label="驳回说明" required>
          <el-input
            v-model="rejectReason"
            type="textarea"
            :rows="2"
            style="width: 300px"
          />
        </el-descriptions-item>
      </el-descriptions> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowCheckDialog = false">取 消</el-button>
        <el-button type="primary" @click="headleSendRefuse">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="远程会诊申请单" :visible.sync="isShowInventoryDialog" width="700px" append-to-body>
      <div class="inventory-c">
        <el-descriptions :column="2">
          <el-descriptions-item label="经治科室">{{ data.doctorDeptName || '-' }}</el-descriptions-item>
          <el-descriptions-item label="患者姓名">{{ data.patientName }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{ data.patientGender ? `${data.patientGender === 1 ? '男' : '女'}` : '-' }}</el-descriptions-item>
          <el-descriptions-item label="年龄">{{ data.patientAge ? `${data.patientAge}` : '-' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1">
          <el-descriptions-item label="病情描述">{{ data.diseaseDescription || '-' }}</el-descriptions-item>
          <el-descriptions-item label="当前诊断">{{ data.diagnoseNames ? `${data.diagnoseNames.join()}` : '-' }}</el-descriptions-item>
          <el-descriptions-item label="会诊类型">普通会诊</el-descriptions-item>
          <el-descriptions-item label="申请会诊原因">{{ data.applyReasonTexts ? `${data.applyReasonTexts.join()}` : '-' }}</el-descriptions-item>
          <el-descriptions-item label="会诊目的">{{ data.consultationPurposeTexts ? `${data.consultationPurposeTexts.join()}` : '-' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1" :colon="false">
          <template slot="title">
            <div class="des-t">会诊医生</div>
          </template>
          <el-descriptions-item>
            <div class="doctor-list">
              <div v-for="(item, index) in groupDoctorList" :key="index" class="doctor-card">
                <div v-if="data.doctorId === item.doctorId" class="doctor-card-tab">经治医生</div>
                <div class="doctor-card-cont">
                  <div class="doctor-card-head">
                    <img :src="item.doctorHeadPortrait" alt="" />
                  </div>
                  <div>
                    <div class="doctor-card-text">
                      <span>{{ item.doctorName || '-' }}</span>
                      {{ item.doctorPositionName || '-' }}
                    </div>
                    <div class="doctor-card-text">
                      <span>{{ item.doctorDeptName || '-' }}</span>
                      <span>|</span>
                      {{ item.doctorHisName || '-' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <div class="inventory-qm">
          <div>
            患者（或委托人） 签名：
            <img v-if="data.patientSign" :src="data.patientSign" alt="" />
            <template v-else>
              -
            </template>
          </div>
          <div>日期：{{ data.patientSignTime || '-' }}</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="reportData.doctorId === data.doctorId ? '远程会诊治疗方案' : '远程会诊报告'" :visible.sync="isShowReportDialog" width="700px" append-to-body>
      <div class="h-item">
        <el-descriptions :column="1">
          <el-descriptions-item label="会诊单编号">{{ data.consultationNo || '-' }}</el-descriptions-item>
          <el-descriptions-item label="会诊时间">{{ getLocalTime(data.meetingStartTime) }} - {{ getLocalTime(data.meetingEndTime) }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="h-item">
        <el-descriptions :column="1">
          <el-descriptions-item label="患者">
            {{ data.patientName }}，{{ data.patientGender ? `${data.patientGender === 1 ? '男' : '女'}` : '-' }}，{{ data.patientAge ? `${data.patientAge}` : '-' }}
          </el-descriptions-item>
          <el-descriptions-item label="病情描述">{{ data.diseaseDescription || '-' }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="h-item" style="margin-bottom: 14px">
        <div class="h-item-c">
          <div class="h-label">{{ reportData.doctorId === data.doctorId ? '治疗方案：' : '会诊意见：' }}</div>
          <div>
            {{ reportData.consultationReport || '-' }}
          </div>
          <div class="sign-c">
            <div class="des-t">
              <i class="el-icon-help" />
              医生签名
            </div>
            <img :src="reportData.doctorSign" alt="" />
          </div>
        </div>
      </div>
      <el-descriptions :column="1">
        <el-descriptions-item :label="reportData.doctorId === data.doctorId ? '经治医生' : '报告医生'">{{ reportData.doctorName }}</el-descriptions-item>
        <el-descriptions-item label="提交时间">{{ reportData.reportTime || '-' }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
import canvasSign from '@/components/canvasSign'
export default {
  components: { canvasSign },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      isShowDoctorDialog: false,
      filterText: '',
      doctorList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      pageVo: {
        pageNum: 1,
        pageSize: 30
      },
      officeList: [],
      selectArr: [],
      isShowCheckDialog: false,
      formObj: {
        rejectReason: ''
      },
      isShowInventoryDialog: false,
      isShowReportDialog: false,
      reportData: {}
    }
  },
  computed: {
    doctorArr() {
      // 最终计算出的树结构列表状态
      let _arr = []
      if (this.selectArr.length >= 3) {
        this.doctorList.forEach(i => {
          i.children.forEach(e => {
            if (!this.getSome(e)) {
              e.disabled = true
            }
          })
        })
        _arr = this.doctorList
      } else {
        this.doctorList.forEach(i => {
          i.children.forEach(e => {
            e.disabled = false
          })
        })
        _arr = this.doctorList
      }
      return _arr
    },
    groupDoctorList() {
      // 会诊列表显示的医生
      let _arr = []
      const _arr2 = [
        {
          doctorId: this.data.doctorId,
          doctorHeadPortrait: this.data.doctorHeadPortrait,
          doctorPositionName: this.data.doctorDeptName,
          doctorName: this.data.doctorName,
          doctorHisName: this.data.doctorHisName,
          doctorDeptName: this.data.doctorDeptName,
          consultationReport: this.data.treatmentPlan,
          doctorSign: this.data.doctorSign,
          reportTime: this.data.endTime
        }
      ]
      if (this.data.approveStatus === 1) {
        if (this.data.doctorList && this.data.doctorList.length > 0) {
          _arr = _arr2.concat(this.data.doctorList)
        } else {
          _arr = []
        }
      } else {
        if (this.data.doctorList && this.data.doctorList.length > 0) {
          _arr = _arr2.concat(this.data.doctorList)
        } else {
          _arr = _arr2
        }
      }
      return _arr
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    getSome(item) {
      const boo = this.selectArr.some(i => {
        return i.id === item.id
      })
      return boo
    },
    handleChoice(arr) {
      if (arr && arr.length > 0) {
        this.doctorList = []
        this.selectArr = []
        const _list = []
        this.data.consultationDeptNames.forEach((i, index) => {
          // 循环整理出科室
          _list.push({
            departmentName: i,
            departmentNo: arr[index]
          })
        })
        this.officeList = _list
        console.log(_list)
        const params = {
          deptNos: arr.join()
        }
        this.$api.telemedicinecheck.doctorFindPage(params).then(res => {
          if (res.code === 200) {
            const doctorArr = res.data.filter(item => item.doctorId !== this.data.doctorId)
            if (doctorArr.length > 0) {
              console.log(res)
              let _defaultArr = JSON.parse(JSON.stringify(this.officeList))
              _defaultArr.forEach((i, index) => {
                i.id = index
                i.label = '' // 由于父级不接入搜索，置空
                i.children = []
                i.isParent = true
                i.disabled = true
                doctorArr.forEach(e => {
                  if (i.departmentNo === e.departmentNo) {
                    console.log(i.departmentNo, e)
                    e.label = e.doctorName
                    e.disabled = false
                    i.children.push(e)
                  }
                })
              })
              _defaultArr = _defaultArr.filter(item => item.children.length > 0)
              this.doctorList = _defaultArr
              this.isShowDoctorDialog = true
            } else {
              this.$message({ message: '当前科室没有医生！', type: 'info' })
            }
          }
        })
      } else {
        this.$message({ message: '没有科室', type: 'error' })
      }
      // this.isShowDoctorDialog = true
    },
    filterNode(value, data) {
      // 模糊查询树结构
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleNodeClick(data, checked, node) {
      // 当勾选动作触发时执行动作
      let selectArr = JSON.parse(JSON.stringify(this.$refs.tree.getCheckedNodes()))
      selectArr = selectArr.filter(item => !item.isParent)
      this.selectArr = selectArr
      console.log(selectArr)
    },
    headleConfirm() {
      if (this.selectArr && this.selectArr.length > 0) {
        let _sum = 0
        const _deoctrArr = []
        this.selectArr.forEach(i => {
          _sum += i.consultationFee
          const _item = {
            doctorId: i.doctorId,
            doctorHeadPortrait: i.headPortrait,
            doctorPositionName: i.positionCategory_dictName,
            doctorName: i.doctorName,
            doctorHisName: i.hispitalName,
            doctorDeptName: i.departmentName
          }
          _deoctrArr.push(_item)
        })
        this.data.doctorList = _deoctrArr
        this.data.consultationFee = _sum
        this.isShowDoctorDialog = false
      } else {
        this.$message({ message: '至少选择一个医生', type: 'error' })
      }
    },
    renderContent(h, { node, data, store }) {
      // 自定义树列表的显示
      console.log(node)
      let _htm
      if (node.data.isParent) {
        _htm = (
          <span class='custom-tree-node'>
            <span>{node.data.departmentName}</span>
          </span>
        )
      } else {
        _htm = (
          <div class='custom-tree-doctor'>
            <div class='custom-tree-doctor-item'>{node.data.doctorName}</div>
            <div class='custom-tree-doctor-item'>{node.data.positionCategory_dictName}</div>
            <div class='custom-tree-doctor-item'>会诊费￥{node.data.consultationFee}/次</div>
          </div>
        )
      }
      return _htm
    },
    selectDel(id) {
      // 删除已选列表的操作
      this.selectArr = this.selectArr.filter(item => item.id !== id)
      this.$refs.tree.setCheckedNodes(this.selectArr)
    },
    getLocalTime(timestamp) {
      // 时间转换
      const time = new Date(timestamp)
      const year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      let hours = time.getHours()
      let minute = time.getMinutes()
      let second = time.getSeconds()

      if (month < 10) {
        month = '0' + month
      }
      if (date < 10) {
        date = '0' + date
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      if (second < 10) {
        second = '0' + second
      }
      const _date = year + '-' + month + '-' + date + ' ' + hours + ':' + minute
      return _date
    },
    // 获取签名
    getSign(signUrl) {
      const _arr = []
      this.data.doctorList.forEach(i => {
        _arr.push(i.doctorId)
      })
      const _data = {
        consultationNo: this.data.consultationNo,
        approveDoctorSign: signUrl,
        doctorList: _arr
      }
      this.$api.telemedicinecheck.passCheck(_data).then(res => {
        if (res.code === 200) {
          this.$message({ message: '操作成功', type: 'success' })
          const _newTime = new Date().getTime()
          const timeDiff = this.data.meetingStartTime - _newTime
          if (timeDiff > 0) {
            const days = Math.floor(timeDiff / (24 * 3600 * 1000)) // 计算出天数
            const leavel1 = timeDiff % (24 * 3600 * 1000) // 计算天数后剩余的时间
            const hours = Math.floor(leavel1 / (3600 * 1000)) // 计算天数后剩余的小时数
            const leavel2 = timeDiff % (3600 * 1000) // 计算剩余小时后剩余的毫秒数
            const minutes = Math.floor(leavel2 / (60 * 1000)) // 计算剩余的分钟数
            console.log(days, leavel1, hours, leavel2, minutes)
            if (days <= 0 && hours < 12) {
              this.$confirm(`距离预约开始会诊时间只剩${hours}小时${minutes}分钟，请及时通知患者支付和知会会诊医生。`, '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {})
                .catch(() => {})
            }
          }
          const _con = {
            type: 'pass',
            consultationNo: this.data.consultationNo
          }
          this.$emit('succeed', _con)
        } else {
          // this.$message({ message: '操作失败，' + res.msg, type: 'error' })
        }
      })
    },
    handleSendRecords() {
      // 通过审核，备案
      if (!(this.data.doctorList && this.data.doctorList.length > 0)) {
        this.$message({ message: '至少选择一个医生', type: 'error' })
        return
      }
      this.$refs.canvassign.init()
    },
    handleRefuse() {
      this.formObj.rejectReason = ''
      this.isShowCheckDialog = true
    },
    headleSendRefuse() {
      if (!this.formObj.rejectReason.trim()) {
        this.$message({ message: '驳回说明为必填且不能为空格', type: 'error' })
        return
      }
      const _data = {
        consultationNo: this.data.consultationNo,
        rejectReason: this.formObj.rejectReason
      }
      this.$api.telemedicinecheck.noPassCheck(_data).then(res => {
        if (res.code === 200) {
          this.$message({ message: '操作成功', type: 'success' })
          this.isShowCheckDialog = false
          const _con = {
            type: 'noPass',
            consultationNo: this.data.consultationNo
          }
          this.$emit('succeed', _con)
        } else {
          // this.$message({ message: '操作失败，' + res.msg, type: 'error' })
        }
      })
    },
    handleInventory() {
      this.isShowInventoryDialog = true
    },
    handleSeeReport(item) {
      if (item.consultationReport) {
        this.reportData = item
        this.isShowReportDialog = true
      } else {
        this.reportData = {}
        this.$message({ message: `${item.doctorName}医生未填写${item.doctorId === this.data.doctorId ? '治疗方案' : '会诊报告'}`, type: 'info' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-timeline {
  padding: 0;
}
.cont-de {
  position: relative;
  ::v-deep .el-descriptions__header {
    margin: 16px 0 10px 0;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 4px;
  }
  .inventory-c {
    ::v-deep .el-descriptions__header {
      margin: 16px 0 10px 0;
    }
    ::v-deep .el-descriptions-item__cell {
      padding-bottom: 12px;
    }
  }
  .cont-ee {
    padding: 20px 20px 0 20px;
  }
  .img-yz {
    position: relative;
    height: 0;
    z-index: 2;
    width: 100%;
    .img-yz-i {
      width: 120px;
      height: 120px;
      position: absolute;
      top: 15px;
      right: 20%;
      img {
        width: 100%;
      }
    }
  }
  .cont-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
    .doctor-n {
      display: flex;
      margin-top: 10px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      .doctor-head {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 50%;
        background: #eee;
        margin-right: 5px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .cont-tab {
      font-size: 14px;
      color: #606266;
      margin-top: 20px;
      padding-bottom: 4px;
      display: flex;
      .label {
        color: #303133;
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
  .check-but {
    padding: 10px 20px;
    position: sticky;
    z-index: 6;
    bottom: 0;
    left: 0;
    background: #fff;
    border-top: 1px solid #eee;
    text-align: right;
  }
}
.title-de {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  span {
    font-weight: normal;
    color: #fff;
    font-size: 14px;
    margin-left: 15px;
    padding: 0px 8px;
    border-radius: 4px;
    &.back10 {
      background: #0052d9;
    }
    &.back20 {
      background: #67c23a;
    }
    &.back30 {
      background: #f56c6c;
    }
  }
  .t-text {
    float: right;
    color: #666;
    font-size: 14px;
    font-weight: normal;
  }
}
.doctor-list {
  display: flex;
  flex-wrap: wrap;
}
.doctor-card {
  margin-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  .doctor-card-cont {
    padding: 10px;
    display: flex;
    .doctor-card-head {
      width: 42px;
      height: 42px;
      overflow: hidden;
      border-radius: 50%;
      background: #f1f1f1;
      margin-right: 10px;
      img {
        width: 100%;
        display: block;
      }
    }
    .doctor-card-text {
      font-size: 14px;
      color: #606266;
      line-height: 1;
      margin-bottom: 10px;
      span {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .doctor-card-but {
    padding: 0 10px;
    border-top: 1px solid #eee;
    text-align: center;
  }
  .doctor-card-tab {
    position: absolute;
    padding: 4px 8px;
    border-radius: 0 0 4px 0;
    color: #fff;
    line-height: 1;
    font-size: 12px;
    background: #0052d9;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.des-t {
  font-size: 14px;
  i {
    margin-right: 5px;
  }
}
.timeline-card {
  padding: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  span {
    color: #409eff;
    margin-left: 10px;
  }
}
.doctorlist {
  display: flex;
  .doctorlist-item {
    width: 576px;
    border-right: 1px solid #eee;
    height: 60vh;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
    &:last-child {
      border: 0;
      width: 700px;
    }
    .doctor-tree {
      margin-top: 10px;
    }
    .doctorlist-select-top {
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      border-bottom: 1px solid #eee;
      text-align: center;
      line-height: 40px;
    }
    .doctorlist-select-list {
      margin: 10px 0 0 0;
      padding: 0;
      list-style: none;
      li {
        display: flex;
        border-bottom: 1px dashed #eee;
        box-sizing: border-box;
        padding-right: 30px;
        position: relative;
        line-height: 40px;
        .doctorlist-select-list-i {
          flex: 1;
        }
        .doctorlist-select-list-icon {
          width: 40px;
          position: absolute;
          top: 12px;
          right: 0;
          z-index: 3;
          color: red;
          cursor: pointer;
          text-align: center;
        }
      }
    }
  }
}
.dzdialog {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-tree-node.is-current > .el-tree-node__content {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #333;
  }
  ::v-deep .el-tree-node.is-current > .el-tree-node__content > .el-tree-node__expand-icon {
    color: #333;
  }
}
::v-deep .el-tree-node__expand-icon {
  font-size: 12px;
}
::v-deep .custom-tree-doctor {
  display: flex;
  width: 360px;
  .custom-tree-doctor-item {
    flex: 1;
    box-sizing: border-box;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
::v-deep .el-tree {
  // 不可全选样式
  .el-tree-node {
    .is-leaf + .el-checkbox .el-checkbox__inner {
      display: inline-block;
    }
    .el-checkbox .el-checkbox__inner {
      display: none;
    }
  }
}
.inventory-qm {
  text-align: right;
  img {
    width: 119px;
  }
}
.h-item {
  padding: 10px 0;
  border-bottom: 1px dashed #eee;
  .h-item-c {
    padding-bottom: 12px;
    color: #606266;
    .sign-c {
      text-align: right;
      margin-top: 24px;
      img {
        width: 119px;
      }
    }
  }
  .h-label {
    padding-bottom: 12px;
  }
}
</style>
