import axios from '../../axios'
import { export_blob } from '@/utils/exportFile'
// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/mgmt/order/orderFlows',
    method: 'get',
    params: data
  })
}

// 统计
export function statistics(data) {
  return axios({
    url: '/mgmt/order/orderFlows/_statistics',
    method: 'post',
    data
  })
}

// 流水详情
// export function orderFlowsDetail(id) {
//   return axios({
//     url: '/mgmt/order/orderFlows/' + id,
//     method: 'get'
//   })
// }
// 导出
export const exportExcel = (data, loadingMessage) => {
  return axios({
    url: '/mgmt/order/orderFlows/_export',
    method: 'post',
    responseType: 'blob',
    data
  }).then(response => {
    export_blob(response, `${data.type === '0' ? '支付明细' : '退款明细'}.xls`)
    if (loadingMessage) {
      setTimeout(function() {
        loadingMessage.close()
      }, 100)
    }
  }).catch(function(error) {
    console.log(error)
  })
}
