var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "title pg" },
    [
      _c("span", { staticClass: "shu" }),
      _vm._v(" "),
      _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
      _vm._v(" "),
      _vm.isRefresh
        ? _c(
            "el-button",
            { staticClass: "d-refresh", on: { click: _vm.oRefresh } },
            [_vm._v("刷新")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }