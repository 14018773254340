/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-04-06 10:12:41
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-07-27 14:08:55
 * @FilePath: \drant-manager\src\http\moudules\base\general.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'
// 药房药品导入模板
export const exportDrugTemp = data => {
  return axios({
    url: '/mgmt/medicine/common/_export-drug-temp',
    method: 'get',
    params: data
  })
}
// 药品库导入模板
export const exportDrugsTemp = data => {
  return axios({
    url: '/mgmt/medicine/common/_export-drugs-temp',
    method: 'get',
    params: data
  })
}
// 下载药品批量更新模板
export const exportDrugImpTemplate = data => {
  return axios({
    url: '/mgmt/medicine/drugs/exportDrugImpTemplate',
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
// 导入药品批量更新模板
export function _updateSyncDrugs(data) {
  return axios({
    url: '/mgmt/medicine/drugs/_updateSyncDrugs',
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'application/octet-stream'
  }})
}
