<template>
  <div class="d-flex a-start" :class="{'hei-one':!isshei}" style="width:90%">
    <div class="mb-1 d-flex a-center">
      <div class="search d-flex f-h">
        <div v-for="(item,index) in opstion.opstionList" :key="index" class="setion">
          <div class="demo-input-suffix d-flex a-center ">
            <div class="w-s mr-1"> {{ item.label }}:</div>
            <div>
              <el-input
                v-if="item.isselect==='input'"
                v-model="item.value"
                :placeholder="item.placeholder"
                clearable
              />
              <el-select v-if="item.isselect==='select'" v-model="item.value" :placeholder="item.placeholder" clearable>
                <el-option
                  v-for="ite in item.select"
                  :key="ite.value"
                  :label="ite.label"
                  :value="ite.value"
                />
              </el-select>
              <el-cascader
                v-if="item.isselect==='region'"
                v-model="item.value"
                size="large"
                :options="options"
                :props="addrProps"
              />
              <el-date-picker
                v-if="item.isselect==='datelimit'"
                v-model="item.value"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                style="width:100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="demo-input-suffix d-flex a-center ml-1 mt-2">
      <!-- <el-button type="primary" @click.native="opstion.query">查询</el-button> -->
      <el-button type="primary" @click.native="query">查询</el-button>
      <el-button @click.native="reset">重置</el-button>
      <i v-if=" opstion.opstionList.length>3" class="ml-2 cupron" :class="icon" @click="screening()" />
    </div>
  </div>
</template>
<script>
import { regionData } from 'element-china-area-data'
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    opstion: Object
  },
  data() {
    return {
      options: regionData,
      formdata: [],
      isshei: false,
      icon: 'el-icon-arrow-down',
      addrProps: {
        value: 'label'
      }
    }
  },
  methods: {
    circular() {
    },
    reset() {
      for (let i = 0; i < this.opstion.opstionList.length; i++) {
        this.opstion.opstionList[i].value = ''
      }
      const datafrom = {}
      this.$emit('query', datafrom)
    },
    query() {
      var datafrom = {}
      for (let i = 0; i < this.opstion.opstionList.length; i++) {
        datafrom[this.opstion.opstionList[i].prop] = this.opstion.opstionList[i].value ? this.opstion.opstionList[i].value : null
      }
      this.$emit('query', datafrom)
    },
    screening() {
      this.isshei = !this.isshei
      if (!this.isshei) {
        this.icon = 'el-icon-arrow-down'
      } else {
        this.icon = 'el-icon-arrow-up'
      }
    }
  }
}
</script>
<style lang="scss">
.search{
  width:100%;
  padding: 20px 0 0px 30px;
  .setion{
    width: 380px;
    margin-bottom: 20px;
  }
}
.w-s{
  white-space:nowrap;
  min-width: 99px;
  text-align: right;
}
.hei-one{
  height: 70px;
  overflow: hidden;
}
.cupron{
  cursor: pointer;
}
</style>
