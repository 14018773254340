<template>
  <div>
    <el-dialog :visible.sync="dialog.showDialog" :title="dialog.dialogTitle" :width="dialog.width" :before-close="handleClose">
      <el-form ref="ruleForm" label-width="100px" :model="dialog.datalist" :rules="dialog.rules" class="demo-ruleForm">
        <el-form-item
          v-for="(item,index) in dialog.formlist"
          :key="index"
          :label="item.label"
        >
          <el-input v-if="item.type==='input'" v-model="item.value" clearable />
          <el-select v-if="item.type==='select'" v-model="item.value" :placeholder="item.placeholder">
            <el-option v-for="(ite,ind) in item.select" :key="ind" :label="ite.label" :value="ite.value" />
          </el-select>
          <el-switch v-if="item.type==='switch'" v-model="item.value" />
          <el-input v-if="item.type==='textarea'" v-model="item.value" clearable type="textarea" />
          <el-radio-group v-if="item.type==='radio'" v-model="item.value">
            <el-radio label="线上品牌商赞助" />
            <el-radio label="线下场地免费" />
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm','dialog.formlist')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    dialog: Object,
    // eslint-disable-next-line vue/require-default-prop
    dataFrom: Object
  },
  created() {
  },
  methods: {
    submitForm(formName) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('submitForm', formName)
    },
    resetForm(formName, form) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('resetForm')
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>
