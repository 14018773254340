import axios from '../../axios'

export function getRecommendPage(data) {
  return axios({
    url: '/mgmt/medical/recommend/getPage',
    method: 'get',
    params: data
  })
}

export function addRecommend(data) {
  return axios({
    url: '/mgmt/medical/recommend/add',
    method: 'post',
    data: data
  })
}

export function editRecommend(data) {
  return axios({
    url: '/mgmt/medical/recommend/edit',
    method: 'post',
    data: data
  })
}

export function deleteRecommend(data) {
  return axios({
    url: '/mgmt/medical/recommend/delete',
    method: 'post',
    data: data
  })
}