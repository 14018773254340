import axios from '../../axios'
const basePath = '/core/chatmessage'

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}
// 审核
export function updateMessageReader(data) {
  return axios({
    url: basePath + '/updateMessageReader',
    method: 'post',
    data
  })
}
