import axios from '../../axios'
const basePath = '/mgmt/medicine/drug-statistics'
// 药品销售统计-药房
export function getList(data) {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}
// 导出
export function drugStatisticsExport(data) {
  return axios({
    url: basePath + '/_export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
// 药品销售统计-财务
export function getListFinance(data) {
  return axios({
    url: '/mgmt/order/finance-drug-statistics',
    method: 'get',
    params: data
  })
}

// 导出-财务
export function drugStatisticsExportFinance(data) {
  return axios({
    url: '/mgmt/order/finance-drug-statistics/_export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 导出-药品销售统计包含了sku商品
export function drugSalesExport(data) {
  return axios({
    url: '/mgmt/medicine/drug-statistics/drugSalesExport',
    method: 'post',
    responseType: 'blob',
    data
  })
}
