var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paragraph-wrap nop-fill-box" },
    [
      _c(
        "h2",
        { staticClass: "sub-title" },
        [
          _vm.heresy.text.length === 0 && _vm.en
            ? [_vm._v("\n      Nothing\n    ")]
            : _vm.heresy.text.length === 0 && !_vm.en
            ? [_vm._v("\n      无\n    ")]
            : [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.en
                        ? "Boby " + _vm.heresy.text.join("、")
                        : "体" + _vm.heresy.text.join("、")
                    ) +
                    "\n    "
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.heresy.text.length > 0,
              expression: "heresy.text.length > 0",
            },
          ],
          staticClass: "paragraph",
          attrs: { "data-op-type": "block" },
        },
        [
          _vm.en
            ? _c("h3", [_vm._v("Annotation:")])
            : _c("h3", [_vm._v("注释:")]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.heresy.desc, function (item, index) {
        return _c(
          "p",
          {
            key: index,
            staticClass: "paragraph-p",
            attrs: { "data-op-type": "text" },
          },
          [
            item.split("null").filter(function (v) {
              return !!v
            }).length > 0
              ? [_vm._v("\n      " + _vm._s(item.split("null")) + "\n    ")]
              : [_vm._v("\n      无\n    ")],
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }