<template>
  <div :class="['item', `on${on}`]">
    <div class="chart-qxjy-bg">
      <div class="border border1"></div>
      <div class="border border2"></div>
      <div class="border border3"></div>
      <div class="border border4"></div>
      <div class="border border5"></div>
      <div class="border border6"></div>
      <div class="border border7"></div>
      <div class="color"></div>
    </div>
    <div :class="['icon', icon]">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    on: {
      type: Number,
      default: 4
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.chart-qxjy-bg {
    background-color: #faf6ec;
    position: relative;
    overflow: hidden;
    height: 75%;
  }

  .border1 {
    border-top: 0.5px solid #cf1e29;
  }

  .border2 {
    border-top: 0.5px solid #fdb73d;
  }

  .border3 {
    border-top: 0.5px solid #fcda6e;
  }

  .border4 {
    height: 25%;
    border-top: 0.5px solid #13d1b5;
    border-bottom: 0.5px solid #13d1b5;
  }

  .border5 {
    border-bottom: 0.5px solid #fcda6e;
  }

  .border1, .border2, .border3, .border5, .border6, .border7 {
    height: 12.5%;
  }

  .color {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;

    &:after {
      content: '';
      background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/color_top.png');
      position: absolute;
      width: 100%;
      height: 30px;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .icon {
    margin-top: 0.5rem;
    width: 100%;
    padding-top: 2.4rem;
    font-size: 1.4rem;
    line-height: 1.5;
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_qi.png');
    background-repeat: no-repeat;
    background-size: 2.4rem;
    background-position: top;
    text-align: center;
  }

  .blood {
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_xue.png');
  }

  .fluids {
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_jin.png');
  }

  .humor {
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_ye.png');
  }

  .on1 .color, .on7 .color {
    background-color: #cf1e29;
  }

  .on2 .color, .on6 .color {
    background-color: #fdb73d;
  }

  .on3 .color, .on5 .color {
    background-color: #fcda6e;
  }

  .on4 .color {
    background-color: #13d1b5;
  }

  .on1 .color {
    height: 100%;
    border-top: 0.5px solid #cf1e29;
  }

  .on2 .color {
    height: 87.5%;
    border-top: 0.5px solid #fdb73d;
  }

  .on3 .color {
    height: 75%;
    border-top: 0.5px solid #fcda6e;
  }

  .on4 .color {
    height: 50%;
  }

  .on5 .color {
    height: 37.5%;
  }

  .on6 .color {
    height: 25%;
  }

  .on7 .color {
    height: 12.5%;
    border-top: 0.5px solid #fdb73d;
  }
</style>
