import axios from '../../axios'

const basePath = '/mgmt/log/auditLogs'

/**
 * 系统日志分页查询
 */
export const findPage = (params, pageVo) => {
  if (pageVo) {
    params.curPage = pageVo.curPage
    params.pageSize = pageVo.pageSize
  }
  return axios({
    url: `${basePath}`,
    method: 'get',
    params: { ...params }
  })
}

/**
 * 系统日志详情查询
 */
export const findDetail = (id) => {
  return axios({
    url: `${basePath}/${id}`,
    method: 'get'
  })
}
