import axios from '../../axios'
const basePath = '/mgmt/drugs/modify/'

export function audit(data) {
  return axios({
    url: basePath + 'audit',
    method: 'post',
    data
  })
}

export function list(data) {
  return axios({
    url: basePath + 'getPage',
    method: 'get',
    params: data
  })
}

export function detail(id) {
  return axios({
    url: basePath + 'detail?id=' + id,
    method: 'get',
  })
}
