import axios from '../../axios'

export const diagnostic = (data) => {
  return axios({
    url: '/mgmt/diagnostic-categories',
    method: 'get',
    params: data
  })
}

export const diagnosticEdit = (data) => {
  return axios({
    url: '/mgmt/diagnostic-categories',
    method: 'put',
    data
  })
}

export const getDiagnosisIdList = (params) => {
  return axios({
    url: '/mgmt/diagnosis/getPage',
    method: 'get',
    params
  })
}
