const calculate = {

  date: function(date) {
    var nowdate = new Date(date).toLocaleDateString().replace(/\//g, '-')
    return nowdate
  }
}

export {
  calculate
}

