import Vue from 'vue'
import Router from 'vue-router'
// import api from '@/http/api'
Vue.use(Router)
/* Layout */
import Layout from '@/layout'
/* Router Modules */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/faceR',
    component: () => import('@/views/login/faceR'),
    hidden: true
  },
  {
    path: '/pulseTaking',
    component: () => import('@/views/login/pulseTaking'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  // {
  //   path: '/freight', // 运费设置
  //   component: () => import('@/views/baseconfiguration/freight'),
  //   hidden: true
  // },
  {
    path: '/chineseMedicineRoutine', // 中药常规用量
    component: () => import('@/views/baseconfiguration/chineseMedicineRoutine'),
    hidden: true
  },
  {
    path: '/businessInvitationDoctor', // 商务邀请医生
    component: () => import('@/views/businessmanage/businessInvitationDoctor'),
    hidden: true
  },
  {
    path: '/casehistory', // 线下看线上病历
    component: () => import('@/views/casehistory'),
    hidden: true
  },
  {
    path: '*',
    component: () => import('@/views/error-page/401'),
    hidden: true
  }
  // {
  //   path: '/fileLog', // 归档
  //   component: () => import('@/views/systemmanage/fileLog'),
  //   hidden: true
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import('@/views/dashboard/index'),
  //       name: 'Dashboard',
  //       meta: { title: '系统首页', icon: 'dashboard', affix: true }
  //     }
  //   ]
  // }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
