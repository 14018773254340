var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [
              _c("router-view", {
                key: _vm.key,
                ref: "routerViewRef",
                on: { sendMessage: _vm.sendMessage, reconnect: _vm.reconnect },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "copyright",
          on: {
            click: function ($event) {
              return _vm.tolink()
            },
          },
        },
        [
          _vm._v(
            "@2023  广州蚂蚁云医互联网医院  版权所有 v" + _vm._s(_vm.version)
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }