import axios from '../../axios'
import { export_blob } from '@/utils/exportFile'
const basePath = '/core'
//
export const ExportExcelBase = (data, config, loadingMessage) => {
  return axios({
    url: config.url,
    method: 'POST',
    responseType: 'blob',
    data
  }).then(response => {
    export_blob(response, (new Date().getTime()) + '.xls')
    if (loadingMessage) {
      setTimeout(function() {
        loadingMessage.close()
      }, 100)
    }
  }).catch(function(error) {
    console.log(error)
  })
}

export const ExportExcel1 = (data, loadingMessage) => {
  return ExportExcelBase(data, {
    url: basePath + '/financeBoard/income/export'
  }, loadingMessage)
}

export const ExportExcel2 = (data, loadingMessage) => {
  return ExportExcelBase(data, {
    url: basePath + '/financeBoard/expend/export'
  }, loadingMessage)
}

export const ExportExcel3 = (data, loadingMessage) => {
  return ExportExcelBase(data, {
    url: basePath + '/doctor/doctorpageAll/export'
  }, loadingMessage)
}

export const ExportExcel4 = (data, loadingMessage) => {
  return ExportExcelBase(data, {
    url: basePath + '/memberIncomeInfo/incomepageAll/export'
  }, loadingMessage)
}
