import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/sysBannelContent/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/sysBannelContent/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/sysBannelContent/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/sysBannelContent/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + '/sysBannelContent/findInfoByUniqueKey',
    method: 'get',
    params: data
  })
}

// 审核
export function approve(data) {
  return axios({
    url: basePath + '/sysBannelContent/approve',
    method: 'post',
    data
  })
}

// 上移
export function stopTop(data) {
  return axios({
    url: basePath + '/sysBannelContent/stopTop',
    method: 'post',
    data
  })
}

// 下移
export function stopDown(data) {
  return axios({
    url: basePath + '/sysBannelContent/stopDown',
    method: 'post',
    data
  })
}
