import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 后台管理员使用店主列表
export function findPage(data) {
  return axios({
    url: '/mgmt/shopkeeper/pageList',
    method: 'get',
    params: data
  })
}
// 机构使用店主列表
export function findPageAgent(data) {
  return axios({
    url: '/mgmt/shopkeeper/pageListAgent',
    method: 'get',
    params: data
  })
}
// 新增店主
export function assistantAdd(data) {
  return axios({
    url: '/mgmt/shopkeeper/add',
    method: 'post',
    data
  })
}
// 编辑店主
export function assistantUpdate(data) {
  return axios({
    url: '/mgmt/shopkeeper/update',
    method: 'post',
    data
  })
}
// 获取用户列表
export function memberPageList(data) {
  return axios({
    url: '/core/member/pageList',
    method: 'get',
    params: data
  })
}

// 店主申请列表
export function shopkeeperApplyList(data) {
  return axios({
    url: '/mgmt/shopkeeper/apply/pageList',
    method: 'get',
    params: data
  })
}

// 店主申请审核
export function shopkeeperAudit(data) {
  return axios({
    url: '/mgmt/shopkeeper/apply/audit',
    method: 'post',
    data
  })
}