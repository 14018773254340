var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["chart-branch-3d2", { "no-rotate": !_vm.rotate }] },
    [
      _c("div", {
        ref: "refChart",
        class:
          this.channelWidth > 1000
            ? "refChart"
            : this.channelWidth > 650
            ? "refChart1"
            : "refChart2",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }