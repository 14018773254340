
import { Message } from 'element-ui'

const mymessage = function(text, types) {
  var arrow = ''
  if (!types) {
    arrow = 'success'
  } else if (types === 1) {
    arrow = 'error'
  } else {
    arrow = 'warning'
  }
  Message({
    message: text,
    type: arrow // error,warning,success
  })
}
export default mymessage
