<template>
  <el-dialog title="修改密码" :visible.sync="changedown" width="30%">
    <el-form ref="editFrm" :model="form" :rules="passwordEdit" @load="resetFields">
      <el-form-item label="旧密码" label-position="right" label-width="120px" prop="oldPassword">
        <el-input v-model="form.oldPassword" clearable autocomplete="off" type="password" autocompleted="off" />
      </el-form-item>
      <el-form-item label="新密码" label-position="right" label-width="120px" prop="newPassword">
        <el-input v-model="form.newPassword" clearable autocomplete="off" type="password" autocompleted="off" />
      </el-form-item>
      <el-form-item label="确认密码" label-position="right" label-width="120px" prop="confirmPassword">
        <el-input v-model="form.confirmPassword" clearable autocomplete="off" type="password" autocompleted="off" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetFields">取 消</el-button>
      <el-button type="primary" @click="affirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { MessageBox } from 'element-ui'
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      changedown: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordEdit: {
        oldPassword: [{ required: true, trigger: 'blur', validator: (rule, value, callback) => {
          if (!value) {
            callback(new Error('请输入旧密码'))
          // } else if (value === this.form.oldPassword) {
          //   callback(new Error('新密码跟旧密码不能一样'))
          } else {
            callback()
          }
        } }],
        newPassword: [{
          required: true,
          validator: this.Format.FormValidate.Form().PawIntension, // validator： 自定义验证规则
          trigger: 'blur'// change blur
        },
        { required: true, trigger: 'blur', validator: (rule, value, callback) => {
          if (value === this.form.oldPassword) {
            callback(new Error('新密码跟旧密码不能一样'))
          } else {
            callback()
          }
        } }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: (rule, value, callback) => {
            if (value !== this.form.newPassword) {
              callback(new Error('第二次密码输入错误'))
            } else {
              callback()
            }
          } }
        ]
      }
    }
  },
  methods: {
    init() {
      this.changedown = true
    },
    resetFields() {
      this.changedown = !this.changedown
      this.$refs['editFrm'].resetFields()
      this.$emit('cb')
    },
    affirm() {
      this.$refs.editFrm.validate(flag => {
        if (flag) { // 表单验证通过再执行ajax
          this.$api.user.updPassword(this.form).then(res => {
            if (res.code === 200) {
              this.changedown = false
              this.$message.success('密码修改成功!')
              MessageBox.alert('修改密码后需重新登入', '重新登入', {
                confirmButtonText: '确定',
                callback: () => {
                  removeToken()
                  this.$store.commit('user/SET_PERMSS', [])
                  this.$store.commit('permission/SET_MENUS', [])
                  this.$store.commit('user/SET_ROLES', [])
                  this.$store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
                  this.$store.commit('app/webReloadLoginFlag', false)// 重新登入为false
                  this.$store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色
                  this.$store.commit('user/SET_TOKEN', '')
                  location.reload()
                }
              })
              return
            }
          })
        }
      })
    }
  }
}
</script>

<style>

</style>
