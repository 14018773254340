// 渐变颜色
/** @internal */
export const getItemColors = (start, end, steps, gamma = 1) => {
  let i;
  let j;
  let ms;
  let me;
  const output = [];
  const so = [];
  gamma = 1;

  const normalize = (channel) => {
    return Math.pow(channel / 255, gamma);
  };

  const parseColor = (hexStr) => {
    return hexStr.length === 4
      ? hexStr
          .substr(1)
          .split('')
          .map(s => 0x11 * parseInt(s, 16))
      : [hexStr.substr(1, 2), hexStr.substr(3, 2), hexStr.substr(5, 2)].map(s => parseInt(s, 16));
  };

  // zero-pad 1 digit to 2
  const pad = (s) => {
    return s.length === 1 ? '0' + s : s;
  };

  const starts = parseColor(start).map(normalize);
  const ends = parseColor(end).map(normalize);

  for (i = 0; i < steps; i++) {
    ms = i / (steps - 1);
    me = 1 - ms;
    for (j = 0; j < 3; j++) {
      so[j] = pad(
        Math.round(Math.pow(starts[j] * me + ends[j] * ms, 1 / gamma) * 255).toString(16)
      );
    }
    output.push('#' + so.join(''));
  }

  return output;
};

// 计算每个点的颜色
export const getColor = (plotData, c1, c2, c3) => {
  const obj = {};
  
  for (const k in plotData) {
    obj[plotData[k][2]] = 0;
  }
  
  const arr = Object.keys(obj);
  arr.sort((a, b) => +a - +b);

  const len = Math.ceil(arr.length / 2);
  const color1 = getItemColors(c1, c2, len);
  const color2 = getItemColors(c2, c3, len);
  const color = [...color1, ...color2];

  const res = {};
  for (const k in arr) {
    res[arr[k]] = color[k];
  }

  return res;
};

// 合并数据与颜色
export const dataAndColor = (plotData, color) => {
  // 合并数据与颜色
  const plot3dDataColor = [];
  for (const k in plotData) {
    plot3dDataColor.push({
      value: plotData[k],
      itemStyle: {
        color: color[plotData[k][2]]
      }
    });
  }
  return plot3dDataColor;
};