import axios from '../../axios'

export function getSpecialDiseaseIntro(data) {
  return axios({
    url: '/core/special-disease/intro/getSpecialDiseaseIntro',
    method: 'get',
    params: data
  })
}

export function editIntro(data) {
  return axios({
    url: '/core/special-disease/intro/edit',
    method: 'post',
    data: data
  })
}

export function saveIntro(data) {
  return axios({
    url: '/core/special-disease/intro/save',
    method: 'post',
    data: data
  })
}

export function getSpecialDiseaseList(data) {
  return axios({
    url: '/mgmt/special-disease/appoint/getPage',
    method: 'get',
    params: data
  })
}
