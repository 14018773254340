var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-branch" }, [
    _c("h2", { staticClass: "sub-title" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.title) + "\n    " + _vm._s(_vm.mai) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "chart" },
      [
        _c("Branch3d", {
          attrs: {
            a: _vm.branchFeature.amp,
            mai: _vm.mai,
            rotate: _vm.rotate,
            w: _vm.branchFeature.width,
            width: _vm.width3d,
          },
        }),
        _vm._v(" "),
        _c("Bar", {
          attrs: {
            color: _vm.color,
            height: _vm.widthBar * 1.5,
            value: _vm.value,
            mai: _vm.mai,
            width: _vm.widthBar,
            showKong: _vm.showKong,
            en: _vm.en,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }