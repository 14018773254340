import axios from '../../axios'
const basePath = '/mgmt/medicine/pharmacyDoctors'

export function edit(data) {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + `/${data.id}/bindings`,
    method: 'delete'
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

export function saveBatch(data) {
  return axios({
    url: basePath + '/bindings',
    method: 'post',
    data
  })
}
