<template>
  <div :class="['item', cla]">
   
    <div>
      <div class="top">
        <span :class="['txt', on === 'left' ? 'on' : '']">{{ titles[0] }}</span>
      </div>
      <div class="bottom">
        <span :class="['txt', on === 'right' ? 'on' : '']">{{ titles[1] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cla: {
      type: String,
      default: ''
    },
    titles: {
      type: Array,
      default: () => ['', '']
    },
    on: {
      type: String,
      default: ''
    },
    en: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
 .item .top {
  position: absolute;
  width: 100%;
  top: 0px;
  transform: translateY(-100%)
}

 .item .bottom {
  position: absolute;
  width: 100%;
  bottom: 0px;
  transform: translateY(100%)
}

 .item .txt {
  font-size: 2rem
}

 .item .on.txt {
  color: #d5454b
}
</style>
