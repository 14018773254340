import axios from '../../axios'
import { export_blob } from '@/utils/exportFile'
const basePath = '/mgmt/medicareMedicine'

export function save(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath,
    method: 'put',
    data
  })
}

export function del(id) {
  return axios({
    url: basePath + `/${id}`,
    method: 'delete'
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 详情
export const findInfoByKey = (id) => {
  return axios({
    url: basePath + `/detailById/${id}`,
    method: 'get'
  })
}

// 导入
export function importExcel(data) {
  return axios({
    url: '/mgmt/medicareMedicine/import',
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'application/octet-stream'
  }})
}

// 导出
export const exportExcel = (data, loadingMessage) => {
  return axios({
    url: '/mgmt/medicareMedicine/export',
    method: 'post',
    responseType: 'blob',
    data
  }).then(response => {
    export_blob(response, `医保药品国家标准编码(${data.type === '0' ? '西药类型' : '中药饮片'}).xls`)
    if (loadingMessage) {
      setTimeout(function() {
        loadingMessage.close()
      }, 100)
    }
  }).catch(function(error) {
    console.log(error)
  })
}

// 导入详情
export const importTask = (data) => {
  return axios({
    url: `/mgmt/importTask`, // result
    method: 'get',
    params: data
  })
}
