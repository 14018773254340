/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-08-07 14:32:47
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-08-09 13:54:03
 * @FilePath: \drant-manager\src\http\moudules\ram\topicClass.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 话题-列表
export const getlist = data => {
  return axios({
    url: '/mgmt/trends/talk/getList',
    method: 'get',
    params: data
  })
}
// 话题-详情
export const talkDetail = data => {
  return axios({
    url: '/mgmt/trends/talk/detail',
    method: 'get',
    params: data
  })
}
// 话题-新增
export const talkAdd = data => {
  return axios({
    url: '/mgmt/trends/talk/add',
    method: 'post',
    data
  })
}
// 发动态
export const shareTrends = data => {
  return axios({
    url: '/mgmt/trends/content/shareTrends',
    method: 'post',
    data
  })
}
// 话题-审核
export const talkAudit = data => {
  return axios({
    url: '/mgmt/trends/talk/audit',
    method: 'post',
    data
  })
}
// 话题-设置热门
export const talkSetHot = data => {
  return axios({
    url: '/mgmt/trends/talk/setHot',
    method: 'post',
    data
  })
}
// 话题-设置推荐
export const talkSetRecommend = data => {
  return axios({
    url: '/mgmt/trends/talk/setRecommend',
    method: 'post',
    data
  })
}
// 话题-禁用、启用
export const talkForbiddenOrEnable = data => {
  return axios({
    url: '/mgmt/trends/talk/forbiddenOrEnable',
    method: 'post',
    data
  })
}
// 话题-删除
export const talkDelete = data => {
  return axios({
    url: '/mgmt/trends/talk/delete/' + data,
    method: 'post'
    // data
  })
}
