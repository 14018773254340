import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/healthCmsContent/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/healthCmsContent/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/healthCmsContent/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/mgmt/cms/healthCmsContent',
    method: 'get',
    params: data// data
  })
}

export const findInfoByUniqueKey = (id) => {
  return axios({
    url: `/mgmt/cms/healthCmsContent/${id}`,
    method: 'get'
  })
}

// 审核
export function approve(data) {
  return axios({
    url: basePath + '/healthCmsContent/approve',
    method: 'post',
    data
  })
}

// 获取患者资讯分类
export const getCategoryTreeByWhere = (data) => {
  return axios({
    url:'/mgmt/health/cms/category/getCategoryTreeByWhere',
    method: 'get',
    params: data
  })
}

// 新增患者资讯分类
export const addCategory = (data) => {
  return axios({
    url: '/mgmt/health/cms/category/add',
    method: 'post',
    data
  })
}

// 编辑患者资讯分类
export const editCategory = (data) => {
  return axios({
    url: '/mgmt/health/cms/category/edit',
    method: 'post',
    data
  })
}

// 删除患者资讯分类
export const deleteCategory = (data) => {
  return axios({
    url: '/mgmt/health/cms/category/delete',
    method: 'post',
    data
  })
}
