
import { Loading } from 'element-ui'

let loadingCount = 0
let loading
const startLoading = () => {
  loading = Loading.service({ // 声明一个loading对象
    lock: true, // 是否锁屏
    text: '正在加载...', // 加载动画的文字
    spinner: 'el-icon-loading', // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.3)', // 背景颜色
    target: 'body', // 需要遮罩的区域
    body: true,
    customClass: 'mask' // 遮罩层新增类名
  })
}

const endLoading = () => {
  loading.close()
}

export const showLoading = () => {
  if (loadingCount === 0) {
    startLoading()
  }
  loadingCount += 1
}

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  if (loadingCount === 0) {
    endLoading()
  }
}
