import axios from '../../axios'
const basePath = '/mgmt/consultation/feesettings'
// 诊费列表
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 诊费详情
export const findOnlyKey = (id) => {
  return axios({
    url: basePath + `/${id}`,
    method: 'get'
  })
}

// 修改诊费
export const editFees = (data) => {
  return axios({
    url: basePath + `/${data.id}`,
    method: 'put',
    data
  })
}
