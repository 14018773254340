import axios from '../../axios'
const basePath = '/mgmt/offline/orders'

// 线下药房订单分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}
// 线下药房订单详情
export const findInfoByUniqueKey = (data) => {
  return axios({
    url: `/core/order/offline/findInfoByUniqueKey`,
    method: 'get',
    params: data
  })
}

// 确认发货
export function confirmShipment(data) {
  return axios({
    url: basePath + '/confirmShipment',
    method: 'post',
    data
  })
}

// 确认收货
export function confirmReceipt(data) {
  return axios({
    url: basePath + '/id/confirmReceipt',
    method: 'post',
    data
  })
}

// 确认取货
export function pickUpCode(data) {
  return axios({
    url: basePath + '/pickUp',
    method: 'post',
    data
  })
}

// 物流查询
export function logistics(data) {
  return axios({
    url: '/mgmt/online/logistics',
    method: 'get',
    params: data
  })
}
