var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-qxjy" }, [
    _c(
      "div",
      {
        directives: [
          { name: "show", rawName: "v-show", value: _vm.en, expression: "en" },
        ],
        staticClass: "column",
      },
      [
        _c("Item", { attrs: { icon: "qi", on: _vm.value.qi, title: "Qi" } }),
        _vm._v(" "),
        _c("Item", {
          attrs: { icon: "blood", on: _vm.value.blood, title: "Blood" },
        }),
        _vm._v(" "),
        _c("Item", {
          attrs: { icon: "fluids", on: _vm.value.fluids, title: "Thin" },
        }),
        _vm._v(" "),
        _c("Item", {
          attrs: { icon: "humor", on: _vm.value.humor, title: "Thick" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.en,
            expression: "!en",
          },
        ],
        staticClass: "column",
      },
      [
        _c("Item", { attrs: { icon: "qi", on: _vm.value.qi, title: "气" } }),
        _vm._v(" "),
        _c("Item", {
          attrs: { icon: "blood", on: _vm.value.blood, title: "血" },
        }),
        _vm._v(" "),
        _c("Item", {
          attrs: { icon: "fluids", on: _vm.value.fluids, title: "津" },
        }),
        _vm._v(" "),
        _c("Item", {
          attrs: { icon: "humor", on: _vm.value.humor, title: "液" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }