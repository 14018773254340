var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["branch-canvas-wrap"],
      style: { width: _vm.computedWidth + "px", height: _vm.height + "px" },
    },
    [
      _c("canvas", {
        ref: "branchCanvas",
        attrs: { height: _vm.height * 2, width: _vm.canvasWidth },
        on: { click: _vm.canBuildLine },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }