<template>
  <div class="chart-qxjy">
    <div class="column" v-show="en">
      <Item icon="qi" :on="value.qi" title="Qi" />
      <Item icon="blood" :on="value.blood" title="Blood" />
      <Item icon="fluids" :on="value.fluids" title="Thin" />
      <Item icon="humor" :on="value.humor" title="Thick" />
    </div>
    <div class="column" v-show="!en">
      <Item icon="qi" :on="value.qi" title="气" />
      <Item icon="blood" :on="value.blood" title="血" />
      <Item icon="fluids" :on="value.fluids" title="津" />
      <Item icon="humor" :on="value.humor" title="液" />
    </div>
  </div>
</template>

<script>
import Item from './Item.vue';

export default {
  components: {
    Item
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    en: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">


.chart-qxjy {
  padding: 2.4rem 0;
  width: 100%;
  height: 320px;

  .column {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .column .item {
    width: 18%;
  }

  .chart-qxjy-bg {
    background-color: #faf6ec;
    position: relative;
    overflow: hidden;
    height: 75%;
  }

  .border1 {
    border-top: 0.5px solid #cf1e29;
  }

  .border2 {
    border-top: 0.5px solid #fdb73d;
  }

  .border3 {
    border-top: 0.5px solid #fcda6e;
  }

  .border4 {
    height: 25%;
    border-top: 0.5px solid #13d1b5;
    border-bottom: 0.5px solid #13d1b5;
  }

  .border5 {
    border-bottom: 0.5px solid #fcda6e;
  }

  .border1, .border2, .border3, .border5, .border6, .border7 {
    height: 12.5%;
  }

  .color {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;

    &:after {
      content: '';
      background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/color_top.png');
      position: absolute;
      width: 100%;
      height: 30px;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .icon {
    margin-top: 0.5rem;
    width: 100%;
    padding-top: 2.4rem;
    font-size: 1.4rem;
    line-height: 1.5;
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_qi.png');
    background-repeat: no-repeat;
    background-size: 2.4rem;
    background-position: top;
    text-align: center;
  }

  .blood {
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_xue.png');
  }

  .fluids {
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_jin.png');
  }

  .humor {
    background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_ye.png');
  }

  .on1 .color, .on7 .color {
    background-color: #cf1e29;
  }

  .on2 .color, .on6 .color {
    background-color: #fdb73d;
  }

  .on3 .color, .on5 .color {
    background-color: #fcda6e;
  }

  .on4 .color {
    background-color: #13d1b5;
  }

  .on1 .color {
    height: 100%;
    border-top: 0.5px solid #cf1e29;
  }

  .on2 .color {
    height: 87.5%;
    border-top: 0.5px solid #fdb73d;
  }

  .on3 .color {
    height: 75%;
    border-top: 0.5px solid #fcda6e;
  }

  .on4 .color {
    height: 50%;
  }

  .on5 .color {
    height: 37.5%;
  }

  .on6 .color {
    height: 25%;
  }

  .on7 .color {
    height: 12.5%;
    border-top: 0.5px solid #fdb73d;
  }
}


// .chart-qxjy {
//     padding: 2.4rem 0;
//     width: 100%;
//     height: 320px
// }

// .chart-qxjy .column {
//     display: flex;
//     justify-content: space-between;
//     margin: auto
// }

// .chart-qxjy .column .item {
//     width: 18%;
// }

// .chart-qxjy .chart-qxjy-bg {
//     background-color: #faf6ec;
//     position: relative;
//     overflow: hidden;
//     height: 75%
// }

// .chart-qxjy .border1 {
//     border-top: .5px solid #cf1e29
// }

// .chart-qxjy .border2 {
//     border-top: .5px solid #fdb73d
// }

// .chart-qxjy .border3 {
//     border-top: .5px solid #fcda6e
// }

// .chart-qxjy .border4 {
//     height: 25%;
//     border-top: .5px solid #13d1b5;
//     border-bottom: .5px solid #13d1b5
// }

// .chart-qxjy .border5 {
//     border-bottom: .5px solid #fcda6e
// }

// .chart-qxjy .border1,.chart-qxjy .border2,.chart-qxjy .border3,.chart-qxjy .border5,.chart-qxjy .border6,.chart-qxjy .border7 {
//     height: 12.5%;
// }

// .chart-qxjy .color {
//     position: absolute;
//     width: 100%;
//     box-sizing: border-box;
//     bottom: 0
// }

// .chart-qxjy .color:after {
//     content: "";
//     background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/color_top.png');
//     position: absolute;
//     width: 100%;
//     height: 30px;
//     left: 0;
//     top: 0;
//     background-repeat: no-repeat;
//     background-size: contain
// }

// .chart-qxjy .icon {
//     margin-top: .5rem;
//     width: 100%;
//     padding-top: 2.4rem;
//     font-size: 1.4rem;
//     line-height: 1.5;
//     background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_qi.png');
//     background-repeat: no-repeat;
//     background-size: 2.4rem;
//     background-position: top;
//     text-align: center
// }

// .chart-qxjy .blood {
//   background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_xue.png');
// }

// .chart-qxjy .fluids {
//   background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_jin.png');
// }

// .chart-qxjy .humor {
//   background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_ye.png');
// }

// .chart-qxjy .on1 .color,.chart-qxjy .on7 .color {
//     background-color: #cf1e29
// }

// .chart-qxjy .on2 .color,.chart-qxjy .on6 .color {
//     background-color: #fdb73d
// }

// .chart-qxjy .on3 .color,.chart-qxjy .on5 .color {
//     background-color: #fcda6e
// }

// .chart-qxjy .on4 .color {
//     background-color: #13d1b5
// }

// .chart-qxjy .on1 .color {
//     height: 100%;
//     border-top: .5px solid #cf1e29
// }

// .chart-qxjy .on2 .color {
//     height: 87.5%;
//     border-top: .5px solid #fdb73d
// }

// .chart-qxjy .on3 .color {
//     height: 75%;
//     border-top: .5px solid #fcda6e
// }

// .chart-qxjy .on4 .color {
//     height: 50%
// }

// .chart-qxjy .on5 .color {
//     height: 37.5%
// }

// .chart-qxjy .on6 .color {
//     height: 25%
// }

// .chart-qxjy .on7 .color {
//     height: 12.5%;
//     border-top: .5px solid #fdb73d
// }
</style>
