import axios from '../../axios'
const basePath = '/core/treatmentRecord'

export function save(data) {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/mgmt/treatmentRecords',
    method: 'get',
    params: data
  })
}

// 分页查询
export const findPageForManagement = (data) => {
  return axios({
    url: '/mgmt/treatmentRecords/management',
    method: 'get',
    params: data
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + '/findInfoByUniqueKey/record-mgmt',
    method: 'get',
    params: data
  })
}
// 用药建议审核
export function approveTreatmentRecord(data) {
  return axios({
    url: basePath + '/approveTreatmentRecord',
    method: 'post',
    data
  })
}

// 处方审核
export function approveTreatmentOrder(data) {
  return axios({
    url: '/mgmt/treatmentRecords/_approve',
    method: 'post',
    data
  })
}

// 审核常用语列表
export const getCommonWords = (data) => {
  return axios({
    url: '/mgmt/hospitalmanager/common-words',
    method: 'get',
    params: data
  })
}

// 新增审核常用语
export function addCommonWords(data) {
  return axios({
    url: '/mgmt/hospitalmanager/common-words',
    method: 'post',
    data
  })
}

// 删除审核常用语
export function delCommonWords(id) {
  return axios({
    url: `/mgmt/hospitalmanager/common-words/${id}`,
    method: 'delete'
  })
}
