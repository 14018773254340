<template>
  <div class="mobile">
    <div style="">
      <div class="mai-item stability">
        <h1 class="title">测量稳定指数：{{ data.score }}%</h1>
        <div class="paragraph-wrap">
          <!---->
          <div class="paragraph">
            <p class="paragraph-p">若稳定指数低于60%，脉象结果不建议采用，可重新测量。</p>
            <p class="paragraph-p">注意测量过程中避免说话、身体晃动等。</p>
          </div>
        </div>
      </div>
      <div class="page-top gray">
        <div class="print-user">
          <div class="portrait" style="background-image: url('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/wxmini/static/common/huanzhetx.png')"></div>
          <div class="info">
            <div class="name">
              <strong><!----></strong>
              <span></span>
              <span style="display: none"></span>
              <span></span>
            </div>
            <div>{{ data.time }}</div>
            <div class="hand" style="">
              <span style="display: none">Both hands</span>
              <span>{{ data.hand == 2 ? '双手' : '单手' }}</span>
            </div>
          </div>
        </div>
        <div class="title" style="display: none">
          <h1>Health report</h1>
          <h2>Intelligent pulse diagnosis system</h2>
        </div>
        <div class="">
          <div style="font-size: 26px; font-weight: 700">健康报告</div>
          <div style="text-align: right">智能脉诊系统</div>
        </div>
      </div>
    </div>
    <div class="mai-item mai-desc" style="">
      <div class="desc">
        <h1 class="title">
          <div>总体脉象：{{ talSummary}}</div>
        </h1>
        <div class="heart-rate img-heart-rate">心率：{{ data.heartrate }}次/分钟</div>
        <p>注释:</p>
        <p v-for="(item, index) in data.mais.summary_infos" :key="index">{{ item.explaination_cn }}</p>
        <!-- <p>{{ data.mais.summary_infos[1].explaination_cn }}</p>
        <p>{{ data.mais.summary_infos[2].explaination_cn }}</p> -->
      </div>
      <div class="mai-hand">
        <div class="img-hand right"></div>
        <div class="img-hand left"></div>
      </div>
    </div>

    <!-- 左手 -->
    <div class="mai-item mai-3d">
      <h1 class="title">左手脉象：{{ branchResult.left.total }}</h1>
      <Total3d :feature="feature.left" :width="channelWidth" :total-mai="totalMai.title" />
    </div>

    <div style="">
      <div class="mai-item mai-branch">
        <h1 class="title">
          <span>左手分部脉象</span>
          <div class="branch-legend">
            <div class="unhealthy">异常区</div>
            <div>健康区</div>
          </div>
        </h1>
        <BranchGroup :branch-result="branchResult.left" :feature="feature.left" :width-bar="150" :width3d="150 * 2.3" />

        <div class="hole-body" style="margin-top: 3rem">
          <div style="">
            <div style="display: flex">
              <div class="handimg">
                <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/lefthand.png" alt="" class="mypic" />
              </div>
              <div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/cun_top.png" alt="" />
                  </div>
                  <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png" alt="" />
                  <img :src="data.data_left.heatMap.heatmap_img_cun_url" alt="" class="mai-img" style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">左寸</span>
                  <div class="mai-color-image"><img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png" alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font"><img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/gun_top.png" alt="" /></div>
                  <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png" alt="" />
                  <img :src="data.data_left.heatMap.heatmap_img_guan_url" alt="" class="mai-img" style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">左关</span>
                  <div class="mai-color-image"><img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png" alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/chi_top.png" alt="" />
                  </div>
                  <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png" alt="" />
                  <img :src="data.data_left.heatMap.heatmap_img_chi_url" alt="" class="mai-img" style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">左尺</span>
                  <div class="mai-color-image">
                    <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png" alt="" class="mai-color-img" />
                  </div>
                </div>
                <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/waiya.png" alt="" class="timeWidth" style="width: 88%" />
              </div>
            </div>
            <div style="display: flex; width: 100%">
              <div class="far">
                <div>
                  <div>外</div>
                  <div>压</div>
                </div>
              </div>
              <div class="chart-branch-3d2" v-if="isgetLeftHeatmap">
                <Chart :x="xLeftValue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右手 -->
    <div class="mai-item mai-3d">
      <h1 class="title">右手脉象：{{ branchResult.right.total }}</h1>
      <Total3dRight :feature="feature.right" :width="channelWidth" :total-mai="totalMai.title" />
    </div>

    <div style="">
      <div class="mai-item mai-branch">
        <h1 class="title">
          <span>右手分部脉象</span>
          <div class="branch-legend">
            <div class="unhealthy">异常区</div>
            <div>健康区</div>
          </div>
        </h1>
        <BranchGroup :branch-result="branchResult.right" :feature="feature.right" :width-bar="150" :width3d="150 * 2.3" />

        <div class="hole-body" style="margin-top: 3rem">
          <div style="">
            <div style="display: flex">
              <div class="handimg">
                <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/lefthand.png" alt="" class="mypic" />
              </div>
              <div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/cun_top.png" alt="" />
                  </div>
                  <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png" alt="" />
                  <img :src="data.data_right.heatMap.heatmap_img_cun_url" alt="" class="mai-img" style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">右寸</span>
                  <div class="mai-color-image"><img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png" alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font"><img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/gun_top.png" alt="" /></div>
                  <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png" alt="" />
                  <img :src="data.data_right.heatMap.heatmap_img_guan_url" alt="" class="mai-img" style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">右关</span>
                  <div class="mai-color-image"><img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png" alt="" /></div>
                </div>
                <div class="mai-all">
                  <div class="mai-font">
                    <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/chi_top.png" alt="" />
                  </div>
                  <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/kedu1.png" alt="" />
                  <img :src="data.data_right.heatMap.heatmap_img_chi_url" alt="" class="mai-img" style="position: relative" />
                  <span style="position: absolute; top: 0px; left: 8%; color: white">右尺</span>
                  <div class="mai-color-image">
                    <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/colorimg1.png" alt="" class="mai-color-img" />
                  </div>
                </div>
                <img src="https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/waiya.png" alt="" class="timeWidth" style="width: 88%" />
              </div>
            </div>
            <div style="display: flex; width: 100%">
              <div class="far">
                <div>
                  <div>外</div>
                  <div>压</div>
                </div>
              </div>
              <div class="chart-branch-3d2" v-if="isgetRightHeatmap">
                <Chart :x="xRightValue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 六经辩证 -->
    <div class="mai-item mai-branch">
      <h1 class="title">
        六经辨证
        <span class="show-img" @click="showImgFn">查看大图</span>
      </h1>
      <div :class="{ 'channel-big-img': isShowImg }">
        <i class="icon-close" :style="{ display: isShowImg ? 'initial' : 'none' }" @click="showImgFn"></i>
        <ChannelChart :channels="channelReault" :heartrate="heartrate" :summary="summary" :width="isShowImg ? '700' : channelWidth" />
      </div>

      <ChannelParagraph :channels="channelReault" :symptoms="symptomsResult" />
    </div>

    <!-- 气血津液 -->
    <div class="mai-item">
      <h1 class="title">气血津液</h1>
      <QxjyChart :value="qxjyResult.level" />
      <QxjyParagraph :qxjy="qxjyResult" />
    </div>

    <!-- 邪气 -->
    <div class="mai-item">
      <h1 class="title">邪气</h1>
      <HeresyIcon :value="heresyResult.value" />
      <HeresyParagraph :heresy="heresyResult" />
    </div>

    <!-- 建议 -->
    <div class="mai-item" style="">
      <h1 class="title">养生建议</h1>
      <div class="paragraph-wrap">
        <div class="paragraph">
          <h2 class="sub-title">饮食</h2>
          <p class="paragraph-p">{{ healthAdvice.new_food_propose }}</p>
          <h2 class="sub-title">运动</h2>
          <p class="paragraph-p">{{ healthAdvice.new_sport_propose }}</p>
          <h2 class="sub-title">生活</h2>
          <p class="paragraph-p">{{ healthAdvice.other_propose }}</p>
          <h2 class="sub-title" style="display: none">Nothing</h2>
          <h2 class="sub-title" style="display: none">无</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Total3d from './components/total3d.vue'
import Total3dRight from './components/total3dRight.vue'
import BranchGroup from './components/Branch/Group.vue'
import Chart from './components/Branch/Chart.vue'
import ChannelChart from './components/ChartM.vue'
import ChannelParagraph from './components/Paragraph.vue'
import QxjyChart from './components/QxjyChart/QxjyChart.vue'
import QxjyParagraph from './components/QxjyChart/QxjyParagraph.vue'
import HeresyIcon from './components/HeresyIcon/Icon.vue'
import HeresyParagraph from './components/HeresyIcon/paragraph.vue'
import { getTotalMaiResult, getBranchResult } from './components/three.js'
import { getChannelResult, getSymptomsResult, getQxjyResult, getHeresyResult } from './components/maiResult.js'
import 'whatwg-fetch'

export default {
  components: {
    Total3d,
    Total3dRight,
    Chart,
    BranchGroup,
    ChannelChart,
    ChannelParagraph,
    QxjyChart,
    QxjyParagraph,
    HeresyIcon,
    HeresyParagraph
  },
  data() {
    return {
      talSummary:null,
      isShowImg: false,
      healthAdvice: {},
      qxjyResult: {},
      heresyResult: {},
      totalMai: {},
      channelReault: {},
      heartrate: null,
      summary: null,
      symptomsResult: null,
      data: {
        summary: { shu: [0, 0.286], chi: [0, 0], hua: [0, 0], xi: [0, 0.141], xian: [1, 0.676], ruan: [0, 0], fu: [0, 0], chen: [0, 0.158], kong: [0, 0], shi: [0, 0] },
        data_left: {
          cun: { shu: 64, hua: 99, xian: 84, fu: 57 },
          guan: { shu: 64, hua: 36, xian: 20, fu: 28 },
          chi: { shu: 64, hua: 50, xian: 20, fu: 14 },
          heatMap: {
            heatmap_data_raw_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676.csv',
            heatmap_data_res_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_data_res.csv',
            heatmap_img_cun_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_img_cun.png',
            heatmap_img_guan_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_img_guan.png',
            heatmap_img_chi_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_img_chi.png',
            heatmap_data_res_cun_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_data_res_cun.csv',
            heatmap_data_res_guan_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_data_res_guan.csv',
            heatmap_data_res_chi_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-17-22-676_heatmap_data_res_chi.csv'
          },
          flag: { upDownFlag: 0, sortFlag: 'F' }
        },
        data_right: {
          cun: { shu: 64, hua: 99, xian: 84, fu: 57 },
          guan: { shu: 64, hua: 36, xian: 20, fu: 28 },
          chi: { shu: 64, hua: 50, xian: 20, fu: 14 },
          heatMap: {
            heatmap_data_raw_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560.csv',
            heatmap_data_res_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_data_res.csv',
            heatmap_img_cun_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_img_cun.png',
            heatmap_img_guan_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_img_guan.png',
            heatmap_img_chi_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_img_chi.png',
            heatmap_data_res_cun_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_data_res_cun.csv',
            heatmap_data_res_guan_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_data_res_guan.csv',
            heatmap_data_res_chi_url: 'https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-09-18_19-19-30-560_heatmap_data_res_chi.csv'
          },
          flag: { upDownFlag: 0, sortFlag: 'F' }
        },
        feature_left: {
          cun_amp_max: 28.96,
          cun_emerge_force: 6584,
          cun_width: 21.6,
          cun_a_w_ratio: 5,
          cun_vital_pres: 11561,
          guan_amp_max: 9.88,
          guan_emerge_force: 6486,
          guan_width: 6.4,
          guan_vital_pres: 15498,
          chi_amp_max: 9.88,
          chi_emerge_force: 8514,
          chi_width: 8,
          chi_a_w_ratio: 4,
          chi_vital_pres: 18460
        },
        feature_right: {
          cun_amp_max: 28.96,
          cun_emerge_force: 6584,
          cun_width: 21.6,
          cun_a_w_ratio: 5,
          cun_vital_pres: 11561,
          guan_amp_max: 9.88,
          guan_emerge_force: 6486,
          guan_width: 6.4,
          guan_vital_pres: 15498,
          chi_amp_max: 9.88,
          chi_emerge_force: 8514,
          chi_width: 8,
          chi_a_w_ratio: 4,
          chi_vital_pres: 18460
        },
        heartrate: 83,
        hand: 2,
        timezone: 'UTC+08:00',
        time: '2024-09-18 19:17:23',
        summary_desc: { summary: ['弦'], left: { summary: ['弦'], guan: '软', chi: '软,沉', cun: '弦,滑' }, right: { summary: ['弦'], guan: '软', chi: '软,沉', cun: '弦,滑' } },
        clinic: '',
        app_name: '20',
        mais: {
          other_propose: { other_propose: '避免熬夜、思虑过度，脑力劳动和体力劳动相结合。' },
          new_food_propose: { new_food_propose: '吃白萝卜、佛手、百合、银耳、木耳、绿叶蔬菜。' },
          summary_infos: [
            {
              explaination_cn: '如按琴弦，端直而长，挺然有力',
              explaination_en: 'feels straight, long and tense, like the feeling of pressing a tight string of a musical instrument.',
              name_cn: '弦',
              name_en: 'Wiry'
            }
          ],
          create_time: '2024-09-18T19:19:39',
          desc_detail: { desc_detail: ['由压力大、生活节奏快等导致。指平时轻度压力增大、用脑过度，自我调节不足，导致情绪紧张或容易紧张。应适当增加体力运动，学会自我调节、自我放松。'] },
          health_score: 94,
          dampness: {
            score: '0',
            text: '',
            desc: '湿是水在皮肤肌肉关节中代谢迟涩的表现，中医认为水代谢不仅和肾的利尿，膀胱的储存及排尿有关，还和肺对水的输布，心的传导，汗液排放及脾胃的运化功能有关，其中脾胃的运化功能起决定性作用。湿郁肌表：胸脘满闷，口淡纳呆；湿阻经络：神昏，肢厥，身热不扬，闷乱等；湿着筋骨：关节疼痛或肿胀，手足沉重等。湿邪留滞，闭阻气血，则肌肤麻木不仁，活动不便等；湿蔽清阳：头重如裹或头晕目眩，听力减退证，肢体重滞疼痛，困倦不举。《黄帝内经.素问》：“诸痉项强，皆属于湿。”'
          },
          chill: { score: '0', text: '', desc: 'nullnull' },
          fever: { score: '0', text: '', desc: 'nullnull' },
          blood: { score: '100', text: '血好', desc: '血指血液，好指合宜、妥当，血好指血液量正好不多不少。' },
          humor: { score: '75', text: '液少', desc: '液是人体正常的一种水分，液少是指人体正常的液减少。' },
          prescription_propose: { prescription_propose: '逍遥散，小柴胡汤' },
          qi: { score: '125', text: '气滞', desc: '气指人体正气，滞指停滞、不流通。气滞指人体正气运行不通畅。' },
          new_sport_propose: { new_sport_propose: '适度运动，如慢跑、散步，不要大汗淋漓，不宜过度运动。以运动后身体舒服为度，如运动后感觉劳累、疲倦为过度。' },
          other_symptoms: [{ other_symptoms: '紧张' }, { other_symptoms: '' }, { other_symptoms: '' }, { other_symptoms: '' }, { other_symptoms: '' }, { other_symptoms: '' }],
          phlegm: { score: '0', text: '', desc: 'nullnull' },
          desc: { desc: ['紧张'] },
          fluids: { score: '75', text: '津少', desc: '津是人体正常的一种水分，津少是指人体正常的津减少。' }
        },
        channels: {},
        device_model: 'TY-07-E19EF76D5015',
        score: 100,
        single_hand_can_ctmd: ''
      },
      // data: {
      //   "summary": {
      //     "shu": [
      //       0,
      //       0.197
      //     ],
      //     "chi": [
      //       0,
      //       0.0
      //     ],
      //     "hua": [
      //       0,
      //       0.0
      //     ],
      //     "xi": [
      //       1,
      //       0.869
      //     ],
      //     "xian": [
      //       0,
      //       0.0
      //     ],
      //     "ruan": [
      //       1,
      //       0.893
      //     ],
      //     "fu": [
      //       0,
      //       0.0
      //     ],
      //     "chen": [
      //       1,
      //       0.815
      //     ],
      //     "kong": [
      //       0,
      //       0.0
      //     ],
      //     "shi": [
      //       0,
      //       0.0
      //     ]
      //   },
      //   "data_left": {
      //     "cun": {
      //       "shu": 64,
      //       "hua": 9,
      //       "xian": 8,
      //       "fu": 14
      //     },
      //     "guan": {
      //       "shu": 64,
      //       "hua": 2,
      //       "xian": 8,
      //       "fu": 42
      //     },
      //     "chi": {
      //       "shu": 64,
      //       "hua": 9,
      //       "xian": 4,
      //       "fu": 6
      //     },
      //     "heatMap": {
      //       "heatmap_data_raw_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64.csv",
      //       "heatmap_data_res_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_data_res.csv",
      //       "heatmap_img_cun_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_img_cun.png",
      //       "heatmap_img_guan_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_img_guan.png",
      //       "heatmap_img_chi_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_img_chi.png",
      //       "heatmap_data_res_cun_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_data_res_cun.csv",
      //       "heatmap_data_res_guan_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_data_res_guan.csv",
      //       "heatmap_data_res_chi_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-23-17-64_heatmap_data_res_chi.csv"
      //     },
      //     "flag": {
      //       "upDownFlag": 0,
      //       "sortFlag": "F"
      //     }
      //   },
      //   "data_right": {
      //     "cun": {
      //       "shu": 55,
      //       "hua": 2,
      //       "xian": 5,
      //       "fu": 87
      //     },
      //     "guan": {
      //       "shu": 55,
      //       "hua": 29,
      //       "xian": 4,
      //       "fu": 5
      //     },
      //     "chi": {
      //       "shu": 55,
      //       "hua": 13,
      //       "xian": 4,
      //       "fu": 34
      //     },
      //     "heatMap": {
      //       "heatmap_data_raw_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5.csv",
      //       "heatmap_data_res_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_data_res.csv",
      //       "heatmap_img_cun_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_img_cun.png",
      //       "heatmap_img_guan_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_img_guan.png",
      //       "heatmap_img_chi_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_img_chi.png",
      //       "heatmap_data_res_cun_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_data_res_cun.csv",
      //       "heatmap_data_res_guan_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_data_res_guan.csv",
      //       "heatmap_data_res_chi_url": "https://oss-cn-beijing.aliyuncs.com/taiyi/Diabetes_APP/user/TY-KUqiZTPu-7686/heatMap/2024-08-20_10-25-13-5_heatmap_data_res_chi.csv"
      //     },
      //     "flag": {
      //       "upDownFlag": 0,
      //       "sortFlag": "F"
      //     }
      //   },
      //   "feature_left": {
      //     "cun_amp_max": 4.9,
      //     "cun_emerge_force": 4577,
      //     "cun_width": 2.4,
      //     "cun_a_w_ratio": 14,
      //     "cun_vital_pres": 18440,
      //     "guan_amp_max": 5.04,
      //     "guan_emerge_force": 4379,
      //     "guan_width": 0.8,
      //     "guan_vital_pres": 13543,
      //     "chi_amp_max": 2.94,
      //     "chi_emerge_force": 4468,
      //     "chi_width": 2.4,
      //     "chi_a_w_ratio": 8,
      //     "chi_vital_pres": 21456
      //   },
      //   "feature_right": {
      //     "cun_amp_max": 3.64,
      //     "cun_emerge_force": 4532,
      //     "cun_width": 0.8,
      //     "cun_a_w_ratio": 32,
      //     "cun_vital_pres": 6553,
      //     "guan_amp_max": 2.66,
      //     "guan_emerge_force": 6436,
      //     "guan_width": 5.6,
      //     "guan_vital_pres": 22326,
      //     "chi_amp_max": 2.66,
      //     "chi_emerge_force": 8399,
      //     "chi_width": 3.2,
      //     "chi_a_w_ratio": 5,
      //     "chi_vital_pres": 14656
      //   },
      //   "heartrate": 80,
      //   "hand": 2,
      //   "timezone": "UTC+08:00",
      //   "time": "2024-08-20 10:23:18",
      //   "summary_desc": {
      //     "summary": [
      //       "细",
      //       "软",
      //       "沉"
      //     ],
      //     "left": {
      //       "summary": [
      //         "细",
      //         "软",
      //         "沉"
      //       ],
      //       "guan": "软,细",
      //       "chi": "软,沉,细",
      //       "cun": "软,沉,细"
      //     },
      //     "right": {
      //       "summary": [
      //         "细",
      //         "软"
      //       ],
      //       "guan": "软,沉",
      //       "chi": "软,细",
      //       "cun": "软,浮,细"
      //     }
      //   },
      //   "clinic": "",
      //   "app_name": "20",
      //   "mais": {
      //     "other_propose": {
      //       "other_propose": "避免熬夜、思虑过度，脑力劳动和体力劳动相结合。适度运动，一日三餐按时吃。"
      //     },
      //     "new_food_propose": {
      //       "new_food_propose": "适当吃瘦肉，如牛肉、羊肉、鸡肉等。"
      //     },
      //     "summary_infos": [
      //       {
      //         "explaination_cn": "细如丝，应指明显，层次分明",
      //         "explaination_en": "as thin as a silken thread, clearly perceptible under pressure with distinct gradation",
      //         "name_cn": "细",
      //         "name_en": "Thread"
      //       },
      //       {
      //         "explaination_cn": "如帛衣在水，柔软无力",
      //         "explaination_en": "soft and weak like silk clothes floating on the water",
      //         "name_cn": "软",
      //         "name_en": "Soft"
      //       },
      //       {
      //         "explaination_cn": "轻取不应指，重按始得",
      //         "explaination_en": "difficult to feel and requires heavy \r\npressure",
      //         "name_cn": "沉",
      //         "name_en": "Deep"
      //       }
      //     ],
      //     "create_time": "2024-08-20T10:25:22",
      //     "desc_detail": {
      //       "desc_detail": [
      //         "虚寒证引起的怕冷，不愿意活动、不想说话。多由于人体阳气不足，寒邪内生引起的虚寒证，形寒肢冷指身体感到寒冷而四肢不温。倦卧懒言指精神不振疲倦、想坐着或躺着不愿意活动、甚至连话都懒得说。"
      //       ]
      //     },
      //     "health_score": 76,
      //     "dampness": {
      //       "score": "200",
      //       "text": "",
      //       "desc": "湿是水在皮肤肌肉关节中代谢迟涩的表现，中医认为水代谢不仅和肾的利尿，膀胱的储存及排尿有关，还和肺对水的输布，心的传导，汗液排放及脾胃的运化功能有关，其中脾胃的运化功能起决定性作用。湿郁肌表：胸脘满闷，口淡纳呆；湿阻经络：神昏，肢厥，身热不扬，闷乱等；湿着筋骨：关节疼痛或肿胀，手足沉重等。湿邪留滞，闭阻气血，则肌肤麻木不仁，活动不便等；湿蔽清阳：头重如裹或头晕目眩，听力减退证，肢体重滞疼痛，困倦不举。《黄帝内经.素问》：“诸痉项强，皆属于湿。”"
      //     },
      //     "chill": {
      //       "score": "200",
      //       "text": "",
      //       "desc": "nullnull"
      //     },
      //     "fever": {
      //       "score": "0",
      //       "text": "有湿，有寒",
      //       "desc": "nullnull"
      //     },
      //     "blood": {
      //       "score": "75",
      //       "text": "血虚",
      //       "desc": "血指人体血液，虚指空虚、虚弱。血虚指人体血液虚损、不足。"
      //     },
      //     "humor": {
      //       "score": "75",
      //       "text": "液少",
      //       "desc": "液是人体正常的一种水分，液少是指人体正常的液减少。"
      //     },
      //     "prescription_propose": {
      //       "prescription_propose": "四君子汤，小建中汤"
      //     },
      //     "qi": {
      //       "score": "75",
      //       "text": "气虚",
      //       "desc": "气指人体正气，虚指空虚、虚弱。气虚指人体正气虚弱不足。"
      //     },
      //     "new_sport_propose": {
      //       "new_sport_propose": "适度运动，如慢跑、散步，不要大汗淋漓，不宜过度运动。以运动后身体舒服为度，如运动后感觉劳累、疲倦为过度。"
      //     },
      //     "other_symptoms": [
      //       {
      //         "other_symptoms": ""
      //       },
      //       {
      //         "other_symptoms": ""
      //       },
      //       {
      //         "other_symptoms": "倦卧懒言"
      //       },
      //       {
      //         "other_symptoms": ""
      //       }
      //     ],
      //     "phlegm": {
      //       "score": "0",
      //       "text": "",
      //       "desc": "nullnull"
      //     },
      //     "desc": {
      //       "desc": [
      //         "形寒肢冷，倦卧懒言"
      //       ]
      //     },
      //     "fluids": {
      //       "score": "75",
      //       "text": "津少",
      //       "desc": "津是人体正常的一种水分，津少是指人体正常的津减少。"
      //     }
      //   },
      //   "channels": {
      //     "xian_ruan": "软",
      //     "taiyang": "",
      //     "create_time": "2019-06-17T09:44:21",
      //     "jueyin": "",
      //     "shu_chi": "",
      //     "yangming": "",
      //     "hua_xi": "细",
      //     "remark": "缺气",
      //     "shaoyang": "",
      //     "shaoyin": "少阴",
      //     "fu_chen": "沉",
      //     "taiyin": "",
      //     "id": 22
      //   },
      //   "device_model": "TY-07-0139E1001E7E",
      //   "score": 100,
      //   "single_hand_can_ctmd": ""
      // },
      webviewStyles: {
        progress: {
          color: '#8362DF'
        }
      },
      handtype: '',
      ppgBack: '',
      essayUrl: '',
      channelWidth: 500,
      feature: {},
      branchResult: {},
      xLeftValue: [],
      xRightValue: [],
      isgetLeftHeatmap: false,
      isgetRightHeatmap: false
    }
  },
  computed: {
    // channelWidth() {
    //   if (this.channelWidth > 500) {
    //     return 500;
    //   } else if (this.channelWidth < 320) {
    //     return 288;
    //   } else {
    //     return this.channelWidth * 0.9;
    //   }
    // }
  },
  methods: {
    showImgFn() {
      this.isShowImg = !this.isShowImg
    },
    back() {
      getApp().globalData.navigator('back')
    },
    // 左手
    async getLeftHeatmap(a) {
      const res = await this.fGetHeatmapData(a)

      const xSarr = []
      res.x.forEach((i, ind) => {
        xSarr.push([i / 1000, res.y[ind] / 100])
      })

      this.xLeftValue = xSarr
      this.isgetLeftHeatmap = true
    },
    // 右手
    async getRightHeatmap(a) {
      const res = await this.fGetHeatmapData(a)

      const xSarr = []
      res.x.forEach((i, ind) => {
        xSarr.push([i / 1000, res.y[ind] / 100])
      })

      this.xRightValue = xSarr
      this.isgetRightHeatmap = true
    },
    // 定义一个函数来获取数据
    async fGetHeatmapData(resUrl) {
      const url = 'https://api.reborn-tech.com/tycenter/heatmap/data?resUrl=' + resUrl

      try {
        // 发送请求
        const response = await fetch(url)

        // 检查响应是否成功
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        // 解析响应数据为 JSON
        const data = await response.json()

        // 打印数据到控制台（或者你可以进行其他处理）
        console.log(data, 'fGetHeatmapData')

        // 处理数据
        return data
      } catch (error) {
        // 捕捉错误并打印
        console.error('Error fetching heatmap data:', error)
      }
    }
  },
  mounted() {
    document.title = '检测报告'
  },
  created() {
    // console.log(JSON.parse(this.result),'totalMai');
    const screenWidth = window.innerWidth
    console.log('当前屏幕宽度:', screenWidth)

    this.channelWidth = screenWidth > 500 ? 500 : screenWidth < 320 ? 288 : screenWidth * 0.9
    let str = this.data.summary_desc.summary
     this.talSummary =str.join(",")
    this.getLeftHeatmap(this.data.data_left.heatMap.heatmap_data_res_url)

    this.getRightHeatmap(this.data.data_right.heatMap.heatmap_data_res_url)
    const result = this.data.mais
    console.log(result, 'result')

    this.totalMai = getTotalMaiResult(result.summary_infos || [])
    console.log(this.totalMai, 'this.totalMai')

    this.feature = { left: this.data.feature_left, right: this.data.feature_right }

    // 分部
    this.branchResult = {
      left: getBranchResult(this.data.data_left, this.data.summary_desc.left),
      right: getBranchResult(this.data.data_right, this.data.summary_desc.right)
    }

    console.log(this.branchResult, 'this.branchResult')

    this.channelReault = getChannelResult(this.data.channels)
    this.heartrate = this.data.heartrate
    this.summary = this.data.summary
    this.symptomsResult = getSymptomsResult(result)
    this.qxjyResult = getQxjyResult(result)
    this.heresyResult = getHeresyResult(result)
    this.healthAdvice = {
      new_food_propose: result.new_food_propose.new_food_propose,
      other_propose: result.other_propose.other_propose,
      new_sport_propose: result.new_sport_propose.new_sport_propose
    }
  }
}
</script>

<style scoped>
@import url('./pulse.css');

/* img */
.img-hand {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/mai_hand_right.jpg');
}

.img-hand.left {
  background-image: url('https://taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/mai_hand_left.jpg');
}

.img-heart-rate {
  background-image: url('https://taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/heart.png');
  background-repeat: no-repeat;
}

/* 样式定义 */
</style>
