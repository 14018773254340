<template>
  <div class="paragraph-wrap nop-fi11-box">
    <h2 class="sub-title" data-op-type="block">
      {{ qxjy.text.join('、') }}
    </h2>
    <div v-if="qxjy.desc.length > 0 && en" data-op-type="block" class="paragraph">
      <h3>Annotation:</h3>
    </div>
    <div v-if="qxjy.desc.length > 0 && !en" data-op-type="block" class="paragraph">
      <h3>注释:</h3>
    </div>
    <p v-for="(item, index) in qxjy.desc" :key="index" data-op-type="text" class="paragraph-p">
      {{ item }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    qxjy: {
      type: Object,
      required: true
    },
    en: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>
