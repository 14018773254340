import axios from '../../axios'
const basePath = '/core'
/*
 * 机构管理模块
 */

// 保存
export const save = (data) => {
  return axios({
    url: basePath + '/dept/save',
    method: 'post',
    data
  })
}
// 删除
export const batchDelete = (data) => {
  return axios({
    url: basePath + '/dept/delete',
    method: 'post',
    data
  })
}
// 查询机构树
export const findDeptTree = () => {
  return axios({
    url: basePath + '/dept/findTree',
    method: 'get'
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/dept/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}
