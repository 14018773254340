import axios from '../../axios'
const basePath = '/mgmt/chronic'

export function save(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath,
    method: 'put',
    data
  })
}

export function del(id) {
  return axios({
    url: basePath + `/${id}`,
    method: 'delete'
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 分页查询
export const findInfoKey = (id) => {
  return axios({
    url: basePath + `/detailById/${id}`,
    method: 'get'
  })
}
