<template>
    <div class="paragraph-wrap nop-fill-box">
      <h2 class="sub-title">
        <template v-if="heresy.text.length === 0 && en">
          Nothing
        </template>
        <template v-else-if="heresy.text.length === 0 && !en">
          无
        </template>
        <template v-else>
          {{ en ? `Boby ${heresy.text.join('、')}` : `体${heresy.text.join('、')}` }}
        </template>
      </h2>
      <div v-show="heresy.text.length > 0" data-op-type="block" class="paragraph">
        <h3 v-if="en">Annotation:</h3>
        <h3 v-else>注释:</h3>
      </div>
      <p v-for="(item, index) in heresy.desc" :key="index" data-op-type="text" class="paragraph-p">
        <template v-if="item.split('null').filter(v => !!v).length > 0">
          {{ item.split('null') }}
        </template>
        <template v-else>
          无
        </template>
      </p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      heresy: {
        type: Object,
        required: true
      },
      en: {
        type: String,
        default: ''
      }
    }
  }
  </script>
  