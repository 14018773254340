import axios from '../../axios'
const basePath = '/mgmt/medicine/decoctions'

export function findPage(params) {
  return axios({
    url: basePath,
    method: 'get',
    params
  })
}
// 添加
export function add(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}

// 修改
export function edit(data) {
  return axios({
    url: basePath + '/' + data.id,
    method: 'put',
    data
  })
}

// // 根据药品名称查询煎煮方法
// export function getMedicalDeco(drugsName) {
//   return axios({
//     url: basePath + `/${drugsName}`,
//     method: 'get'
//   })
// }
