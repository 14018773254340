/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-08-01 17:38:54
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-08-02 15:45:13
 * @FilePath: \drant-manager\src\http\moudules\memberManage\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 会员VIP套餐配置-列表
export const getlist = data => {
  return axios({
    url: '/mgmt/member/option/list',
    method: 'get',
    params: data
  })
}
// 会员VIP套餐配置详情
export const optiondetail = data => {
  return axios({
    url: '/mgmt/member/option/detail',
    method: 'get',
    params: data
  })
}
// 新增会员VIP套餐
export const optionadd = data => {
  return axios({
    url: '/mgmt/member/option/add',
    method: 'post',
    data
  })
}
// 删除会员VIP套餐
export const optiondelete = data => {
  return axios({
    url: '/mgmt/member/option/delete/' + data,
    method: 'post'
    // data
  })
}

// 附属礼包-列表
export const giftList = data => {
  return axios({
    url: '/mgmt/member/option/gift/package/getPage',
    method: 'get',
    params: data
  })
}

// 新增附属礼包
export const addGift = data => {
  return axios({
    url: '/mgmt/member/option/gift/package/add',
    method: 'post',
    data
  })
}

// 编辑附属礼包
export const editGift = data => {
  return axios({
    url: '/mgmt/member/option/gift/package/edit',
    method: 'post',
    data
  })
}

// 删除附属礼包
export const deleteGift = data => {
  return axios({
    url: '/mgmt/member/option/gift/package/delete/' + data,
    method: 'post'
  })
}
