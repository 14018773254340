<template>
    <div class="chart-heresy">
      <div v-for="item in getHeresyItems()" :key="item.key" :class="`item icon_${item.key}`">
        <p>{{ item.text }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { heresyItems } from '../Branch/index.js';
  
  export default {
    props: {
      value: {
        type: Array,
        default: () => []
      },
      en: {
        type: String,
        default: ''
      }
    },
    methods: {
      getHeresyItems() {
        const exists = heresyItems.filter(item => this.value.includes(item.key));
        return exists.map(item => ({
          key: item.key,
          text: this.en ? item.key : item.title
        }));
      }
    }
  }
  </script>
  
  <style scoped >
  .chart-heresy {
    padding: 1.4rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.chart-heresy .item {
    text-align: center;
    width: 8rem;
    padding-top: 52px;
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: top;
    font-size: 1.4rem;
    margin-bottom: 1rem
}

.chart-heresy .icon_chill {
    background-image: url(//taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/icon_han.png?v=2.25.2)
}

.chart-heresy .icon_fever {
    background-image: url(//taiyi.oss-accelerate.aliyuncs.com/website/hybrid-app/report/icon_re.png?v=2.25.2)
}

.chart-heresy .icon_dampness {
    background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_shi.png)
}

.chart-heresy .icon_feng {
    background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_feng.png)
}

.chart-heresy .icon_phlegm {
    background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_yin.png)
}

.chart-heresy .icon_tan {
    background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_tan.png)
}

.chart-heresy .icon_qijie {
    background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_qijie.png)
}

.chart-heresy .icon_yuxue {
    background-image: url(https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/pulseTaking/icon_yuxue.png)
}

  </style>
  