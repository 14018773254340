import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/hispital/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/hispital/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/hispital/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/hispital/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
