'use strict'

const watermark = {}

const setWatermark = (str, date, fillSt) => {
  const id = '1.23452384164.123412415'
  if (!date) {
    const dateTime = new Date()
    const year = dateTime.getFullYear()
    let month = dateTime.getMonth() + 1
    let day = dateTime.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    date = year + '' + month + day
  }

  if (document.getElementById(id) !== null) {
    document.getElementById('printMe').removeChild(document.getElementById(id))
  }

  const can = document.createElement('canvas')
  can.width = 225
  can.height = 180

  const cans = can.getContext('2d')
  cans.rotate(-45 * Math.PI / 180)
  cans.font = '14px Vedana'
  cans.fillStyle = fillSt // 'rgba(200, 200, 200, 0.10)' //'rgba(0, 0, 0, 0.10)'//
  //   cans.textAlign = 'left'
  //   cans.textBaseline = 'Middle'
  cans.fillText(str, -62, can.height / 2)
  // cans.fillText(date, -75, 108)

  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '0'
  div.style.left = '0'
  div.style.position = 'absolute'
  div.style.zIndex = '10000'
  div.style.width = '100%'
  div.style.height = '100%'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  console.log(21)
  if (!document.getElementById('printMe')) {
    return
  }
  document.getElementById('printMe').appendChild(div)
  return id
}

// 该方法只允许调用一次
watermark.set = (str, date, fillSt) => {
  let id = setWatermark(str, date, fillSt)
  // const aa = setInterval(() => {
  if (document.getElementById(id) === null) {
    id = setWatermark(str, date, fillSt)
  }
  // }, 500)
  window.onresize = () => {
    // clearInterval(aa)
    setWatermark(str, date, fillSt)
  }
}

export default watermark
