import router from './router'
// import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { setToken, getToken, removeToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import api from '@/http/api'
import store from '@/store'
import Layout from '@/layout'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['/login', '/auth-redirect', '/casehistory', '/faceR','/pulseTaking'] // no redirect whitelist
// import { MessageBox } from 'element-ui'
router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  const userName = sessionStorage.getItem('user')
  // const userId = sessionStorage.getItem('userId')
  // const hasToken = sessionStorage.getItem('token') // Cookies.get('token')// getToken()
  if (getToken() && to.path !== '/casehistory') {
    if (to.path === '/login' || to.path === '/faceR') {
      // if is logged in, redirect to the home page
      if (window.location.search.indexOf('orderNo') > -1) {
        next()
      } else {
        // const res = await api.organizationManager.getAgentInfo()
        // console.log('拦截3')
        // if (res.code === 200 && res !== null) {
        //   // 代理商机构拦截跳转
        //   next({ path: '/organization' })
        // } else {
        next({ path: '/dashboard/dashboard' })
        // }
      }
      NProgress.done()
    } else {
      try {
        if (window.location.search.indexOf('orderNo') > -1) {
          sessionStorage.setItem('facer', '')
          next(`/faceR${window.location.search}`)
          // return
        }
        // 加载动态菜单和路由
        // if (userName) {
        addDynamicMenuAndRoutes(userName, null, to, next)
        store.commit('user/SET_NAME', userName)
        // next()
        // } else {
        //   console.log('login')
        //   if (window.location.search.indexOf('orderNo') > -1) {
        //     next(`/faceR${window.location.search}`)
        //     return
        //   }
        //   loginOut(next, to)
        // }
      } catch (error) {
        // remove token and go to login page to re-login
        loginOut(next, to)
      }
      // addDynamicMenuAndRoutes(userName, userId, to, next)
      //   }
      // } catch (error) {
      //   // remove token and go to login page to re-login
      //   loginout()
      // }
    }
  } else {
    /* has no token*/
    if (to.query.token) {
      // 线上病历
      // sessionStorage.setItem('token', to.query.token)
      setToken(to.query.token)
    }
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

function loginOut(next, to) {
  // MessageBox.confirm('登录已注销，点击取消按钮停留当前页面或重新登录！', '注销确认', {
  //   confirmButtonText: '重新登录',
  //   cancelButtonText: '取消',
  //   type: 'warning'
  // }).then(() => {
  sessionStorage.clear() // 清除本地缓存
  // Cookies.remove('token')
  removeToken()
  store.commit('user/SET_PERMSS', [])
  store.commit('permission/SET_MENUS', [])
  store.commit('user/SET_ROLES', [])
  store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
  store.commit('user/SET_TOKEN', '') // 设置token
  store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色
  next(`/login?redirect=${to.path}`)
  NProgress.done()
  // })
}
/**
 * 加载动态菜单和路由
 */
async function addDynamicMenuAndRoutes(userName, userId, to, next) {
  // 处理IFrame嵌套页面
  // handleIFrameUrlhandleIFrameUrl(to.path)
  if (store.state.app.menuRouteLoaded) {
    // const res = await api.organizationManager.getAgentInfo()
    // console.log('拦截5')
    // if (res.code === 200 && res !== null) {
    //   // 代理商机构拦截跳转
    //   next({ path: '/organization' })
    // } else {
    next()
    // }
    console.log('动态菜单和路由已经存在.',)
    return
  }
  console.log('hahha')
  // const permissions = []
  let uname = ''
  await api.user
    .getCurrentUser()
    .then(res => {
      // 保存用户当前角色
      const currentRole = {
        roleId: res.data.currentRoleId,
        roleName: res.data.currentRoleName,
        roleCode: res.data.currentRoleCode
      }
      store.commit('user/SET_CURRENT_ROLE', currentRole) // 设置token
      sessionStorage.setItem('userInfo', JSON.stringify(res.data))
      store.commit('user/SET_ROLES', res.data.userRoles)
      // permissions = res.data.permissions || []
      uname = res.data.userName
      store.commit('user/SET_NAME', uname)
      const permissions = res.data.permissions || []
      const getpermArr = []
      permissions.forEach(v => {
        if (v.urlCode) {
          getpermArr.push(v.urlCode)
        }
      })
      store.commit('user/SET_PERMSS', getpermArr)
    })
    .catch(function(res3) {
      console.log('aaa', res3)
      loginOut(next, to)
      // removeToken()
      // const err = new Error(res3.data, res3.msg)
      // err.data = res3.data
      // throw err
    })
  store.commit('permission/SET_MENUS', [])
  if (!uname) {
    return
  }
  api.menu
    .findNavTree({ userName: uname })
    .then(res => {
      // 添加动态路由
      res.data.map((item, index) => {
        if (item.url === '/dashboard') {
          res.data.unshift(res.data.splice(index, 1)[0])
        }
      })
      store.commit('user/SET_PERMSSURL', res.data)
      const dynamicRoutes = addDynamicRoutes(res.data)
      // router.addRoutes(ansyRoutes)
      router.addRoutes(dynamicRoutes)
      console.log(true)
      // 保存加载状态
      store.commit('app/menuRouteLoaded', true)
      // 保存菜单树
      // store.commit('permission/SET_MENUS', ansyRoutes)
      store.commit('permission/SET_MENUS', dynamicRoutes)
      console.log('to', to)
      api.organizationManager
        .getAgentInfo()
        .then(r => {
          if (r.code === 200 && r.data !== null) {
            // 代理商机构拦截跳转
            if (to.path === '/dashboard/dashboard' || to.path === '/') {
              next({ path: '/organization/organizationList' })
            } else {
              next({ ...to })
            }
          } else {
            if (to.path === '/') {
              next({ path: '/dashboard/dashboard' })
            } else {
              next({ ...to })
            }
          }
        })
        .catch(function(res) {
          const err = new Error(res.data, res.msg)
          err.data = res.data
          throw err
        })
    })
    .catch(function(res) {
      const err = new Error(res.data, res.msg)
      err.data = res.data
      throw err
    })
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function addDynamicRoutes(menuList = [], routes = []) {
  var temp = []
  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i].isHidden && menuList[i].type === 0) {
      // 状态为隐藏的目录不加载
      continue
    }
    if (menuList[i].type === 1 && menuList[i].level === 0) {
      // 首级是菜单
      console.log('首级是菜单')
      menuList[i].parentUrl = menuList[i].parentUrl.replace(/^\//, '')
      const parentRoute1 = {
        path: '/' + menuList[i].parentUrl,
        name: menuList[i].parentUrl,
        component: Layout,
        redirect: '',
        children: []
      }
      // routes.push(parentRoute1)
      menuList[i].url = menuList[i].url.replace(/^\//, '')
      // 创建路由配置
      const route1 = {
        path: menuList[i].url,
        component: null,
        name: menuList[i].url,
        meta: {
          icon: menuList[i].icon,
          index: menuList[i].id,
          title: menuList[i].name
        }
      }
      // route1.parentRoute = parentRoute1
      if (menuList[i].isHidden) {
        route1.hidden = true
      }
      const url = getMenuComponentUrl(menuList[i].parentUrl, menuList[i].url)

      route1['component'] = resolve => require([`@/views/${url}`], resolve)
      parentRoute1.children.push(route1)
      routes.push(parentRoute1)
    } else {
      // 存在子菜单
      if (menuList[i].children && menuList[i].children.length >= 1) {
        var parentRoute = {
          path: menuList[i].url,
          component: Layout,
          redirect: '',
          alwaysShow: true,
          name: menuList[i].name,
          meta: { title: menuList[i].name, icon: menuList[i].icon },
          children: []
        }
        const childrens = menuList[i].children
        for (const index in childrens) {
          const item = childrens[index]
          item.parentRoute = parentRoute
        }
        routes.push(parentRoute)
        temp = temp.concat(menuList[i].children)
      } else if (menuList[i].url && /\S/.test(menuList[i].url) && menuList[i].type === 1) {
        // 存在子菜单
        menuList[i].url = menuList[i].url.replace(/^\//, '')
        // 创建路由配置
        const route = {
          path: menuList[i].url,
          component: null,
          name: menuList[i].url,
          meta: {
            icon: menuList[i].icon,
            index: menuList[i].id,
            title: menuList[i].name
          }
        }
        if (menuList[i].isHidden) {
          route.hidden = true
        }

        try {
          // 根据菜单URL动态加载vue组件，这里要求vue组件须按照url路径存储
          // 如url="sys/user"，则组件路径应是"@/views/sys/user.vue",否则组件加载不到
          const url = getMenuComponentUrl(menuList[i].parentUrl, menuList[i].url)

          route['component'] = resolve => require([`@/views/${url}`], resolve)
        } catch (e) {
          // console.log(e)
          const err = new Error(e.data, e.msg)
          err.data = e.data
          throw err
        }
        if (menuList[i].parentRoute && menuList[i].parentRoute.children) {
          menuList[i].parentRoute.children = menuList[i].parentRoute.children.concat(route)
        }
        // routes.push(route)
      }
    }
  }
  if (temp.length >= 1) {
    addDynamicRoutes(temp, routes)
  } else {
    console.log('动态路由加载...')
    // console.log(routes)
    console.log('动态路由加载完成.',routes)
  }
  return routes
}

function getMenuComponentUrl(parentUrlParam, urlParam) {
  const arrayParentPath = parentUrlParam.split('/')
  let parenturl1 = ''
  for (let i = 0; i < arrayParentPath.length; i++) {
    // url += array[i].substring(0, 1).toUpperCase() + array[i].substring(1) + '/'
    parenturl1 += arrayParentPath[i] + '/'
  }
  parenturl1 = parenturl1.substring(0, parenturl1.length)
  const array = urlParam.split('/')
  let url = ''
  for (let i = 0; i < array.length; i++) {
    // url += array[i].substring(0, 1).toUpperCase() + array[i].substring(1) + '/'
    url += array[i] + '/'
  }
  url = url.substring(0, url.length - 1) + '/index'
  if (parenturl1) {
    url = parenturl1 + url
  }
  return url
}

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
