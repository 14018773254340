export default {
  getSHA256Str: function(param) {
    if (param) {
      const sha256 = require('js-sha256').sha256// 这里用的是require方法
      const signStr = sha256(param)
      return signStr
    }
    return null
  },
  getSignStr: function(paramMap) {
    let sign = ''
    const key = 'zxcvbnmasdfghjklqwertyuiopzxcvbnmasdfghjklqwertyuiopzxcvbnmasdfg'
    const pre = key.substring(0, 31)
    const behind = key.substring(31)
    let str = ''
    for (const item of paramMap) {
      str += item
    }
    const newStr = pre + str + behind
    const secendStro = this.getStr(newStr, true)
    const secendStrq = this.getStr(newStr, false)
    const securityStr = secendStro + secendStrq
    sign = this.getSHA256Str(securityStr)
    sign = this.getSHA256Str(sign)
    sign = this.getSHA256Str(sign)
    return sign
  },
  getStr: function(param, flag) {
    let qNumStr = ''
    let oNumStr = ''
    for (let i = 0; i < param.length; i++) {
      if (i % 2 === 0) {
        oNumStr += param.charAt(i)
      } else {
        qNumStr += param.charAt(i)
      }
    }
    if (flag) {
      return oNumStr
    }
    return qNumStr
  }
}
