import axios from '../../axios'

// 专方列表
export function getSpecialDisease(data) {
  return axios({
    url: '/mgmt/special-disease/getPage',
    method: 'get',
    params: data
  })
}

// 新增专方
export function addSpecialDisease(data) {
  return axios({
    url: '/mgmt/special-disease/save',
    method: 'post',
    data: data
  })
}

// 编辑专方
export function editSpecialDisease(data) {
  return axios({
    url: '/mgmt/special-disease/edit',
    method: 'post',
    data: data
  })
}

// 专方详情
export function SpecialDiseaseDetail(data) {
  return axios({
    url: '/mgmt/special-disease/detail/'+data.id,
    method: 'get',
  })
}


// 删除专方
export function deleteSpecialDisease(data) {
  return axios({
    url: '/mgmt/special-disease/delete/'+data.id,
    method: 'post',
  })
}
