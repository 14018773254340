import axios from '../../axios'
const basePath = '/core/doctor'

export function save(data) {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}
export function add(data) {
  return axios({
    url: '/mgmt/hospital/doctors/_add',
    method: 'post',
    data
  })
}
// 获取用户列表
export function memberPageList(data) {
  return axios({
    url: '/core/member/pageList',
    method: 'get',
    params: data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findConsultationPage = (data, pageVo) => {
  return axios({
    url: basePath + '/findConsultationPage/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const findPage2 = (data) => {
  return axios({
    url: '/mgmt/hospital/doctors',
    method: 'get',
    params: data
  })
}

export const findDoctorByUniqueKey = (data) => {
  return axios({
    url: basePath + '/findDoctorByUniqueKey',
    method: 'get',
    params: data
  })
}

export function approve(data) {
  return axios({
    url: basePath + '/approve',
    method: 'post',
    data
  })
}

export function updateDoctorEnable(data) {
  return axios({
    url: basePath + '/updateDoctorEnable',
    method: 'post',
    data
  })
}

export function updateDoctorUnEnable(data) {
  return axios({
    url: basePath + '/updateDoctorUnEnable',
    method: 'post',
    data
  })
}

// 获取当前医生
export function findCurrentDoctor() {
  return axios({
    url: basePath + '/findCurrentDoctor',
    method: 'get'
  })
}

// 获取医生签名
export function apiGetUserStatus(data) {
  return axios({
    url: basePath + '/apiGetUserStatus',
    method: 'get',
    params: data
  })
}

// ---------------------------------------------------------------------------------------------------------------------------------------------------------

// 获取推荐医生列表
export const recommendFindPage = (data, pageVo) => {
  return axios({
    url: '/core/doctorRecommend/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 获取推荐名医医生列表
export const famousDoctorFindPage = (data, pageVo) => {
  return axios({
    url: '/core/doctorRecommend/page/doctorList/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 添加名医
export function addDoctor(data) {
  return axios({
    url: '/core/doctorRecommend/saveList',
    method: 'post',
    data
  })
}

// 删除名医
export function deleteDoctor(data) {
  return axios({
    url: '/core/doctorRecommend/deleteBatch',
    method: 'post',
    data
  })
}

// 上移
export function stopTop(data) {
  return axios({
    url: '/core/doctorRecommend/stopTop',
    method: 'post',
    data
  })
}

// 下移
export function stopDown(data) {
  return axios({
    url: '/core/doctorRecommend/stopDown',
    method: 'post',
    data
  })
}

// 医生状态数量
export const statusNum = (data) => {
  return axios({
    url: basePath + '/_statisticsByFlowStatus',
    method: 'get',
    params: data
  })
}

// 医生重新提交审核
export const applyFor = (data) => {
  return axios({
    url: basePath + '/_submit',
    method: 'post',
    data
  })
}

// 新增医生
export const appendDoctor = (data) => {
  return axios({
    url: `/mgmt/hospital/doctors/_register`,
    method: 'post',
    data
  })
}
