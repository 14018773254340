<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div class="fixed-header">
        <navbar ref="navbarRef" @updPassword="updPassword" @receiveMessage="receiveMessage" />
        <!-- <tags-view v-if="needTagsView" /> -->
        <div class="d-flex a-center">
          <span class="el-icon-info ml-2" />
          <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
        </div>
      </div>
      <app-main ref="appMainRef" @sendMessage="sendMessage" @reconnect="reconnect" />
    </div>
    <!-- <el-dialog title="修改密码" :visible.sync="changedown" width="30%">
      <el-form ref="editFrm" :model="form" :rules="passwordEdit" @load="resetFields">
        <el-form-item label="旧密码" label-position="right" label-width="120px" prop="oldPassword">
          <el-input v-model="form.oldPassword" clearable autocomplete="off" type="password" autocompleted="off" />
        </el-form-item>
        <el-form-item label="新密码" label-position="right" label-width="120px" prop="newPassword">
          <el-input v-model="form.newPassword" clearable autocomplete="off" type="password" autocompleted="off" />
        </el-form-item>
        <el-form-item label="确认密码" label-position="right" label-width="120px" prop="confirmPassword">
          <el-input v-model="form.confirmPassword" clearable autocomplete="off" type="password" autocompleted="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button type="primary" @click="affirm">确 定</el-button>
      </div>
    </el-dialog> -->
    <changePassword ref="changePassword" />
  </div>
</template>
<script>
//  TagsView
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import { MessageBox } from 'element-ui'
import changePassword from '@/components/changePassword'
import { removeToken } from '@/utils/auth'
// 使用vuex做全局loading时使用
import store from '@/store'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
    Breadcrumb,
    changePassword
    // TagsView
  },
  mixins: [ResizeMixin],
  data() {
    return {
      changedown: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordEdit: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword: [{
          required: true,
          validator: this.Format.FormValidate.Form().PawIntension, // validator： 自定义验证规则
          trigger: 'blur'// change blur
        }],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: (rule, value, callback) => {
            if (value !== this.form.newPassword) {
              callback(new Error('第二次密码输入错误'))
            } else {
              callback()
            }
          } }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  created() {
  },
  methods: {
    sendMessage(data) {
      this.$refs.navbarRef.sendMessage(data)
    },
    reconnect() {
      this.$refs.navbarRef.reconnect()
    },
    receiveMessage(data) {
      if (this.$refs.appMainRef.receiveMessage) {
        this.$refs.appMainRef.receiveMessage(data)
      }
    },
    computationtime() {
      var newdata = new Date()
      var time = newdata.getTime()
      var fiveten = time + 15 * 24 * 60 * 60 * 1000
      var exptimer = this.$store.state.user.currentUser.expirationDate

      if (exptimer) {
        if (fiveten > exptimer) {
          this.changedown = true
        } else {
          this.changedown = false
        }
      } else {
        this.changedown = false
      }
    },
    updPassword() {
      // this.changedown = true
      this.$refs.changePassword.init()
    },
    resetFields() {
      this.changedown = !this.changedown
      this.$refs['editFrm'].resetFields()
    },
    affirm() {
      this.$refs.editFrm.validate(flag => {
        if (flag) { // 表单验证通过再执行ajax
          this.$api.user.updPassword(this.form).then(res => {
            if (res.code === 200) {
              this.changedown = false
              this.$message.success('密码修改成功!')
              MessageBox.alert('修改密码后需重新登入', '重新登入', {
                confirmButtonText: '确定',
                callback: function() {
                  removeToken()
                  store.commit('user/SET_PERMSS', [])
                  store.commit('permission/SET_MENUS', [])
                  store.commit('user/SET_ROLES', [])
                  store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
                  store.commit('app/webReloadLoginFlag', false)// 重新登入为false
                  store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色
                  store.commit('user/SET_TOKEN', '')
                  location.reload()
                }
              })
              return
            }
          })
        }
      })
    },
    initSystemWebsocket() {
      this.$refs.navbarRef.initSystemWebsocket()
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    cpassword(data) {
      this.changedown = data
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
    background: #fff;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }

</style>
