var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["chart-branch-3d", { "no-rotate": !_vm.rotate }] },
    [
      _c("div", {
        ref: "chart3d",
        class: this.channelWidth ? "chart3d" : "chart3d1",
        on: { click: _vm.canBuildHand },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }