var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cur_case_container" }, [
    !!_vm.medicalHistoryList.length
      ? _c("div", { staticClass: "cur_case_container" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.medicalHistoryList, function (v, i) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: v.appointNo,
                      attrs: { timestamp: v.createTime, placement: "top" },
                    },
                    [
                      _c(
                        "el-card",
                        {
                          class:
                            i === _vm.curIdx ? "card_box active" : "card_box",
                          nativeOn: {
                            click: function ($event) {
                              return _vm.getDetail(v.id, i)
                            },
                          },
                        },
                        [
                          _c(
                            "el-descriptions",
                            { attrs: { column: 1 } },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "门诊号" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(v.inquiryAppoint.inquiryNo) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { size: "small" } },
                                        [_vm._v("复诊")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "接诊医生" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        v.doctorName +
                                          " | " +
                                          v.departmentName +
                                          " | " +
                                          v.positionCategory_dictName
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "主诉" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        v.inquiryAppoint.diseaseDescription ||
                                          "-"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          v.treatmentRecordsVos &&
                          v.treatmentRecordsVos.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-descriptions",
                                    { attrs: { column: 1 } },
                                    [
                                      _vm.diagnoseDistinguish(
                                        v.treatmentDiagnoses,
                                        1
                                      )
                                        ? _c(
                                            "el-descriptions-item",
                                            { attrs: { label: "西医诊断" } },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.diagnoseZH(
                                                      v.treatmentDiagnoses ||
                                                        [],
                                                      1
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.diagnoseDistinguish(
                                        v.treatmentDiagnoses,
                                        2
                                      )
                                        ? _c(
                                            "el-descriptions-item",
                                            {
                                              attrs: {
                                                label: "中医辨病",
                                                span: 1,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.diagnoseZH(
                                                      v.treatmentDiagnoses ||
                                                        [],
                                                      2
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.diagnoseDistinguish(
                                        v.treatmentDiagnoses,
                                        3
                                      )
                                        ? _c(
                                            "el-descriptions-item",
                                            {
                                              attrs: {
                                                label: "中医辩证",
                                                span: 1,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.diagnoseZH(
                                                      v.treatmentDiagnoses ||
                                                        [],
                                                      3
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "title" }, [_vm._v("病历详情")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right_main" },
              [
                _c(
                  "el-descriptions",
                  { attrs: { title: "病历信息", column: 3 } },
                  [
                    _c("el-descriptions-item", { attrs: { label: "就诊人" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.caseObj.patientName) +
                          " | " +
                          _vm._s(_vm.genderType[_vm.caseObj.gender] || "-") +
                          " | " +
                          _vm._s(
                            _vm.caseObj.age > 0
                              ? _vm.caseObj.age + "岁"
                              : "" +
                                  (_vm.caseObj.ageMonth > 0
                                    ? _vm.caseObj.ageMonth + "月"
                                    : "未知")
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-descriptions-item", { attrs: { label: "门诊号" } }, [
                      _vm._v(_vm._s(_vm.caseObj.inquiryAppoint.inquiryNo)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "初诊标志" } },
                      [_vm._v("复诊")]
                    ),
                    _vm._v(" "),
                    _vm.caseObj.hadGuardian === 1
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "监护人" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.caseObj.guardianName || "-") +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.caseObj.hadGuardian === 1
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "监护人身份证号", span: 2 } },
                          [_vm._v(_vm._s(_vm.caseObj.guardianIdCard))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "就诊类型" } },
                      [_vm._v(_vm._s(_vm.inquiryTypeZH(_vm.caseObj)))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "就诊机构" } },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "就诊时间" } },
                      [_vm._v(_vm._s(_vm.caseObj.createTime || "-"))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "就诊科室" } },
                      [_vm._v(_vm._s(_vm.caseObj.departmentName || "-"))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "接诊医生", span: 2 } },
                      [_vm._v(_vm._s(_vm.caseObj.doctorName))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "主诉", span: 3 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.caseObj.inquiryAppoint.diseaseDescription || "-"
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "现病史", span: 3 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.caseObj.hadMedicalHistory === 1
                              ? "" +
                                  (_vm.caseObj.medicalHistory
                                    ? _vm.caseObj.medicalHistory
                                    : "不详")
                              : "无"
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "既往史", span: 3 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.caseObj.hadPreviousHistory === 1
                              ? "" +
                                  (_vm.caseObj.previousHistory
                                    ? _vm.caseObj.previousHistory
                                    : "不详")
                              : "无"
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "过敏史", span: 3 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.caseObj.hadAllergy === 1
                              ? "" +
                                  (_vm.caseObj.allergy
                                    ? _vm.caseObj.allergy
                                    : "不详")
                              : "无"
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "病历资料", span: 3 } },
                      [
                        _vm.caseObj.inquiryAppoint.medicalNoteImages &&
                        _vm.caseObj.inquiryAppoint.medicalNoteImages.length > 0
                          ? _c(
                              "div",
                              { staticClass: "material-list" },
                              _vm._l(
                                _vm.caseObj.inquiryAppoint.medicalNoteImages,
                                function (i, n) {
                                  return _c(
                                    "div",
                                    {
                                      key: n,
                                      staticClass: "material-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePreview(
                                            _vm.caseObj.inquiryAppoint
                                              .medicalNoteImages,
                                            n
                                          )
                                        },
                                      },
                                    },
                                    [_c("img", { attrs: { src: i, alt: "" } })]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("span", [_vm._v("记录遗失/不在身边")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      {
                        attrs: {
                          label: "体格检查",
                          span: 3,
                          "content-class-name": "health_checkup",
                        },
                      },
                      [
                        _c("span", [_vm._v("体温(℃)：-      ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v("脉率（次/min）：-      ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v("呼吸频率(次/min)：-      ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v("收缩压/舒张压(mmHg)：-      ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v("身高（cm）：-     ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v("未见异常")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "中医四诊", span: 3 } },
                      [_vm._v("-")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "辅助检查", span: 3 } },
                      [_vm._v("-")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "西医诊断", span: 3 } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.diagnoseZH(
                                _vm.caseObj.treatmentDiagnoses,
                                1
                              ) || "-"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "中医辨病" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.diagnoseZH(
                                _vm.caseObj.treatmentDiagnoses,
                                2
                              ) || "-"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "中医辩证", span: 2 } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.diagnoseZH(
                                _vm.caseObj.treatmentDiagnoses,
                                3
                              ) || "-"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "辩证依据", span: 3 } },
                      [_vm._v("-")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "治则治法", span: 3 } },
                      [_vm._v("-")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "医嘱备注", span: 3 } },
                      [_vm._v("-")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "处置记录", span: 3 } },
                      [_vm._v("-")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.recipeList.length > 0
                  ? _c(
                      "div",
                      [
                        _c("h1", { staticClass: "el-descriptions__title" }, [
                          _vm._v("处方信息"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.recipeList, function (v) {
                          return _c(
                            "div",
                            { key: v.outpatientNo, staticClass: "recipe_box" },
                            [
                              _c(
                                "el-descriptions",
                                { attrs: { title: "" } },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "处方类型" } },
                                    [_vm._v(_vm._s(_vm.typeObj[v.type]))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "处方编号" } },
                                    [_vm._v(_vm._s(v.outpatientNo))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "开具时间" } },
                                    [_vm._v(_vm._s(v.createTime))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              v.type === 1
                                ? [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-bottom": "10px",
                                        },
                                        attrs: {
                                          data: v.treatmentOrderItems,
                                          border: "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            type: "index",
                                            label: "序号",
                                            width: "50",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "medicineName",
                                            label: "药品名称",
                                            width: "180",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "specification",
                                            label: "规格",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "drugsForm",
                                            label: "剂型",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.dosageFormObj[
                                                            scope.row.drugsForm
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "phruom",
                                            label: "每次用量",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    每次" +
                                                        _vm._s(
                                                          scope.row.useQty
                                                        ) +
                                                        _vm._s(
                                                          scope.row
                                                            .phruom_dictName
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "usage",
                                            label: "用药途径",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "timsPerday",
                                            label: "用药频次",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: { prop: "qty", label: "数量" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.qty +
                                                            scope.row
                                                              .salesUnit_dictName
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "el-descriptions",
                                        { attrs: { title: "", column: 3 } },
                                        [
                                          _c(
                                            "el-descriptions-item",
                                            {
                                              attrs: { label: "处方", span: 3 },
                                            },
                                            _vm._l(
                                              v.treatmentOrderItems,
                                              function (e) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: e.id,
                                                    staticClass: "recipe_box_z",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(e.medicineName) +
                                                        _vm._s(e.qty) +
                                                        _vm._s(
                                                          e.salesUnit_dictName
                                                        )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-descriptions-item",
                                            {
                                              attrs: { label: "剂数", span: 1 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(v.totalDosage) + "剂"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-descriptions-item",
                                            {
                                              attrs: {
                                                label: "用药方法",
                                                span: 2,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.usageType[v.usage]
                                                  ) +
                                                  ",   " +
                                                  _vm._s(v.fewDays) +
                                                  "日" +
                                                  _vm._s(v.severalDoses) +
                                                  "剂,   一剂分" +
                                                  _vm._s(v.howManyTimes) +
                                                  "次服用\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-descriptions-item",
                                            {
                                              attrs: {
                                                label: "用药说明",
                                                span: 3,
                                              },
                                            },
                                            [_vm._v(_vm._s(v.decoctingMethod))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _c(
                                "el-descriptions",
                                { attrs: { title: "" } },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "处方医生" } },
                                    [_vm._v(_vm._s(v.createDoctorName))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "审核药师" } },
                                    [
                                      _vm._v(
                                        _vm._s(v.auditPharmacistName || "-")
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _c("div", { staticClass: "no-data" }, [
          _vm._v("\n    暂无线上就诊记录!\n  "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }