var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.add
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: _vm.baseUrlPath,
                headers: _vm.reqHeaders,
                "before-upload": _vm.beforeImageUpload,
                "show-file-list": false,
                "on-success": _vm.headImgSuccess,
                disabled: _vm.disabled,
              },
            },
            [
              _vm.headImgUrl
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.headImgUrl },
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
            ]
          )
        : _c(
            "el-upload",
            {
              attrs: {
                action: _vm.baseUrlPath,
                "list-type": "picture-card",
                headers: _vm.reqHeaders,
                "file-list": _vm.fileLicenseList,
                "before-upload": _vm.beforeImageUpload,
                "on-preview": _vm.imgPreview,
                "on-remove": _vm.licenseImgRemove,
                "on-success": _vm.headImgSuccess,
                disabled: _vm.disabled,
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }