<template>
  <!-- 中药常规用量 -->
  <div class="container">
    <ykj-search :query-list="queryList" @query="inquire" />
    <MDTable :table="table" :page-vo="pageVo" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
    <el-dialog
      :title="oType === 'add' ? '新增配置' : '编辑配置'"
      :visible.sync="dialogVisible"
      width="420px"
    >
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
        <el-form-item label="药品通用名:" prop="name">
          <el-input v-model="ruleForm.name" clearable :disabled="oType === 'edit'" maxlength="20" />
        </el-form-item>
        <el-form-item label="常规最大用量(g):" prop="maxQty">
          <!-- <el-input v-model="ruleForm.maxQty" clearable /> -->
          <el-input-number v-model="ruleForm.maxQty" :precision="1" :step="0.1" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button v-norepeat type="primary" @click="submitForm('ruleForm')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { provinceAndCityData } from 'element-china-area-data'
export default {
  data() {
    return {
      queryList: {
        type: 'normal', // 查询表单类型 normal(普通类型)、takeTab（带有tab快捷切换搜索类型）
        list: [ // 查询列表
          {
            label: '药品名称：',
            placeholder: '请输入药品名称',
            type: 'input', // 输入框类型 input、Select、Date、TimeRange、DateRange、Datetime、Time
            paramsName: 'name', // 参数名称 注：如果是TimeRange、DateRange类型的，参数名可以使用两个使用逗号隔开（如：beginTime,endTime）
            value: '' // 内容 注：如果是TimeRange、DateRange类型的，默认值设为数组
          }
        ]
      },
      table: {
        stripe: false, // 是否为斑马纹
        loading: false, // 数据加载loading,
        change: this.handleSelectionChange,
        selections: [],
        operation: [
          {
            type: 'primary', // 按钮的颜色，属性 常用的有'primary','success','info','warning','danger'
            label: '新增',
            event: this.handleAdd, // 点击事件
            icon: 'el-icon-circle-plus',
            alwayShow: true
            // sysPerm: '$Service$user$save'
          }
        ],
        data: [
        ],
        event: {
          sortEvent: this.tableSort
        },
        // solt: true,
        header: [
          {
            prop: 'index',
            label: '序号',
            width: 50
          },
          {
            prop: 'name',
            label: '药品通用名'
          },
          {
            prop: 'maxQty',
            label: '常规最大用量',
            soltObj: true,
            soltmeoth: (obj) => {
              return (obj.maxQty || '') + (obj.drugUnit || '')
            }
          },
          {
            prop: 'createTime',
            label: '创建时间'
          },
          {
            prop: 'options', // options 为操作按钮
            label: '操作',
            width: '200',
            fixed: 'right'
          }
        ],
        options: [
          {
            type: 'text', // 按钮的颜色，属性 常用的有'primary','success','info','warning','danger'
            label: '编辑',
            // icon: 'el-icon-edit-outline',
            size: 'mini',
            event: this.handleEdit
          },
          {
            type: 'text', // 按钮的颜色，属性 常用的有'primary','success','info','warning','danger'
            label: '删除',
            // icon: 'el-icon-delete',
            size: 'mini',
            event: this.handleDelete // 点击事件
          }
        ]
      },
      pageVo: {
        pageNum: 1,
        pageSize: 10
      },
      queryVo: {
      },
      dialogVisible: false,
      ruleForm: {
        id: null,
        type: 0,
        drugUnit: 'g', // 克
        name: '',
        maxQty: null
      },
      rules: {
        name: [
          { required: true, message: '请输入药品通用名', trigger: 'blur' }
        ],
        maxQty: [
          { required: true, message: '请输入常规最大用量', trigger: 'blur' }
        ]
      },
      oType: 'add',
      oform: {
        hispitalFullName: null,
        detailAddress: null,
        grade: null
      }
    }
  },
  created() {
    // console.log(provinceAndCityData)
    this.initPageList()
  },
  methods: {
    handleSizeChange(val) {
      this.pageVo.pageSize = val
      this.findPage(this.oform)
    },
    handleCurrentChange(val) {
      this.pageVo.pageNum = val
      this.findPage(this.oform)
    },
    handleAdd() {
      this.ruleForm = {
        type: 0,
        drugUnit: 'g',
        name: '',
        maxQty: null
      }
      this.oType = 'add'
      this.address = []
      this.dialogVisible = true
    },
    handleEdit(item) {
      this.ruleForm = {
        id: item.id,
        type: 0,
        drugUnit: 'g',
        name: item.name,
        maxQty: item.maxQty
      }
      this.oType = 'edit'
      this.dialogVisible = true
    },
    initPageList(data) {
      this.pageVo.pageNum = 1
      this.queryVo = data
      this.findPage(this.queryVo)
    },
    findPage(params) {
      // const queryParams = params || {}
      this.$api.chineseMedicineRoutine.findPage({ ...params, curPage: this.pageVo.pageNum, pageSize: this.pageVo.pageSize, type: 0 }).then((res) => {
        if (res.code === 200) {
          const { records, current, size, total } = res.data || {}
          this.table.data = records
          this.pageVo.pageNum = current
          this.pageVo.pageSize = size
          this.pageVo.totalSize = total
          // this.pageVo.totalPages = res.data.pages
        }
      })
    },
    handleSelectionChange(selections) {
      this.selections = selections
    },
    // 批量删除
    // handleBatchDelete: function() {
    //   if (this.selections == null || this.selections.length === 0) {
    //     this.$message({
    //       message: '请至少选择一条数据',
    //       type: 'info'
    //     })
    //     return
    //   }
    //   this.delete(this.selections)
    // },
    // 删除
    handleDelete: function(data) {
      this.delete(data)
    },
    delete(data) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.chineseMedicineRoutine.del(data).then((res) => {
          if (res.code === 200) {
            this.$message({ message: '操作成功', type: 'success' })
            this.findPage()
          }
        })
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.name.trim()) {
            return this.$message({
              message: '药品名称不能为空格',
              type: 'error'
            })
          }
          if (!this.ruleForm.maxQty) {
            return this.$message({
              message: '常规最大用量需大于0',
              type: 'error'
            })
          }
          if (this.oType === 'add') {
            this.add()
          } else {
            this.edit()
          }
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.dialogVisible = false
    },
    add() {
      // const _data = { ...this.ruleForm }
      this.$api.chineseMedicineRoutine.save(this.ruleForm).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.dialogVisible = false
          this.findPage()
        }
      })
    },
    edit() {
      // const _data = { ...this.ruleForm }
      this.$api.chineseMedicineRoutine.edit(this.ruleForm).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.dialogVisible = false
          this.findPage()
        }
      })
    },
    inquire(params) {
      this.oform = params
      this.pageVo.pageNum = 1
      this.findPage(this.oform)
    }
  }
}
</script>
<style lang="scss" scoped>
  .file-search{
    padding: 24px;
    .file-search-cont{
      margin-bottom: 24px;
      position: relative;
      display: flex;
      line-height: 40px;
      font-size: 14px;
      .file-search-cont-lebal{
        width: 120px;
        margin-right: 10px;
        text-align: right;
      }
      .file-search-cont-inp{
        width: calc(90% - 100px);
      }
    }
  }
</style>
