<template>
  <div>
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
      <div class="title-container">
        <!-- <span><img class="inputimg" src="img/inputlogo.png" alt=""></span> -->
        <el-tooltip v-if="title.length > 11" class="item" effect="dark" :content="title + '后台管理系统'" placement="top">
          <span class="title">{{ title }}后台管理系统</span>
        </el-tooltip>
        <span v-else class="title">{{ title }}后台管理系统</span>
      </div>
      <div class="logman">
        <el-form-item prop="userName">
          <el-input
            ref="userName"
            v-model="loginForm.userName"
            placeholder="请输入用户名"
            name="userName"
            type="text"
            tabindex="1"
            autocomplete="on"
            prefix-icon="el-icon-user-solid"
            prefix-icon-color="red"
          />
        </el-form-item>
        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :disabled="loading"
              :type="passwordType"
              placeholder="请输入您的账户密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              prefix-icon="el-icon-s-cooperation"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
          </el-form-item>
        </el-tooltip>
        <el-form-item prop="code" class="codefrom">
          <el-input
            ref="code"
            v-model="loginForm.code"
            :disabled="loading"
            :style="{ width: '65%' }"
            class="code"
            :type="codeType"
            placeholder="请输入验证码"
            name="code"
            tabindex="2"
            autocomplete="on"
            prefix-icon="el-icon-warning"
            maxlength="4"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="shu" />
          <!-- <valid-code style="width: 99px" class="codes" :value.sync="validCode" @upvalue="upvalue" /> -->
          <div class="code-img">
            <div v-if="isImgLoading">
              <div class="code-img-shade-loading">
                <i class="el-icon-loading" />
              </div>
            </div>
            <div v-else @click="getImageCode">
              <div v-if="isReset" class="code-img-shade">
                <i class="el-icon-refresh-right" />
                点击刷新
              </div>
              <img :src="codeImgUrl" alt="" />
            </div>
          </div>
        </el-form-item>
        <div class="tabcard">
          <div><el-checkbox v-model="checked">记住密码</el-checkbox></div>
          <!-- <div><el-link type="primary">忘记密码？</el-link></div> -->
        </div>
        <el-button :loading="loading" class="frombtn" style="width:100%;" @click.native.prevent="handleLogin">登录</el-button>
      </div>
      <!-- <el-dialog
        title="双重验证"
        :visible.sync="dialogNoteCode"
        width="400px"
        append-to-body
      >
        <el-form ref="noteLoginForm" :model="noteLoginForm" :rules="noteLoginRules" class="login-form" autocomplete="on" label-position="left">
          <el-form-item prop="code" class="codefrom">
            <el-input
              ref="code"
              v-model="noteLoginForm.code"
              :style="{'width':'60%'}"
              class="code"
              :type="codeType"
              placeholder="请输入验证码"
              name="code"
              tabindex="2"
              autocomplete="on"
              prefix-icon="el-icon-warning"
              maxlength="6"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleNoteLogin"
            />
            <span class="shu" />
            <el-button type="text" :disabled="huoqu" style="margin-left: 10px;" @click="obtainCode">{{ huoqu ? `${timer}s后重新获取` : '获取验证码' }}</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogNoteCode = false">取 消</el-button>
          <el-button type="primary" @click="handleNoteLogin">确 定</el-button>
        </span>
      </el-dialog>
    </el-form> -->
      <dialogNoteCode ref="dialogNoteCode" @cb="getInfo" />
      <changePassword ref="changePassword" @cb="getInfo" />
    </el-form>
  </div>
</template>
<script>
import ValidCode from '@/components/ValidCode/valid-code'
import changePassword from '@/components/changePassword'
import dialogNoteCode from '@/components/dialogNoteCode'
// import { title } from '@/utils/global'
import { setToken, removeToken } from '@/utils/auth'
export default {
  name: 'Login',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidCode,
    changePassword,
    dialogNoteCode
  },
  data() {
    const validateUserName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('账号不能为空！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error('请输入大于5位密码'))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入您的验证码'))
      } else if (value.toLowerCase() !== this.validCode.toLowerCase() && this.validCode) {
        callback(new Error('验证码不正确!'))
      } else {
        callback()
      }
    }
    return {
      // isLogin: 0, // 0 可以点击 1 点击中 2 返回结果
      dialogNoteCode: false,
      checked: true,
      validCode: '',
      codeImgUrl: '',
      codeKey: '',
      loginForm: {
        userName: '',
        password: '',
        code: ''
      },
      loginRules: {
        userName: [{ required: true, trigger: 'blur', validator: validateUserName }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        code: [{ required: true, trigger: 'blur', validator: validateCode }]
      },
      noteLoginForm: {},
      noteLoginRules: {
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      },
      passwordType: 'password',
      codeType: 'code',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
      title: '',
      isVerify: true,
      isReset: false,
      timer: 120,
      huoqu: false,
      intervalBtn: null,
      setTime: null,
      JustEnterThe: false,
      isImgLoading: false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
    // 类方法 (实际是静态方法直接调用) 位置：Person类的外部 语法格式：类名称.方法名称 = function([参数...]){ 语句行; }
    const _session = sessionStorage.getItem('systemInfo')
    const _info = JSON.parse(_session)
    if (!_info) {
      this.init()
    } else {
      this.title = _info.system.title
    }
    this.JustEnterThe = true
    // this.tailorURL()
    this.getImageCode()
  },
  mounted() {
    // if (this.loginForm.username === '') {
    //   this.$refs.username.focus()
    // } else if (this.loginForm.password === '') {
    //   this.$refs.password.focus()
    // } else if (this.loginForm.code !== this.validCode) {
    //   this.$refs.code.focus()
    // }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    init() {
      this.$api.settings.headInfo({ groups: 'system,ca,func,prescription,map,pharmacy,logistics,cod,heart-check-report' }).then(res => {
        sessionStorage.setItem('systemInfo', JSON.stringify(res.data))
        this.title = res.data.system.title
      })
    },
    // tailorURL() { // 链接参数裁剪
    //   const query = window.location.search.substring(1)
    //   if (query) {
    //     const vars = query.split('&')
    //     const _arr = []
    //     for (var i = 0; i < vars.length; i++) {
    //       const pair = vars[i].split('=')
    //       _arr.push(pair)
    //     }
    //     const code = _arr.filter(item => item[0] === 'code')[0][1]
    //     if (code === '0' && this.isVerify) {
    //       this.isVerify = false
    //       const orderNo = _arr.filter(item => item[0] === 'orderNo')[0][1]
    //       this.getResult(orderNo)
    //     }
    //     console.log(_arr, code)
    //   }
    // },
    // getResult(orderNo) {
    //   const _token = sessionStorage.getItem('temporaryTOKEN')
    //   this.$api.login.getFaceResult({ orderNo: orderNo }, _token).then(res => {
    //     if (res.code === 200) {
    //       this.isVerify = true
    //       sessionStorage.setItem('token', _token)
    //       sessionStorage.removeItem('temporaryTOKEN')
    //       this.getInfo()
    //     }
    //   })
    // },
    getImageCode() {
      this.isReset = false
      this.isImgLoading = true
      this.$api.captcha.getImgCode().then(res => {
        if (res.code === 200) {
          this.isImgLoading = false
          this.codeImgUrl = res.data.base64Image
          this.codeKey = res.data.key
          if (res.data.expireIn) {
            this.countDown(res.data.expireIn, res.serviceTimestamp)
          }
        }
      })
    },
    countDown(expireIn, serviceTimestamp) {
      const current = serviceTimestamp // new Date().getTime()
      const mills = expireIn - current

      if (!mills && mills <= 0) {
        console.error('图形验证码倒计时时间小于0')
        return
      }
      clearInterval(this.intervalBtn)
      this.isReset = false
      // console.log(`图形验证码将在${mills}ms后重置`)
      this.intervalBtn = setInterval(() => {
        this.isReset = true
        // console.log(`图形验证码已失效`)
      }, mills)
    },
    upvalue(value) {
      this.validCode = value
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      if (this.loading) {
        return
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          const login = {
            username: this.loginForm.userName,
            password: this.loginForm.password
          }
          const header = {
            key: this.codeKey,
            captcha: this.loginForm.code
          }
          this.$api.login
            .login(login, header)
            .then(res => {
              console.log(`login response: ${JSON.stringify(res)}`)
              if (res.code === 200) {
                if (res.msg != null && res.msg !== '') {
                  this.$message({
                    message: res.msg,
                    type: 'error'
                  })
                } else {
                  setToken(res.data.accessToken)
                  if (res.data.tfaRequired) {
                    // 双重验证
                    if (res.data.tfaType === 0) {
                      console.log('需要人脸')
                      this.toFacialRecognition(res.data.accessToken)
                    } else if (res.data.tfaType === 1) {
                      // sessionStorage.setItem('token', res.data.accessToken)
                      // this.dialogNoteCode = true
                      this.$refs.dialogNoteCode.init()
                    } else {
                      console.error('登录异常')
                    }
                  } else {
                    if (res.data.changePasswordRequired) {
                      this.$confirm('您的密码已过期，是否立即修改密码', '提示', {
                        confirmButtonText: '立即修改',
                        cancelButtonText: '暂不修改',
                        type: 'warning'
                      })
                        .then(() => {
                          this.$refs.changePassword.init()
                        })
                        .catch(() => {
                          this.getInfo()
                        })
                    } else {
                      this.getInfo()
                    }
                  }
                }
              } else {
                this.JustEnterThe = true
                this.getImageCode()
                // this.$message({
                //   message: res.msg,
                //   type: 'error'
                // })
              }

              this.loading = false
            })
            .catch(res => {
              this.loading = false
              this.getImageCode()
            })
        } else {
          return false
        }
      })
    },
    // obtainCode() {
    //   this.$api.login.getNoteCode().then((res) => {
    //     if (res.code === 200) {
    //       this.timer = 120
    //       this.huoqu = true
    //       this.countDownCode()
    //     } else {
    //       // this.$message({ message: '获取验证码失败,' + res.msg, type: 'error' })
    //     }
    //   })
    // },
    // countDownCode() {
    //   const _timer = setInterval(() => {
    //     if (this.timer < 1) {
    //       this.huoqu = false
    //       clearInterval(_timer)
    //     } else {
    //       this.timer -= 1
    //     }
    //   }, 1000)
    // },
    // handleNoteLogin() {
    //   this.$refs.noteLoginForm.validate(valid => {
    //     if (valid) {
    //       this.$api.login.toLogin(this.noteLoginForm).then((res) => {
    //         if (res.code === 200) {
    //           this.getInfo()
    //         } else {
    //           // this.$message({ message: '登陆失败,' + res.msg, type: 'error' })
    //         }
    //       })
    //     } else {
    //       return false
    //     }
    //   })
    // },
    toFacialRecognition(token) {
      const join = {
        redirectUrl: window._conf.baseApi
      }
      this.$api.login.getFace(join, token).then(res => {
        if (res.code === 200) {
          console.log(res)
          sessionStorage.setItem('temporaryTOKEN', token)
          window.location.href = res.data
        } else {
          this.getImageCode()
          // this.$message({ message: `登录失败，${res.msg}`, type: 'error' })
        }
      })
    },
    getInfo() {
      this.$api.user.getCurrentUser().then(async res => {
        if (res.code === 200) {
          if (!(res.data.currentRoleCode === 'offlineAdmin' || res.data.currentRoleCode === 'offlinePharmacist' || res.data.currentRoleCode === 'offlineSender')) {
            sessionStorage.setItem('user', res.data.userName) // 保存用户到本地会话
            this.$store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
            this.$store.commit('user/SET_TOKEN', res.data) // 设置token
            let hasR = false
            if (this.redirect) {
              console.log(this.redirect)
              const url = this.redirect.split('/')
              await this.$api.menu.findNavTree({ userName: res.data.userName }).then(resq => {
                const data = resq.data || []
                const fun = d => {
                  d.some(v => {
                    if (v.url.indexOf(url[0] + '/' + url[1]) > -1) {
                      console.log('hahah')
                      hasR = true
                      return true
                    } else if (v.children && v.children.length) {
                      fun(v.children)
                    }
                  })
                }
                fun(data)
              })
            }
            if (hasR) {
              this.$router.push({ path: this.redirect || '/', query: this.otherQuery }) // 登录成功，跳转到主页、重定向
            } else {
              this.$router.push({ path: '/' })
            }
          } else {
            // sessionStorage.clear()// 清除本地缓存
            removeToken()
            // Cookies.remove('token')
            this.$message({ message: '您无权登陆该系统，请联系系统管理员。', type: 'error' })
          }
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #333;

.login-container {
  .login-form {
    position: relative;
    width: 500px;
    max-width: 100%;

    position: fixed;
    border-radius: 10px;
    right: 15%;
    top: 200px;
    overflow: hidden;
    background: #fff;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    height: 99px;
    background: #1f50cc;
    display: flex;
    align-items: center;
    padding-left: 16px;
    .inputimg {
      width: 70px;
      height: 70px;
      margin: 0 20px;
    }

    .title {
      font-size: 28px;
      color: #1e3e6c;
      // line-height: 99px;
      color: #fff;

      text-align: center;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-height: 99px;
      max-width: 500px;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
.adminlogo {
  position: fixed;
  left: 94px;
  top: 67px;
  width: 466px;
  height: 64px;
}
.logman {
  padding: 58px 39px 29px;
  box-sizing: border-box;
  background: #fff;
}
.frombtn {
  background: #fd9333;
  color: #fff;
  height: 50px;
  border-radius: 4px;
}
.codes {
  float: right;
  margin: 3px 21px;
}
.el-form-item__content {
  display: flex;
  align-items: center;
}
.shu {
  display: inline-block;
  width: 1px;
  height: 20px;
  background: #333;
  position: relative;
  top: 3px;
}
.tabcard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}
>>> .el-input--prefix .el-input__inner {
  padding-left: 30px;
  border: none;
  height: 100%;
}

.el-input {
  display: inline-block;
  height: 47px;
  width: 100%;
  min-height: 100%;

  input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 6px;
    padding: 12px 5px 12px 35px;
    color: #333;
    height: 47px;
    caret-color: $cursor;
    height: 100%;

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px $bg inset !important;
      -webkit-text-fill-color: $cursor !important;
    }
  }
}

.el-form-item {
  border: 1px solid rgba(153, 153, 153, 1);
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  color: #454545;
  margin-bottom: 28px;
}
.code-img {
  cursor: pointer;
  float: right;
  margin: 4px 20px;
  width: 100px;
  height: 40px;
  position: relative;
  .code-img-shade {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
  }
  .code-img-shade-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    background: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #666;
  }
  img {
    display: block;
    width: 100%;
  }
}
</style>
