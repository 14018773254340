import axios from '../../axios'
const basePath = '/mgmt/medicine/category/'
// 获取药品分类资源树

// 废弃
export const getCategoryTree = (data) => {
  return axios({
    url: basePath + 'getCategoryTree',
    method: 'get',
    params: data
  })
}
export const getCategoryTreeByWhere = (data) => {
  return axios({
    url: basePath + 'getCategoryTreeByWhere',
    method: 'get',
    params: data
  })
}
export const addCategory = (data) => {
  return axios({
    url: basePath + 'addCategory',
    method: 'post',
    data
  })
}
export const editCategory = (data) => {
  return axios({
    url: basePath + 'editCategory',
    method: 'post',
    data
  })
}

export const deleteCategory = (data) => {
  return axios({
    url: basePath + 'deleteCategory',
    method: 'post',
    data
  })
}

