<template>
  <el-dialog
    ref="cur_container"
    title="手写签名"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="600px"
    @mousedown.native="updateStatus"
    @mouseup.native="updateStatus"
    @mousemove.native="updateStatus"
  >
    <div ref="canvasHW" class="box" style="width: 100%;height:260px" @mousedown.stop="mouseDown" @mousemove.stop="mouseMove" @mouseup.stop="mouseUp">
      <!-- height="200" width="706"  -->
      <canvas id="canvas" ref="canvasF">签名</canvas>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleOverwrite">重签</el-button>
      <el-button type="primary" @click="submitSign">提交签名</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'CanvasSign',
  props: {
    isHas: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      points: [],
      canvasTxt: null,
      stage_info: [],
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      isDown: false,
      strokeStyle: '#000',
      lineWidth: 3.8,
      signUrl: ''
    }
  },
  mounted() {
  },
  methods: {
    init(signUrl = '') {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.signUrl = signUrl
        this.initCanvas()
      })
    },
    // 初始化Canvas
    initCanvas() {
      const canvas = this.$refs.canvasF
      // 获取画布的高度
      canvas.height = this.$refs.canvasHW.offsetHeight - 20
      // 获取画布的宽度
      canvas.width = this.$refs.canvasHW.offsetWidth - 20
      // 创建 context 对象
      this.canvasTxt = canvas.getContext('2d')
      const _this = this
      const image = new Image()
      image.src = this.signUrl
      image.onload = function() {
        // 签名回显
        _this.canvasTxt.drawImage(this, 0, 0)
      }

      this.stage_info = canvas.getBoundingClientRect()
    },
    updateStatus(ev) {
      ev = ev || event
      ev.stopPropagation()
      ev.preventDefault()
      this.isDown = false
    },
    // 鼠标按下事件 - 准备绘画
    mouseDown(ev) {
      ev = ev || event
      ev.preventDefault()
      if (ev) {
        const obj = {
          x: ev.offsetX,
          y: ev.offsetY
        }
        this.startX = obj.x
        this.startY = obj.y
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.points.push(obj)
        this.isDown = true
      }
    },
    // 鼠标移动事件 - 开始绘画
    mouseMove(ev) {
      ev = ev || event
      ev.preventDefault()
      if (this.isDown) {
        const obj = {
          x: ev.offsetX,
          y: ev.offsetY
        }
        this.moveY = obj.y
        this.moveX = obj.x
        this.canvasTxt.strokeStyle = this.strokeStyle
        this.canvasTxt.lineWidth = this.lineWidth
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.startY = obj.y
        this.startX = obj.x
        this.points.push(obj)
      }
    },
    // 松开鼠标事件 - 停止绘画
    mouseUp(ev) {
      console.log('mouseUp')
      ev = ev || event
      ev.preventDefault()
      if (ev) {
        const obj = {
          x: ev.offsetX,
          y: ev.offsetY
        }
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.points.push(obj)
        this.points.push({ x: -1, y: -1 })
        this.isDown = false
      }
    },
    // 提交签名
    submitSign() {
      const canvas = this.$refs.canvasF
      if (this.isCanvasBlank(canvas)) {
        this.$message.error('请签写您的名字')
        return
      } else {
        const base64 = canvas.toDataURL()
        this.$emit('cb', base64)
        this.dialogVisible = false
      }
    },
    isCanvasBlank(canvas) {
      // PNG 算法
      var blank = document.createElement('canvas')
      blank.width = canvas.width
      blank.height = canvas.height
      return canvas.toDataURL() === blank.toDataURL()
    },
    // 重写
    handleOverwrite() {
      this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height)
      this.points = []
    }
  }
}

</script>

<style lang="scss" scoped>
  .box{
    background: #f2f2f2;
    border: 1px solid #f1f1f1;
  }
</style>
