import axios from '../../axios'
const basePath = '/mgmt/pharmacy'
/*
 * 药房钱包
 */

// // 保存
// export const save = (data) => {
//   return axios({
//     url: basePath + '/save',
//     method: 'post',
//     data
//   })
// }

// export const edit = (data) => {
//   return axios({
//     url: basePath + '/edit',
//     method: 'post',
//     data
//   })
// }

// export const batchDelete = (data) => {
//   return axios({
//     url: basePath + '/delete',
//     method: 'post',
//     data
//   })
// }
// 查钱包流水
export function getWalletLog(data) {
  // /mgmt/pharmacy/wallet/getPharmacyWalletLog?sourceTarget=1&sourceType=1&curPage=1&pageSize=10&pharmacyId=3
  return axios({
    url: '/mgmt/pharmacy/wallet/getPharmacyWalletLog',
    method: 'get',
    params: data
  })
}
// 获取钱包余额
export const getBalance = (data, pageVo) => {
  return axios({
    // url: basePath + '/wallet' + pageVo.pageSize + '/' + pageVo.pageNum,
    url: basePath + '/wallet/' + data.id,
    method: 'get',
    params: data
  })
}

// 对账明细 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: '/mgmt/order/pharmacy/settle/getPage',
    method: 'get',
    params: data
  })
}

// // 明细查询
// export const findInfoByUniqueKey = (params) => {
//   return axios({
//     url: basePath + '/findById',
//     method: 'get',
//     params
//   })
// }

// // 药品商品分享
// export const shareStore = (data) => {
//   return axios({
//     url: '/mgmt/wxmini-qrcodes/_createQRCode',
//     method: 'post',
//     data
//   })
// }
