<template>
  <div class="search-box">
    <div class="search-box-label" :style="{ 'text-align': textAlign }">
      {{ item.label }}
    </div>
    <el-input
      v-if="item.type === 'input'"
      v-model="item.value"
      class="search-input"
      :placeholder="item.placeholder || '请输入'"
      clearable
      @change="quer()"
    />
    <el-select
      v-else-if="item.type === 'Select'"
      v-model="item.value"
      :placeholder="item.placeholder || '请选择'"
      :filterable="item.filterable"
      :remote="item.remote"
      :remote-method="item.remoteMethod"
      @change="selectChange(item)"
    >
      <el-option
        v-for="i in item.options"
        :key="i.value"
        :label="i.label"
        :value="i.value"
      />
    </el-select>
    <el-cascader
      v-else-if="item.type === 'Cascader'"
      v-model="item.value"
      :options="item.options"
      :placeholder="item.placeholder || '请选择'"
      :props="item.defaultParams"
      clearable
    />
    <el-date-picker
      v-else-if="item.type === 'Date'"
      v-model="item.value"
      type="date"
      class="search-input"
      placeholder="选择日期"
      value-format="yyyy-MM-dd"
    />
    <el-time-picker
      v-else-if="item.type === 'TimeRange'"
      v-model="item.value"
      is-range
      range-separator="-"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      placeholder="选择时间范围"
      value-format="HH:mm:ss"
      class="search-input"
    />
    <el-date-picker
      v-else-if="item.type === 'DateRange'"
      v-model="item.value"
      type="daterange"
      :picker-options="pickerOptions"
      :clearable="!(item.clearable && item.clearable === 2)"
      range-separator="-"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      class="search-input"
    />
    <el-date-picker
      v-else-if="item.type === 'Datetime'"
      v-model="item.value"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm:ss"
      placeholder="选择日期时间"
      class="search-input"
    />
    <el-time-picker
      v-else-if="item.type === 'Time'"
      v-model="item.value"
      placeholder="选择时间"
      value-format="HH:mm:ss"
      class="search-input"
    />
    <el-date-picker
      v-else-if="item.type === 'month'"
      v-model="item.value"
      type="month"
      placeholder="选择月份"
      value-format="yyyy-MM"
      class="search-input"
    />
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: [Array, String, Object, Boolean, Number],
      default: null
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      startTime: '',
      pickerOptions: {
        onPick: ({ minDate }) => {
          this.startTime = minDate.getTime()
        },
        disabledDate: time => {
          // return time.getTime() > Date.now();
          if (this.startTime && this.item.only) {
            const selT = new Date(this.startTime) // .getDate()
            // const maxT = new Date(selT).setMonth(new Date(selT).getMonth())
            const maxT = new Date(selT).setDate(
              new Date(selT).getDate() + this.item.only
            )
            return time.getTime() > maxT
          }
        }
      }
    }
  },
  methods: {
    init() {
      this.startTime = ''
    },
    quer() {
      console.log('键盘按下回车')
      this.$emit('upQuer')
    },
    selectChange(item) {
      if (item.paramsName === 'appId') {
        // this.$emit('appIdChange')
        this.$emit('upQuer')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box {
  // margin-bottom: 16px;
  padding: 8px 28px 8px 80px;
  // padding-left: 80px;
  // padding-right: 28px;
  position: relative;
  min-height: 40px;
  .search-box-label {
    line-height: 40px;
    color: #666;
    // text-align: right;
    position: absolute;
    left: 0;
    top: 8px;
    z-index: 2;
    width: 70px;
    font-size: 14px;
  }
  .search-input {
    width: 100%;
  }
}
</style>
