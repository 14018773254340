/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-08-30 11:03:42
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-09-18 17:45:13
 * @FilePath: \drant-manager\src\http\moudules\aiAssist\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 华稷辅诊ai

// 药品列表
export const getHjdrugs = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs',
    method: 'get',
    params: data
  })
}
// 搜索药房药品
export const getSearch = data => {
  return axios({
    url: '/mgmt/medicine/drugs/search',
    method: 'get',
    params: data
  })
}
// 添加药品
export const addHjdrugs = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/save',
    method: 'post',
    data
  })
}
// 编辑药品
export const editHjdrugs = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/edit',
    method: 'post',
    data
  })
}
// 删除药品
export const deltHjdrugs = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/' + data,
    method: 'post'
  })
}
// 确认药品
export const affirmHjdrugs = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/affirm/' + data,
    method: 'post'
  })
}
// 关联药房药品
export const bindDrugs = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/bindDrugs',
    method: 'post',
    data
  })
}
// 批量禁用药品
export const batchDisable = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/_batch-disable',
    method: 'post',
    data
  })
}
// 批量启用药品
export const batchEnable = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/_batch-enable',
    method: 'post',
    data
  })
}
// 批量删除药品
export const batchDelete = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/_batch-delete',
    method: 'post',
    data
  })
}
// 导出华稷药品批量更新关联模板
export const exportUpdTemplate = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/exportUpdTemplate',
    method: 'get',
    responseType: 'blob'
  })
}
// Excel批量更新华稷药品关联药房药品
export const _importAssocRelation = data => {
  return axios(
    {
      url: '/mgmt/medicine/hjdrugs/_importAssocRelation',
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }
  )
}
// 系统管理员使用导入Excel通过药品名字自动关联
export const bindDrugsBySys = data => {
  return axios(
    {
      url: '/mgmt/medicine/hjdrugs/bindDrugsBySys',
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }
  )
}
// 导出华稷药品模板 (用于批量新增和更新)
export const exportImpTemplate = data => {
  return axios({
    url: '/mgmt/medicine/hjdrugs/exportImpTemplate',
    method: 'get',
    responseType: 'blob'
  })
}
// Excel批量新增华稷药品
export const _importDrugs = data => {
  return axios(
    {
      url: '/mgmt/medicine/hjdrugs/_importDrugs',
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }
  )
}
// Excel批量更新华稷药品
export const _importUpdateDrugs = data => {
  return axios(
    {
      url: '/mgmt/medicine/hjdrugs/_importUpdateDrugs',
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }
  )
}
