import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 企业会员列表
export function findPage(data) {
    return axios({
        url: '/mgmt/health/ambassador/pageList',
        method: 'get',
        params: data
    })
}

// 新增企业会员
export function assistantAdd(data) {
    return axios({
        url: '/mgmt/health/ambassador/add',
        method: 'post',
        data
    })
}
// 编辑企业会员
export function assistantUpdate(data) {
    return axios({
        url: '/mgmt/health/ambassador/update',
        method: 'post',
        data
    })
}
// 获取用户列表
export function memberPageList(data) {
    return axios({
        url: '/core/member/pageList',
        method: 'get',
        params: data
    })
}