import axios from '../../axios'

export function getReferrerPage(data) {
  return axios({
    url: '/mgmt/medical/showCase/officer/getPage',
    method: 'get',
    params: data
  })
}

export function addReferrer(data) {
  return axios({
    url: '/mgmt/medical/showCase/officer/add',
    method: 'post',
    data: data
  })
}

export function editReferrer(data) {
  return axios({
    url: '/mgmt/medical/showCase/officer/edit',
    method: 'post',
    data: data
  })
}

