import axios from '../../axios'
const basePath = '/core/inquire'

export const findByUniqueKey = (data) => {
  return axios({
    url: basePath + '/findByUniqueKey',
    method: 'get',
    params: data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 问诊单分页查询
export const tInquiryAppoint = (data, pageVo) => {
  return axios({
    url: '/core/tInquiryAppoint/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
// 问诊单导出
export const InquiryExport = (data, pageVo) => {
  return axios({
    url: '/mgmt/inquiry-appoints/_export',
    method: 'get',
    responseType: 'blob',
    params: data
  })
}
// 问诊单唯一健查询
export const tInquiryAppointKey = (uuid) => {
  const params = { uuid: uuid }
  return axios({
    url: '/core/tInquiryAppoint/findInfoByUniqueKey',
    method: 'get',
    params: { ...params }
  })
}

// 问诊单聊天记录分页查询
export const conversationMsg = (data, pageVo) => {
  data.curPage = pageVo.pageNum
  data.pageSize = pageVo.pageSize
  return axios({
    url: '/mgmt/conversationMsgs',
    method: 'get',
    params: data
  })
}

// 音视频通信基础信息分页查询

export const trtcinfoBase = (data, pageVo) => {
  return axios({
    url: '/core/trtcinfoBase/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 会话成员查询
export const conversationMember = (data) => {
  return axios({
    url: '/core/conversationMember/findall',
    method: 'get',
    params: data
  })
}

// 会话成员头像查询
export const conversationMemberImg = (data) => {
  return axios({
    url: '/core/conversationMember/findallHeadImg',
    method: 'get',
    params: data
  })
}

// 问诊记录分页查询
export const doctorappoint = (data, pageVo) => {
  return axios({
    url: '/core/doctorappoint/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 健康档案查询
export const findMemberByUniqueKey = (data) => {
  return axios({
    url: `mgmt/headacheQuestionnaires`,
    method: 'get',
    params: data
  })
}

// 电子处方分页查询
export const treatmentRecord = (data, pageVo) => {
  return axios({
    url: '/core/treatmentRecord/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 头痛日记分页查询
export const headacheDiary = (data, pageVo) => {
  return axios({
    url: '/core/headacheDiary/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 曾问诊医生查询
export const findInquiryAppointDoctor = (id) => {
  const data = { memberId: id }
  return axios({
    url: `/core/tInquiryAppoint/findInquiryAppointDoctorByMemberId`,
    method: 'get',
    params: data
  })
}
// 评价分页查询
export const appraiseFindPage = (data, pageVo) => {
  return axios({
    url: '/core/appointEvaluation/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 评价唯一健查询
export const appraiseKey = (data) => {
  return axios({
    url: `/core/appointEvaluation/findInfoByUniqueKey`,
    method: 'get',
    params: data
  })
}

// 修改评价
export const appraiseEdit = (data) => {
  return axios({
    url: '/core/appointEvaluation/edit',
    method: 'post',
    data
  })
}

// 删除评价
export const appraiseDel = (data) => {
  return axios({
    url: '/core/appointEvaluation/delete',
    method: 'post',
    data
  })
}
// 获取im聊天
export const getIMrecord = (data) => {
  return axios({
    url: `/mgmt/inquiry-appoints/${data.id}/group-msg`,
    method: 'get',
    params: data
  })
}

// 结束问诊单
export const finish = (inquiryNo) => {
  return axios({
    url: `/mgmt/inquiry-appoints/${inquiryNo}/_finish`,
    method: 'post'
  })
}


// 问诊记录导出（用于患者回访）
export const exportByRevisit = (data) => {
  return axios({
    url: '/mgmt/inquiry-appoints/exportByRevisit',
    method: 'post',
    responseType: 'blob',
    data: data
  })
}




// 回访记录分页查询
export const pageRevisitList = (data,pageVo) => {
  return axios({
    url: '/mgmt/inquiry/revisit/getPage',
    method: 'get',
    params: {
      ...data,
      ...pageVo,
    }
  })
}


// 添加回访记录
export const addRevisit = data => {
  return axios({
    url: '/mgmt/inquiry/revisit/save',
    method: 'post',
    data
  })
}
// 编辑回访记录
export const editRevisit = data => {
  return axios({
    url: '/mgmt/inquiry/revisit/edit',
    method: 'post',
    data
  })
}
// 删除回访记录
export const deleteRevisit = id => {
  return axios({
    url: '/mgmt/inquiry/revisit/delete/'+id,
    method: 'post',
  })
}
// 回访记录详情
export const getRevisitDetail = id => {
  return axios({
    url: '/mgmt/inquiry/revisit/detail/'+id,
    method: 'get',
  })
}