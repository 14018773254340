import axios from '../../axios'

// // 分页查询
// export const info = (data) => {
//   return axios({
//     url: '/mgmt/core/groups',
//     method: 'get',
//     params: data
//   })
// }
//
// // 编辑配置
// export const edit = (data) => {
//   return axios({
//     url: '/mgmt/setting/groups/_batch_update',
//     method: 'post',
//     data
//   })
// }

// 分页查询
export const headInfo = (data) => {
  return axios({
    url: '/api/core/settings',
    method: 'get',
    params: data
  })
}

// new分页查询
export const allInfo = (data) => {
  return axios({
    url: '/mgmt/setting/groups',
    method: 'get',
    params: data
  })
}

// 编辑配置
export const allEdit = (data) => {
  return axios({
    url: '/mgmt/setting/groups/_batch_update',
    method: 'post',
    data
  })
}

// 获取健康金结算比
export const cooperSettle = (data) => {
  return axios({
    url: '/mgmt/setting/groups/cooper-settle',
    method: 'get',
    params: data
  })
}

// 历史分页查询
export const historyList = (data) => {
  return axios({
    url: '/mgmt/setting/histories',
    method: 'get',
    params: data
  })
}

// 回滚历史
export const restore = (data) => {
  return axios({
    url: '/mgmt/setting/histories/_restore',
    method: 'post',
    data
  })
}
export const consultation = () => {
  return axios({
    url: '/mgmt/setting/groups/consultation',
    method: 'get'
  })
}

// // 收益分配查询
// export const findEarningsKey = (groupName) => {
//   return axios({
//     url: `/mgmt/core/all-settings/${groupName}`,
//     method: 'get'
//   })
// }
