/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-09-11 14:44:04
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-09-11 14:46:10
 * @FilePath: \drant-manager\src\http\moudules\ram\report.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-09-11 14:44:04
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-09-11 14:44:53
 * @FilePath: \drant-manager\src\http\moudules\ram\report.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 举报-列表
export const getlist = data => {
  return axios({
    url: '/mgmt/trends/accusation/getList',
    method: 'get',
    params: data
  })
}
// 举报审核
export const audit = data => {
  return axios({
    url: '/mgmt/trends/accusation/audit',
    method: 'post',
    data
  })
}
