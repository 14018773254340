/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-10-19 14:06:49
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-27 10:08:25
 * @FilePath: \drant-manager\src\http\moudules\device\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'
// 账单详情
export const debtDetail = data => {
  return axios({
    url: '/mgmt/credit/debt/detail',
    method: 'get',
    params: data
  })
}
// 账单列表
export const debtGetPage = data => {
  return axios({
    url: '/mgmt/credit/debt/getPage',
    method: 'get',
    params: data
  })
}
