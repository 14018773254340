import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/business/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/business/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/business/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/business/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + '/business/findInfoByUniqueKey',
    method: 'get',
    params: data// data
  })
}

// 审核
export function approve(data) {
  return axios({
    url: basePath + '/business/approve',
    method: 'post',
    data
  })
}
// 商务邀请医生

// 列表
export function inviteDoctor(params) {
  return axios({
    url: `/mgmt/hospital/invite-doctors`,
    method: 'get',
    params
  })
}

// 导出
export function inviteExport(data) {
  return axios({
    url: `/mgmt/hospital/invite-doctors/_export`,
    method: 'post',
    responseType: 'blob',
    data
  })
}
