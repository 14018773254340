
export default {
  datas: function(data) {
    // 时间处理 主要根据当前时间的凌晨和当前时间周一的凌晨 做判断依据 （时间都转化成时间戳好计算，自认为）
    // var n = new Date().toString()
    // var time = n[1] ? n : '0' + n
    // const date = time.replace(/\//g, '-')
    // var date1 = new Date(date) // 现在的时间
    var lc = new Date().setHours(0, 0, 0, 0) // 当前时间的凌晨的时间戳
    var nowlc = new Date(lc)
    var nowTime = nowlc.getTime()
    var day = nowlc.getDay()
    var oneDay = 24 * 60 * 60 * 1000
    var Monday = nowTime - (day - 1) * oneDay
    var monday = new Date(Monday)
    var mondaytamp = Number(monday) // 当前时间周一凌晨的时间戳
    var date2 = new Date(data)
    var timetamp = Number(new Date(data)) // 转化从时间戳
    const d = ((lc - date2) / 1000) // 两个时间相差的秒数
    // console.log(d)
    if (d > 0) {
      var min = (d / 60).toFixed(0)
      var h = (min / 60).toFixed(0)
      // console.log(h)
      if (h <= 24) {
        data = '昨天'
      } else if (h <= 48) {
        data = '前天'
      } else if (h > 48) {
        const d = timetamp - mondaytamp
        if (d >= 0) {
          var l = ['日', '一', '二', '三', '四', '五', '六']
          const z = date2.getDay()
          data = '周' + l[z]
          // if (z == 1) {
          //   data = "周一"
          // } else if (z == 2) {
          //   data = "周二"
          // } else if (z == 3) {
          //   data = "周三"
          // } else if (z == 4) {
          //   data = "周四"
          // } else if (z == 5) {
          //   data = "周五"
          // } else if (z == 6) {
          //   data = "周六"
          // } else if (z == 0) {
          //   data = "周日"
          // }
        } else if (d < 0) {
          const y = date2.getFullYear()
          const m = date2.getMonth()
          const d = date2.getDate()
          data = y + '-' + (m + 1) + '-' + d
        }
      }
    } else {
      // 当时时间段  时分
      const h = date2.getHours()
      const m = date2.getMinutes()
      data = h + ':' + m
    }
    console.log(data)
    return data
  }
}

