import axios from '../../axios'
const basePath = '/core/financeBoard'

export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/detail/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
