<template>
  <div>
    <div v-if="queryList.type === 'takeTab' || queryList.type === 'bothTab'" class="take-tab-cont">
      <el-tabs v-if="queryList.tab" v-model="queryList.tab.value" class="tabs-bottom" @tab-click="handleClick">
        <slot name="button" />
        <el-tab-pane v-for="(item, index) in queryList.tab.options" :key="index" :name="String(item.value)">
          <span slot="label">
            <el-badge v-if="item.isBadge" :value="item.badge" :max="99" class="badge-item">
              {{ item.label }}
            </el-badge>
            <span v-else>
              {{ item.label }}
            </span>
          </span>
        </el-tab-pane>
      </el-tabs>
    </div>


    <div v-if="queryList.list.length > 0" class="search-cont">
      <div class="search-hide">
        <div class="search-hide-list" :class="{ active: isShow }">
          <div class="search-hide-list-flex">
            <div class="search-hide-list-l">
              <el-row :gutter="20">
                <el-col v-for="(item, index) in queryList.list" :key="index" :span="queryList.span||8">
                  <item v-if="!item.hide" :ref="'item' + item.type" :item="item" :text-align="queryList.align" @upQuer="query()" />
                </el-col>
                <el-col :span="8">
                  <slot name="other" />
                </el-col>
              </el-row>
            </div>
            <div
              class="search-hide-list-r"
              :style="{
                'text-align': queryList.align ? `${queryList.align}` : 'left'
              }"
            >
              <el-button v-norepeat type="primary" size="small" @click="query()">查 询</el-button>
              <el-button size="small" @click="query(1)">重 置</el-button>
              <el-button v-if="queryList.list.length > 3" class="more-but" size="small" :class="{ active: isShow }" type="text" @click="handleShow">
                {{ isShow ? '收起' : '展开' }}
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <!-- <el-button v-if="queryList.customButton" size="small" :type="queryList.customButton.type" @click="queryList.customButton.event">{{ queryList.customButton.label }}</el-button> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="queryList.type === 'bothTab'" class="bothTab">
        <div
          class="bothTab-balel"
          :style="{
            'text-align': queryList.align ? `${queryList.align}` : 'left'
          }"
        >
          {{ queryList.bothTab.label }}
        </div>
        <div class="bothTab-radio-group">
          <div
            v-for="(item, index) in queryList.bothTab.options"
            :key="index"
            class="bothTab-radio-item"
            :class="{ active: queryList.bothTab.value === item.value }"
            @click="changeBothTab(item.value)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Item from './components/item.vue'
export default {
  components: {
    Item
  },
  props: {
    queryList: {
      type: Object,
      default() {
        return {
          type: 'normal'
        }
      }
    },
    formType: {
      // 特殊处理状态
      type: String,
      default: ''
    },
    show: {
      type: Boolean, // 默认展开收起状态
      default: false
    }
  },
  data() {
    return {
      params: {},
      isShow: false,
      defaultType: '',
      isHaveBeenPerformed: false // 是否已经点击过查询或者重置
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.isShow = val
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.formType === 'special') {
      this.defaultType = this.queryList.tab.value
    }
  },
  methods: {
    query(type) {
      const { bothTab = {}, tab = {} } = this.queryList
      this.isHaveBeenPerformed = true
      if (type) {
        if (this.queryList.type === 'takeTab' && tab.value !== 'all') {
          this.params = {
            [tab.paramsName]: tab.value
          }
        } else if (this.queryList.type === 'bothTab') {
          if (tab.value !== 'all' && bothTab.value !== 'all') {
            console.log(1)
            this.params = {
              [tab.paramsName]: tab.value,
              [bothTab.paramsName]: bothTab.value
            }
          } else if (tab.value !== 'all' && bothTab.value === 'all') {
            console.log(2)
            this.params = {
              [tab.paramsName]: tab.value
            }
          } else if (tab.value === 'all' && bothTab.value !== 'all') {
            console.log(3)
            this.params = {
              [bothTab.paramsName]: bothTab.value
            }
          } else {
            console.log(4)
            this.params = {}
          }
        } else {
          this.params = {}
        }
        this.queryList.list.forEach(i => {
          if (i.type === 'TimeRange' || i.type === 'DateRange') {
            i.value = []
          } else {
            // appid 不重置
            i.value = i.paramsName === 'appId' ? i.value : ''
          }
        })
      } else {
        this.queryList.list.forEach(i => {
          if (i.type === 'TimeRange' || i.type === 'DateRange') {
            const _paramsName = i.paramsName.split(',')
            if (i.value && i.value.length > 0) {
              if (i.type === 'DateRange') {
                this.params[_paramsName[0]] = i.value[0] + ' ' + '00:00:00'
                this.params[_paramsName[1]] = i.value[1] + ' ' + '23:59:59'
              } else {
                this.params[_paramsName[0]] = i.value[0]
                this.params[_paramsName[1]] = i.value[1]
              }
            } else {
              delete this.params[_paramsName[0]]
              delete this.params[_paramsName[1]]
            }
          } else if (i.value || i.value === 0) {
            this.params[i.paramsName] = i.value
          } else {
            delete this.params[i.paramsName]
          }
        })
        if (this.queryList.type === 'takeTab' && tab.value !== 'all') {
          this.params[tab.paramsName] = tab.value
        } else if (this.queryList.type === 'bothTab') {
          if (tab.value !== 'all' && bothTab.value !== 'all') {
            this.params[tab.paramsName] = tab.value
            this.params[bothTab.paramsName] = bothTab.value
          } else if (tab.value !== 'all' && bothTab.value === 'all') {
            this.params[tab.paramsName] = tab.value
            delete this.params[bothTab.paramsName]
          } else if (tab.value === 'all' && bothTab.value !== 'all') {
            this.params[bothTab.paramsName] = bothTab.value
            delete this.params[tab.paramsName]
          }
        }
      }
      if (type && this.$refs.itemDateRange) {
        this.$refs.itemDateRange[0].init()
      }
      this.$emit('query', this.params, type)
    },
    handleClick() {
      // const { bothTab = {}, tab = {}} = this.queryList
      const { tab = {} } = this.queryList
      if (this.formType === 'special' && this.defaultType !== tab.value) {
        this.defaultType = tab.value
        this.params = {
          [tab.paramsName]: tab.value
        }
        this.queryList.list.forEach(i => {
          if (!(i.type === 'TimeRange' || i.type === 'DateRange')) {
            i.value = ''
          } else {
            i.value = []
          }
        })
      }
      // 这里处理如果有默认值的时候切换的情况
      if (!this.isHaveBeenPerformed) {
        this.defaultParams()
      }
      if (this.queryList.type === 'bothTab') {
        // if (bothTab.value !== 'all' && tab.value !== 'all') {
        //   this.params[bothTab.paramsName] = bothTab.value
        //   this.params[tab.paramsName] = tab.value
        // } else if (bothTab.value !== 'all' && tab.value === 'all') {
        //   this.params[bothTab.paramsName] = bothTab.value
        //   delete this.params[tab.paramsName]
        // } else if (bothTab.value === 'all' && tab.value !== 'all') {
        //   this.params[tab.paramsName] = tab.value
        //   delete this.params[bothTab.paramsName]
        // } else {
        //   delete this.params[bothTab.paramsName]
        //   delete this.params[tab.paramsName]
        // }
        this.changeBothTab()
      } else {
        if (tab.value !== 'all') {
          this.params[tab.paramsName] = tab.value
        } else {
          delete this.params[tab.paramsName]
        }
      }
      this.$emit('query', this.params)
    },
    handleShow() {
      this.isShow = !this.isShow
    },
    changeBothTab(item) {
      const { bothTab = {}, tab = {} } = this.queryList
      if (item) {
        this.queryList.bothTab.value = item
      }
      if (bothTab.value !== 'all' && tab.value !== 'all') {
        this.params[bothTab.paramsName] = bothTab.value
        this.params[tab.paramsName] = tab.value
      } else if (bothTab.value !== 'all' && tab.value === 'all') {
        this.params[bothTab.paramsName] = bothTab.value
        delete this.params[tab.paramsName]
      } else if (bothTab.value === 'all' && tab.value !== 'all') {
        this.params[tab.paramsName] = tab.value
        delete this.params[bothTab.paramsName]
      } else {
        delete this.params[bothTab.paramsName]
        delete this.params[tab.paramsName]
      }
      if (item) {
        this.$emit('query', this.params)
      }
    },
    defaultParams() {
      this.isHaveBeenPerformed = true
      this.queryList.list.forEach(i => {
        const _type = typeof i.value
        if ((_type === 'object' && i.value.length > 0) || (_type === 'string' && i.value)) {
          if (i.type === 'TimeRange' || i.type === 'DateRange') {
            const _paramsName = i.paramsName.split(',')
            if (i.value && i.value.length > 0) {
              if (i.type === 'DateRange') {
                if (i.value[0].indexOf(' ') === -1 || i.value[1].indexOf(' ') === -1) {
                  this.params[_paramsName[0]] = i.value[0] + ' ' + '00:00:00'
                  this.params[_paramsName[1]] = i.value[1] + ' ' + '23:59:59'
                } else {
                  this.params[_paramsName[0]] = i.value[0]
                  this.params[_paramsName[1]] = i.value[1]
                }
              } else {
                this.params[_paramsName[0]] = i.value[0]
                this.params[_paramsName[1]] = i.value[1]
              }
            } else {
              delete this.params[_paramsName[0]]
              delete this.params[_paramsName[1]]
            }
          } else if (i.value || i.value === 0) {
            this.params[i.paramsName] = i.value
          } else {
            delete this.params[i.paramsName]
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.search-cont {
  padding: 12px 20px;
  .bothTab {
    margin-top: 12px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    padding-bottom: 8px;
    display: flex;
    .bothTab-balel {
      width: 70px;
      line-height: 40px;
      margin-right: 10px;
      color: #666;
      font-size: 14px;
    }
    .bothTab-radio-group {
      padding: 12px 0px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      .bothTab-radio-item {
        padding: 0px 20px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 1;
        border-right: 1px solid #dcdcdc;
        box-sizing: border-box;
        &:last-child {
          border: 0;
        }
        &.active {
          color: #3d68cc;
        }
      }
    }
  }
}
.take-tab-cont {
  padding: 14px 20px 1px 20px;
  border-bottom: 1px solid #eee;
  ::v-deep .el-tabs__nav-wrap {
    &::after {
      background: none;
    }
  }
  ::v-deep .el-tabs__header {
    margin: 0;
  }
  ::v-deep .el-tabs__active-bar {
    height: 0;
    border-bottom: 2px solid #3d68cc;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background: none;
  }
}
.search-hide {
  .search-hide-list {
    // background: #eee;
    // padding: 16px;
    .search-hide-list-flex {
      display: flex;
      max-height: 58px;
      transition: all 0.3s;
      overflow: hidden;
      .search-hide-list-l {
        width: calc(100% - 200px);
      }
      .search-hide-list-r {
        width: 236px;
        // text-align: center;
        box-sizing: border-box;
        padding: 12px 0;
        span {
          margin-left: 10px;
        }
        .more-but {
          color: rgba(0, 0, 0, 0.6);
          i {
            transition: all ease-in 0.2s;
          }
          &.active {
            i {
              transition: all ease-in 0.2s;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &.active {
      .search-hide-list-flex {
        max-height: 400px;
        transition: max-height ease-in 0.5s;
      }
    }
  }

  .search-hide-but {
    width: 100%;
    height: 40px;
    text-align: center;
    background: #fff;
    line-height: 40px;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: block;
      overflow: hidden;
      max-width: 0px;
      white-space: nowrap;
      opacity: 0;
    }
    i {
      transition: all ease-in 0.2s;
      &.active {
        transform: rotate(180deg);
      }
    }
    &:hover {
      i {
        color: #3d68cc;
        margin-right: 10px;
        transition: all ease-in 0.2s;
      }
      span {
        color: #3d68cc;
        max-width: 100px;
        opacity: 1;
        transition: all ease-in 0.2s;
      }
    }
  }
}
.badge-item {
  ::v-deep .el-badge__content {
    position: absolute;
    top: 10px;
    right: -3px;
    line-height: 1.3;
  }
}
</style>
