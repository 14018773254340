import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/sysCmsContent/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/sysCmsContent/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/sysCmsContent/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/sysCmsContent/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + '/sysCmsContent/findByUniqueKey',
    method: 'get',
    params: data// data
  })
}

// 审核
export function approve(data) {
  return axios({
    url: basePath + '/sysCmsContent/approve',
    method: 'post',
    data
  })
}

// 获取当前药师
export function findCurrentPharmacist() {
  return axios({
    url: basePath + '/pharmacist/findCurrentPharmacist',
    method: 'get'
  })
}

// 修改当前药师签名
export function updateCurrentPharmacistSignUrl(data) {
  return axios({
    url: basePath + '/pharmacist/edit',
    method: 'post',
    data
  })
}
