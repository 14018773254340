import Vue from 'vue'
/* 自定义全局指令 */

// 阻止按钮重复提交(防抖) v-norepeat
Vue.directive('norepeat', {
  inserted(el, binding) {
    let timer
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      if (!el.disabled) {
        el.disabled = true
        timer = setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  }
})
