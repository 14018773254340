var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("ykj-search", {
        attrs: { "query-list": _vm.queryList },
        on: { query: _vm.inquire },
      }),
      _vm._v(" "),
      _c("MDTable", {
        attrs: { table: _vm.table, "page-vo": _vm.pageVo },
        on: {
          handleSizeChange: _vm.handleSizeChange,
          handleCurrentChange: _vm.handleCurrentChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.oType === "add" ? "新增配置" : "编辑配置",
            visible: _vm.dialogVisible,
            width: "420px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "药品通用名:", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      disabled: _vm.oType === "edit",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "常规最大用量(g):", prop: "maxQty" } },
                [
                  _c("el-input-number", {
                    attrs: { precision: 1, step: 0.1 },
                    model: {
                      value: _vm.ruleForm.maxQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "maxQty", $$v)
                      },
                      expression: "ruleForm.maxQty",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [{ name: "norepeat", rawName: "v-norepeat" }],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }