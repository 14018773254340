import axios from '../../axios'
const basePath = '/mgmt/medicine/blacklists'

export function save(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + `/${data.id}`,
    method: 'put',
    data
  })
}

export function del(id) {
  return axios({
    url: basePath + `/${id}`,
    method: 'delete'
  })
}

export function batchDelete(data) {
  return axios({
    url: basePath + '/_batch_delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 药品导入
export function importExcel(data) {
  return axios({
    url: basePath + '/_import',
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'application/octet-stream'
  }})
}

// 检验黑名单药品
export const checkout = (data) => {
  return axios({
    url: basePath + '/_check_keyword',
    method: 'get',
    params: data
  })
}
