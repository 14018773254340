import axios from '../../axios'
const basePath = '/core'
/*
 * 菜单管理模块
 */

// 保存
export const save = (data) => {
  return axios({
    url: basePath + '/menu/save',
    method: 'post',
    data
  })
}
// 删除
export const batchDelete = (data) => {
  return axios({
    url: basePath + '/menu/delete',
    method: 'post',
    data
  })
}
// 查找导航菜单树
export const findNavTree = (params) => {
  return axios({
    url: basePath + '/menu/findNavTree',
    method: 'get',
    params
  })
}
// 查找菜单树(不包含子节点)
export const findMenuTree = () => {
  return axios({
    url: basePath + '/menu/findMenuTree',
    method: 'get'
  })
}

// 查找菜单树(包含子节点)
export const findAllMenuTree = () => {
  return axios({
    url: basePath + '/menu/findAllMenuTree',
    method: 'get'
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/menu/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 上移
export const stopTop = (data) => {
  return axios({
    url: basePath + '/menu/stopTop',
    method: 'post',
    data
  })
}

// 下移
export const stopDown = (data) => {
  return axios({
    url: basePath + '/menu/stopDown',
    method: 'post',
    data
  })
}

// 唯一性校验
export const checkExists = (data) => {
  return axios({
    url: basePath + '/menu/checkExists',
    method: 'get',
    params: data
  })
}

export const deleteCheck = (data) => {
  return axios({
    url: basePath + '/menu/deleteCheck',
    method: 'post',
    data
  })
}
