const vueselect = {}
export default (vueselect.install = (Vue, options = {}) => {
    Vue.directive('loadmoreTable', {
        inserted(el, binding) {
            const selectWrap = el.querySelector(".el-table__body-wrapper");
            selectWrap.addEventListener("scroll", function () {
                const scrollDistance =
                    this.scrollHeight - this.scrollTop - this.clientHeight;
                if (scrollDistance <= 0.5) {
                    binding.value()
                }
            });
        }
    })
})