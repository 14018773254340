/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-10-19 14:06:49
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-27 09:46:34
 * @FilePath: \drant-manager\src\http\moudules\device\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'
// 获取设备列表
export const deviceList = data => {
  return axios({
    url: '/mgmt/device/list',
    method: 'get',
    params: data
  })
}
// 新增设备
export const deviceAdd = data => {
  return axios({
    url: '/mgmt/device/add',
    method: 'post',
    data
  })
}
// 编辑设备
export const deviceEdit = data => {
  return axios({
    url: '/mgmt/device/edit',
    method: 'post',
    data
  })
}
// 设备上下架
export const deviceStatus = data => {
  return axios({
    url: '/mgmt/device/status',
    method: 'post',
    data
  })
}
// 设备详情
export const deviceDetail = data => {
  return axios({
    url: '/mgmt/device/detail',
    method: 'get',
    params: data
  })
}
// 设备申请列表
export const deviceApplyPage = data => {
  return axios({
    url: '/mgmt/device/apply/getPage',
    method: 'get',
    params: data
  })
}
// 设备申请审核
export const deviceApplyAudit = data => {
  return axios({
    url: '/mgmt/device/apply/audit',
    method: 'post',
    data
  })
}
// 获取用户的授信额度
export const getMemberCredit = data => {
  return axios({
    url: '/mgmt/member/getMemberCredit',
    method: 'get',
    params: data
  })
}
