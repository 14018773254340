<template>
  <div>
    <div v-if="type === 'head'" class="head-cont">
      <el-upload ref="uploadImageUrl" class="avatar-uploader" :clear-files="true" action="test123" :show-file-list="false"
        :http-request="uploadImageSectionFile" :disabled="disabled">
        <!-- <el-button type="text">{{ uploadButText }}</el-button> -->
        <div v-if="imageUrl" class="img-cont">
          <div class="img-cont-shade">
            <i class="el-icon-zoom-in" @click.stop="handleLookImg" />
            <i class="el-icon-upload2" />
          </div>
          <img :src="imageUrl" class="d-avatar" />
        </div>
        <div v-else class="up-add">
          <div class="up-add-cont">
            <i class="el-icon-plus" />
            <p v-if="uploadButText">{{ uploadButText }}</p>
          </div>
        </div>
      </el-upload>
    </div>
    <el-upload v-else-if="type === 'list'" ref="uploadImageUrl" :auto-upload="false" :clear-files="true" action="test123"
      drag multiple list-type="picture-card" :limit="limit" :on-remove="licenseImgRemove" :file-list="imageUrl"
      :on-preview="handleLookImg" :on-exceed="onExceed" :disabled="disabled" :on-change="onChange">
      <div slot="file" slot-scope="{file}">
        <div style="position: relative;" v-if="isVideo(file.url)">
          <video :src="file.url" class="image_item" controls />
          <div class="delete_video" @click="licenseImgRemove(file)">
            <i class="el-icon-delete"></i>
          </div>
        </div>
        <img :src="file.url" class="image_item" v-else>
        <span class="el-upload-list__item-actions" v-if="!isVideo(file.url)">
          <span class="el-upload-list__item-preview" @click="handleLookImg(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="licenseImgRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <i class="el-icon-plus" />
    </el-upload>
  </div>
</template>
<script>
import compressImg from '@/mixins/compressImg'
let time = null
export default {
  mixins: [compressImg],
  props: {
    maxSize: {
      type: Number,
      default: 10
    },
    tagUrl: {
      // 上传请求标识
      type: String,
      default: ''
    },
    uploadButText: {
      // 上传按钮文字自定义
      type: String,
      default: ''
    },
    type: {
      // 上传类型 head、list
      type: String,
      default: 'head'
    },
    imageUrl: {
      // 上传列表图片
      type: [String, Array],
      default: null
    },
    site: {
      // 上传顺序自定义
      type: [String, Number],
      default: null
    },
    limit: {
      // 最大上传数量，默认10
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 上传队列 针对多文件上传 保证顺序不变
      uploadFiles: []
    }
  },
  computed: {
    isVideo() {
      return (url) => url.includes('.mp4')
    }
  },
  methods: {
    beforeImageUpload(file) {
      const arrImage = ['image/jpeg', 'image/png']
      const isJPG = arrImage.concat(file.type)

      if (!isJPG) {
        this.$refs.uploadImageUrl.uploadFiles = this.$refs.uploadImageUrl.uploadFiles.filter(item => item.name !== file.name)
        this.$message.error('上传图片只能是jpg/png格式!')
        return false
      }
      const isLtMaxSize = file.size / 1024 / 1024 <= this.maxSize
      // console.log(isLtMaxSize, this.maxSize)
      if (!isLtMaxSize) {
        this.$refs.uploadImageUrl.uploadFiles = this.$refs.uploadImageUrl.uploadFiles.filter(item => item.name !== file.name)
        this.$message.error(`上传图片大小不能超过 ${this.maxSize}MB!`)
        return false
      }
      return true
      // return isJPG && isLtMaxSize
    },
    async onChange(file, fileList) {
      console.log(file);
      let flag = true;
      if (file.raw.type !== 'video/mp4') {
        flag = this.beforeImageUpload(file.raw)
      }
      if (flag) {
        this.uploadFiles.push(file.raw)
        clearTimeout(time)
        time = setTimeout(async () => {
          if (this.uploadFiles.length > 1) {
            // 队列上传
            this.uploadArrFile()
          } else {
            if (file.size > 5242880 && file.raw.type !== 'video/mp4') {
              // 图片压缩
              this.isCompressImg(async (file, newImg) => {
                console.log(newImg)
                await this.confirmUpload(newImg)
              })
            } else {
              await this.confirmUpload(file.raw)
              this.uploadFiles = []
            }
          }
        }, 500)
      }
    },
    confirmUpload(file) {
      return new Promise(async (resolve, reject) => {
        try {
          var formData = new FormData()
          formData.append('file', file)
          const resdata = await this.uploadFile(this.tagUrl, formData)
          let att = []
          let imageUrl = null
          if (this.type === 'head') {
            att = this.concatAttVo(resdata, this.$refs.uploadImageUrl.uploadFiles[0], this.tagUrl)
            if (!att.url) {
              imageUrl = URL.createObjectURL(this.$refs.uploadImageUrl.uploadFiles[0].raw)
            } else {
              imageUrl = att.url
            }
            this.$refs.uploadImageUrl.uploadFiles = []
          } else {
            att = this.concatAttVoa(resdata, '', this.tagUrl)
          }
          const costList = [imageUrl, att, this.tagUrl, this.site]
          this.$emit('imgSucess', costList)
          resolve()
        } catch (error) {
          this.uploadFiles.splice(this.uploadFiles.length - 1, 1)
          this.$refs.uploadImageUrl.uploadFiles.splice(this.$refs.uploadImageUrl.uploadFiles.length - 1, 1)
          this.licenseImgRemove(file)
          reject(error)
        }
      })
    },
    // 队列上传
    async uploadArrFile() {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const file = this.uploadFiles[i]
        await this.confirmUpload(file)
      }
      this.uploadFiles = []
    },

    async uploadImageSectionFile(params) {
      var file = params.file
      var flag = this.beforeImageUpload(file)
      if (flag) {
        await this.confirmUpload(file)
      }
    },
    async uploadFile(entityType, data, callback) {
      return new Promise((resolve, reject) => {
        this.$api.uploadfile.upload(entityType, data).then(res => {
          const { code, data } = res
          if (code === 200 && data) {
            if (typeof callback === 'function') {
              callback.call(data)
            }
            resolve(data)
          } else {
            reject(data)
          }
        }).catch(err => {
          reject(data)
        })
      })
    },
    handleLookImg(val) {
      if (this.type === 'head') {
        this.$hevueImgPreview({
          multiple: false, // 开启多图预览模式
          url: this.imageUrl,
          mainBackground: 'rgba(0, 0, 0, .4)' // 整体背景颜色
        })
      } else {
        const _index = this.getArrayIndex(this.imageUrl, val.taskId)
        const _imgArr = []
        this.imageUrl.forEach(i => {
          _imgArr.push(i.url)
        })
        this.$hevueImgPreview({
          multiple: true, // 开启多图预览模式
          nowImgIndex: _index, // 显示图片默认值
          imgList: _imgArr, // 需要预览的多图数组
          mainBackground: 'rgba(0, 0, 0, .4)' // 整体背景颜色
        })
      }
    },
    onExceed() {
      this.$message.error(`最多只能上传${this.limit}张图片！`)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length
      while (i--) {
        if (arr[i].taskId === obj) {
          return i
        }
      }
      return -1
    },
    licenseImgRemove(file) {
      this.$emit('licenseImgRemove', [file, this.tagUrl])
    },
    concatAttVoa(response, file, entityType) {
      const att = {
        name: '',
        url: response.url,
        docName: '',
        docSize: '',
        uid: response.taskId,
        docType: '',
        entityType: entityType,
        taskId: response.taskId
      }
      console.log('response.taskId', response.taskId)
      return att
    },
    concatAttVo(response, file, entityType) {
      const fileName = file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)

      const att = {
        name: fileName,
        url: response.url,
        docName: fileName,
        docSize: file.size,
        uid: file.uid,
        docType: fileType,
        entityType: entityType,
        taskId: response.taskId
      }
      return att
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload-dragger {
  width: auto;
  height: auto;
}

::v-deep .el-upload--picture-card {
  border: none;
}

>>>.avatar-uploader .el-upload {
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.up-add {
  width: 148px;
  height: 148px;
  position: relative;
  z-index: 2;
  background: #fbfdff;

  p {
    margin: 0;
    color: #999;
    font-size: 14px;
  }

  .up-add-cont {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);

    i {
      font-size: 28px;
      color: #999;
    }
  }
}

.d-avatar {
  width: 148px;
  height: 148px;
  display: block;
}

.img-cont {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;

  .img-cont-shade {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    line-height: 148px;
    opacity: 0;

    i {
      margin: 0 10px;
      color: #fff;
      font-size: 20px;
    }
  }

  &:hover {
    .img-cont-shade {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
}

.head-cont {
  width: 148px;
}

.image_item {
  width: 146px;
  height: 146px;
  border-radius: 4px;
  object-fit: cover;
}

.delete_video {
  position: absolute;
  top: 0px;
  right: 10px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
</style>
