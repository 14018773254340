var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "back-cont" }, [
    _c(
      "div",
      {
        staticClass: "ykj-button",
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      },
      [_c("i", { staticClass: "el-icon-arrow-left" }), _vm._v("\n    返回\n  ")]
    ),
    _vm._v(" "),
    _vm.text
      ? _c("div", { staticClass: "back-tab" }, [
          _vm.icon
            ? _c("i", {
                class: _vm.icon,
                staticStyle: { "padding-right": "10px" },
              })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }