import axios from '../../axios'
const basePath = '/mgmt/medical/dosage'

export function findPage(data) {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 新增
export function save(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}

// 编辑
export function edit(data) {
  return axios({
    url: basePath,
    method: 'put',
    data
  })
}

// 删除
export function del(data) {
  return axios({
    url: basePath + '/' + data.id,
    method: 'delete',
    data
  })
}
