var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("canvas", {
    ref: "canvas",
    staticClass: "result-canvas",
    attrs: { id: "canvas", height: _vm.canvasHeight, width: _vm.canvasWidth },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }