import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 服务站人员列表  管理员使用
export function adminFindPage(data) {
    return axios({
        url: '/mgmt/service/station/staff/pageList',
        method: 'get',
        params: data
    })
}

// 服务站人员列表  机构
export function agentFindPage(data) {
    return axios({
        url: '/mgmt/service/station/staff/pageListAgent',
        method: 'get',
        params: data
    })
}

// 新增服务站人员
export function serviceStationAdd(data) {
    return axios({
        url: '/mgmt/service/station/staff/add',
        method: 'post',
        data
    })
}
// 编辑服务站人员
export function serviceStationUpdate(data) {
    return axios({
        url: '/mgmt/service/station/staff/update',
        method: 'post',
        data
    })
}