import axios from '../../axios'
const basePath = '/mgmt/archiving/archiveLogs'

export function archiveLogs(params) {
  return axios({
    url: basePath,
    method: 'get',
    params
  })
}

//
export function archiving(id) {
  return axios({
    url: basePath + `/${id}/_archiving`,
    method: 'post'
  })
}
