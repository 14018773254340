<template>
  <div class="login-container">
    <!-- <img class="adminlogo" src="img/adminlogo.png" alt=""> -->
    <transition name="el-zoom-in-center">
      <!-- <div v-show="show2" class="transition-box">.el-zoom-in-center</div> -->
      <password v-show="show2" />
    </transition>
    <transition name="el-zoom-in-center" :duration="2000">
      <phone v-show="show1" />
    </transition>
    <!-- <el-button class="cut" icon="el-icon-sort" @click="showdown">切换登录</el-button> -->
  </div>
</template>
<script>
import password from './components/password'
import phone from './components/phone'
export default {
  components: { password, phone },

  data() {
    return {
      show2: true,
      show1: false
    }
  },
  methods: {
    showdown() {
      this.show2 = !this.show2
      this.show1 = !this.show1
    }
  }
}

</script>

<style lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#fff;
$light_gray:#fff;
$cursor: #333;
$imgpath:"https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager";

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}
/* reset element-ui css */
.login-container {
  min-height: 100%;
  width: 100%;
  background:url($imgpath + '/bg/bg1.png') center;
  overflow: hidden;
  min-width: 1400px;
  overflow-y: auto;
  background-size: 100% 100%;
  .adminlogo{
        right: -50px;
        top: 50px;
    position: relative;
  }

}
@media screen and (max-width: 1920px){
  .login-container {
    background:url($imgpath + '/bg/bg2.png') center;
    background-size: auto auto;
  }
}
.cut{
  position: fixed;
  top: 160px;
  right: 500px;
}

</style>

