<template>
  <div class="cur_case_container">
    <div v-if="!!medicalHistoryList.length" class="cur_case_container">
      <div class="left">
        <el-timeline>
          <el-timeline-item v-for="(v, i) in medicalHistoryList" :key="v.appointNo" :timestamp="v.createTime" placement="top">
            <el-card :class="i === curIdx ? 'card_box active' : 'card_box'" @click.native="getDetail(v.id, i)">
              <el-descriptions :column="1">
                <el-descriptions-item label="门诊号">
                  {{ v.inquiryAppoint.inquiryNo }}
                  <span><el-tag size="small">复诊</el-tag></span>
                </el-descriptions-item>
                <el-descriptions-item label="接诊医生">
                  {{ v.doctorName + ' | ' + v.departmentName + ' | ' + v.positionCategory_dictName }}
                </el-descriptions-item>
                <el-descriptions-item label="主诉">
                  {{ v.inquiryAppoint.diseaseDescription || '-' }}
                </el-descriptions-item>
              </el-descriptions>
              <div v-if="v.treatmentRecordsVos && v.treatmentRecordsVos.length > 0">
                <el-descriptions :column="1">
                  <el-descriptions-item v-if="diagnoseDistinguish(v.treatmentDiagnoses, 1)" label="西医诊断">
                    {{ diagnoseZH(v.treatmentDiagnoses || [], 1) }}
                    <!-- {{ diagnoseZH(v.treatmentRecordsVos, 1).map(i=> i.diagnosisName).join('、') }} -->
                  </el-descriptions-item>
                  <el-descriptions-item v-if="diagnoseDistinguish(v.treatmentDiagnoses, 2)" label="中医辨病" :span="1">
                    {{ diagnoseZH(v.treatmentDiagnoses || [], 2) }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="diagnoseDistinguish(v.treatmentDiagnoses, 3)" label="中医辩证" :span="1">
                    {{ diagnoseZH(v.treatmentDiagnoses || [], 3) }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="right">
        <div class="title">病历详情</div>
        <div class="right_main">
          <el-descriptions title="病历信息" :column="3">
            <el-descriptions-item label="就诊人">
              {{ caseObj.patientName }} | {{ genderType[caseObj.gender] || '-' }} | {{ caseObj.age > 0 ? `${caseObj.age}岁` : `${caseObj.ageMonth > 0 ? caseObj.ageMonth + '月' : '未知'}` }}
            </el-descriptions-item>
            <el-descriptions-item label="门诊号">{{ caseObj.inquiryAppoint.inquiryNo }}</el-descriptions-item>
            <el-descriptions-item label="初诊标志">复诊</el-descriptions-item>
            <el-descriptions-item v-if="caseObj.hadGuardian === 1" label="监护人">
              {{ caseObj.guardianName || '-' }}
            </el-descriptions-item>
            <el-descriptions-item v-if="caseObj.hadGuardian === 1" label="监护人身份证号" :span="2">{{ caseObj.guardianIdCard }}</el-descriptions-item>
            <el-descriptions-item label="就诊类型">{{ inquiryTypeZH(caseObj) }}</el-descriptions-item>
            <el-descriptions-item label="就诊机构">{{ title }}</el-descriptions-item>
            <el-descriptions-item label="就诊时间">{{ caseObj.createTime || '-' }}</el-descriptions-item>
            <el-descriptions-item label="就诊科室">{{ caseObj.departmentName || '-' }}</el-descriptions-item>
            <el-descriptions-item label="接诊医生" :span="2">{{ caseObj.doctorName }}</el-descriptions-item>
            <el-descriptions-item label="主诉" :span="3">{{ caseObj.inquiryAppoint.diseaseDescription || '-' }}</el-descriptions-item>
            <el-descriptions-item label="现病史" :span="3">{{ caseObj.hadMedicalHistory === 1 ? `${caseObj.medicalHistory ? caseObj.medicalHistory : '不详'}` : '无' }}</el-descriptions-item>
            <el-descriptions-item label="既往史" :span="3">{{ caseObj.hadPreviousHistory === 1 ? `${caseObj.previousHistory ? caseObj.previousHistory : '不详'}` : '无' }}</el-descriptions-item>
            <el-descriptions-item label="过敏史" :span="3">{{ caseObj.hadAllergy === 1 ? `${caseObj.allergy ? caseObj.allergy : '不详'}` : '无' }}</el-descriptions-item>
            <el-descriptions-item label="病历资料" :span="3">
              <div v-if="caseObj.inquiryAppoint.medicalNoteImages && caseObj.inquiryAppoint.medicalNoteImages.length > 0" class="material-list">
                <div v-for="(i, n) in caseObj.inquiryAppoint.medicalNoteImages" :key="n" class="material-item" @click="handlePreview(caseObj.inquiryAppoint.medicalNoteImages, n)">
                  <img :src="i" alt="" />
                </div>
              </div>
              <span v-else>记录遗失/不在身边</span>
            </el-descriptions-item>
            <el-descriptions-item label="体格检查" :span="3" content-class-name="health_checkup">
              <span>体温(℃)：- &nbsp; &nbsp; &nbsp;</span>
              <span>脉率（次/min）：- &nbsp; &nbsp; &nbsp;</span>
              <span>呼吸频率(次/min)：- &nbsp; &nbsp; &nbsp;</span>
              <span>收缩压/舒张压(mmHg)：- &nbsp; &nbsp; &nbsp;</span>
              <span>身高（cm）：-&nbsp; &nbsp; &nbsp;</span>
              <span>未见异常</span>
            </el-descriptions-item>
            <el-descriptions-item label="中医四诊" :span="3">-</el-descriptions-item>
            <el-descriptions-item label="辅助检查" :span="3">-</el-descriptions-item>
            <!-- <el-descriptions :column="1"> -->
            <el-descriptions-item label="西医诊断" :span="3">
              {{ diagnoseZH(caseObj.treatmentDiagnoses, 1) || '-' }}
            </el-descriptions-item>
            <el-descriptions-item label="中医辨病">
              {{ diagnoseZH(caseObj.treatmentDiagnoses, 2) || '-' }}
            </el-descriptions-item>
            <el-descriptions-item label="中医辩证" :span="2">
              {{ diagnoseZH(caseObj.treatmentDiagnoses, 3) || '-' }}
            </el-descriptions-item>
            <!-- </el-descriptions> -->
            <!-- <el-descriptions-item label="西医诊断" :span="3">无</el-descriptions-item>
          <el-descriptions-item label="中医辨病">无</el-descriptions-item>
          <el-descriptions-item label="中医辩证" :span="2">无</el-descriptions-item> -->
            <el-descriptions-item label="辩证依据" :span="3">-</el-descriptions-item>
            <el-descriptions-item label="治则治法" :span="3">-</el-descriptions-item>
            <el-descriptions-item label="医嘱备注" :span="3">-</el-descriptions-item>
            <el-descriptions-item label="处置记录" :span="3">-</el-descriptions-item>
          </el-descriptions>
          <div v-if="recipeList.length > 0">
            <h1 class="el-descriptions__title">处方信息</h1>
            <div v-for="v in recipeList" :key="v.outpatientNo" class="recipe_box">
              <el-descriptions title="">
                <el-descriptions-item label="处方类型">{{ typeObj[v.type] }}</el-descriptions-item>
                <el-descriptions-item label="处方编号">{{ v.outpatientNo }}</el-descriptions-item>
                <el-descriptions-item label="开具时间">{{ v.createTime }}</el-descriptions-item>
              </el-descriptions>
              <template v-if="v.type === 1">
                <el-table :data="v.treatmentOrderItems" border style="width: 100%;margin-bottom:10px">
                  <el-table-column type="index" label="序号" width="50" />
                  <el-table-column prop="medicineName" label="药品名称" width="180" />
                  <el-table-column prop="specification" label="规格" />
                  <el-table-column prop="drugsForm" label="剂型">
                    <template slot-scope="scope">
                      {{ dosageFormObj[scope.row.drugsForm] }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="phruom" label="每次用量">
                    <template slot-scope="scope">
                      每次{{ scope.row.useQty }}{{ scope.row.phruom_dictName }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="usage" label="用药途径" />
                  <el-table-column prop="timsPerday" label="用药频次" />
                  <el-table-column prop="qty" label="数量">
                    <template slot-scope="scope">
                      {{ scope.row.qty + scope.row.salesUnit_dictName }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <div v-else>
                <el-descriptions title="" :column="3">
                  <el-descriptions-item label="处方" :span="3">
                    <div v-for="e in v.treatmentOrderItems" :key="e.id" class="recipe_box_z">{{ e.medicineName }}{{ e.qty }}{{ e.salesUnit_dictName }}</div>
                  </el-descriptions-item>
                  <el-descriptions-item label="剂数" :span="1">{{ v.totalDosage }}剂</el-descriptions-item>
                  <el-descriptions-item label="用药方法" :span="2">
                    {{ usageType[v.usage] }}, &nbsp; {{ v.fewDays }}日{{ v.severalDoses }}剂, &nbsp; 一剂分{{ v.howManyTimes }}次服用
                  </el-descriptions-item>
                  <el-descriptions-item label="用药说明" :span="3">{{ v.decoctingMethod }}</el-descriptions-item>
                </el-descriptions>
              </div>
              <el-descriptions title="">
                <el-descriptions-item label="处方医生">{{ v.createDoctorName }}</el-descriptions-item>
                <el-descriptions-item label="审核药师">{{ v.auditPharmacistName || '-' }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      暂无线上就诊记录!
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curIdx: 0,
      title: '',
      genderType: { 1: '男', 2: '女' },
      usageType: { 1: '内服', 2: '外用' },
      typeObj: { 1: '西药处方', 2: '中药处方' },
      medicalHistoryList: [
        // {
        //   appointNo: 2022012210172441,
        //   doct: '张三 内科 主治医师',
        //   zs: '咳嗽',
        //   xiyi: '慢性咽炎',
        //   zybb: '感冒',
        //   zybz: '热证'
        // }
      ],
      recipeList: [], // 处方
      caseObj: {
        inquiryAppoint: {},
        treatmentRecordsVos: []
      },
      dosageFormObj: {} // 剂型字典对象
    }
  },
  created() {
    const { idCard, token } = this.$route.query
    if (!token || !idCard) {
      return
    }

    // 获取系统配置标题
    this.$api.settings.headInfo({ groups: 'system' }).then(res => {
      // sessionStorage.setItem('systemInfo', JSON.stringify(res.data))
      this.title = res.data.system.title
    })
    // 获取患者id
    this.$api.patient.findByIdCard({ idCard: idCard }).then(res => {
      if (res.code === 200) {
        if (res.data && res.data.length) {
          this.medicalHistoryFinpage(res.data.join(','))
          this.dictionaryList()
        } else {
          this.$message.error('没有该患者相关信息')
        }
      }
    })
    // 测试
    // this.medicalHistoryFinpage(73)
  },
  methods: {
    // 药品剂型字典
    dictionaryList() {
      // 药品剂型字典
      this.$api.dictionary.dictionaryList({ categoryCode: 'ywjxdm' }).then(data => {
        const dosageFormObj = {}
        data.data.forEach(v => {
          dosageFormObj[v.value] = v.label
        })
        this.dosageFormObj = dosageFormObj
      })
    },
    getDetail(id, i = 0) {
      this.$api.patient.findMedicalHistoryByUniqueKey(id).then(res => {
        if (res.code === 200) {
          this.curIdx = i
          this.caseObj = res.data
          // 处方
          const treatmentRecordsVos = res.data.treatmentRecordsVos || []
          this.recipeList = treatmentRecordsVos
        }
      })
    },
    medicalHistoryFinpage(id) {
      // 病历列表
      const queryParams = {
        curPage: 1,
        pageSize: 10000,
        patientIds: id
      }
      this.$api.patient.medicalHistoryFindPage(queryParams).then(res => {
        if (res.code === 200) {
          this.medicalHistoryList = res.data.records || []
          if (this.medicalHistoryList.length) {
            this.getDetail(this.medicalHistoryList[0].id)
            // this.caseObj = this.medicalHistoryList[0]
          }
        }
      })
    },
    handlePreview(Arr, index) {
      this.$hevueImgPreview({
        multiple: true, // 开启多图预览模式
        nowImgIndex: index, // 显示图片默认值
        imgList: Arr, // 需要预览的多图数组
        mainBackground: 'rgba(0, 0, 0, .4)' // 整体背景颜色
      })
    },
    inquiryTypeZH(item) {
      let _str = '-'
      if (item.inquiryAppoint) {
        if (item.inquiryAppoint.askType === 0) {
          _str = '快速问诊'
        } else if (item.inquiryAppoint.askType === 1) {
          _str = `名医预约 | ${item.inquiryAppoint.type === 1 ? '图文问诊' : '视频问诊'}`
        } else if (item.inquiryAppoint.askType === 2) {
          _str = '线上复诊'
        } else if (item.inquiryAppoint.askType === 3) {
          _str = '慢性病复诊 | 图文问诊'
        } else if (item.inquiryAppoint.askType === 4) {
          _str = '快速续方'
        }
      }
      return _str
    },
    diagnoseDistinguish(arr, type) {
      // 鉴定是否存在中药/成药处方
      if (!arr || !arr.length) {
        return false
      }
      let _Boole = false
      arr.forEach(i => {
        if (i.diagnosisType === type) {
          _Boole = true
        }
      })
      return _Boole
    },
    diagnoseZH(Arr, type) {
      const diagnosisArr = []
      // Arr.forEach(i => {
      //   i.treatmentDiagnoses.forEach(e => {
      //     if (e.diagnosisType === type) { // 整合所有的处方诊断
      //       diagnosisArr.push(e.diagnosisName)
      //     }
      //   })
      // })
      Arr.forEach(i => {
        // i.treatmentDiagnoses.forEach(e => {
        if (i.diagnosisType === type) {
          // 整合所有的处方诊断
          console.log(i.diagnosisType)
          diagnosisArr.push(i.diagnosisName)
        }
        // })
      })
      return Array.from(new Set(diagnosisArr)).join('、')
    }
  }
}
</script>

<style lang="scss">
.left {
  float: left;
  width: 340px;
  height: 100%;
  border-right: 1px solid #e2e4ea;
  header {
    height: 80px;
    background: #f3f3f3;
    box-sizing: border-box;
    padding: 0 10px;
  }
  main {
    height: 100%;
    background: #fff;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 0px;
    }
    .conta {
      height: 100%;
      background: #fff;
      overflow-y: auto;
    }
  }
  footer {
    height: 80px;
    background: #fff;
    border-top: 1px solid #dedede;
  }
}
.right {
    float: left;
    width: calc(100% - 340px);
    height: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    header{
      height: 85px;
      background: #fff;
      border-bottom: 1px solid #E2E4EA;
      position: relative;
      z-index: 3;
    }
    main{
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        border-radius: 0px;
      }
      .conta{
        height: 566px;
        background: #fff;
        padding: 0;
        overflow-y: auto;
        &::-webkit-scrollbar-track{
          background: #fff;
          border-radius:2px;
          box-shadow: none;
        }
        &::-webkit-scrollbar-thumb{
          background: #E9E9EB;
          border-radius:0px;
          box-shadow: none;
        }
        &::-webkit-scrollbar-thumb:hover{
          background: #333;
        }
        &::-webkit-scrollbar-corner{
          background: none;
        }
      }
    }
    footer{
       height: 280px;
       background: #fff;
       border-top: 1px solid #dedede;
       padding: 0 10px;
    }
}
.cur_case_container {
  padding: 15px 15px 15px 0;
  overflow: hidden;
  height: 100%;
  .no-data {
    text-align: center;
    font-size: 36px;
    line-height: 5;
  }
  .left {
    width: 400px;
    padding-right: 15px;
    overflow: auto;
    .el-descriptions-item__content {
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .card_box {
      cursor: pointer;
      p {
        margin-top: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
    .card_box.active {
      .el-card__body {
        background: rgba(242, 242, 242, 1);
      }
      .el-descriptions__body {
        background: rgba(242, 242, 242, 1);
      }
    }
    // .card_box.active{
    //   .el-card__body{
    //     background: #fff;
    //   }
    //   .el-descriptions__body{
    //     background: #fff;
    //   }
    // }
  }
  .right {
    width: calc(100% - 405px);
    // height: auto;
    display: block;
    overflow: auto;
    .title {
      background: #81b2d9;
      color: #fff;
      padding-left: 20px;
      height: 40px;
    }
    .right_main {
      padding: 20px;
      .recipe_box {
        border: 1px solid #ccc;
        padding: 10px 10px 0;
        margin-bottom: 10px;
      }
      .recipe_box_z {
        width: 150px;
      }
    }
    .health_checkup {
      display: flex;
      flex-flow: wrap;
      // span{
      //   display: inline-block;
      // }
    }
  }
}
</style>
