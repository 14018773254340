import MDtitle from './MDtitle'
import search from './MDinquire'
import MDTable from './MDtable'
import MDldialog from './MDldialog'
import MDupload from './MDupload'
import ykjSearch from './Search' // 新的全局查询组件
import ykjUpload from './Upload' // 新的上传组件
import ykjGoBack from './goback' // 返回上一页
import groupcConsultationDetails from './groupcConsultationDetails' // 会诊详情页

const component = {
  install: function(Vue) {
    Vue.component('Search', search)
    Vue.component('ykjSearch', ykjSearch)
    Vue.component('ykjUpload', ykjUpload)
    Vue.component('ykjGoBack', ykjGoBack)
    Vue.component('groupcConsultationDetails', groupcConsultationDetails)
    Vue.component('MDTable', MDTable)
    Vue.component('MDtitle', MDtitle)
    Vue.component('Ldialog', MDldialog)
    Vue.component('MDupload', MDupload)
  } // 'component-name'这就是后面可以使用的组件的名字，install是默认的一个方法 component-name 是自定义的，我们可以按照具体的需求自己定义名字

}
// 导出该组件
export default component
