import axios from '../../axios'
const basePath = '/core'
/*
 * 角色管理模块
 */

// 保存
export const save = (data) => {
  return axios({
    url: basePath + '/role/save',
    method: 'post',
    data
  })
}
// 编辑
export const edit = (data) => {
  return axios({
    url: basePath + '/role/edit',
    method: 'post',
    data
  })
}
// 删除
export const batchDelete = (data) => {
  return axios({
    url: basePath + '/role/delete',
    method: 'post',
    data
  })
}
// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/role/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
// 查询全部
export const findAll = () => {
  return axios({
    url: basePath + '/role/findAll',
    method: 'get'
  })
}
// 查询角色菜单集合
export const findRoleMenus = (params) => {
  return axios({
    url: basePath + '/role/findRoleMenus',
    method: 'get',
    params: { ...params }
  })
}
// 保存角色菜单集合
export const saveRoleMenus = (data) => {
  return axios({
    url: basePath + '/role/saveRoleMenus',
    method: 'post',
    data
  })
}
// 唯一性校验
export const checkExists = (data) => {
  return axios({
    url: basePath + '/role/checkExists',
    method: 'get',
    params: data
  })
}

export const deleteCheck = (data) => {
  return axios({
    url: basePath + '/role/deleteCheck',
    method: 'post',
    data
  })
}
