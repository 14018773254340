/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-08-02 15:32:59
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-19 14:07:14
 * @FilePath: \drant-manager\src\http\moudules\obs\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-07-25 09:59:23
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-07-26 15:50:08
 * @FilePath: \drant-manager\src\http\moudules\obs\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 获取文件列表
export const getFileListPage = data => {
  return axios({
    url: 'core/file/getFileListPage',
    method: 'get',
    params: data
  })
}
// 创建文件夹
export const createFolder = data => {
  return axios({
    url: 'core/file/createFolder',
    method: 'post',
    data
  })
}
// 下载文件
export const downloadFile = data => {
  return axios({
    url: 'core/file/downloadFile',
    method: 'post',
    data
  })
}
// 上传文件
export const uploadFile = data => {
  return axios(
    {
      url: 'core/file/uploadFile',
      method: 'post',
      data
    },
    // {
    //   headers: {
    //     'Content-Type': 'application/octet-stream'
    //   }
    // }
  )
}
