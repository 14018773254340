var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "双重验证",
        visible: _vm.dialogNoteCode,
        width: "400px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogNoteCode = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "noteLoginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.noteLoginForm,
            rules: _vm.noteLoginRules,
            autocomplete: "on",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { staticClass: "codefrom", attrs: { prop: "code" } },
            [
              _c("el-input", {
                ref: "code",
                staticClass: "code",
                style: { width: "60%" },
                attrs: {
                  type: _vm.codeType,
                  placeholder: "请输入验证码",
                  name: "code",
                  tabindex: "2",
                  autocomplete: "on",
                  "prefix-icon": "el-icon-warning",
                  maxlength: "6",
                },
                on: {
                  blur: function ($event) {
                    _vm.capsTooltip = false
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleNoteLogin($event)
                  },
                },
                model: {
                  value: _vm.noteLoginForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.noteLoginForm, "code", $$v)
                  },
                  expression: "noteLoginForm.code",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "shu" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "text", disabled: _vm.huoqu },
                  on: { click: _vm.obtainCode },
                },
                [
                  _vm._v(
                    _vm._s(_vm.huoqu ? _vm.timer + "s后重新获取" : "获取验证码")
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogNoteCode = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleNoteLogin } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }