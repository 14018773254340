var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-center" } },
        [
          _c("password", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show2,
                expression: "show2",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-center", duration: 2000 } },
        [
          _c("phone", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show1,
                expression: "show1",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }