<template>
    <div>
        <Branch v-for="(item, index) in currentGroups" :key="index" :branchFeature="{
            amp: feature[`${item.key}_amp_max`],
            pres: feature[`${item.key}_vital_pres`],
            width: feature[`${item.key}_width`]
        }" :en="en" :color="branchColor[item.key]" :rotate="rotate" :title="item.title" :mai="branch[item.key].mai"
            :value="branch[item.key].value" :measure="measure" :widthBar="widthBar" :width3d="width3d"
            :showKong="showKong" />
    </div>
</template>

<script>
// import { branchColor } from './index.js'
import Branch from './index.vue'
import { groups, groups1 } from './groups'

export default {
    components: {
        Branch
    },
    data() {
        return {
            branchColor: {
                cun: 'rgba(255, 50, 51, 1)',
                guan: 'rgba(255, 152, 0, 1)',
                chi: 'rgba(32, 178, 41, 1)'
            }
        }
    },
    props: {
        widthBar: {
            type: Number,
            default: 150
        },
        width3d: {
            type: Number,
            default: 240
        },
        feature: {
            type: Object,
            default: () => null
        },
        branchResult: {
            type: Object,
            default: () => null
        },
        rotate: {
            type: Boolean,
            default: true
        },
        measure: {
            type: Object,
            default: () => null
        },
        en: {
            type: String,
            default: ''
        },
        showKong: Boolean
    },
    computed: {
        branch() {
            return this.branchResult ? this.branchResult.branch : {}
        },
        currentGroups() {
            return this.en ? groups1 : groups
        }
    }
}
</script>