const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  appSocketFlag: state => state.app.appSocketFlag,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  currentRole: state => state.user.currentRole,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  currentPerms: state => state.user.perms,
  currentPermsUrl: state => state.user.permsUrl,
  currentAssistantInfo: state => state.user.assistantInfo,
  currentUserInfo: state => state.user.currentUser,
  freightChange: state => state.freight.freightChange,
  menuClick: state => state.freight.menuClick,
  nextPath: state => state.freight.nextPath
}
export default getters
