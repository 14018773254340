import axios from '../../axios'
// ------------------------------------------- 机构信息
// 更改机构信息
export function updateInfo(data) {
  return axios({
    url: '/mgmt/ins/institutions',
    method: 'post',
    data
  })
}

// 查询机构信息
export const getOnlyKey = (data) => {
  return axios({
    url: '/mgmt/ins/institutions',
    method: 'get',
    params: data
  })
}

// ------------------------------------------- 业务开展情况

// 业务开展情况分页查询
export const findYwkzqkPage = (data) => {
  return axios({
    url: '/mgmt/ins/institutionbusinesses',
    method: 'get',
    params: data
  })
}

// 新增业务开展情况信息
export const addYwkzqk = (data) => {
  return axios({
    url: '/mgmt/ins/institutionbusinesses',
    method: 'post',
    data
  })
}

// 修改业务开展情况信息
export const editYwkzqk = (data) => {
  return axios({
    url: `/mgmt/ins/institutionbusinesses/${data.id}`,
    method: 'put',
    data
  })
}

// 删除业务开展情况信息
export const delYwkzqk = (id) => {
  return axios({
    url: `/mgmt/ins/institutionbusinesses/${id}`,
    method: 'delete'
  })
}

// 校验某月份的业务开展情况记录是否已存在
export const verifyYwkzqk = (data) => {
  return axios({
    url: `/mgmt/ins/institutionbusinesses/_nfExists`,
    method: 'get',
    params: data
  })
}

// ------------------------------------------- 行政科室通讯录信息

// 行政科室通讯录信息分页查询
export const findXzkstxlPage = (data) => {
  return axios({
    url: '/mgmt/ins/contacts',
    method: 'get',
    params: data
  })
}

// 新增业务开展情况信息
export const addXzkstxl = (data) => {
  return axios({
    url: '/mgmt/ins/contacts',
    method: 'post',
    data
  })
}

// 修改业务开展情况信息
export const editXzkstxl = (data) => {
  return axios({
    url: `/mgmt/ins/contacts/${data.id}`,
    method: 'put',
    data
  })
}

// 校验某月份的业务开展情况记录是否已存在
export const verifyXzkstxl = (data) => {
  return axios({
    url: `/mgmt/ins/contacts/_bmExists`,
    method: 'get',
    params: data
  })
}

// ------------------------------------------- 设备资源信息

// 设备资源信息分页查询
export const findDevicesPage = (data) => {
  return axios({
    url: '/mgmt/ins/devices',
    method: 'get',
    params: data
  })
}

// 新增设备资源信息
export const addDevices = (data) => {
  return axios({
    url: '/mgmt/ins/devices',
    method: 'post',
    data
  })
}

// 修改设备资源信息
export const editDevices = (data) => {
  return axios({
    url: `/mgmt/ins/devices/${data.id}`,
    method: 'put',
    data
  })
}

// 删除设备资源信息
export const delDevices = (id) => {
  return axios({
    url: `/mgmt/ins/devices/${id}`,
    method: 'delete'
  })
}

// 设备资源信息详情
export const getOnlyDevicesKey = (id) => {
  return axios({
    url: `/mgmt/ins/devices/${id}`,
    method: 'get'
  })
}
