import axios from '../../axios'

// 分页查询
export const findPage = data => {
  return axios({
    url: '/mgmt/patient/patients',
    method: 'get',
    params: data
  })
}
// 档案记录列表分页查询
export const getPatientHealthRecordList = data => {
  return axios({
    url: '/mgmt/patient/healthRecord/getPatientHealthRecordList',
    method: 'get',
    params: data
  })
}

export const findPatientByUniqueKey = id => {
  return axios({
    url: `/mgmt/patient/patients/${id}`,
    method: 'get'
  })
}
// 查询健康档案字典全部分类名
export const getAllConstantDesc = id => {
  return axios({
    url: `/mgmt/patient/healthRecord/getAllConstantDesc`,
    method: 'get'
  })
}
// 查看患者档案
export const getPatientHealthRecordDetail = data => {
  return axios({
    url: `/mgmt/patient/healthRecord/getPatientHealthRecordDetail`,
    method: 'get',
    params: data
  })
}

// 病历分页查询
export const medicalHistoryFindPage = data => {
  return axios({
    url: '/mgmt/patient/medical-notes',
    method: 'get',
    params: data
  })
}

// 病历唯一健查询
export const findMedicalHistoryByUniqueKey = id => {
  return axios({
    url: `/mgmt/patient/medical-notes/${id}`,
    method: 'get'
  })
}
// 根据身份证号查询患者ID idCard
export const findByIdCard = params => {
  return axios({
    url: `/mgmt/patient/patients/_findIdsByIdCard`,
    method: 'get',
    params
  })
}
