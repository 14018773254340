<template>
  <div :class="['branch-canvas-wrap']" :style="{ width: computedWidth + 'px', height: height + 'px' }">
    <canvas
      ref="branchCanvas"
      :height="height * 2"
      :width="canvasWidth"
      @click="canBuildLine"
    ></canvas>
  </div>
</template>

<script>
import { branchColumns, branchColumns1 } from './index.js'
// import { BranchColumns, BranchOneValue } from '@report/config/type'
import { chartBranchBar } from './chartBranchBar.js'

export default {
  props: {
    width: {
      type: Number,
      default: 140
    },
    height: {
      type: Number,
      default: 180
    },
    value: {
      type: Object,
      required: true
    },
    mai: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'rgba(255, 50, 51, 1)'
    },
    en: {
      type: String,
      default: ''
    },
    showKong: Boolean
  },
  data() {
    return {
      canvasWidth: this.en ? this.width * 3 : this.width * 2
    }
  },
  computed: {
    computedWidth() {
      return this.en ? this.width * 1.5 : this.width
    }
  },
  methods: {
    canBuildLine() {
      this.$emit('canbuildline', true)
    }
  },
  mounted() {
    if (!this.$refs.branchCanvas) return
    this.canBuildLine()
    const ctx = this.$refs.branchCanvas.getContext('2d')
    if (ctx) {
      const values = []
      const columns = this.en ? branchColumns1 : branchColumns
      columns.forEach((c) => {
        values.push({
          ...c,
          value: this.value[c.key] || c.value
        })
      })
      chartBranchBar(ctx, this.height * 2, this.canvasWidth, values, this.color, this.showKong, this.mai, this.en)
    }
  }
}
</script>

<style scoped lang="scss">
.branch-canvas-wrap {
  margin: auto 0;
  overflow: hidden;

}
canvas {
    backface-visibility: hidden;
    transform: scale(0.5) translate(-50%, -50%);
  }
  .branch-canvas-wrap {
  width: 148px;
  height: 222px;
}

.branchCanvas {
  width: 300px;
  height: 444px;
}

@media screen and (max-width: 390px) {
  .branch-canvas-wrap {
    width: 150px;
    height: 127px;
  }
  .branchCanvas {
  width: 250px;
  height: 255px;
}
}
</style>
