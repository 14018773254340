import axios from '../../axios'

export function getAboutUsContent(data) {
  return axios({
    url: '/core/sysAboutUs/getAboutUsContent',
    method: 'get',
    params: data
  })
}

export function save(data) {
  return axios({
    url: '/core/sysAboutUs/save',
    method: 'post',
    data: data
  })
}

export function edit(data) {
  return axios({
    url: '/core/sysAboutUs/edit',
    method: 'post',
    data: data
  })
}
