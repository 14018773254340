import axios from '../../axios'
// 快检列表
export const reportList = (data) => {
  return axios({
    url: '/mgmt/fastCheck/getPage',
    method: 'get',
    params: data
  })
}
// 下载
export const download = (data) => {
  return axios({
    url: '/mgmt/fastCheck/download/'+ data.checkId,
    method: 'post',
    data
  })
}
// 清除
export const deletePdf = (data) => {
  return axios({
    url:  'mgmt/fastCheck/deletePdf/' + data.checkId,
    method: 'post'
  })
}

// 脉诊列表
export const rpulseTakingList = (data) => {
  return axios({
    url: '/mgmt/pulse/diagnosis/getPage',
    method: 'get',
    params: data
  })
}

// 舌面诊列表
export const tongueList = (data) => {
  return axios({
    url: '/mgmt/tongue/getPage',
    method: 'get',
    params: data
  })
}
