<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/dashboard/dashboard">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title">{{ title }} </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/dashboard/dashboard">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <el-tooltip v-if="title.length > 9" class="item" effect="dark" :content="title" placement="right">
          <h1 class="sidebar-title">{{ title }} </h1>
        </el-tooltip>
        <h1 v-else class="sidebar-title">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
// import { title, logo } from '../../../utils/global'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '互联网医院',
      logo: null
    }
  },
  created() {
    const _session = sessionStorage.getItem('systemInfo')
    const _info = JSON.parse(_session)
    if (_info) {
      this.title = _info.system.title
      this.logo = _info.system.adminLogo
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #163997;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  padding:0 16px;
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin-right: 6px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
      max-width: 132px;
      max-height: 42px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
    padding: 0;
  }
}
</style>
