<template>
  <canvas
    ref="canvas"
    id="canvas"
    class="result-canvas"
    :height="canvasHeight"
    :width="canvasWidth"
  />
</template>

<script>
import { chartChannel } from './chartChannel.js';

export default {
  props: {
    mais: {
      type: Object,
      default: () => null
    },
    width: {
      type: Number,
      default: 800
    }
  },
  
  computed: {
    canvasWidth() {
      return this.width * 0.72875;
    },
    canvasHeight() {
      return this.width * 0.72875;
    }
  },

  watch: {
    mais: {
      handler(newMais) {
        if (!newMais) return;
        this.$nextTick(() => {
          const canvas = this.$refs.canvas;
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
              for (const k in newMais) {
                const key = k;
                const mai = newMais[key];
                chartChannel(ctx, this.width, key, mai.level, mai.division);
              }
            } else {
              console.error('Failed to get canvas 2D context.');
            }
          } else {
            console.error('Canvas element not found.');
          }
        });
        
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
</style>
