/**
 * 绘制分部柱状图
 * @param ctx canvas对象
 * @param height 高度
 * @param width 宽度
 * @param columns 每列值的
 * @param color 折线颜色
 * @param showKong 是否显示空实
 * @param mai 是否为无
 * @param en 是否英文
 */
export const chartBranchBar = (
  ctx,
  height,
  width,
  columns,
  color,
  showKong = false,
  mai,
  en
) => {
  if (mai === '无') {
    columns.forEach((a) => {
      delete a.value;
    });
  }

  // 是否显示空实
  const columns2 = showKong ? columns : columns.filter((item) => item.key !== 'kong');

  const multiple = width > 250 ? 1 : 1;
  const fontSize = 18 * multiple; // 字体大小
  const lineHeight = fontSize * 1.5; // 行高
  const spacing = en ? 40 : 20; // 条形间隔
  const h = height - lineHeight * 2; // 列高
  const w = en ? 40 : (width - spacing * (columns2.length - 1)) / columns2.length; // 列宽
  const spots = []; // 折线坐标

  columns2.forEach((column, index) => {
    const start = (w + spacing) * index;

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#F7F3E9';
    ctx.rect(start, lineHeight, w, h);
    ctx.fillStyle = '#F7F3E9';
    ctx.fill();

    // 健康区
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#A3E5D9';
    ctx.rect(start, h / 4 + lineHeight, w, h / 2);
    ctx.fillStyle = '#A3E5D9';
    ctx.fill();
    ctx.stroke();

    // 顶部的字
    ctx.beginPath();
    ctx.fillStyle = '#333333';
    ctx.textAlign = 'center';
    ctx.font = `${fontSize}px Arial`;
    ctx.fillText(column.title[0], start + w / 2, fontSize);
    ctx.stroke();

    // 下面的字
    ctx.beginPath();
    ctx.fillStyle = '#333333';
    ctx.textAlign = 'center';
    ctx.font = `${fontSize}px Arial`;
    ctx.fillText(column.title[1], start + w / 2, height - 5);
    ctx.stroke();

    // 中间分割线
    ctx.beginPath();
    ctx.moveTo(start, height / 2);
    ctx.lineTo(start + w, height / 2);
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 0.5;
    ctx.stroke();

    // 计算点的位置
    const x = start + w / 2;
    const y = column.value ? lineHeight + (h - h * (column.value / 100)) : lineHeight - h;
    spots.push([x, y]);
  });

  // 点
  spots.forEach((spot) => {
    ctx.beginPath();
    ctx.arc(spot[0], spot[1], 2.5 * multiple, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
  });

  ctx.beginPath();
  ctx.moveTo(spots[0][0], spots[0][1]);
  // 连线
  spots.forEach((spot) => {
    ctx.lineTo(spot[0], spot[1]);
  });
  ctx.strokeStyle = color;
  ctx.lineWidth = 1.5 * multiple;
  ctx.stroke();
};
