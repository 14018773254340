<template>
    <div class="chart-branch">
      <h2 class="sub-title">
        {{ title }}
        {{ mai }}
      </h2>
      <div class="chart">
        <Branch3d
          :a="branchFeature.amp"
          :mai="mai"
          :rotate="rotate"
          :w="branchFeature.width"
          :width="width3d"
        />
        <Bar
          :color="color"
          :height="widthBar * 1.5"
          :value="value"
          :mai="mai"
          :width="widthBar"
          :showKong="showKong"
          :en="en"
        />
      </div>
    </div>
  </template>
  
  <script>
  import Branch3d from './Branch3d.vue'
  import Bar from './Bar.vue'
  import { branchOneDefalutValue } from './index.js'
  
  export default {
    components: {
      Bar,
      Branch3d
    },
  
    props: {
      title: {
        type: String,
        default: ''
      },
      mai: {
        type: String,
        default: ''
      },
      width3d: {
        type: Number,
        default: 200
      },
      widthBar: {
        type: Number,
        default: 120
      },
      branchFeature: {
        type: Object,
        default: () => ({})
      },
      value: {
        type: Object,
        default: branchOneDefalutValue
      },
      color: {
        type: String,
        default: 'rgba(255, 50, 51, 1)'
      },
      measure: {
        type: Object,
        default: () => ({})
      },
      rotate: {
        type: Boolean,
        default: true
      },
      itemKey: {
        type: String,
        default: ''
      },
      en: {
        type: String,
        default: ''
      },
      showKong: {
        type: Boolean,
        default: false
      }
    },
  
    mounted() {
      console.log(this.branchFeature.width)
    }
  }
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  