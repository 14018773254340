// 分部脉象3D图脉搏波曲线
export { baseSeries } from './baseSeries'
// import { BranchColumnKey, DuadMaiTitle } from './type'

export const productionSourceMap = false

// 八卦图角度
export const chartAngle = {
  xian: (1.5 - 1 / 8) * Math.PI,
  fu: (1.5 + 1 / 8) * Math.PI,
  fu2: (1.5 + 2 / 8) * Math.PI,
  hua: (1.5 + 3 / 8) * Math.PI,
  chi: (1.5 + 5 / 8) * Math.PI,
  ruan: (1.5 + 7 / 8) * Math.PI,
  chen: (1.5 + 9 / 8) * Math.PI,
  chen2: (1.5 + 10 / 8) * Math.PI,
  xi: (1.5 + 11 / 8) * Math.PI,
  shu: (1.5 + 13 / 8) * Math.PI,
  shu2: (1.5 + 14 / 8) * Math.PI,
  kong: 0,
  shi: 0
}

// 分部脉等级
export const summaryDefaultValue = {
  shu: [0, 0],
  chi: [0, 0],
  hua: [0, 0],
  xi: [0, 0],
  xian: [0, 0],
  ruan: [0, 0],
  fu: [0, 0],
  chen: [0, 0],
  kong: [0, 0],
  shi: [0, 0]
}

// 分部脉象折线颜色
export const branchColor = {
  cun: 'rgba(255, 50, 51, 1)',
  guan: 'rgba(255, 152, 0, 1)',
  chi: 'rgba(32, 178, 41, 1)'
}
// 分部脉象柱形图
export const branchColumns = [
  {
    key: 'xian',
    title: ['弦', '软'],
    value: 50
  },
  {
    key: 'hua',
    title: ['滑', '细'],
    value: 50
  },
  {
    key: 'fu',
    title: ['浮', '沉'],
    value: 50
  },
  {
    key: 'shu',
    title: ['数', '迟'],
    value: 50
  },
  {
    key: 'kong',
    title: ['空', '实'],
    value: 50
  }
];

// 分部脉象柱形图
export const branchColumns1 = [
  {
    key: 'xian',
    title: ['Wiry', 'Soft'],
    value: 50
  },
  {
    key: 'hua',
    title: ['Slippery', 'Thread'],
    value: 50
  },
  {
    key: 'fu',
    title: ['Floating', 'Deep'],
    value: 50
  },
  {
    key: 'shu',
    title: ['Rapid', 'Slow'],
    value: 50
  },
  {
    key: 'kong',
    title: ['Empty', 'Full'],
    value: 50
  }
];

// 分部脉象默认值
export const branchOneDefalutValue = {
  mai: '',
  value: { xian: 50, hua: 50, fu: 50, shu: 50, kong: 50 }
}
// 分部脉象三部默认值
export const branchResultDefault = {
  cun: branchOneDefalutValue,
  guan: branchOneDefalutValue,
  chi: branchOneDefalutValue,
}

// 脉象红色字体默认值
export const duadMaiDefaultValue = {
  shu: '',
  hua: '',
  xian: '',
  fu: '',
  kong: ''
}
// 成对脉象Title
const _duadMaiTitle = {};
branchColumns.forEach((column) => {
  _duadMaiTitle[column.key] = [...column.title];
});
export const duadMaiTitle = _duadMaiTitle;

// 成对脉象Title
const _duadMaiTitle1 = {};
branchColumns1.forEach((column) => {
  _duadMaiTitle1[column.key] = [...column.title];
});
export const duadMaiTitle1 = _duadMaiTitle1;


// 邪性
export const heresyItems = [
  {
    title: '寒',
    key: 'chill'
  },
  {
    title: '热',
    key: 'fever'
  },
  {
    title: '湿',
    key: 'dampness'
  },
  {
    title: '风',
    key: 'feng'
  },
  {
    title: '饮',
    key: 'phlegm'
  },
  {
    title: '痰',
    key: 'tan'
  },
  {
    title: '气结',
    key: 'qijie'
  },
  {
    title: '淤血',
    key: 'yuxue'
  }
]

// 气血津液默认等级
export const qxjyDefalutLevel = { qi: 4, blood: 4, fluids: 4, humor: 4 }

// 养生建议Title
export const proposeTitle = {
  new_food_propose: '饮食',
  new_sport_propose: '运动',
  other_propose: '生活'
}
