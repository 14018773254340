/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-04-06 10:12:41
 * @LastEditors: FYL 1251652793@qq.com
 * @LastEditTime: 2023-11-29 18:15:00
 * @FilePath: \drant-manager\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import '@/styles/common.scss' // global css
import { version } from '../package.json' // 获取版本号
localStorage.setItem('version', version)
import App from './App'
import store from './store'
import router from './router'
import api from './http'
import imgpath from './imgpath'
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import * as filters from './filters' // global filters
import '@/utils/directives' // 常用的自定义指令
import install from '@/components/install'
// import './common/font/font.css'
import { calculate } from '@/utils/calculate.js'
import Format from '@/api/validator.js'
import time from '@/api/time.js'
import mymessage from './http/mymessage.js'
import dyhutil from '@/utils/commonMethod.js'
import hevueImgPreview from 'hevue-img-preview'
import loadMore from '@/directive/select'
import loadmoreTable from '@/directive/el-table/lodmore'
import permission from '@/directive/permission/index.js'
import tooltip from '@/directive/tooltip'
import commonMethod from '@/utils/commonMethod.js'

Vue.use(loadMore)
Vue.use(loadmoreTable)
Vue.use(permission)
Vue.use(tooltip)
Vue.use(hevueImgPreview)
// vue图片查看器 https://fengyuanchen.github.io/viewerjs/
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)

import '@ant-hospital/app-module/lib/index.css'
import fylCommon from '@ant-hospital/app-module'
Vue.use(fylCommon)

Vue.prototype.$dyhutil = dyhutil
Vue.prototype.$mymessage = mymessage

// 图片路径 "https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager"
Vue.prototype.$imgpath = imgpath


/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */

// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
Vue.use(Element)
// Vue.use(lrz)

Vue.use(api)
Vue.use(install)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.prototype.calculate = calculate
Vue.prototype.Format = Format
Vue.prototype.time = time
Vue.prototype.$commonMethod = commonMethod

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
