import axios from '../../axios'
const basePath = '/core/doctorassistantInfo'

export function save(data) {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}

export function editDisabled(data) {
  return axios({
    url: basePath + '/editDisabled',
    method: 'post',
    data
  })
}

export function editEnabled(data) {
  return axios({
    url: basePath + '/editEnabled',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
