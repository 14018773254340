import axios from '../../axios'
const basePath = '/core/doctor'
/*
 * 常用医嘱分类模块
 */

// 保存
export const save = data => {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}

export const edit = data => {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}

export const batchDelete = data => {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}
// 医生在线列表分页
export const findDoctorDailyPage = data => {
  return axios({
    url: '/mgmt/doctor/doctors/findDoctorDailyPage',
    method: 'get',
    params: data // data
  })
}
// 医生在线列表详情
export const findDoctorDailyDetail = data => {
  return axios({
    url: '/mgmt/doctor/doctors/findDoctorDailyDetail',
    method: 'get',
    params: data // data
  })
}
// 医生考勤列表导出
export function doctorsExport(data) {
  return axios({
    url: `/mgmt/doctor/doctors/export`,
    method: 'post',
    responseType: 'blob',
    data
  })
}
// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data // data
  })
}

export const checkExists = data => {
  return axios({
    url: basePath + '/checkExists',
    method: 'get',
    params: data // data
  })
}

export const UnEnable = data => {
  return axios({
    url: basePath + '/updateDoctorUnEnable',
    method: 'post',
    data
  })
}

export const Enable = data => {
  return axios({
    url: basePath + '/updateDoctorEnable',
    method: 'post',
    data
  })
}

// 商务医生分页
export const findBusinessPage = (data, pageVo) => {
  return axios({
    url: basePath + '/findAllBusinessPage/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data // data
  })
}

// 商务销量统计
export const findAllMedicineCounts = data => {
  return axios({
    url: 'core/business/findBehiendAllMedicineCounts',
    method: 'get',
    params: data // data
  })
}

// 唯一健查询医生
export const findDoctorByUniqueKey = data => {
  return axios({
    url: basePath + '/findDoctorByUniqueKey',
    method: 'get',
    params: data // data
  })
}

// 团队分页查询
export const teamFindPage = (data, pageVo) => {
  return axios({
    url: '/core/doctorassistantInfo/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data // data
  })
}

// 新增团队医生
export const doctorAssistantInfo = data => {
  return axios({
    url: '/core/doctorassistantInfo/saveBatch',
    method: 'post',
    data
  })
}

// 药师分页查询
export const pharmacistFindPage = (data, pageVo) => {
  return axios({
    url: '/core/pharmacist/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data // data
  })
}

// 新增药师
export const pharmacistSave = data => {
  return axios({
    url: '/core/pharmacist/save',
    method: 'post',
    data
  })
}

// 修改药师
export const pharmacistEdit = data => {
  return axios({
    url: '/core/pharmacist/edit',
    method: 'post',
    data
  })
}

// 删除药师
export const pharmacistdel = id => {
  return axios({
    url: `/mgmt/user/pharmacists/${id}`,
    method: 'delete'
  })
}

// 药师唯一健查询
export const UniqueKey = data => {
  return axios({
    url: `/core/pharmacist/findInfoByUniqueKey`,
    method: 'get',
    params: data // data
  })
}

// 医生是否离线
export const getAppointCounts = data => {
  return axios({
    url: `/core/doctorappoint/getAppointCounts`,
    method: 'get',
    params: data // data
  })
}

// 医生企业邀请列表
export const getCpIndicatorPage = data => {
  return axios({
    url: `/mgmt/doctors/cpIndicator/getPage`,
    method: 'get',
    params: data // data
  })
}

// 医生企业邀请编辑
export const editCpIndicator = data => {
  return axios({
    url: '/mgmt/doctors/cpIndicator/edit',
    method: 'post',
    data
  })
}

// 医生企业邀请删除
export const deleteCpIndicator = data => {
  return axios({
    url: `/mgmt/doctors/cpIndicator/delete/${data}`,
    method: 'post',
  })
}
