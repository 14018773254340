import axios from '../../axios'
// 待开发状态，需后台接口做代理处理
export const getAccessToken = data => {
  return axios({
    url: 'https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wx4131fd77122b6157&secret=b68ff32eef1409c6560a7dca6f76b881',
    // headers: {'Access-Control-Allow-Origin': '*'},
    method: 'get',
    params: data
  })
}
// 待开发状态，需后台接口做代理处理
export const getLink = data => {
  return axios({
    url:
      'https://api.weixin.qq.com/wxa/generate_urllink?access_token=68_ceg1a5YqJBYLaXk-zDj17B1deZxiEuGFDpPRHjABBHf-L27pLmDQEwNBNhKvsSIXoq2SZ2EdQbZu6z3aoyDa_lMlWG3b51706DsapMRCc9jnRP3sFbkAzMmZR_QMFWeAAATMDhttps://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wx4131fd77122b6157&secret=b68ff32eef1409c6560a7dca6f76b881',
    // headers: {'Access-Control-Allow-Origin': '*'},
    method: 'post',
    data
  })
}
