<template>
  <div class="title pg">
    <span class="shu" />
    <span class="text">{{ text }}</span>
    <el-button v-if="isRefresh" class="d-refresh" @click="oRefresh">刷新</el-button>
  </div>

</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '角色管理'
    },
    isRefresh: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    oRefresh() {
      this.$router.go(0)
    }
  }
}
</script>
<style>
.pg{
  position: relative;
}
 .d-refresh{
   position: absolute;
   right: 20px;
 }
</style>
