/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-09-06 10:46:28
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-09-06 18:33:23
 * @FilePath: \drant-manager\src\directive\tooltip.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import App from './App.vue'
// import { Tooltip } from 'element-ui'
const vuetooltip = {}
export default (vuetooltip.install = Vue => {
  Vue.directive('tooltip', {
    bind(el, binding) {
      const tooltip = document.createElement('div')
      tooltip.className = 'custom-tooltip'
      tooltip.textContent = binding.value
      el.appendChild(tooltip)
      el._tooltip = tooltip
      // console.log(binding, '参数')
      // console.log(el, '元素')

      if (binding.rawName) {
        let position = 'bottom'

        if (binding.rawName.includes('.')) {
          const a = binding.rawName.split('.')[1]
          position = a.toLowerCase()
        }

        // 根据参数设置样式
        if (position === 'top') {
          tooltip.style.bottom = '100%'
        } else if (position === 'bottom') {
          tooltip.style.top = '100%'
        } else if (position === 'left') {
          tooltip.style.right = '100%'
        } else if (position === 'right') {
          tooltip.style.left = '100%'
        }
      }

      // 鼠标进入和离开时显示隐藏提示框
      el.addEventListener('mouseenter', showTooltip)
      el.addEventListener('mouseleave', hideTooltip)
    },
    unbind(el) {
      el.removeEventListener('mouseenter', showTooltip)
      el.removeEventListener('mouseleave', hideTooltip)
      el.removeChild(el._tooltip)
      el._tooltip = null
    }
  })
})

function showTooltip(event) {
  event.target._tooltip.style.display = 'block'
}

function hideTooltip(event) {
  event.target._tooltip.style.display = 'none'
}
