/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-07-10 13:52:48
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-10-12 11:58:07
 * @FilePath: \drant-manager\src\http\moudules\miniprogramManage\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'
const basePath = '/mgmt/apps/settings'

// 分页查询
export const findPage = data => {
  return axios({
    url: basePath + `/getOpenAppsSettingList`,
    method: 'get',
    params: data
  })
}
// 获取商家详情
export const getOpenAppsSettingDetail = data => {
  return axios({
    url: basePath + `/getOpenAppsSettingDetail`,
    method: 'get',
    params: data
  })
}
// 编辑状态
export const editOpenAppStatus = data => {
  return axios({
    url: basePath + `/editOpenAppStatus`,
    method: 'post',
    data
  })
}
// 新增商家
export const addOpenAppsSetting = data => {
  return axios({
    url: basePath + `/addOpenAppsSetting`,
    method: 'post',
    data
  })
}
// 编辑商家
export const editOpenAppsSetting = data => {
  return axios({
    url: basePath + `/editOpenAppsSetting`,
    method: 'post',
    data
  })
}
// openapi模块
// 分页查询
export const openFindPageApps = data => {
  return axios({
    url: `/mgmt/open/apps`,
    method: 'get',
    params: data
  })
}
// 获取详情
export const getOpenAppsDetail = data => {
  return axios({
    url: `/mgmt/open/apps/` + data,
    method: 'get'
    // params: data
  })
}
// 编辑
export const OpenAppsUpdate = (data, id) => {
  return axios({
    url: `/mgmt/open/apps/update/${id}`,
    method: 'post',
    data
  })
}
// 新增
export const addOpenApps = data => {
  return axios({
    url: `/mgmt/open/apps`,
    method: 'post',
    data
  })
}
// 删除
export const deleteOpenApps = data => {
  return axios({
    url: `/mgmt/open/apps/delete/${data}`,
    method: 'post',
    data
  })
}
// 关联第三方应用商品库

// 分页查询商品
export const medicineOpen = data => {
  return axios({
    url: `/mgmt/medicine/open`,
    method: 'get',
    params: data
  })
}
// 新增商品
export const medicineOpenSave = data => {
  return axios({
    url: `/mgmt/medicine/open/save`,
    method: 'post',
    data
  })
}
// 导入商品
export function medicineOpenImportDrugs(data, appid) {
  return axios(
    {
      url: '/mgmt/medicine/open/importDrugs/' + appid,
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }
  )
}
// 导出新增商品模板
export const medicineOpenExportImpTemplate = data => {
  return axios({
    url: '/mgmt/medicine/open/exportImpTemplate',
    method: 'get',
    responseType: 'blob'
  })
}
// 批量禁用商品
export const batchDisable = (data) => {
  return axios({
    url: '/mgmt/medicine/open/batch-disable',
    method: 'post',
    data
  })
}
// 批量启用商品
export const batchEnable = (data) => {
  return axios({
    url: '/mgmt/medicine/open/batch-enable',
    method: 'post',
    data
  })
}
