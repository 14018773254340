import axios from '../../axios'
import { export_blob } from '@/utils/exportFile'
const basePath = '/mgmt/medicine/pharmacies'

export function save(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + `/${data.id}`,
    method: 'put',
    data
  })
}
export function batchDelete(id) {
  return axios({
    url: basePath + `/${id}`,
    method: 'delete'
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 唯一健查询药房
export const findInfoByUniqueKey = (id) => {
  return axios({
    url: `/mgmt/medicine/pharmacies/${id}`,
    method: 'get'
  })
}

// 检验手机号能否注册
export const validateMobile = (data) => {
  return axios({
    url: basePath + '/_validate-mobile',
    method: 'post',
    data
  })
}

// 药房药品分页查询
export const drugsFindPage = (data) => {
  return axios({
    url: '/mgmt/medicine/drugs',
    method: 'get',
    params: data
  })
}

// 药品导入
export function importExcel(data) {
  return axios({
    url: '/mgmt/medicine/drugs/_import',
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'application/octet-stream'
  }})
}

// 药品导出
export const exportExcel = (data, loadingMessage, id) => {
  return axios({
    url: '/mgmt/medicine/drugs/_export',
    method: 'post',
    responseType: 'blob',
    data
  }).then(response => {
    export_blob(response, '药品信息.xls')
    if (loadingMessage) {
      setTimeout(function() {
        loadingMessage.close()
      }, 100)
    }
  }).catch(function(error) {
    console.log(error)
  })
}

// 药品新增
export function drugSave(data) {
  return axios({
    url: '/mgmt/medicine/drugs',
    method: 'post',
    data
  })
}
// 药品编辑
export function drugEdit(data) {
  return axios({
    url: '/mgmt/medicine/drugs' + `/${data.id}`,
    method: 'put',
    data
  })
}

// 药品删除
export function drugDel(id) {
  return axios({
    url: '/mgmt/medicine/drugs/' + id,
    method: 'delete'
  })
}
// 药品批量删除
export function batchDel(data) {
  return axios({
    url: '/mgmt/medicine/drugs/_batch-delete',
    method: 'post',
    data
  })
}

// 药品批量禁用
export function batchDisabled(data) {
  return axios({
    url: '/mgmt/medicine/drugs/_batch-disable',
    method: 'post',
    data
  })
}
// 药品批量启用
export function batchEnable(data) {
  return axios({
    url: '/mgmt/medicine/drugs/_batch-enable',
    method: 'post',
    data
  })
}

// 拼音
export const getChinesePinyin = (data) => {
  console.log(data)
  return axios({
    url: `/mgmt/medicine/common/getChinesePinyin`,
    method: 'get',
    params: data
  })
}

// 药品明细
export function findById(id) {
  return axios({
    url: `/mgmt/medicine/drugs/${id}`,
    method: 'get'
  })
}

// 获取数据看板基础数据
export function getPharmacyDataBoard(data) {
  return axios({
    url: `/mgmt/pharmacy/data/getPageBoard`,
    method: 'get',
    params:data
  })
}
