/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-08-07 14:32:47
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-08-21 12:31:44
 * @FilePath: \drant-manager\src\http\moudules\ram\topicClass.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../../axios'

// 话题分类-列表
export const getlist = data => {
  return axios({
    url: '/mgmt/trends/talk/category/getList',
    method: 'get',
    params: data
  })
}
// 话题分类-关联的话题列表
export const detailTalkList = data => {
  return axios({
    url: '/mgmt/trends/talk/category/detailTalkList',
    method: 'get',
    params: data
  })
}
// 话题分类-详情
export const talkCategoryDetail = data => {
  return axios({
    url: '/mgmt/trends/talk/category/detail',
    method: 'get',
    params: data
  })
}
// 话题分类-新增
export const talkCategoryAdd = data => {
  return axios({
    url: '/mgmt/trends/talk/category/add',
    method: 'post',
    data
  })
}
// 话题分类-删除
export const talkCategoryDelete = data => {
  return axios({
    url: '/mgmt/trends/talk/category/delete/' + data,
    method: 'post'
    // data
  })
}
