import { saveAs } from 'file-saver'

function b64toFile(b64Data, contentType) {
  var sliceSize = 512
  var byteCharacters = atob(b64Data)
  var byteArrays = []
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)
    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  var blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export function export_d(d) {
  var file = b64toFile(d.base64 || d.row2Base64, 'test', 'application/vnd.ms-excel;charset=utf-8')
  saveAs(file, d.fileName)
}

// 文件流导出
export function export_blob(content, filename) {
  let url
  const a = document.createElement('a')
  a.download = filename
  a.style.display = 'none'
  const blob = new Blob([content])
  a.href = url = URL.createObjectURL(blob)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // 释放掉blob对象
  URL.revokeObjectURL(url)
  console.log('下载完成')
}
