import axios from '../../axios'
const basePath = '/core/chatsetcontent'
/*
 * 常用聊天语内容模块
 */

// 保存
export const save = (data) => {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}

export const edit = (data) => {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}

export const batchDelete = (data) => {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}

export const checkExists = (data) => {
  return axios({
    url: basePath + '/checkExists',
    method: 'get',
    params: data// data
  })
}
