<template>
  <!-- <div :class="['chart-3d', { 'no-rotate': !rotate }]" @click="canBuild">
      <div ref="chart3d" :style="{ width: getWidth + 'px', height: getHeight + 'px' }"></div>
    </div> -->
 
    <div :class="['chart-3d']">
      <div ref="refChar3d" id="refChar3d" style="width:100%;height: 400px;" @click="canBuild"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-gl';
import { getFeatureOption } from './three.js';

export default {
  props: {
    feature: Object,
    totalMai: {
      type: Array,
      default: () => []
    },
    measure: {
      type: Array,
      default: () => []
    },
    // en: Boolean,
    autoRoentate: {
      type: Boolean,
      default: true
    },
    // autoRotate: Boolean,
    autoRotate: {
      type: Boolean,
      default: false
    },
    // rotate: Boolean,
    rotate: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 800 // 设置默认宽度
    }
  },
  data() {
    return {
      chart: null
    };
  },
  computed: {
    getWidth() {
      console.log(this.width, 'this.width;');

      return this.width;
    },
    getHeight() {
      return this.width * 0.8;
    }
  },
  // created(){
  //   this.handler()
  // },
  mounted() {
    this.$nextTick(() => {
      this.handler();
    });
  },
  methods: {
    canBuild() {
      this.$emit('canbuild', true);
    },
    androidLoadFinished() {
      // 这里是安卓完成加载的回调处理
    },
    handler() {
      // this.$nextTick(() => {
      if (!this.feature || !this.$refs.refChar3d) return;

      const feature = this.feature;
      console.log(feature, 'feature', this.$refs.refChar3d);


      // this.chart = echarts.init(this.$refs.refChar3d);
      this.chart = echarts.init(document.getElementById('refChar3d'));
      console.log(this.chart, 'this.chart');


      const L = [28, 40]; // 关、迟位置
      const A = [feature.cun_amp_max, feature.guan_amp_max, feature.chi_amp_max]; // 扬悟
      const W = [feature.cun_width, feature.guan_width, feature.chi_width]; // 欲赏
      const totalMai = this.totalMai;
      const measure = this.measure;
      const surfaceImg = 'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/surfaceImg.png'; // 这里需要定义 surfaceImg
      const cgcImg = 'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-manager/common/cgcImg.png'; // 这里需要定义 cgcImg
      const option = getFeatureOption(L, A, W, totalMai, surfaceImg, this.en ? cgcImg1 : cgcImg, true, this.autoRotate, measure);
      console.log(option, 'option');

      this.chart.setOption(option);

      // 完成事件处理
      // this.chart.on('finished', this.androidLoadFinished);
      // this.chart.on('finished', this.canBuild);
      // });
    },
    // getFeatureOption(L, A, W, totalMai, surfaceImg, cgcImg, autoRotate, measure) {
    //   // 这里实现你的选项配置逻辑
    //   return {};
    // }
  },
  watch: {
    // feature: {
    //   handler() {
    //     this.$nextTick(() => {
    //       if (!this.feature || !this.$refs.refChar3d) return;

    //       const feature = this.feature;
    //       console.log(feature,'feature',this.$refs.refChar3d);


    //       this.chart = echarts.init(this.$refs.refChar3d);
    //       console.log(this.chart,'this.chart');


    //       const L = [28, 40]; // 关、迟位置
    //       const A = [feature.cun_amp_max, feature.guan_amp_max, feature.chi_amp_max]; // 扬悟
    //       const W = [feature.cun_width, feature.guan_width, feature.chi_width]; // 欲赏
    //       const totalMai = this.totalMai;
    //       const measure = this.measure;
    //       const surfaceImg = ''; // 这里需要定义 surfaceImg
    //       const cgcImg = ''; // 这里需要定义 cgcImg
    //       const option = getFeatureOption(L, A, W, totalMai, surfaceImg, this.en ? cgcImg1 : cgcImg, true, this.autoRotate, measure);
    //       this.chart.setOption(option);

    //       // 完成事件处理
    //       // this.chart.on('finished', this.androidLoadFinished);
    //       this.chart.on('finished', this.canBuild);
    //     });
    //   },
    //   deep: true
    // }
  },
  beforeDestroy() {
    // 销毁
    if (this.chart) {
      this.chart.clear();
      this.chart.dispose();
      this.chart = null;
    }
  }
};
</script>

<style scoped lang="scss">
.chart-3d {
  text-align: center;
  position: relative
}

.chart-3d>div {
  margin: auto
}

.chart-3d.no-rotate:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

/* 根据需要添加样式 */
</style>