<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view ref="routerViewRef" :key="key" @sendMessage="sendMessage" @reconnect="reconnect" />
      </keep-alive>
    </transition>
    <p class="copyright" @click="tolink()">@2023  广州蚂蚁云医互联网医院  版权所有 v{{ version }}</p>
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  data() {
    return {
      version: localStorage.getItem('version')
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  },
  methods: {
    sendMessage(data) {
      this.$emit('sendMessage', data)
    },
    reconnect() {
      this.$emit('reconnect')
    },
    receiveMessage(data) {
      if (this.$refs.routerViewRef.receiveMessage) { this.$refs.routerViewRef.receiveMessage(data) }
    },
    tolink() {
      // window.open('https://www.yuankangjian.com', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  height: 100%;
  width: 100%;
  position: relative;

}

.fixed-header+.app-main {
  padding-top: 96px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

}
</style>

<style lang="scss">
// fix css style bug in open el-dialog

.copyright{
    bottom: 0;
    position: fixed;
    height: 60px;
    line-height: 60px;
    background: #f5f5f5;
    color: #97A8BE;
    text-align: center;
    margin: 0;
    font-size: 14px;
    width: 90%;
    z-index: 3;
    cursor: pointer;
    @media (max-width: 1600px) {
      width: 83%;
      height: 40px;
      line-height: 40px;
    }
}
</style>
