import axios from '../../axios'
const basePath = '/core/file'
/*
 * 文件上传模块
 */
export const upload = async (entityType, data) => {
  const reuslt = await axios(
    {
      url: basePath + '/upload/' + entityType + '?entityId=test1234',
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return reuslt
}

/*
 * 获取分片上传任务id
 */
export const getUploadId = data => {
  return axios({
    url: basePath + '/getUploadId/' + data,
    method: 'get'
  })
}

/*
 * 分片上传
 */
export const chunkV2 = async data => {
  const reuslt = await axios(
    {
      url: basePath + '/chunkV2',
      method: 'post',
      data
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return reuslt
}

/*
 * 合并上传
 */
export const completeUpload = data => {
  return axios({
    url: basePath + '/completeUpload',
    method: 'post',
    data
  })
}
