var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog.showDialog,
            title: _vm.dialog.dialogTitle,
            width: _vm.dialog.width,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "showDialog", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.dialog.datalist,
                rules: _vm.dialog.rules,
              },
            },
            [
              _vm._l(_vm.dialog.formlist, function (item, index) {
                return _c(
                  "el-form-item",
                  { key: index, attrs: { label: item.label } },
                  [
                    item.type === "input"
                      ? _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "select"
                      ? _c(
                          "el-select",
                          {
                            attrs: { placeholder: item.placeholder },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          },
                          _vm._l(item.select, function (ite, ind) {
                            return _c("el-option", {
                              key: ind,
                              attrs: { label: ite.label, value: ite.value },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "switch"
                      ? _c("el-switch", {
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "textarea"
                      ? _c("el-input", {
                          attrs: { clearable: "", type: "textarea" },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "radio"
                      ? _c(
                          "el-radio-group",
                          {
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          },
                          [
                            _c("el-radio", {
                              attrs: { label: "线上品牌商赞助" },
                            }),
                            _vm._v(" "),
                            _c("el-radio", {
                              attrs: { label: "线下场地免费" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("立即创建")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm", "dialog.formlist")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }