var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-heresy" },
    _vm._l(_vm.getHeresyItems(), function (item) {
      return _c("div", { key: item.key, class: "item icon_" + item.key }, [
        _c("p", [_vm._v(_vm._s(item.text))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }