// Default levels for Qi, Blood, Fluids, and Humor
const qxjyDefaultLevel = { qi: 4, blood: 4, fluids: 4, humor: 4 };

/**
 * Gets the total result for Mai.
 * @param {Object[]} infos - An array of info objects.
 * @returns {Object} - An object containing titles and descriptions.
 */
const getTotalMaiResult = (infos) => {
  const res = { title: [], desc: [] };
  if (!infos) return res;

  infos.forEach(info => {
    res.title.push(info.name_cn);
    res.desc.push(info.explaination_cn);
  });

  return res;
};

/**
 * Gets the level based on the score for Qi, Blood, Fluids, and Humor.
 * @param {number} score - The score value.
 * @returns {number} - The level.
 */
const qxjyLevel = (score) => {
  if (score < 50) {
    return 7;
  } else if (score >= 50 && score < 80) {
    return 6;
  } else if (score >= 80 && score < 90) {
    return 5;
  } else if (score >= 90 && score <= 110) {
    return 4;
  } else if (score > 110 && score < 120) {
    return 3;
  } else if (score >= 120 && score < 150) {
    return 2;
  } else if (score >= 150) {
    return 1;
  }

  return 4;
};

// Propose titles
const proposeTitle = {
  new_food_propose: '饮食',
  new_sport_propose: '运动',
  other_propose: '生活'
};

/**
 * Gets the Qxjy result from the provided data.
 * @param {Object} mais - The data containing Mai results.
 * @returns {Object} - The Qxjy result including levels, text, and descriptions.
 */
export const getQxjyResult = (mais) => {
  const arr = ['qi', 'blood', 'fluids', 'humor'];
  const res = { level: { ...qxjyDefaultLevel }, text: [], desc: [] };

  arr.forEach(key => {
    const item = mais[key];
    if (!item) return;
    res.level[key] = qxjyLevel(item.score);
    res.text.push(item.text);
    res.desc.push(item.desc);
  });

  return res;
};

/**
 * Gets the Heresy result from the provided data.
 * @param {Object} mais - The data containing Mai results.
 * @returns {Object} - The Heresy result including values, text, and descriptions.
 */
export const getHeresyResult = (mais) => {
  const arr = ['phlegm', 'dampness', 'chill', 'fever'];
  const res = { value: [], text: [], desc: [] };

  arr.forEach(key => {
    const item = mais[key];
    if (!item) return;

    if (item.score > 0) {
      res.value.push(key);
      res.desc.push(item.desc);
    }
    if (item.text) {
      res.text.push(item.text);
    }
  });

  return res;
};

/**
 * Gets the Propose result from the provided data.
 * @param {Object} mais - The data containing Mai results.
 * @returns {Object[]} - An array of proposals including title and description.
 */
const getProposeResult = (mais) => {
  const arr = ['new_food_propose', 'new_sport_propose', 'other_propose'];
  const res = [];

  arr.forEach(key => {
    const item = mais[key];
    if (!item || !item[key]) return;

    res.push({
      title: proposeTitle[key],
      desc: item[key]
    });
  });

  return res;
};

/**
 * Gets the Symptoms result from the provided data.
 * @param {Object} mais - The data containing Mai results.
 * @returns {Object} - The Symptoms result including text and descriptions.
 */
export const getSymptomsResult = (mais) => {
  const res = { text: [], desc: [] };

  if (mais.desc) {
    res.text = [...mais.desc.desc];
  }
  if (mais.desc_detail) {
    res.desc = [...mais.desc_detail.desc_detail];
  }

  return res;
};

/**
 * Gets the Channel result from the provided channels.
 * @param {Object} channels - The data containing channel information.
 * @returns {Object[]} - An array of channel results including key and text.
 */
export const getChannelResult = (channels) => {
  const arr = ['taiyang', 'yangming', 'shaoyang', 'shaoyin', 'jueyin', 'taiyin'];
  const res = [];

  arr.forEach(key => {
    if (channels[key]) {
      res.push({
        key,
        text: channels[key]
      });
    }
  });
  console.log(res,'getChannelResult');
  
  return res;
};
