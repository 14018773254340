import axios from '../../axios'
const basePath = '/mgmt/examine/records'
// 列表
export const reportList = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}
// 编辑报告
export const reportEdit = (data) => {
  return axios({
    url: basePath,
    method: 'put',
    data
  })
}
// 详情
export const reportDetail = (id) => {
  return axios({
    url: basePath + '/' + id,
    method: 'get'
  })
}

// 面诊详情
export const getDetailFace = (data) => {
  return axios({
    url: `/api/tongue/detailFace`,
    method: 'get',
    params: data
  })
}

// 舌诊详情
export const gettongueDetail = (data) => {
  return axios({
    url: `/api/tongue/detailTongue`,
    method: 'get',
    params: data
  })
}