/*
 * @Author: tyx 762842353@qq.com
 * @Date: 2023-09-05 20:49:59
 * @LastEditors: tyx 762842353@qq.com
 * @LastEditTime: 2023-09-05 20:52:04
 * @FilePath: \drant-manager\src\directive\select.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const vueselect = {}
export default (vueselect.install = (Vue, options = {}) => {
  Vue.directive('loadmore', {
    inserted(el, binding) {
      // 获取element-ui定义好的scroll盒子
      const SELECTDOWN_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
      SELECTDOWN_DOM.addEventListener('scroll', function() {
        const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
        if (CONDITION) {
          binding.value()
        }
      })
    }
  })
})
