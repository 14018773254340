import axios from '../../axios'
import { export_blob } from '@/utils/exportFile'
export const drugsLibrary = (data) => {
  return axios({
    url: '/mgmt/medicine/drugs-library/search_library',
    method: 'get',
    params: data
  })
}

export const findPage = (data) => {
  return axios({
    url: '/mgmt/medicine/drugs-library',
    method: 'get',
    params: data
  })
}

// 药品导入
export function importExcel(data) {
  return axios({
    url: '/mgmt/medicine/drugs-library/_import',
    method: 'post',
    data
  }, { headers: {
    'Content-Type': 'application/octet-stream'
  }})
}

// 药品导出
export const exportExcel = (data, loadingMessage, id) => {
  return axios({
    url: '/mgmt/medicine/drugs-library/_export',
    method: 'post',
    responseType: 'blob',
    data
  }).then(response => {
    export_blob(response, '药品信息.xls')
    if (loadingMessage) {
      setTimeout(function() {
        loadingMessage.close()
      }, 100)
    }
  }).catch(function(error) {
    console.log(error)
  })
}

// 药品新增
export function drugSave(data) {
  return axios({
    url: '/mgmt/medicine/drugs-library',
    method: 'post',
    data
  })
}
// 药品编辑
export function drugEdit(data) {
  return axios({
    url: '/mgmt/medicine/drugs-library' + `/${data.id}`,
    method: 'put',
    data
  })
}

// 药品删除
export function drugDel(id) {
  return axios({
    url: '/mgmt/medicine/drugs-library/' + id,
    method: 'delete'
  })
}

// 药品明细
export function findById(id) {
  return axios({
    url: `/mgmt/medicine/drugs-library/${id}`,
    method: 'get'
  })
}
