import axios from '../../axios'
const basePath = '/mgmt/supplier/'

// 分页查询会诊审核记录
export function list(data) {
  return axios({
    url: basePath + 'getPage',
    method: 'get',
    params: data
  })
}

// 分页查询会诊审核记录
export function add(data) {
    return axios({
      url: basePath + 'add',
      method: 'post',
       data
    })
  }
  
// 编辑
export function edit(data) {
  return axios({
    url: basePath + 'edit/' + data.id,
    method: 'post',
    data: data
  })
}

// 查询详情
export function info(data) {
    return axios({
      url: basePath + 'edit/' + data.id,
      method: 'get',
      params: data
    })
  }
