import axios from '../../axios'

// 获取海报
export function getNoticeBoardQuote(data) {
  return axios({
    url: '/mgmt/notice/board/quote/getNoticeBoardQuote',
    method: 'get',
    params: data
  })
}

// 新增/编辑海报
export function saveNoticeBoardQuote(data,mode) {
  return axios({
    url: '/mgmt/notice/board/quote/'+mode,
    method: 'post',
    data: data
  })
}

// 编辑海报
// export function editNoticeBoardQuote(data) {
//   return axios({
//     url: '/mgmt/notice/board/quote/edit',
//     method: 'post',
//     data: data
//   })
// }

// 获取模块节点
export function getNoticeModules(data) {
  return axios({
    url: '/mgmt/notice/board/node/getPage?curPage=1&pageSize=999',
    method: 'get',
    params: data
  })
}

// 新增模块节点
export function addNoticeModules(data) {
  return axios({
    url: '/mgmt/notice/board/node/add',
    method: 'post',
    data: data
  })
}

// 删除模块节点
export const handleDelete = data => {
  return axios({
    url: '/mgmt/notice/board/node/delete',
    method: 'post',
    data
  })
}

// 编辑模块节点
export const handleEdit = data => {
  return axios({
    url: '/mgmt/notice/board/node/edit',
    method: 'post',
    data
  })
}


// export function edit(data) {
//   return axios({
//     url: '/core/sysAboutUs/edit',
//     method: 'post',
//     data: data
//   })
// }
