<template>
  <div>人脸识别</div>
</template>

<script>
export default {
  data() {
    return {
      type: 'face',
      isVerify: true
    }
  },
  created() {
    console.log('cd')
    this.tailorURL()
  },
  methods: {
    tailorURL() { // 链接参数裁剪
      const query = window.location.search.substring(1)
      if (query) {
        const vars = query.split('&')
        const _arr = []
        for (var i = 0; i < vars.length; i++) {
          const pair = vars[i].split('=')
          _arr.push(pair)
        }
        const code = _arr.filter(item => item[0] === 'code')[0][1]
        if (code === '0' && this.isVerify) {
          this.isVerify = false
          const orderNo = _arr.filter(item => item[0] === 'orderNo')[0][1]
          this.getResult(orderNo)
        } else {
          // this.$router.push('/login')
          window.location.href = window.location.origin + '/#/login'
        }
        console.log(_arr, code)
      }
    },
    getResult(orderNo) {
      const _token = sessionStorage.getItem('token')
      this.$api.login.getFaceResult({ orderNo: orderNo }, _token).then(res => {
        if (res.code === 200) {
          this.isVerify = true
          // sessionStorage.setItem('token', _token)
          // sessionStorage.removeItem('temporaryTOKEN')
          this.getInfo()
        }
      })
    },
    getInfo() {
      this.$api.user.getCurrentUser().then((res) => {
        if (res.code === 200) {
          if (!(res.data.currentRoleCode === 'offlineAdmin' || res.data.currentRoleCode === 'offlinePharmacist' || res.data.currentRoleCode === 'offlineSender')) {
            sessionStorage.setItem('user', res.data.userName) // 保存用户到本地会话
            this.$store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
            this.$store.commit('user/SET_TOKEN', res.data) // 设置token
            // this.$router.push('/') // 登录成功，跳转到主页
            window.location.href = window.location.origin + '/#/'
          } else {
            // sessionStorage.clear()// 清除本地缓存
            sessionStorage.removeItem('token')
            // Cookies.remove('token')
            this.$message({ message: '您无权登陆该系统，请联系系统管理员。', type: 'error' })
          }
        }
      })
    }
    // toFacialRecognition(token) {
    //   const join = {
    //     redirectUrl: 'http://localhost:9527/#/'// window._conf.baseApi
    //   }
    //   this.$api.login.getFace(join, token).then(res => {
    //     if (res.code === 200) {
    //       console.log(res)
    //       sessionStorage.setItem('temporaryTOKEN', token)
    //       window.location.href = res.data
    //     } else {
    //       this.getImageCode()
    //       // this.$message({ message: `登录失败，${res.msg}`, type: 'error' })
    //     }
    //   })
    // }
  }
}
</script>

<style>

</style>
