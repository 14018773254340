import axios from '../../axios'

// 在线开通 列表
export const getlist = data => {
  return axios({
    url: '/mgmt/assistant/product/list',
    method: 'get',
    params: data
  })
}
// 详情
export const optiondetail = data => {
  return axios({
    url: '/mgmt/assistant/product/detail',
    method: 'get',
    params: data
  })
}
// 新增 
export const optionadd = data => {
  return axios({
    url: '/mgmt/assistant/product/add',
    method: 'post',
    data
  })
}
// 编辑会员VIP套餐
export const optionedit = data => {
  return axios({
    url: '/mgmt/assistant/product/edit',
    method: 'post',
    data
  })
}
// 删除
export const optiondelete = data => {
  return axios({
    url: '/mgmt/assistant/product/delete/' + data,
    method: 'post'
    // data
  })
}
