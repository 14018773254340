import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/wxminicode/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/wxminicode/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/wxminicode/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/wxminicode/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + '/wxminicode/findInfoByUniqueKey',
    method: 'get',
    params: data
  })
}

// 审核
export function approve(data) {
  return axios({
    url: basePath + '/wxminicode/approve',
    method: 'post',
    data
  })
}

// 助理二维码
export function createAssistantCode(data) {
  return axios({
    url: basePath + '/wxminicode/createAssistantCode',
    method: 'post',
    data
  })
}

// 医生二维码
export function createDoctorCode(data) {
  return axios({
    url: basePath + '/wxminicode/createDoctorCode',
    method: 'post',
    data
  })
}

// 商务二维码
export function createBusinessCode(data) {
  return axios({
    url: basePath + '/wxminicode/createBusinessCode',
    method: 'post',
    data
  })
}

// 患者二维码
export function createMemberCode(data) {
  return axios({
    url: basePath + '/wxminicode/createMemberCode',
    method: 'post',
    data
  })
}
export function homePageCount() {
  return axios({
    url: '/mgmt/home',
    method: 'get'
  })
}
