import axios from 'axios'
import config from './config'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'
import { MessageBox, Message } from 'element-ui'
// 全局loading使用
import { showLoading, hideLoading } from './loading'
// import clipboard from '@/utils/clipboard'
import store from '@/store'
import sign from '@/utils/sign'
import commonMethod from '@/utils/commonMethod'
let _cancelToken = null
const blobToText = blob => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsText(blob)
    fileReader.onload = function () {
      try {
        const result = JSON.parse(this.result)
        if (result) {
          resolve(result)
        } else {
          reject()
        }
      } catch (e) {
        reject()
      }
    }
  })
}
export default function $axios(options, autoconfig) {
  return new Promise((resolve, reject) => {
    if (options.url !== '/mgmt/notifications' && options.url !== '/mgmt/notifications/message-count') {
      showLoading()
    }
    const token = getToken() // Cookies.get('token')
    if (!token && options.url === '/mgmt/notifications/message-count') {
      return
    }
    const instance = axios.create({
      baseURL: options.baseUrl ? options.baseUrl : config.baseUrl,
      headers: autoconfig ? autoconfig.headers : config.headers, // 有需要的自定义
      timeout: config.timeout,
      withCredentials: config.withCredentials
    })
    // request 拦截器
    instance.interceptors.request.use(
      config => {
        if (config.url.indexOf('/mgmt/notifications/message-count') > -1) {
          // 可取消
          const CancelToken = axios.CancelToken
          config.cancelToken = new CancelToken(cel => {
            _cancelToken = cel
            // console.log(_cancelToken)
          })
        }
        // const token = sessionStorage.getItem('token') // Cookies.get('token')
        // 1. 请求开始的时候可以结合 vuex 开启全屏 loading 动画
        // 2. 带上token
        // if (token) {
        config.headers['Auth-token'] = token
        // } else {
        //   // 重定向到登录页面
        //   router.push('/login')
        // }
        // 3. 根据请求方法，序列化传来的参数，根据后端需求是否序列化
        const attsArr = []
        let date = new Date().toLocaleDateString() // 获取当前日期
        date = date.replace(/\//g, '') // 替换2017/05/03 为    2017-05-03
        var num = Math.floor(Math.random() * 1000000) + 1
        var nonceStr = date + num
        if (config.method.toLowerCase() === 'get' && config.params) {
          const params = commonMethod.objKeySort(config.params)
          for (const key in params) {
            if (params[key] || params[key] === 0) {
              attsArr.push(params[key])
            }
          }
          attsArr.push(nonceStr)
          const signStr = sign.getSignStr(attsArr)
          config.headers['nonceStr'] = nonceStr
          config.headers['sign'] = signStr
          // config.params = params
        } else if (config.method.toLowerCase() === 'post' && config.data) {
          if (!commonMethod.isArray(config.data)) {
            // 数组放过
            const params1 = commonMethod.objKeySort(config.data)
            for (const key in params1) {
              if (commonMethod.isArray(params1[key])) {
                // 数组放过
                continue
              }
              if (params1[key] || params1[key] === 0) {
                attsArr.push(params1[key])
              }
            }
            attsArr.push(nonceStr)
            const signStr = sign.getSignStr(attsArr)
            config.headers['nonceStr'] = nonceStr
            config.headers['sign'] = signStr
          }
        }
        return config
      },

      error => {
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          // return service.request(originalRequest);// 再重复请求一次
        }
        // 2. 需要重定向到错误页面
        const errorInfo = error.response
        if (errorInfo) {
          error = errorInfo.data // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          const errorStatus = errorInfo.status // 404 403 500 ...
          router.push({
            path: `/error/${errorStatus}`
          })
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )
    const errObj = {
      401: '未授权，请登录',
      403: '拒绝访问',
      405: '非法请求',
      406: '参数错误',
      408: '请求超时',
      500: '服务器内部错误',
      501: '服务未实现',
      502: '网关错误',
      503: '服务不可用',
      504: '网关超时',
      505: 'HTTP版本不受支持'
    }
    // response 拦截器
    instance.interceptors.response.use(
      async response => {
        hideLoading()

        const res = response.data
        let data = res
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (res === undefined) {
          data = JSON.parse(response.request.responseText)
        }

        // 针对文件下载报错处理
        if (response.request.responseType === 'blob' && response.data.type === 'application/json') {
          const result = await tansformTxToJson(response.data);
          if (data.code != 200) {
            Message({
              message: result.msg || '系统异常，请联系管理员',
              type: 'error',
              duration: 3 * 1000
            })
            reject(data)
            return;
          } else {
            resolve(response.data)
            return data;
          }
        } else {
          // 若不是正确的返回code，且已经登录，就抛出错误 response.request.responseType === 'blob'
          if (res.code !== 200 && response.request.responseType !== 'blob') {
            if (res.code === 500 || res.code === 999999) {
              MessageBox.confirm(`${res.msg || '系统异常，请联系管理员'}<textarea id="copyText" style="opacity: 0;height: 0;float: right" />`, '提示', {
                showConfirmButton: true,
                showCancelButton: true,
                showClose: false,
                // confirmButtonText: '确定',
                cancelButtonText: '复制错误信息',
                type: 'warning',
                dangerouslyUseHTMLString: true,
                beforeClose: (action, instance, done) => {
                  if (action === 'confirm') {
                    done()
                  } else {
                    const { url, method, params, data } = response.config
                    const copyText = document.getElementById('copyText')
                    copyText.innerHTML = `url：${url}\n请求方式：${method}\n参数：${JSON.stringify(params || data)}\n返回结果：${response.request.responseText}`
                    copyText.select()
                    document.execCommand('copy')
                  }
                }
              })
            } else {
              Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 3 * 1000
              })
            }
            reject(data)
          }
          return data;
        }
      },
      async err => {
        console.log('err', err);

        // 针对blob 文件流报错处理
        // console.log(typeof err);

        if (_cancelToken && err.response.request.responseURL.indexOf('/mgmt/notifications/message-count') > -1) {
          _cancelToken()
          return
        }
        if (err && err.response) {
          err.message = errObj[err.response.status] || err.msg
          switch (err.response.status) {
            case 400:
              if (err.response.data.code === 400400 && err.response.config.url.indexOf('/mgmt/order/refunds/_approve') > -1) {
                err.message = '微信医保退款异常,请选择手动退款'
              } else {
                let m = ''
                if (err.response.request.responseType === 'blob') {
                  await blobToText(err.response.data)
                    .then(resq => {
                      console.log(resq)
                      if (resq.code === 100400) {
                        for (const v in resq.data) {
                          m = m ? m + ',' : m
                          m += resq.data[v]
                        }
                      } else {
                        err.message = resq.msg
                      }
                    })
                    .catch(() => {
                      m = '未知异常'
                    })
                } else {
                  err.message = err.response.data.msg || '请求错误'
                }
                err.message = m || err.message
                // err.message = err.response.data.msg || '请求错误'
              }

              break
            case 401:
              // err.message = '未授权，请登录'
              if (store.state.app.errorNoAuthCount === 0 || !store.state.app || !store.state.app.errorNoAuthCount) {
                store.commit('app/errorNoAuthCount', 1)
                // 保存加载状态
                store.commit('app/webReloadLoginFlag', true)
              }
              // sessionStorage.removeItem('token')
              break
            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`
              break
            case 408:
              // err.message = '请求超时'
              MessageBox.confirm('请求超时！', '确认', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => { })
              break
            default:
          }
        }
        const req = err.response ? err.response.request : null
        // 登入请求放过
        if (req && req.responseURL && req.responseURL.indexOf('/core/login') > -1) {
          // req.responseURL.indexOf('/core/user/getCurrentUser') > -1
          return Promise.reject(err)
        }
        if (location.href.indexOf('/casehistory') > -1) {
          MessageBox.confirm('token已过期', '提示', {
            showConfirmButton: true,
            showCancelButton: false,
            showClose: false,
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          })
          return
        }

        if (!getToken() && err.response.status === 401) {
          return
        }
        if (store.state.app.webReloadLoginFlag && getToken()) {
          removeToken()
          store.commit('app/webReloadLoginFlag', false)
          MessageBox.confirm('登录已注销，点击取消按钮停留当前页面或重新登录！', '注销确认', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // sessionStorage.removeItem('token')
            store.commit('user/SET_PERMSS', [])
            store.commit('permission/SET_MENUS', [])
            store.commit('user/SET_ROLES', [])
            store.commit('app/menuRouteLoaded', false) // 要求重新加载导航菜单
            // store.commit('app/webReloadLoginFlag', false)// 重新登入为false
            store.commit('app/errorNoAuthCount', 0)
            store.commit('user/SET_CURRENT_ROLE', {}) // 设置当前角色
            store.commit('user/SET_TOKEN', '')
            location.reload()
          })
          return
        }
        const status = err.response.status


        // 针对文件下载报错处理
        if (err.response.request.responseType === 'blob' && err.response.data.type === 'application/json') {
          const result = await tansformTxToJson(err.response.data);
          Message({
            message: result.msg || '系统异常，请联系管理员',
            type: 'error',
            duration: 3 * 1000
          })
        } else {
          if (status === 500) {
            const msg = err.response.data.msg || '系统异常，请联系管理员'
            MessageBox.confirm(`${status === 999999 || status === 500 ? msg : '系统异常，请联系管理员'}<textarea id="copyText" style="opacity: 0;height: 0;float: right" />`, '提示', {
              showConfirmButton: true,
              showCancelButton: true,
              showClose: false,
              // confirmButtonText: '确定',
              cancelButtonText: '复制错误信息',
              type: 'warning',
              dangerouslyUseHTMLString: true,
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  done()
                } else {
                  const { url, method, params, data } = err.response.config
                  const copyText = document.getElementById('copyText')
                  copyText.innerHTML = `url：${url}\n请求方式：${method}\n参数：${JSON.stringify(params || data)}\n返回结果：${req.responseText}`
                  copyText.select()
                  document.execCommand('copy')
                }
              }
            })
          } else {
            if (err.response.data.code !== 300402 && err.response.data.code !== 300402) {
              Message({
                message: err.message || 'Error',
                type: 'error',
                duration: 3 * 1000
              })
            }
          }
        }
        return Promise.reject(err.response) // 返回接口返回的错误信息
      }
    )

    // 请求处理
    instance(options)
      .then(res => {
        hideLoading()
        resolve(res)
        return false
      })
      .catch(error => {
        hideLoading()
        reject(error)
      })
  })
}

// 转换文件流程json 针对文件下载报错自定义错误消息
function tansformTxToJson(bolb) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(bolb, 'utf-8')
    reader.onload = () => {
      const data = JSON.parse(reader.result)
      resolve(data);
    }
  })
}