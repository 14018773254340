import axios from '../../axios'

// 种草列表
export function getTanakaShow(data) {
  return axios({
    url: '/mgmt/medical/showCase/getPage',
    method: 'get',
    params: data
  })
}

// 新增种草
export function addTanakaShow(data) {
  return axios({
    url: '/mgmt/medical/showCase/add',
    method: 'post',
    data: data
  })
}

// 编辑种草
export function editTanakaShow(data) {
  return axios({
    url: '/mgmt/medical/showCase/edit',
    method: 'post',
    data: data
  })
}

// 删除种草
export function deleteTanakaShow(data) {
  return axios({
    url: '/mgmt/medical/showCase/delete',
    method: 'post',
    data: data
  })
}

// 所有种草官

export function getOfficerList(data) {
  return axios({
    url: '/mgmt/medical/showCase/officer/getList',
    method: 'get',
    params: data
  })
}