<template>
  <div class="container">
    <!-- <MDtitle text="商务邀请医生列表" /> -->
    <ykj-search :query-list="queryList" @query="initPageListF" />
    <MDTable :table="table" :page-vo="pageVo" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
  </div>
</template>
<script>
import { export_blob } from '@/utils/exportFile'
export default {
  data() {
    return {
      queryList: {
        type: 'normal', // 查询表单类型 normal(普通类型)、takeTab（带有tab快捷切换搜索类型）
        list: [ // 查询列表
          {
            label: '注册时间：',
            type: 'DateRange', // 输入框类型 input、Select、Date、TimeRange、DateRange、Datetime、Time
            paramsName: 'startTime,endTime', // 参数名称 注：如果是TimeRange、DateRange类型的，参数名可以使用两个使用逗号隔开（如：beginTime,endTime）
            value: [] // 内容 注：如果是TimeRange、DateRange类型的，默认值设为数组
          },
          {
            label: '商务名称：',
            placeholder: '请输入商务名称',
            type: 'input', // 输入框类型 input、Select、Date、TimeRange、DateRange、Datetime、Time
            paramsName: 'businessName', // 参数名称 注：如果是TimeRange、DateRange类型的，参数名可以使用两个使用逗号隔开（如：beginTime,endTime）
            value: '' // 内容 注：如果是TimeRange、DateRange类型的，默认值设为数组
          },
          {
            label: '状态：',
            placeholder: '请选择状态',
            type: 'Select', // 输入框类型 input、Select、Date、TimeRange、DateRange、Datetime、Time
            paramsName: 'flowStatus', // 参数名称 注：如果是TimeRange、DateRange类型的，参数名可以使用两个使用逗号隔开（如：beginTime,endTime）
            value: '', // 内容 注：如果是TimeRange、DateRange类型的，默认值设为数组
            options: [
              {
                value: '',
                label: '全部'
              },
              {
                value: 0,
                label: '填写资料中'
              },
              {
                value: 10,
                label: '审核中'
              },
              {
                value: 20,
                label: '启用'
              },
              {
                value: 30,
                label: '驳回'
              }
            ]
          }

        ],
        tab: {}
      },
      // tableList: [],
      pageVo: {
        pageNum: 1,
        pageSize: 10
      },
      flowStatusFilter: {
        // 0: '填写资料中',
        // 10: '待审核',
        // 20: '审核通过',
        // 30: '审核不通过'
        0: '填写资料中',
        10: '审核中',
        20: '启用',
        30: '驳回'
      },
      queryVo: {
        businessName: null,
        delFlag: null
      },
      table: {
        stripe: false, // 是否为斑马纹
        loading: false, // 数据加载loading,,
        // change: this.handleSelectionChange,
        selections: [],
        operation: [
          {
            label: '导出',
            event: this.handleExport, // 点击事件
            icon: 'el-icon-download',
            alwayShow: true
          }
        ],
        data: [
        ],
        event: {
          sortEvent: this.tableSort
        },
        header: [
          { prop: 'doctorId',
            label: '编号'
          },
          { prop: 'doctorName',
            label: '医生姓名'
          },
          { prop: 'positionCategory_dictName',
            label: '职称'
          },
          { prop: 'hispitalName',
            label: '所属医院',
            width: 160
          },
          { prop: 'departmentName',
            label: '科室',
            width: 180
          },
          { prop: 'businessName',
            label: '邀请商务',
            width: 140
          },
          { prop: 'createTime',
            label: '注册时间',
            width: 140
          },
          { prop: 'flowStatus',
            label: '状态',
            width: 120,
            solt: true,
            soltmeoth: (res) => {
              return this.flowStatusFilter[res]
            }
          },
          { prop: 'approveTime',
            label: '成功入驻时间',
            width: 140
          }
        ],
        options: [

        ]
      }
    }
  },
  // computed: {
  //   ...mapGetters([
  //     'currentPerms'
  //   ])
  // },
  created() {
    this.initPageList()
  },
  methods: {
    initPageListF(params) {
      this.queryVo = params
      this.pageVo.pageNum = 1
      this.findPage(this.queryVo)
    },
    // 导出
    handleExport() {
      let loadingMessage = null
      loadingMessage = this.$message({
        message: '加载中',
        customClass: 'theme-message',
        type: 'success',
        duration: 0,
        iconClass: 'el-icon-loading'
      })
      const _data = this.queryVo// JSON.parse(JSON.stringify(this.queryVo))
      delete _data.curPage
      delete _data.pageSize
      this.$api.business.inviteExport(_data).then(res => {
        export_blob(res, `商务邀请医生.xls`)
        loadingMessage.close()
      })
    },
    initPageList(data = {}) {
      this.pageVo.pageNum = 1
      this.queryVo = data
      this.findPage(this.queryVo)
    },
    handleSizeChange(val) {
      this.pageVo.pageSize = val
      this.findPage(this.queryVo)
    },
    handleCurrentChange(val) {
      this.pageVo.pageNum = val
      this.findPage(this.queryVo)
    },
    findPage(params) {
      const queryParams = params || {}
      queryParams.curPage = this.pageVo.pageNum
      queryParams.pageSize = this.pageVo.pageSize
      this.$api.business.inviteDoctor(queryParams).then((res) => {
        if (res.code === 200) {
          // this.tableList = res.data.records
          this.table.data = res.data.records
          this.pageVo = {
            pageNum: res.data.current,
            pageSize: res.data.size,
            // totalPages
            totalSize: res.data.total
          }
        }
      })
    },
    convertHTML(item) {
      let _html = '-'
      if (item === 1) {
        _html = '<span class="forbidden"><i class="el-icon-error"></i>禁用</span>'
      } else if (item === 0) {
        _html = '<span class="enable"><i class="el-icon-success"></i>启用</span>'
      }
      return _html
    }
  }
}
</script>
<style lang="scss" scoped>
  .oTable{
    padding: 20px;
    .opag{
      margin-top: 20px;
    }
  }
</style>
