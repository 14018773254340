var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "head"
        ? _c(
            "div",
            { staticClass: "head-cont" },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadImageUrl",
                  staticClass: "avatar-uploader",
                  attrs: {
                    "clear-files": true,
                    action: "test123",
                    "show-file-list": false,
                    "http-request": _vm.uploadImageSectionFile,
                    disabled: _vm.disabled,
                  },
                },
                [
                  _vm.imageUrl
                    ? _c("div", { staticClass: "img-cont" }, [
                        _c("div", { staticClass: "img-cont-shade" }, [
                          _c("i", {
                            staticClass: "el-icon-zoom-in",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleLookImg($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-upload2" }),
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "d-avatar",
                          attrs: { src: _vm.imageUrl },
                        }),
                      ])
                    : _c("div", { staticClass: "up-add" }, [
                        _c("div", { staticClass: "up-add-cont" }, [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" "),
                          _vm.uploadButText
                            ? _c("p", [_vm._v(_vm._s(_vm.uploadButText))])
                            : _vm._e(),
                        ]),
                      ]),
                ]
              ),
            ],
            1
          )
        : _vm.type === "list"
        ? _c(
            "el-upload",
            {
              ref: "uploadImageUrl",
              attrs: {
                "auto-upload": false,
                "clear-files": true,
                action: "test123",
                drag: "",
                multiple: "",
                "list-type": "picture-card",
                limit: _vm.limit,
                "on-remove": _vm.licenseImgRemove,
                "file-list": _vm.imageUrl,
                "on-preview": _vm.handleLookImg,
                "on-exceed": _vm.onExceed,
                disabled: _vm.disabled,
                "on-change": _vm.onChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "file",
                  fn: function (ref) {
                    var file = ref.file
                    return _c("div", {}, [
                      _vm.isVideo(file.url)
                        ? _c("div", { staticStyle: { position: "relative" } }, [
                            _c("video", {
                              staticClass: "image_item",
                              attrs: { src: file.url, controls: "" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "delete_video",
                                on: {
                                  click: function ($event) {
                                    return _vm.licenseImgRemove(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            ),
                          ])
                        : _c("img", {
                            staticClass: "image_item",
                            attrs: { src: file.url },
                          }),
                      _vm._v(" "),
                      !_vm.isVideo(file.url)
                        ? _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-preview",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleLookImg(file)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                              _vm._v(" "),
                              !_vm.disabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.licenseImgRemove(file)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ])
                  },
                },
              ]),
            },
            [_vm._v(" "), _c("i", { staticClass: "el-icon-plus" })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }