import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/patientTag/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/patientTag/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/patientTag/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/patientTag/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const checkPatientTagTitle = (data) => {
  return axios({
    url: basePath + '/patientTag/checkPatientTag',
    method: 'get',
    params: data
  })
}
