import axios from '../../axios'
const basePath = '/mgmt/freight'
// 详情
export const freightList = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}
// 编辑
export const freightSetting = (data) => {
  return axios({
    url: basePath + '/_setting',
    method: 'post',
    data
  })
}
