import axios from '../../axios'
const basePath = '/core/assistantInfo'

export function save(data) {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}
export function batchDelete(data) {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data// data
  })
}

export const getCurrentUserAssistantInfo = () => {
  return axios({
    url: basePath + '/getCurrentUserAssistantInfo',
    method: 'get'
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + '/findInfoByUniqueKey',
    method: 'get',
    params: data// data
  })
}

export function bindUser(data) {
  return axios({
    url: basePath + '/bindUser',
    method: 'post',
    data
  })
}
