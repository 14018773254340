import axios from '../../axios'
const basePath = '/mgmt/medicine/prescriptions'
/*
 * 药品库模块
 */

// 保存
export const save = (data) => {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}

export const edit = (data) => {
  return axios({
    url: basePath + `/${data.id}`,
    method: 'put',
    data
  })
}

export const batchDelete = (data) => {
  return axios({
    url: basePath + `/${data.id}`,
    method: 'delete'
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 明细查询
export const findInfoByUniqueKey = (id) => {
  return axios({
    url: basePath + `/${id}`,
    method: 'get'
  })
}
