import axios from '../../axios'
const basePath = '/core/consultation'
const basePath2 = '/core/videoinfo'
const basePath3 = '/core/consultationDoctor'

// 分页会诊医生查询
export const findConsultationDoctorPage = (data, pageVo) => {
  return axios({
    url: basePath3 + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}
export const saveConsultationDoctor = (data) => {
  return axios({
    url: basePath3 + '/save',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/myPageList/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

// 详情
export const detail = (data) => {
  return axios({
    url: basePath + '/detail',
    method: 'get',
    params: data
  })
}

// 审核通过
export const approve = (data) => {
  return axios({
    url: basePath + '/approve',
    method: 'post',
    data
  })
}

// 审核不通过
export const refuse = (data) => {
  return axios({
    url: basePath + '/refuse',
    method: 'post',
    data
  })
}

// 会诊开始
export const start = (data) => {
  return axios({
    url: basePath + '/start',
    method: 'post',
    data
  })
}

// 会诊结束
export const finish = (data) => {
  return axios({
    url: basePath + '/finish',
    method: 'post',
    data
  })
}

// 提交会诊单
export const submit = (data) => {
  return axios({
    url: basePath + '/submit',
    method: 'post',
    data
  })
}

// 提交会诊记录
export const submitRecord = (data) => {
  return axios({
    url: basePath + '/submitRecord',
    method: 'post',
    data
  })
}

// 提交会诊报告
export const submitReport = (data) => {
  return axios({
    url: basePath + '/submitReport',
    method: 'post',
    data
  })
}

// 医生申请审核
export const apply = (data) => {
  return axios({
    url: basePath + '/apply',
    method: 'post',
    data
  })
}

// 支付会诊单
export const pay = (data) => {
  return axios({
    url: basePath + '/pay',
    method: 'post',
    data
  })
}

// 邀请患者进入视频
export const inviteInquirerVideo = (data) => {
  return axios({
    url: basePath + '/inviteInquirerVideo',
    method: 'post',
    data
  })
}

// 踢出患者进入视频
export const endInquirerIVideo = (data) => {
  return axios({
    url: basePath + '/endInquirerIVideo',
    method: 'post',
    data
  })
}

// 邀请医生进入视频
export const inviteDoctorVideo = (data) => {
  return axios({
    url: basePath + '/inviteDoctorVideo',
    method: 'post',
    data
  })
}

// 修改会诊视频状态
export const updateChatVideoInfoState = (data) => {
  return axios({
    url: basePath2 + '/updateChatVideoInfoState',
    method: 'post',
    data
  })
}
