var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ValidCode disabled-select",
      style: "width:" + _vm.width + "; height:" + _vm.height,
      on: { click: _vm.refreshCode },
    },
    _vm._l(_vm.codeList, function (item, index) {
      return _c("span", { key: index, style: _vm.getStyle(item) }, [
        _vm._v(_vm._s(item.code)),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }