var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["chart-3d"] }, [
    _c("div", {
      ref: "refChar3d",
      staticStyle: { width: "100%", height: "400px" },
      attrs: { id: "refChar3d" },
      on: { click: _vm.canBuild },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }