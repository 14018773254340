<template>
  <div class="paragraph-wrap nop-fill-box">
    <h2 v-if="channels.length > 0 && en" class="sub-title">
      Sick in: {{ texts() }}
    </h2>
    <h2 v-if="channels.length > 0 && !en" class="sub-title">
      病在：{{ texts() }}
    </h2>
    <h2 v-if="channels.length === 0 && symptoms.text.length === 0 && en" class="sub-title">
      Nothing
    </h2>
    <h2 v-if="channels.length === 0 && symptoms.text.length === 0 && !en" class="sub-title">
      无
    </h2>
    <h2 v-if="symptoms.text.length > 0 && en" class="sub-title">
      Syndrome differentiation: {{ symptoms.text.join('；') }}
    </h2>
    <h2 v-if="symptoms.text.length > 0 && !en" class="sub-title">
      辨证：{{ symptoms.text.join('；') }}
    </h2>
    <div v-if="symptoms.desc.length > 0 && en" class="paragraph">
      <h3>Dialectical annotation:</h3>
    </div>
    <div v-if="symptoms.desc.length > 0 && !en" class="paragraph">
      <h3>辨证注释:</h3>
    </div>
    <p v-for="(item, index) in symptoms.desc" :key="index" class="paragraph-p">
      {{ item }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    channels: {
      type: Array,
      required: true
    },
    symptoms: {
      type: Object,
      required: true
    },
    en: {
      type: String,
      default: ''
    }
  },
  methods: {
    texts() {
      return this.channels.map(item => item.text).join('、');
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
