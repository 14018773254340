exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#163a99",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#313540",
	"menuHover": "#305ccc",
	"subMenuBg": "#464c5b",
	"subMenuHover": "#4377ff",
	"sideBarWidth": "210px"
};