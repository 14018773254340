import axios from '../../axios'
const basePath = '/core/medicineShowCategory'
/*
 * 数据字典模块
 */

export const saveCategory = (data) => {
  return axios({
    url: basePath + '/save',
    method: 'post',
    data
  })
}

export const editCategory = (data) => {
  return axios({
    url: basePath + '/edit',
    method: 'post',
    data
  })
}

export const batchDeleteCategory = (data) => {
  return axios({
    url: basePath + '/delete',
    method: 'post',
    data
  })
}

export const saveContent = (data) => {
  return axios({
    url: basePath + '/saveContent',
    method: 'post',
    data
  })
}

export const editContent = (data) => {
  return axios({
    url: basePath + '/editContent',
    method: 'post',
    data
  })
}

export const batchDeleteContent = (data) => {
  return axios({
    url: basePath + '/deleteContent',
    method: 'post',
    data
  })
}

export const findCategoryPage = (data, pageVo) => {
  return axios({
    url: basePath + '/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const findContentPage = (data, pageVo) => {
  return axios({
    url: basePath + '/pageContent/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const checkCategoryExists = (data) => {
  return axios({
    url: basePath + '/checkCategoryExists',
    method: 'get',
    params: data
  })
}

export const checkContentExists = (data) => {
  return axios({
    url: basePath + '/checkContentExists',
    method: 'get',
    params: data
  })
}
