<template>
  <div>
    <el-upload
      v-if="add"
      class="avatar-uploader"
      :action="baseUrlPath"
      :headers="reqHeaders"
      :before-upload="beforeImageUpload"
      :show-file-list="false"
      :on-success="headImgSuccess"
      :disabled="disabled"
    >
      <img v-if="headImgUrl" :src="headImgUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon" />
    </el-upload>

    <el-upload
      v-else
      :action="baseUrlPath"
      list-type="picture-card"
      :headers="reqHeaders"
      :file-list="fileLicenseList"
      :before-upload="beforeImageUpload"
      :on-preview="imgPreview"
      :on-remove="licenseImgRemove"
      :on-success="headImgSuccess"
      :disabled="disabled"
    >
      <i class="el-icon-plus" />
    </el-upload>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { baseUrl } from '@/utils/global'
export default {
  props: {
    tagUrl: {
      type: String,
      default() {
        return ''
      }
    }, // 图片的唯一的标识 默认为text
    headImgUrl: {
      type: String,
      default() {
        return ''
      }
    }, // 单张图片的地址
    add: { // 是否为多张图片，true为单张, false为多张，默认为true
      type: Boolean,
      default() {
        return true
      }
    },
    fileLicenseList: { // 多张图片的数组
      type: Array,
      default() {
        return []
      }
    },
    uploadLimitSize: {
      type: Number,
      default: 2 * 1024 * 1024
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // baseUrlPath: this.tagUrl ? baseUrl + '/core/file/upload/' + this.tagUrl + '?entityId=test1234' : baseUrl + '/core/file/upload/' + 'text' + '?entityId=test1234',
      reqHeaders: {
        'Auth-token': getToken()
      },
      dialogImageUrl: null,
      dialogVisible: false
    }
  },
  computed: {
    baseUrlPath() {
      let _str = ''
      const _url = JSON.parse(JSON.stringify(baseUrl))
      const str = _url.charAt(_url.length - 1)
      let _baseUrl = ''
      if (str === '/') {
        _baseUrl = baseUrl
      } else {
        _baseUrl = baseUrl + '/'
      }
      if (this.tagUrl) {
        _str = _baseUrl + 'core/file/upload/' + this.tagUrl + '?entityId=test1234'
      } else {
        _str = _baseUrl + 'core/file/upload/' + 'text' + '?entityId=test1234'
      }
      return _str
    }
  },
  methods: {
    beforeImageUpload(file) {
      const arrImage = ['image/jpeg', 'image/png']
      const isJPG = arrImage.indexOf(file.type) !== -1
      const isLt2M = file.size < this.uploadLimitSize// 图片大小限制,默认小于2M

      if (!isJPG) {
        this.$message.error('上传头像图片只能是jpg/png格式!')
      }
      if (!isLt2M) {
        let showMsg = ''
        if (this.uploadLimitSize / 1024 < 1024) {
          showMsg = this.uploadLimitSize / 1024 + 'K'
        } else {
          showMsg = this.uploadLimitSize / 1024 / 1024 + 'M'
        }
        this.$message.error('上传头像图片大小不能超过' + showMsg)
      }
      return isJPG && isLt2M
    },
    headImgSuccess(response, file) {
      var att = []
      if (this.add) {
        att = this.concatAttVo(response, file, this.tagUrl)// 回调中能拿到值
        var headImgaUrl = URL.createObjectURL(file.raw)// 回调中能拿到值
      } else {
        att = this.concatAttVoa(response, file, this.tagUrl)
      }
      var costList = [headImgaUrl, att, { headerImgName: this.tagUrl }]
      this.$emit('imgSucess', costList)
    },

    concatAttVo(response, file, entityType) {
      const { code, data } = response
      const fileName = file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
      let taskId = null
      if (code === 200 && data) {
        taskId = data.taskId
      }
      const att = {
        name: fileName,
        url: file.url,
        docName: fileName,
        docSize: file.size,
        uid: file.uid,
        docType: fileType,
        entityType: entityType,
        taskId: taskId
      }
      return att
    },
    imgPreview(file) {
      this.$emit('imgPreview', file)
    },
    licenseImgRemove(file) { // 医生证
      // const filter = (value) => {
      //   return value.uid !== file.uid
      // }
      // this.fileLicenseList = this.fileLicenseList.filter(filter)
      this.$emit('licenseImgRemove', [file, this.tagUrl])
    },

    concatAttVoa(response, file, entityType) {
      const { code, data } = response
      const fileName = file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
      let taskId = null
      if (code === 200 && data) {
        taskId = data.taskId
      }
      const att = {
        name: fileName,
        url: file.url,
        docName: fileName,
        docSize: file.size,
        uid: file.uid,
        docType: fileType,
        entityType: entityType,
        taskId: taskId
      }
      return att
    }
  }

}
</script>
<style lang="scss">
 >>>.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>
