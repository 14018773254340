import axios from '../../axios'

// 会员-列表
export const pageList = data => {
  return axios({
    url: 'mgmt/member/pageList',
    method: 'get',
    params: data
  })
}
// 拉入、拉出黑名单
export const operate = data => {
  return axios({
    url: 'mgmt/trends/user/blackList/operate',
    method: 'post',
    data
  })
}
