var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paragraph-wrap nop-fill-box" },
    [
      _vm.channels.length > 0 && _vm.en
        ? _c("h2", { staticClass: "sub-title" }, [
            _vm._v("\n    Sick in: " + _vm._s(_vm.texts()) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.channels.length > 0 && !_vm.en
        ? _c("h2", { staticClass: "sub-title" }, [
            _vm._v("\n    病在：" + _vm._s(_vm.texts()) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.channels.length === 0 && _vm.symptoms.text.length === 0 && _vm.en
        ? _c("h2", { staticClass: "sub-title" }, [_vm._v("\n    Nothing\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.channels.length === 0 && _vm.symptoms.text.length === 0 && !_vm.en
        ? _c("h2", { staticClass: "sub-title" }, [_vm._v("\n    无\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.symptoms.text.length > 0 && _vm.en
        ? _c("h2", { staticClass: "sub-title" }, [
            _vm._v(
              "\n    Syndrome differentiation: " +
                _vm._s(_vm.symptoms.text.join("；")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.symptoms.text.length > 0 && !_vm.en
        ? _c("h2", { staticClass: "sub-title" }, [
            _vm._v(
              "\n    辨证：" + _vm._s(_vm.symptoms.text.join("；")) + "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.symptoms.desc.length > 0 && _vm.en
        ? _c("div", { staticClass: "paragraph" }, [
            _c("h3", [_vm._v("Dialectical annotation:")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.symptoms.desc.length > 0 && !_vm.en
        ? _c("div", { staticClass: "paragraph" }, [
            _c("h3", [_vm._v("辨证注释:")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.symptoms.desc, function (item, index) {
        return _c("p", { key: index, staticClass: "paragraph-p" }, [
          _vm._v("\n    " + _vm._s(item) + "\n  "),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }