import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 邀请有奖列表
export function findPage(data) {
    return axios({
        url: '/mgmt/member/relation/pageList',
        method: 'get',
        params: data
    })
}
// 换绑推荐人
export function changeSharer(data) {
    return axios({
        url: 'mgmt/member/relation/changeSharer',
        method: 'post',
        data
    })
}

// 解绑
export function reqUnbind(data) {
    return axios({
        url: '/mgmt/member/relation/unbind',
        method: 'post',
        data
    })
}


// 绑定上级
export function bindMyParent(data) {
    return axios({
        url: 'mgmt/member/relation/bindMyParent',
        method: 'post',
        data
    })
}