import axios from '../../axios'
const basePath = '/core'
/*
 * 系统日志模块
 */

/**
 * 系统操作日志分页查询
 */
export const findPage = (data, pageSize, curPage) => {
  return axios({
    url: `${basePath}/systemOperationLog/page/list/${pageSize}/${curPage}`,
    method: 'get',
    params: data
  })
}
/**
 * 系统行为操作日志分页查询
 */
export const findActionPage = (data, pageSize, curPage) => {
  return axios({
    url: `${basePath}/systemActionoperationLog/page/list/${pageSize}/${curPage}`,
    method: 'get',
    params: data
  })
}
