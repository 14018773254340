
import axios from '../../axios'
const basePath = '/core'

export function save(data) {
  return axios({
    url: basePath + '/member/save',
    method: 'post',
    data
  })
}

// 列表
export const findPage = data => {
  return axios({
    url: '/mgmt/manual/authentication/pageList',
    method: 'get',
    params: data
  })
}
// 详情
export const getDetail = data => {
  return axios({
    url: '/mgmt/manual/authentication/detail/' + data.id,
    method: 'get',
    params: data
  })
}
// 审核通过或拒绝
export function auditStatus(data) {
  return axios({
    url: '/mgmt/manual/authentication/audit',
    method: 'post',
    data: data
  })
}

