import axios from '../../axios'

// 校验身份信息
export function IdCard(data) {
  return axios({
    url: '/mgmt/validation/_real_name',
    method: 'post',
    data
  })
}
