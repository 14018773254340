<!-- eslint-disable vue/valid-v-for -->

<template>
  <div class="zuma webkit">
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <div class="zuma_top">
      <div v-if="table.operation && table.operation.length" class="project">
        <template v-for="(item, index) in table.operation">
          <template v-if="item.uploader">
            <el-popover v-if="item.popoverRef" :ref="item.popoverRef || 'popover'" trigger="hover" placement="top"
              :width="item.popoverWidth || '100'">
              <div style="display: flex;align-content: center;justify-content: space-between;padding: 10px;">
                <template v-if="item.children">
                  <template v-for="ic in item.children">
                    <el-button :disabled="!isShowTableBut(ic)" :type="ic.type" :icon="ic.icon"
                      @click="ic.event(ic.label)">{{ ic.label }}</el-button>
                  </template>
                </template>
              </div>
            </el-popover>
            <el-upload v-if="isShowTableBut(item)" ref="uploadImageUrl" :key="index" v-popover:[item.popoverRef]
              class="avatar-uploader" :clear-files="true" action="test123" :show-file-list="false"
              accept=".xlsx,.execl,.xls" :http-request="e => uploadImageSectionFile(e, item)">
              <el-button size="medium" :icon="item.icon">{{ item.label }}</el-button>
            </el-upload>
          </template>

          <template v-else-if="isShowTableBut(item)">
            <el-popover v-if="item.popoverRef" :ref="item.popoverRef || 'popover'" trigger="hover" placement="top"
              :width="item.popoverWidth || '100'">
              <div style="display: flex;align-content: center;justify-content: space-between;padding: 10px;">
                <template v-if="item.children">
                  <template v-for="ic in item.children">
                    <el-button :disabled="!isShowTableBut(ic)" :type="ic.type" :icon="ic.icon"
                      @click="ic.event(ic.label)">{{ ic.label }}</el-button>
                  </template>
                </template>
              </div>
            </el-popover>
            <el-button :key="index" v-popover:[item.popoverRef] :type="item.type" size="medium" :icon="item.icon"
              @click="item.event(item.label)">{{ item.label }}</el-button>
          </template>
          <!-- <el-button type="danger" size="medium" icon="el-icon-delete">批量删除</el-button> -->
        </template>
      </div>
      <slot name="other" />
    </div>
    <!-- v-if="!tableHeight" -->
    <div>
      <el-table ref="multipleTable" v-loading="table.loading" :data="table.data" row-key="id" :stripe="table.stripe"
        border :header-cell-style="{
      'background-color': '#fafafa',
      color: 'rgb(0, 0, 0)'
    }" :height="table.height" :row-class-name="table.tableRowClassName" @selection-change="handleSelectionChange">

        <template slot="empty">
          <el-empty />
        </template>

        <template v-for="(item, index) in table.header">
          <el-table-column v-if="item.prop == 'selection'" :key="index" reserve-selection type="selection"
            :selectable="checkboxT" :min-width="item.width" :fixed="item.fixed" />
          <el-table-column v-else-if="item.prop == 'index'" :key="index" :label="item.label" type="index"
            :width="item.width" :fixed="item.fixed" />
          <el-table-column v-else-if="item.solt && !item.hide && !item.type" :key="index" :label="item.label"
            :min-width="item.width" :fixed="item.fixed">
            <template slot-scope="scope">
              <div v-html="item.soltmeoth(item.prop ? scope.row[item.prop] : scope.row)" />
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.type && item.type == 'lineClamp' && item.solt" :label="item.label"
            :min-width="item.width">
            <template slot-scope="scope">
              <el-tooltip placement="top" :key="scope.$index" v-if="item.soltmeoth(item.prop ? scope.row[item.prop] : scope.row).length > 60">
                <p slot="content" style="max-width: 400px;margin: 4px;" v-html="item.soltmeoth(item.prop ? scope.row[item.prop] : scope.row)"></p>
                <div class="tx_ov3" v-html="item.soltmeoth(item.prop ? scope.row[item.prop] : scope.row)" />
              </el-tooltip>
              <div v-else v-html="item.soltmeoth(item.prop ? scope.row[item.prop] : scope.row)" />
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.img && !item.hide" :key="index" :label="item.label" :min-width="item.width"
            :fixed="item.fixed">

            <template slot-scope="scope">
              <div v-if="item.event">
                <el-image v-for="it in item.event(scope.row[item.prop])" :key="it.taskId" :src="it.url" :style="{
      width: item.widthimg + 'px',
      'margin-left': 5 + 'px'
    }" :preview-src-list="[...item.event(scope.row[item.prop]).map(i => i.url)]" />
              </div>
              <div v-else-if="typeof scope.row[item.prop] !== 'string' && typeof scope.row[item.prop] === 'object'">
                <template>
                  <template v-for="it in scope.row[item.prop]">
                    <template v-if="it && it.url">
                      <el-button type="primary" size="small" @click="openVideo(it.url)"
                        v-if="isVideo(it.url)">查看视频</el-button>
                      <el-image v-else :key="it.taskId" :src="it.url" :style="{
        width: item.widthimg + 'px',
        'margin-left': 5 + 'px'
      }" :preview-src-list="[...scope.row[item.prop].map(i => i.url)]" />
                    </template>
                  </template>
                </template>
              </div>
              <div v-else>
                <span v-if="scope.row[item.prop] === '' || scope.row[item.prop] === null">无</span>
                <el-image v-else :src="scope.row[item.prop]" :style="{ width: item.widthimg + 'px' }"
                  :preview-src-list="[scope.row[item.prop]]" />
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.soltObj" :key="index" :label="item.label" :min-width="item.width"
            :fixed="item.fixed">

            <template slot-scope="scope">
              <div v-html="item.soltmeoth(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.switch" :key="index" :label="item.label" :min-width="item.width">

            <template slot-scope="scope">
              <el-switch v-model="scope.row[item.prop]" :active-value="item.activeValue"
                :inactive-value="item.inactiveValue" @change="item.change(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.isButton" :key="index" :label="item.label" :min-width="item.width">

            <template slot-scope="scope">
              <el-button :type="item.type" :size="item.size || 'small'" :icon="item.icon"
                @click="item.event(scope.row)">
                {{ item.butlabel }}
              </el-button>
            </template>
          </el-table-column>

          <template v-else-if="item.prop == 'options' && !item.hide">
            <template v-if="table.options.length">
              <el-table-column :key="index" :label="item.label" :width="item.width" :fixed="item.fixed">
                <template slot-scope="scope">
                  <div
                    v-if="calculateList(table.options, scope.row).length > (table.isOptionsMore ? table.isOptionsMore : 3)">
                    <template v-for="(btn, ind) in calculateList(table.options, scope.row)">
                      <el-button v-if="ind < 2 && !btn.hide" :key="ind" :type="btn.type" :size="btn.size || 'small'"
                        :icon="btn.icon" :disabled="btn.disabled ? btn.disabled(scope.row) : false"
                        @click="btn.event(scope.row)">
                        {{ btn.label }}
                      </el-button>
                    </template>

                    <template>
                      <el-dropdown trigger="click" size="small" style="margin-left:5px">
                        <el-button type="text" size="mini" style="padding: 7px 10px;">
                          更多
                          <i class="el-icon-caret-bottom el-icon--right" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="(btn, ind) in calculateList(table.options, scope.row)">
                            <el-dropdown-item v-if="ind >= 2" :key="ind">
                              <el-button v-if="!btn.hide" :type="btn.type" :size="btn.size || 'small'" :icon="btn.icon"
                                @click="btn.event(scope.row)">
                                {{ btn.label }}
                              </el-button>
                            </el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                  </div>
                  <div v-else>

                    <template v-for="(btn, ind) in calculateList(table.options, scope.row)">
                      <el-popconfirm v-if="!btn.hide && btn.popconfirm" :key="ind" :title="btn.popconfirm.title"
                        @confirm="btn.popconfirm.confirm(scope.row)" @cancel="btn.popconfirm.cancel(scope.row)">
                        <el-button v-if="!btn.hide" :key="ind" slot="reference" :type="btn.type"
                          :size="btn.size || 'small'" :icon="btn.icon"
                          :disabled="btn.disabled ? btn.disabled(scope.row) : false" @click="btn.event(scope.row)">
                          {{ btn.label }}
                        </el-button>
                      </el-popconfirm>
                      <el-button v-else-if="!btn.hide" :key="ind" :type="btn.type" :size="btn.size || 'small'"
                        :icon="btn.icon" :disabled="btn.disabled ? btn.disabled(scope.row) : false"
                        @click="btn.event(scope.row)">
                        {{ btn.label }}
                      </el-button>
                    </template>
                  </div>
                </template>
              </el-table-column>
            </template>
          </template>
          <el-table-column v-else-if="item.prop == 'freezeStatus'" :label="item.label" :min-width="item.width">

            <template slot-scope="scope">
              {{ scope.row.freezeStatus == 1 ? '冻结中' : '正常' }}
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.prop == 'isCanWithdraw'" :label="item.label" :min-width="item.width">

            <template slot-scope="scope">
              {{ scope.row.isCanWithdraw === 1 ? '可提现' : '不可提现' }}
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.type && item.type == 'lineClamp'" :label="item.label"
            :min-width="item.width">

            <template slot-scope="scope">
              <el-tooltip placement="top" :key="scope.$index" v-if="scope.row[item.prop].length > 100">
                <p slot="content" style="max-width: 400px;margin: 4px;">{{ scope.row[item.prop] }}</p>
                <div class="tx_ov3">
                  {{ scope.row[item.prop] }}
                </div>
              </el-tooltip>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column v-else-if="!item.hide" :key="index" :fixed="item.fixed" :prop="item.prop" :label="item.label"
            :min-width="item.width" :formatter="item.formatter || null" :show-overflow-tooltip="item.tooltip || false"
            :sortable="item.sortable || false" />
        </template>
      </el-table>
    </div>
    <div v-if="pageVo.totalSize" :class="{ hidden: hidden }" class="pagination-container">
      <span v-if="pageVo.totalSize < 11" class="el-pagination__total">共 {{ pageVo.totalSize }} 条</span>
      <el-pagination v-else :background="background" :current-page="pageVo.pageNum" :page-sizes="[10, 30, 50, 100, 500]"
        :page-size="pageVo.pageSize" :layout="layoutStr" :total="pageVo.totalSize" :pager-count="pagerCount"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog top="2vh" title="视频查看" :visible.sync="showVideo" width="440px">
      <video :src="videoUrl" controls style="width: 400px;max-height: 400px;"></video>
      <span slot="footer">
        <el-button type="primary" @click="showVideo = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Table',
  props: {
    pagerCount: {
      type: Number,
      default: 7
    },
    // eslint-disable-next-line vue/require-default-prop
    table: Object, // 表格的属性

    // eslint-disable-next-line vue/require-default-prop
    pageVo: {
      type: Object
    },

    limit: {
      type: Number,
      default: 20
    },

    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      // 控制是否有分页
      type: Boolean,
      default: false
    }
    // tableHeight: { // 是否设置高度
    //   type: Boolean
    // }
  },
  data() {
    return {
      videoUrl: "",
      showVideo: false,
      selectionArr: [],
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  computed: {
    ...mapGetters(['currentPerms']),
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val) // 当前页数
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    },
    layoutStr() {
      let str = 'total, sizes, pager'
      if (this.pageVo.totalPages && this.pageVo.totalPages > 5) {
        str = 'total, sizes, prev, pager, next, jumper'
      }
      return str
    },
    isVideo() {
      return (url) => url.indexOf('.mp4') !== -1;
    }
  },
  methods: {
    openVideo(url) {
      if (!url) return;
      this.videoUrl = url;
      this.showVideo = true
    },
    ImgPreview(file) {
      // 图片查看
      if (typeof file === 'string') {
        this.dialogImageUrl = file
      } else {
        this.dialogImageUrl = file.url
      }
      this.dialogVisible = true
    },
    handleSelectionChange(selections) {
      this.table.change(selections)
    },
    handleSizeChange(val) {
      this.$emit('handleSizeChange', val)
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    toggleRowSelection(item, arr) {
      if (item) {
        this.selectionArr = arr
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(item)
        })
      } else {
        this.selectionArr = []
      }
    },
    checkboxT(row, rowIndex) {
      if (this.selectionArr && this.selectionArr.length > 0) {
        // 如果是特殊自定义的则使用禁用方法
        console.log(this.selectionArr)
        if (this.selectionArr.some(item => row.id === item.drugsId)) {
          return false // 如果已选择，则禁用
        } else {
          return true
        }
      } else {
        return true
      }
    },
    beforeImageUpload(file) {
      const arrImage = ['.xlsx', '.execl', '.xls']
      const isJPG = arrImage.concat(file.type)
      const isLt2M = file.size / 1024 / 1024 <= 10

      if (!isJPG) {
        this.$message.error('上传文件只能是.xlsx/.execl/.xls格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isJPG && isLt2M
    },
    uploadImageSectionFile(params, item) {
      var file = params.file
      var flag = this.beforeImageUpload(file)
      if (flag) {
        var formData = new FormData()
        formData.append('file', file)
        if (item.event) {
          item.event(formData, this.$refs.uploadImageUrl.uploadFiles)
        } else {
          this.$emit('handleUpload', formData, this.$refs.uploadImageUrl.uploadFiles)
        }
        // this.uploadFile('patientrecordinfoIMG', formData, resdata => {
        //   this.form.cmsHeadId = resdata.taskId
        //   this.$refs.uploadImageUrl.uploadFiles = []
        // })
      }
    },
    calculateList(arr, item) {
      const _Arr = []
      if (arr && arr.length > 0) {
        arr.forEach(i => {
          if (i.isShow && i.isShow(item)) {
            _Arr.push(i)
          } else if (!i.isShow) {
            _Arr.push(i)
          }
        })
      }
      return _Arr
    },
    isShowTableBut(item) {
      if (typeof item.alwayShow === 'function') {
        return item.alwayShow()
      }
      if (item.alwayShow) {
        return true
      }
      if (item.sysPerm && this.currentPerms.findIndex(btn => btn === item.sysPerm) > -1) {
        return true
      }
      return false
      // let _boo = false
      // if (item.sysPerm && typeof item.alwayShow === 'boolean') {
      //   if (!(this.currentPerms.findIndex(btn => btn === item.sysPerm) > -1) || !item.alwayShow) {
      //     _boo = false
      //   }
      // } else if (item.sysPerm && !(this.currentPerms.findIndex(btn => btn === item.sysPerm) > -1)) {
      //   _boo = false
      // } else if (typeof item.alwayShow === 'boolean' && !item.alwayShow) {
      //   _boo = false
      // }
      // return _boo
    }
  }
}
</script>

<style lang="scss" scoped>
.el-pagination__total {
  font-size: 13px;
}

.zuma {
  position: relative;
  width: 100%;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;

  .project {
    text-align: left;
    margin-bottom: 10px;
  }

  .zuma_top {
    display: flex;
    justify-content: space-between;
  }
}

.pagination-container {
  background: #fff;
  padding: 16px 0;
  text-align: right;
  margin: 0;

  ::v-deep .el-pagination__total {
    float: left;
  }

  ::v-deep .el-pagination {
    &.is-background {
      .el-pager {
        li {
          background: #fff;
          border: 1px solid #dcdfe6;

          &.active {
            background-color: #3d68cc;
            border: none;
          }
        }
      }
    }
  }
}

.pagination-container.hidden {
  display: none;
}

::v-deep .avatar-uploader {
  display: inline-block;
  margin: 0 15px;
}

::v-deep .el-button--text:hover {
  background: #ecf1ff;
}

::v-deep .el-button+.el-button {
  margin-left: 5px;
}

::v-deep .el-table__row td:not(.is-hidden):last-child {
  right: 0;
}

::v-deep .el-button--mini {
  font-size: 14px;
}

::v-deep .el-table__fixed-header-wrapper {
  .el-table__header {
    th:not(.is-hidden):last-child {
      right: -1px;
      border-right: 2px solid #dfe6ec;
      // position: inherit;
    }
  }
}

::v-deep .el-table__fixed-body-wrapper {
  .el-table__row {
    td:not(.is-hidden):last-child {
      right: -1px;
      border-right: 2px solid #dfe6ec;
      // position: inherit;
    }
  }
}
</style>
