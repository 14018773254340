/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 */

// 后台管理系统服务器地址
// export const baseUrl = process.env.VUE_APP_BASE_API
export const baseUrl = window._conf.baseApi
// export const systemWebSocketUrl = process.env.VUE_APP_WEBSOCKET_BASE_API
export const systemWebSocketUrl = window._conf.baseApiWs
// export const tengxunsdkId = process.env.VUE_APP_SYSTEM_TXSDKID
export const tengxunsdkId = window._conf.systemTxSdkId
// export const title = process.env.VUE_APP_SYSTEM_TITLE
export let title = ''
// export const logo = process.env.VUE_APP_SYSTEM_INDEX_LOGO
export let logo = ''
// export const caflag = process.env.VUE_APP_SYSTEM_CA_FLAG
export let caflag = false
export let redEnvelope = false
export let headacheDiary = false
const _session = sessionStorage.getItem('systemInfo')
const _info = JSON.parse(_session)
console.log(_info)
if (_info) {
  title = _info.system.title
  logo = _info.system.adminLogo
  caflag = _info.ca ? _info.ca.enabled : false
  redEnvelope = _info.func ? _info.func.redEnvelope : false
  headacheDiary = _info.func ? _info.func.headacheDiary : false
}
export default {
  baseUrl,
  tengxunsdkId,
  title,
  logo,
  caflag,
  redEnvelope,
  headacheDiary
}
