//脉象红色字体默认值
const duadMaiDefaultValue = {
  shu: '',
  hua: '',
  xian: '',
  fu: '',
  kong: ''
};

/**
 * 数迟等级
 * @param heartrate 心率
 */
const shuChiLevel = (heartrate) => {
  const level = {
    shu: 0,
    chi: 0
  };

  if (heartrate > 180) {
    level.shu = 6;
  } else if (heartrate <= 180 && heartrate > 150) {
    level.shu = 5;
  } else if (heartrate <= 150 && heartrate > 130) {
    level.shu = 4;
  } else if (heartrate <= 130 && heartrate > 110) {
    level.shu = 3;
  } else if (heartrate <= 110 && heartrate > 100) {
    level.shu = 2;
  } else if (heartrate <= 100 && heartrate >= 90) {
    level.shu = 1;
  } else if (heartrate < 90 && heartrate > 60) {
    level.shu = 0;
    level.chi = 0;
  } else if (heartrate <= 60 && heartrate > 50) {
    level.chi = 1;
  } else if (heartrate <= 50 && heartrate > 45) {
    level.chi = 2;
  } else if (heartrate <= 45) {
    level.chi = 3;
  }

  return level;
};

/**
 * 根据等级计算脉象
 * @param summary 每部脉象等级
 * @param heartrate 心率
 * @param channels 六经
 */
export const getMais = (summary, heartrate, channels) => {
  const level = shuChiLevel(heartrate);
  const mais = {};
  let levels = {
    shu: 0,
    chi: 0,
    hua: 0,
    xi: 0,
    xian: 0,
    ruan: 0,
    fu: 0,
    chen: 0,
    kong: 0,
    shi: 0
  };
  const duad = [
    ['shu', 'chi'],
    ['hua', 'xi'],
    ['xian', 'ruan'],
    ['fu', 'chen'],
    ['kong', 'shi']
  ];
  // 有力
  const haveWeakness =
    summary.xian[0] > summary.ruan[0] ||
    (summary.xian[0] === summary.ruan[0] && summary.xian[1] > summary.ruan[1]);
  // 心率数
  const fastHeartRate = level.shu > 0;

  // 成对的等级
  for (const key in summary) {
    levels[key] = summary[key][0];
  }
  levels = { ...levels, ...level };

  const duadMai = { ...duadMaiDefaultValue };

  let zero = 0;
  for (const item of duad) {
    const k1 = item[0];
    const k2 = item[1];
    const num = levels[k1] - levels[k2];
    if (num > 0) {
      mais[k1] = { level: levels[k1], division: false };
      duadMai[k1] = 'left';
    } else if (num < 0) {
      duadMai[k1] = 'right';
      mais[k2] = { level: levels[k2], division: false };
    } else {
      zero++;
      mais[k1] = { level: levels[k1], division: false };
      mais[k2] = { level: levels[k2], division: false };
    }
  }

  // 当五对都为0是，默认平细
  if (zero === duad.length) {
    delete mais.hua;
  }

  // 数、浮、沉一分为二
  if (mais.shu) {
    mais.shu.division = haveWeakness;
  }
  if (mais.fu) {
    mais.fu.division = !fastHeartRate;
  }
  if (mais.chen) {
    mais.chen.division = haveWeakness;
  }

  // 六经
  const lj = {};
  channels.forEach((item) => {
    for (const key in mais) {
      const mai = mais[key];
      if (mai.level === 0) {
        continue;
      }
      let division = '';
      if (key === 'shu' && haveWeakness) {
        division = '2';
      }
      if (key === 'chen' && !haveWeakness) {
        division = '2';
      }
      lj[`${key}${division}_${item.key}`] = true;
    }
  });

  return { mais, duadMai, lj };
};
