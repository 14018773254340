import axios from '../../axios'
const basePath = 'core/videoinfo'

export function updateChatVideoInfoState(data) {
  return axios({
    url: basePath + '/updateChatVideoInfoState',
    method: 'post',
    data
  })
}

export const findChatVideoInfoCount = (data) => {
  return axios({
    url: basePath + '/findChatVideoInfoCount',
    method: 'get',
    params: data
  })
}

export const genSigVidel = (userId) => {
  return axios({
    url: basePath + '/genSigVidel/' + userId,
    method: 'get'
  })
}
