var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["item", _vm.cla] }, [
    _c("div", [
      _c("div", { staticClass: "top" }, [
        _c("span", { class: ["txt", _vm.on === "left" ? "on" : ""] }, [
          _vm._v(_vm._s(_vm.titles[0])),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c("span", { class: ["txt", _vm.on === "right" ? "on" : ""] }, [
          _vm._v(_vm._s(_vm.titles[1])),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }