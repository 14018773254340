import axios from '../../axios'
const basePath = '/mgmt/personnel/consignors'

export function save(data) {
  return axios({
    url: basePath,
    method: 'post',
    data
  })
}
export function edit(data) {
  return axios({
    url: basePath,
    method: 'put',
    data
  })
}
export function batchDelete(id) {
  return axios({
    url: basePath + `/${id}`,
    method: 'delete'
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data// data
  })
}

export const findInfoByUniqueKey = (data) => {
  return axios({
    url: basePath + `/findById`,
    method: 'get',
    params: data// data
  })
}

