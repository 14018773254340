var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("ykj-search", {
        attrs: { "query-list": _vm.queryList },
        on: { query: _vm.initPageListF },
      }),
      _vm._v(" "),
      _c("MDTable", {
        attrs: { table: _vm.table, "page-vo": _vm.pageVo },
        on: {
          handleSizeChange: _vm.handleSizeChange,
          handleCurrentChange: _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }