var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paragraph-wrap nop-fi11-box" },
    [
      _c(
        "h2",
        { staticClass: "sub-title", attrs: { "data-op-type": "block" } },
        [_vm._v("\n    " + _vm._s(_vm.qxjy.text.join("、")) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.qxjy.desc.length > 0 && _vm.en
        ? _c(
            "div",
            { staticClass: "paragraph", attrs: { "data-op-type": "block" } },
            [_c("h3", [_vm._v("Annotation:")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.qxjy.desc.length > 0 && !_vm.en
        ? _c(
            "div",
            { staticClass: "paragraph", attrs: { "data-op-type": "block" } },
            [_c("h3", [_vm._v("注释:")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.qxjy.desc, function (item, index) {
        return _c(
          "p",
          {
            key: index,
            staticClass: "paragraph-p",
            attrs: { "data-op-type": "text" },
          },
          [_vm._v("\n    " + _vm._s(item) + "\n  ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }