var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cont-de" },
    [
      _vm.data.consultationNo
        ? [
            _c(
              "div",
              { staticClass: "cont-ee" },
              [
                _vm.data.flowStatus >= 6 && _vm.type !== "1"
                  ? _c("div", { staticClass: "img-yz" }, [_vm._m(0)])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "cont-top" }, [
                  _c("div", { staticClass: "title-de" }, [
                    _vm._v("\n          专家联合会诊\n          "),
                    _vm.type === "1"
                      ? _c(
                          "span",
                          {
                            class: {
                              back10: _vm.data.approveStatus === 1,
                              back20: _vm.data.approveStatus === 2,
                              back30: _vm.data.approveStatus === 3,
                            },
                          },
                          [
                            _vm.data.approveStatus === 1
                              ? [_vm._v("\n              待审核\n            ")]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.data.approveStatus === 2
                              ? [_vm._v("\n              已通过\n            ")]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.data.approveStatus === 3
                              ? [_vm._v("\n              已拒绝\n            ")]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _c("span", { staticClass: "back10" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.data.flowStatus >= 6 ? "已备案" : "待备案"
                              ) +
                              "\n          "
                          ),
                        ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "t-text" }, [
                      _vm._v("会诊单编号：" + _vm._s(_vm.data.consultationNo)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "doctor-n" }, [
                    _c("div", { staticClass: "doctor-head" }, [
                      _c("img", {
                        attrs: { src: _vm.data.doctorHeadPortrait, alt: "" },
                      }),
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.data.doctorName) +
                        "医生申请\n          "
                    ),
                    _c("img", {
                      staticStyle: { height: "38px", "margin-top": "-10px" },
                      attrs: { src: _vm.data.doctorSign, alt: "" },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont-top" },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "des-t" }, [
                            _c("i", { staticClass: "el-icon-bank-card" }),
                            _vm._v("\n              患者信息\n            "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "患者" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.data.patientName) +
                                "，" +
                                _vm._s(
                                  _vm.data.patientGender
                                    ? "" +
                                        (_vm.data.patientGender === 1
                                          ? "男"
                                          : "女")
                                    : "-"
                                ) +
                                "，" +
                                _vm._s(
                                  _vm.data.patientAge
                                    ? "" + _vm.data.patientAge
                                    : "-"
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "经治科室" } },
                          [_vm._v(_vm._s(_vm.data.doctorDeptName || "-"))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "病情描述" } },
                          [_vm._v(_vm._s(_vm.data.diseaseDescription || "-"))]
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, colon: false } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "des-t" }, [
                            _c("i", { staticClass: "el-icon-help" }),
                            _vm._v("\n              当前诊断\n            "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-descriptions-item", [
                          _vm._v(
                            _vm._s(
                              _vm.data.diagnoseNames
                                ? "" + _vm.data.diagnoseNames.join()
                                : "-"
                            )
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "cont-tab" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("申请会诊原因："),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.data.applyReasonTexts
                              ? "" + _vm.data.applyReasonTexts.join()
                              : "-"
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cont-tab" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("会诊目的："),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.data.consultationPurposeTexts
                              ? "" + _vm.data.consultationPurposeTexts.join()
                              : "-"
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.data.approveStatus === 1 &&
                    _vm.groupDoctorList.length < 1
                      ? _c("div", { staticClass: "cont-tab" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("经治医生："),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "doctor-card" }, [
                            _c("div", { staticClass: "doctor-card-cont" }, [
                              _c("div", { staticClass: "doctor-card-head" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.data.doctorHeadPortrait,
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("div", { staticClass: "doctor-card-text" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.data.doctorName || "-")),
                                  ]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.data.doctorPositionName || "-"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "doctor-card-text" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.data.doctorDeptName || "-")
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("|")]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.data.doctorHisName || "-") +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ])
                      : _c(
                          "el-descriptions",
                          { attrs: { column: 1, colon: false } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("div", { staticClass: "des-t" }, [
                                _c("i", { staticClass: "el-icon-help" }),
                                _vm._v(
                                  "\n              会诊医生\n            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-descriptions-item", [
                              _c(
                                "div",
                                { staticClass: "doctor-list" },
                                _vm._l(
                                  _vm.groupDoctorList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "doctor-card",
                                      },
                                      [
                                        _vm.data.doctorId === item.doctorId
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "doctor-card-tab",
                                              },
                                              [_vm._v("经治医生")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "doctor-card-cont" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "doctor-card-head",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: item.doctorHeadPortrait,
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "doctor-card-text",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.doctorName || "-"
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        item.doctorPositionName ||
                                                          "-"
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "doctor-card-text",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.doctorDeptName ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("span", [_vm._v("|")]),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        item.doctorHisName ||
                                                          "-"
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.type !== "1"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "doctor-card-but",
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSeeReport(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.data.doctorId ===
                                                          item.doctorId
                                                          ? "治疗方案"
                                                          : "会诊报告"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ],
                          2
                        ),
                    _vm._v(" "),
                    _vm.data.approveStatus !== 1 &&
                    (_vm.data.flowStatus < 6 || _vm.type === "1")
                      ? _c("div", { staticClass: "img-yz" }, [
                          _vm.data.approveStatus === 2
                            ? _c("div", { staticClass: "img-yz-i" }, [
                                _c("img", {
                                  attrs: { src: "/img/pass.png", alt: "" },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.approveStatus === 3
                            ? _c("div", { staticClass: "img-yz-i" }, [
                                _c("img", {
                                  attrs: { src: "/img/no-pass.png", alt: "" },
                                }),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, colon: false } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "des-t" }, [
                            _c("i", { staticClass: "el-icon-help" }),
                            _vm._v("\n              会诊科室\n            "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.data.consultationDeptNames
                                    ? "" + _vm.data.consultationDeptNames.join()
                                    : "-"
                                ) +
                                "\n            "
                            ),
                            _vm.data.approveStatus === 1 && _vm.type === "1"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleChoice(
                                          _vm.data.consultationDeptNos
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选择会诊医生")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "des-t" }, [
                            _c("i", { staticClass: "el-icon-help" }),
                            _vm._v("\n              会诊时间\n            "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "开始时间" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getLocalTime(_vm.data.meetingStartTime)
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "预计时长" } },
                          [
                            _vm._v(
                              _vm._s(_vm.data.meetingDuration || "0") + "分钟"
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1 } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "des-t" }, [
                            _c("i", { staticClass: "el-icon-help" }),
                            _vm._v("\n              会诊费用\n            "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "会诊费用" } },
                          [_vm._v(_vm._s(_vm.data.consultationFee || 0) + "元")]
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.data.approveStatus > 1
                      ? _c(
                          "el-descriptions",
                          { attrs: { column: 1, colon: false } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("div", { staticClass: "des-t" }, [
                                _c("i", { staticClass: "el-icon-help" }),
                                _vm._v(
                                  "\n              患者签字远程会诊申请单\n            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-descriptions-item",
                              [
                                _vm.data.approveStatus === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          plain: "",
                                        },
                                        on: { click: _vm.handleInventory },
                                      },
                                      [_vm._v("远程会诊申请单")]
                                    )
                                  : [
                                      _vm._v(
                                        "\n              无\n            "
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.approveStatus === 2
                      ? _c(
                          "el-descriptions",
                          { attrs: { column: 1, colon: false } },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "des-t",
                                  staticStyle: { display: "flex" },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-help" }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("审核人")]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-descriptions-item", [
                              _vm.data.approveStatus === 2
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.data.approveDoctorName) +
                                          "\n              "
                                      ),
                                      _c("img", {
                                        staticStyle: {
                                          height: "38px",
                                          "margin-top": "-10px",
                                        },
                                        attrs: {
                                          src: _vm.data.approveDoctorSign,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-descriptions",
                  { attrs: { column: 1, colon: false } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("div", { staticClass: "des-t" }, [
                        _c("i", { staticClass: "el-icon-help" }),
                        _vm._v("\n            会诊进程\n          "),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-descriptions-item",
                      [
                        _vm.data.logList.length > 0
                          ? _c(
                              "el-timeline",
                              _vm._l(_vm.data.logList, function (item, index) {
                                return _c(
                                  "el-timeline-item",
                                  {
                                    key: index,
                                    attrs: {
                                      timestamp: item.createTime,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "timeline-card" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.operator) +
                                            "\n                "
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.operateContent)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.data.approveStatus === 1 && _vm.type === "1"
              ? _c(
                  "div",
                  { staticClass: "check-but" },
                  [
                    _c("el-button", { on: { click: _vm.handleRefuse } }, [
                      _vm._v("不通过"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSendRecords },
                      },
                      [_vm._v("备案并发送患者")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _c("el-empty"),
      _vm._v(" "),
      _c("canvasSign", { ref: "canvassign", on: { cb: _vm.getSign } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dzdialog",
          attrs: {
            title: "选择医生",
            visible: _vm.isShowDoctorDialog,
            width: "1124px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowDoctorDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "doctorlist" }, [
            _c(
              "div",
              { staticClass: "doctorlist-item" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入医生名称搜索" },
                  model: {
                    value: _vm.filterText,
                    callback: function ($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "doctor-tree" },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        data: _vm.doctorArr,
                        props: _vm.defaultProps,
                        "show-checkbox": "",
                        "node-key": "id",
                        "filter-node-method": _vm.filterNode,
                        "render-content": _vm.renderContent,
                        "default-expand-all": "",
                      },
                      on: { check: _vm.handleNodeClick },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "doctorlist-item" },
              [
                _c("div", { staticClass: "doctorlist-select-top" }, [
                  _vm._v("已选择" + _vm._s(_vm.selectArr.length) + "/3"),
                ]),
                _vm._v(" "),
                _vm.selectArr.length > 0
                  ? _c(
                      "ul",
                      { staticClass: "doctorlist-select-list" },
                      _vm._l(_vm.selectArr, function (item, index) {
                        return _c("li", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "doctorlist-select-list-i" },
                            [_vm._v(_vm._s(item.doctorName))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "doctorlist-select-list-i" },
                            [_vm._v(_vm._s(item.positionCategory_dictName))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "doctorlist-select-list-i" },
                            [_vm._v(_vm._s(item.departmentName))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "doctorlist-select-list-i" },
                            [
                              _vm._v(
                                "会诊费￥" +
                                  _vm._s(item.consultationFee) +
                                  "/次"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "doctorlist-select-list-icon el-icon-error",
                            on: {
                              click: function ($event) {
                                return _vm.selectDel(item.id)
                              },
                            },
                          }),
                        ])
                      }),
                      0
                    )
                  : _c("el-empty", { attrs: { description: "请选择医生" } }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShowDoctorDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.headleConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核详情",
            visible: _vm.isShowCheckDialog,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowCheckDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px", model: _vm.formObj } },
            [
              _c("el-form-item", { attrs: { label: "审核结果:" } }, [
                _vm._v("\n        不通过\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "驳回说明:", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.formObj.rejectReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.formObj, "rejectReason", $$v)
                      },
                      expression: "formObj.rejectReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShowCheckDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.headleSendRefuse },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "远程会诊申请单",
            visible: _vm.isShowInventoryDialog,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowInventoryDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "inventory-c" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "经治科室" } }, [
                    _vm._v(_vm._s(_vm.data.doctorDeptName || "-")),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "患者姓名" } }, [
                    _vm._v(_vm._s(_vm.data.patientName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.data.patientGender
                          ? "" + (_vm.data.patientGender === 1 ? "男" : "女")
                          : "-"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.data.patientAge ? "" + _vm.data.patientAge : "-"
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "病情描述" } }, [
                    _vm._v(_vm._s(_vm.data.diseaseDescription || "-")),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "当前诊断" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.data.diagnoseNames
                          ? "" + _vm.data.diagnoseNames.join()
                          : "-"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "会诊类型" } }, [
                    _vm._v("普通会诊"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "申请会诊原因" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.data.applyReasonTexts
                            ? "" + _vm.data.applyReasonTexts.join()
                            : "-"
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "会诊目的" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.data.consultationPurposeTexts
                          ? "" + _vm.data.consultationPurposeTexts.join()
                          : "-"
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                { attrs: { column: 1, colon: false } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "des-t" }, [_vm._v("会诊医生")]),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", [
                    _c(
                      "div",
                      { staticClass: "doctor-list" },
                      _vm._l(_vm.groupDoctorList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "doctor-card" },
                          [
                            _vm.data.doctorId === item.doctorId
                              ? _c("div", { staticClass: "doctor-card-tab" }, [
                                  _vm._v("经治医生"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "doctor-card-cont" }, [
                              _c("div", { staticClass: "doctor-card-head" }, [
                                _c("img", {
                                  attrs: {
                                    src: item.doctorHeadPortrait,
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("div", { staticClass: "doctor-card-text" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(item.doctorName || "-")),
                                  ]),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(item.doctorPositionName || "-") +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "doctor-card-text" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(item.doctorDeptName || "-")),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("|")]),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(item.doctorHisName || "-") +
                                      "\n                  "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "inventory-qm" }, [
                _c(
                  "div",
                  [
                    _vm._v("\n          患者（或委托人） 签名：\n          "),
                    _vm.data.patientSign
                      ? _c("img", {
                          attrs: { src: _vm.data.patientSign, alt: "" },
                        })
                      : [_vm._v("\n            -\n          ")],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", [
                  _vm._v("日期：" + _vm._s(_vm.data.patientSignTime || "-")),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.reportData.doctorId === _vm.data.doctorId
                ? "远程会诊治疗方案"
                : "远程会诊报告",
            visible: _vm.isShowReportDialog,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowReportDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "h-item" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "会诊单编号" } },
                    [_vm._v(_vm._s(_vm.data.consultationNo || "-"))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "会诊时间" } }, [
                    _vm._v(
                      _vm._s(_vm.getLocalTime(_vm.data.meetingStartTime)) +
                        " - " +
                        _vm._s(_vm.getLocalTime(_vm.data.meetingEndTime))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "h-item" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "患者" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.data.patientName) +
                        "，" +
                        _vm._s(
                          _vm.data.patientGender
                            ? "" + (_vm.data.patientGender === 1 ? "男" : "女")
                            : "-"
                        ) +
                        "，" +
                        _vm._s(
                          _vm.data.patientAge ? "" + _vm.data.patientAge : "-"
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "病情描述" } }, [
                    _vm._v(_vm._s(_vm.data.diseaseDescription || "-")),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "h-item", staticStyle: { "margin-bottom": "14px" } },
            [
              _c("div", { staticClass: "h-item-c" }, [
                _c("div", { staticClass: "h-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.reportData.doctorId === _vm.data.doctorId
                        ? "治疗方案："
                        : "会诊意见："
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.reportData.consultationReport || "-") +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign-c" }, [
                  _c("div", { staticClass: "des-t" }, [
                    _c("i", { staticClass: "el-icon-help" }),
                    _vm._v("\n            医生签名\n          "),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    attrs: { src: _vm.reportData.doctorSign, alt: "" },
                  }),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label:
                      _vm.reportData.doctorId === _vm.data.doctorId
                        ? "经治医生"
                        : "报告医生",
                  },
                },
                [_vm._v(_vm._s(_vm.reportData.doctorName))]
              ),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "提交时间" } }, [
                _vm._v(_vm._s(_vm.reportData.reportTime || "-")),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-yz-i" }, [
      _c("img", { attrs: { src: "/img/end.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cont-tab" }, [
      _c("span", { staticClass: "label" }, [_vm._v("会诊类型：")]),
      _vm._v("\n          普通会诊\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }