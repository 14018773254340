// 分布3D图脉搏波曲线
export const baseSeries = {
  chang: [
    0, 0.42010581, 1.055767625, 2.472645589, 4.690498633, 7.623704499, 10.94194786, 14.41980383,
    17.89271031, 21.20488618, 24.13220905, 26.45801093, 28.05722539, 28.92741114, 29.14832302,
    28.93839388, 28.48295228, 27.82836695, 26.8933288, 25.75341828, 24.54776601, 23.3702378,
    22.26447711, 21.18987605, 20.04922692, 18.83088719, 17.68173356, 16.59111221, 15.42918772,
    14.27854039, 13.35455163, 12.6691491, 12.2985023, 12.40342662, 12.95329816, 13.59586595,
    14.03917293, 14.13652958, 13.86380813, 13.30287754, 12.63105619, 11.96854051, 11.28424667,
    10.57872044, 9.841522915, 9.064613788, 8.286785175, 7.546388938, 6.811850711, 6.040526589,
    5.251498192, 4.475785122, 3.711443778, 3.022926321, 2.487062693, 2.097293505, 1.766128681,
    1.471234415, 1.210994373, 0.987532772, 0.797277282, 0.644916302, 0.517936447, 0.391481725,
    0.260917472, 0.159776395, 0.101481994, 0.07185309, 0.052253961, 0.036705889, 0.024799567,
    0.015676719, 0.010147149, 0.007947626, 0.006869746, 0.005791865, 0.004713985, 0.003636105,
    0.002558224, 0.001480344
  ],
  hua: [
    0, 0.500058941, 1.226229441, 2.758888941, 5.264416749, 8.610899016, 12.43561571, 16.44094147,
    20.39510047, 23.81065085, 26.31377111, 27.89363364, 28.7635628, 29.1088163, 29.14832302,
    29.06634613, 28.92367369, 28.6622773, 28.2029895, 27.4582279, 26.27501574, 24.81088773,
    23.39379599, 22.27617376, 21.48901484, 21.00774438, 20.73028971, 20.55411638, 20.43548824,
    20.27450069, 20.03428234, 19.74894692, 19.46945046, 19.13944972, 18.63003091, 17.89307247,
    17.0206846, 16.11975926, 15.20603173, 14.28588344, 13.39721948, 12.60416697, 11.96123144,
    11.50513198, 11.22893051, 11.06673571, 10.85021577, 10.51676121, 10.11896085, 9.675294013,
    9.052073885, 8.239876504, 7.311293234, 6.280843229, 5.222685825, 4.234906901, 3.380044383,
    2.676559268, 2.164726629, 1.778544978, 1.443921498, 1.152333127, 0.90471387, 0.708103785,
    0.551739872, 0.406913557, 0.260870091, 0.149673334, 0.090376274, 0.062210755, 0.042198444,
    0.027084867, 0.016595662, 0.01020176, 0.007641864, 0.006407367, 0.00517287, 0.003938374,
    0.002703877, 0.00146938
  ],
  xi: [
    0, 0.318878479, 0.676559541, 1.345753281, 2.683835628, 4.855308673, 7.766646672, 11.30711746,
    15.46668834, 19.87398446, 23.87978573, 26.82344203, 28.53425397, 29.14832302, 28.8609798,
    27.73220975, 26.07731727, 24.25006899, 22.34318863, 20.46814036, 18.77063491, 17.23281212,
    15.61099067, 13.86822178, 12.16479273, 10.74347334, 9.640742697, 8.84798627, 8.415568077,
    8.402625562, 8.642820076, 8.903725821, 9.032577321, 9.060925347, 8.946101262, 8.592648575,
    7.973229365, 7.183929807, 6.303750544, 5.396671093, 4.501462338, 3.698666953, 3.086373623,
    2.66839017, 2.346550311, 2.044113948, 1.861111898, 1.910139854, 2.15883158, 2.444383063,
    2.725229568, 3.008539192, 3.21659892, 3.219414587, 3.019175586, 2.731408735, 2.462399253,
    2.196764474, 1.897682859, 1.565807688, 1.263427857, 1.008248421, 0.797728237, 0.631407424,
    0.487710711, 0.345061092, 0.210184698, 0.122576879, 0.077949049, 0.055660318, 0.037736604,
    0.02406058, 0.014512871, 0.009316276, 0.007519768, 0.006312996, 0.005106225, 0.003899453,
    0.002692682, 0.00148591
  ]
}
