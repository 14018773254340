<template>
  <el-dialog
    title="双重验证"
    :visible.sync="dialogNoteCode"
    width="400px"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form ref="noteLoginForm" :model="noteLoginForm" :rules="noteLoginRules" class="login-form" autocomplete="on" label-position="left">
      <el-form-item prop="code" class="codefrom">
        <el-input
          ref="code"
          v-model="noteLoginForm.code"
          :style="{'width':'60%'}"
          class="code"
          :type="codeType"
          placeholder="请输入验证码"
          name="code"
          tabindex="2"
          autocomplete="on"
          prefix-icon="el-icon-warning"
          maxlength="6"
          @blur="capsTooltip = false"
          @keyup.enter.native="handleNoteLogin"
        />
        <span class="shu" />
        <el-button type="text" :disabled="huoqu" style="margin-left: 10px;" @click="obtainCode">{{ huoqu ? `${timer}s后重新获取` : '获取验证码' }}</el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogNoteCode = false">取 消</el-button>
      <el-button type="primary" @click="handleNoteLogin">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogNoteCode: false,
      noteLoginForm: { code: '' },
      codeType: 'code',
      capsTooltip: false,
      noteLoginRules: {
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      },
      huoqu: false,
      timer: 120
    }
  },
  methods: {
    init() {
      this.capsTooltip = false
      this.huoqu = false
      this.timer = 120
      this.noteLoginForm.code = ''
      this.dialogNoteCode = true
    },
    handleNoteLogin() {
      this.$refs.noteLoginForm.validate(valid => {
        if (valid) {
          this.$api.login.toLogin(this.noteLoginForm).then((res) => {
            if (res.code === 200) {
              // this.getInfo()
              this.$emit('cb')
              this.dialogNoteCode = false
            }
          })
        } else {
          return false
        }
      })
    },
    obtainCode() {
      this.$api.login.getNoteCode().then((res) => {
        if (res.code === 200) {
          this.timer = 120
          this.huoqu = true
          this.countDownCode()
        }
      })
    },
    countDownCode() {
      const _timer = setInterval(() => {
        if (this.timer < 1) {
          this.huoqu = false
          clearInterval(_timer)
        } else {
          this.timer -= 1
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;
$cursor: #333;
  .el-form-item {
    border: 1px solid rgba(153, 153, 153, 1);
    background: rgba(255, 255, 255,1);
    border-radius: 5px;
    color: #454545;
    margin-bottom: 28px;
  }
  .shu{
    display: inline-block;
    width: 1px;
    height:20px;
    background: #333;
    position: relative;
    top: 3px;

  }
  .el-input {
    display: inline-block;
    height: 47px;
    width:100%;
    min-height: 100%;
  }
  >>>.el-input--prefix .el-input__inner{
    padding-left: 30px;
    border: none;
    height: 100%;
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width:100%;
      min-height: 100%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 6px;
      padding: 12px 5px 12px 35px;
      color: #333;
      height: 47px;
      caret-color: $cursor;
      height: 100%;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
</style>
