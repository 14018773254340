<template>
  <div class="back-cont">
    <div class="ykj-button" @click="$router.go(-1)">
      <i class="el-icon-arrow-left" />
      返回
    </div>
    <div v-if="text" class="back-tab">
      <i v-if="icon" :class="icon" style="padding-right:10px;"></i>
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.back-cont {
  padding: 17px 20px;
  font-size: 14px;
  color: #303133;
  line-height: 16px;
  display: flex;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  // position: sticky;
  // top: 0;
  // left: 0;
  // z-index: 666;
  background: #fff;
  .ykj-button {
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 8px;
      font-weight: normal;
    }
  }
  .back-tab {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #e4e7ed;
    // color: #fff;
    // text-shadow: 5px 5px 15px hotpink, -5px 5px 35px hotpink, 5px -5px 35px hotpink, -5px -5px 35px hotpink;
  }
}
</style>
