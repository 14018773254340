import axios from '../../axios'
const basePath = '/core'
/*
 * 权限管理模块
 */

// 查找权限树
export const findPermTree = () => {
  return axios({
    url: basePath + '/perm/findPermTree',
    method: 'get'
  })
}

export const findPage = (data, pageVo) => {
  return axios({
    url: basePath + '/perm/page/list/' + pageVo.pageSize + '/' + pageVo.pageNum,
    method: 'get',
    params: data
  })
}

export const handlePerm = (data) => {
  return axios({
    url: basePath + '/perm/initPerms',
    method: 'post',
    data
  })
}
