var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("YkjNotices", {
            attrs: { "jump-page": "/recipecheckpharmacist/checkpharmacist" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "user-info" }, [
            _c("div", { staticClass: "user-info-cont" }, [
              _c(
                "div",
                { staticClass: "user-info-head" },
                [
                  _c("el-avatar", {
                    attrs: {
                      size: 32,
                      src: _vm.currentUserInfo
                        ? _vm.currentUserInfo.wxDoctorHeadimg
                        : "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "user-info-text" }, [
                    _vm._v(_vm._s(_vm.currentUserInfo.userName)),
                  ]),
                  _vm._v(" "),
                  !_vm.roles.length
                    ? _c("div", { staticClass: "user-info-tab" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentUserInfo.isSuperUser === 1
                              ? "超级管理员"
                              : _vm.currentRole.roleName
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.roles.length && _vm.currentUserInfo.auditorLevel
                    ? _c("div", { staticClass: "user-info-tab" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentUserInfo.auditorLevel === 1
                              ? "一级审核人"
                              : "二级审核人"
                          )
                        ),
                      ])
                    : _c(
                        "el-dropdown",
                        { attrs: { trigger: "click" } },
                        [
                          _c("div", { staticClass: "user-info-tab" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentUserInfo.isSuperUser === 1
                                  ? "超级管理员"
                                  : _vm.currentRole.roleName
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.currentUserInfo.auditorLevel
                            ? _c("div", { staticClass: "user-info-tab" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentUserInfo.auditorLevel === 1
                                      ? "一级审核人"
                                      : "二级审核人"
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-menu",
                            [
                              _vm._l(_vm.roles, function (role) {
                                return [
                                  role.id != _vm.currentRole.roleId
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          key: role.id,
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.changeRole(role)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { display: "block" },
                                            },
                                            [_vm._v(_vm._s(role.name))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" } },
            [
              _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("注销"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.updPassword($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("修改密码"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("dialogNoteCode", { ref: "dialogNoteCode2", on: { cb: _vm.up } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }