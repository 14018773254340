<template>
  <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
    <div class="title-container">
      <span><img class="inputimg" src="img/inputlogo.png" alt=""></span>
      <span class="title">后台管理系统</span>
    </div>
    <div class="logman">
      <p>手机号登录</p>
      <el-form-item prop="phone">
        <el-input
          ref="phone"
          v-model="loginForm.phone"
          placeholder="请输入手机号"
          name="phone"
          type="number"
          tabindex="1"
          autocomplete="on"
          prefix-icon="el-icon-mobile"
          prefix-icon-color="red"
        />
      </el-form-item>
      <el-form-item v-if="loginForm.phone.length" prop="code" class="codefrom">
        <el-input
          ref="code"
          v-model="loginForm.code"
          :style="{'width':'65%'}"
          class="code"
          prefix-icon="el-icon-warning-outline"
        />
        <span class="shu" />
        <el-button type="text" :disabled="disabled" @click="getVerifyCode">{{ btnTitle }}</el-button>
      </el-form-item>

      <el-button class="frombtn">登录</el-button>
    </div>
  </el-form>
</template>
<script>
const tel = /^1[345789]\d{9}$/ // 手机号码
const Tel = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('电话不能为空'))
  }
  if (!tel.test(value)) {
    callback(new Error('请正确填写电话号码'))
  } else {
    callback()
  }
}
const Code = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('验证码不能为空'))
  }
}
export default {
  name: 'Phone',
  data() {
    return {
      loginForm: {
        phone: '',
        code: ''
      },
      loginRules: {
        phone: [{ required: true, trigger: 'blur', validator: Tel }],
        code: [{ required: true, trigger: 'blur', validator: Code }]

      },
      isPhone: false,
      btnTitle: '获取验证码',
      disabled: false
    }
  },
  mounted() {
  },
  methods: {
    getVerifyCode() {
      // 获取验证码
      if (this.loginForm.phone && tel.test(this.loginForm.phone)) {
        this.disabled = true
        this.validateBtn()

        // // 发送网络请求
        // this.$axios.post('/api/posts/sms_send',
        //   {
        //     tpl_id: '',
        //     key: '',
        //     phone: this.phone
        //   }).then(res => {
        // })
      }
    },
    validateBtn() {
      // 倒计时
      let time = 60
      this.btnTitle = '60秒后重试'
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time--
        }
      }, 1000)
    }

  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;
$cursor: #333;

.login-container {

  .login-form {
    position: relative;
    width: 500px;
    max-width: 100%;

    position: fixed;
    border-radius: 10px;
    right: 15%;
     top: 200px;
    overflow: hidden;
    background: #fff;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;
    height: 99px;
    background:#1F50CC;
    display: flex;
    align-items: center;
    .inputimg{
      width: 70px;
      height: 70px;
      margin: 0 20px;
    }

    .title {
      font-size: 28px;
      color: #1E3E6C;
      line-height: 99px;
      color: #fff;

      text-align: center;

    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
.adminlogo{
  position: fixed;
  left:94px;
  top: 67px;
  width: 466px;
  height: 64px;
}
.logman{
  padding:20px 39px 29px;
  box-sizing: border-box;
  background: #fff;
}
.frombtn{
  background: #FD9333;
  color: #fff;
  height: 50px;
  border-radius: 4px;
  width: 100%;
}
.codes{
  float: right;
  margin: 3px 21px;
}
.el-form-item__content{
  display: flex;
  align-items: center;
}
.shu{
  display: inline-block;
    width: 1px;
    height:20px;
    background: #333;
   position: relative;
   top: 3px;
  }
  .tabcard{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;

  }
  >>>.el-input--prefix .el-input__inner{
    padding-left: 30px;
    border: none;
    height: 100%;
  }

  .el-input {
    display: inline-block;
    height: 47px;
    width:100%;
      min-height: 100%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 6px;
      padding: 12px 5px 12px 35px;
      color: #333;
      height: 47px;
      caret-color: $cursor;
      height: 100%;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(153, 153, 153, 1);
    background: rgba(255, 255, 255,1);
    border-radius: 5px;
    color: #454545;
    margin-bottom: 28px;
  }

</style>

