import axios from '../../axios'
const basePath = '/mgmt/consultations'
// 分页查询会诊审核记录
export const findPage = (data) => {
  return axios({
    url: basePath,
    method: 'get',
    params: data
  })
}

// 通过审核
export const passCheck = (data) => {
  return axios({
    url: basePath + '/_approved',
    method: 'post',
    data
  })
}

// 审核不通过
export const noPassCheck = (data) => {
  return axios({
    url: basePath + '/_reject',
    method: 'post',
    data
  })
}

// 详情查询
export const findOnlyKey = (no) => {
  return axios({
    url: basePath + `/${no}`,
    method: 'get'
  })
}

// 分页查询会诊审核记录
export const doctorFindPage = (data) => {
  return axios({
    url: '/core/doctor/_findListByDeptNos',
    method: 'get',
    params: data
  })
}
