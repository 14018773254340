import axios from '@/http/axios'

// 运费模版
export const templateList = (data) => {
    return axios({
        url: '/mgmt/freight/template/getPage',
        method: 'get',
        params: data
    })
}

// 运费模版
export const detailTemplate = (id) => {
    return axios({
        url: '/mgmt/freight/template/detail/' + id,
        method: 'get',
    })
}


// 新增模版
export const addTemplate = (data) => {
    return axios({
        url: '/mgmt/freight/template/add',
        method: 'post',
        data
    })
}

// 修改编辑模版
export const editTemplate = (data) => {
    return axios({
        url: '/mgmt/freight/template/edit',
        method: 'post',
        data
    })
}


export const delTemplate = (id) => {
    return axios({
        url: '/mgmt/freight/template/delete/' + id,
        method: 'post',
    })
}

