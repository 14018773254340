var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { staticClass: "fixed-header" },
            [
              _c("navbar", {
                ref: "navbarRef",
                on: {
                  updPassword: _vm.updPassword,
                  receiveMessage: _vm.receiveMessage,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex a-center" },
                [
                  _c("span", { staticClass: "el-icon-info ml-2" }),
                  _vm._v(" "),
                  _c("breadcrumb", {
                    staticClass: "breadcrumb-container",
                    attrs: { id: "breadcrumb-container" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-main", {
            ref: "appMainRef",
            on: { sendMessage: _vm.sendMessage, reconnect: _vm.reconnect },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("changePassword", { ref: "changePassword" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }