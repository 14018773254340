import defaultSettings from '@/utils/global'
console.log(defaultSettings, 'sdsdasdsad')
const settingsApi = require('../http/api')

let title = defaultSettings.title || '后台管理系统'
settingsApi.default.settings.headInfo({ groups: 'system,ca,func,prescription,map,pharmacy,logistics,cod,heart-check-report' }).then(res => {
  if (res.code === 200) {
    sessionStorage.setItem('systemInfo', JSON.stringify(res.data))
    title = res.data.system.title
    document.title = res.data.system.title
  } else {
    // this.$message({ message: res.msg, type: 'error' })
  }
})
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

