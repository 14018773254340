var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chart-channel",
      style: "width: " + _vm.width + "px; height: " + _vm.width + "px;",
    },
    [
      _c(
        "div",
        {
          staticClass: "channel-wrp",
          style: "transform: " + _vm.transform(_vm.width),
        },
        [
          _c("ChannelBg", { attrs: { mais: _vm.mais(), width: 800 } }),
          _vm._v(" "),
          _c("div", { staticClass: "channel" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "top" }, [
                _c("span", { class: ["txt", { on: _vm.lj.xian_shaoyang }] }, [
                  _vm._v("少阳"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _c("span", { class: ["txt", { on: _vm.lj.ruan_taiyang }] }, [
                  _vm._v("太阳"),
                ]),
                _c("span", { class: ["txt", { on: _vm.lj.ruan_jueyin }] }, [
                  _vm._v("厥阴"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item r-2" }, [
              _c("div", { staticClass: "top" }, [
                _c("span", { class: ["txt", { on: _vm.lj.fu_taiyang }] }, [
                  _vm._v("太阳"),
                ]),
                _c("span", { class: ["txt", { on: _vm.lj.fu_yangming }] }, [
                  _vm._v("阳明"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _c("div", { staticClass: "left" }, [
                  _c("span", { class: ["txt", { on: _vm.lj.chen_yangming }] }, [
                    _vm._v("阳明"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "span",
                    { class: ["txt", { on: _vm.lj.chen2_shaoyang }] },
                    [_vm._v("少阳")]
                  ),
                  _c("span", { class: ["txt", { on: _vm.lj.chen2_jueyin }] }, [
                    _vm._v("厥阴"),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item r-3" }, [
              _c("div", { staticClass: "top" }, [
                _c("span", { class: ["txt", { on: _vm.lj.hua_yangming }] }, [
                  _vm._v("阳明"),
                ]),
                _c("span", { class: ["txt", { on: _vm.lj.hua_taiyang }] }, [
                  _vm._v("太阳"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _c("span", { class: ["txt", { on: _vm.lj.xi_jueyin }] }, [
                  _vm._v("厥阴"),
                ]),
                _c("span", { class: ["txt", { on: _vm.lj.xi_shaoyin }] }, [
                  _vm._v("少阴"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item r-4" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "left" }, [
                  _c("span", { class: ["txt", { on: _vm.lj.shu_shaoyin }] }, [
                    _vm._v("少阴"),
                  ]),
                  _c("span", { class: ["txt", { on: _vm.lj.shu_taiyin }] }, [
                    _vm._v("太阴"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c("span", { class: ["txt", { on: _vm.lj.shu2_taiyang }] }, [
                    _vm._v("太阳"),
                  ]),
                  _c("span", { class: ["txt", { on: _vm.lj.shu2_yangming }] }, [
                    _vm._v("阳明"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _c("span", { class: ["txt", { on: _vm.lj.chi_shaoyin }] }, [
                  _vm._v("少阴"),
                ]),
                _c("span", { class: ["txt", { on: _vm.lj.chi_jueyin }] }, [
                  _vm._v("厥阴"),
                ]),
                _c("span", { class: ["txt", { on: _vm.lj.chi_taiyang }] }, [
                  _vm._v("太阳"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mai" },
            [
              _c("Item", {
                attrs: { on: _vm.mai.xian, titles: _vm.duadMaiTitle2.xian },
              }),
              _vm._v(" "),
              _c("Item", {
                staticClass: "r-2",
                attrs: { on: _vm.mai.fu, titles: _vm.duadMaiTitle2.fu },
              }),
              _vm._v(" "),
              _c("Item", {
                staticClass: "r-3",
                attrs: { on: _vm.mai.hua, titles: _vm.duadMaiTitle2.hua },
              }),
              _vm._v(" "),
              _c("Item", {
                staticClass: "r-4",
                attrs: { on: _vm.mai.shu, titles: _vm.duadMaiTitle2.shu },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bg", attrs: { id: "channelchartbg" } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }