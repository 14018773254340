<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { title } from '@/utils/global'
export default {
  name: 'App',
  mounted() {
    document.title = title
  }
}
</script>
