export default {
  name: 'compressImg',
  methods: {
    imageToBase64(file, callback) {
      const reader = new FileReader()
      reader.readAsDataURL(file) // 文件转base64
      reader.addEventListener('load', e => {
        callback && callback(e.target.result)
      })
    },
    base64ToBlob(base64) {
      const arr = base64.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    compressImg(originalImage, compressRatio = 1, callback) {
      const image = new Image()
      image.src = originalImage
      /* 监听图片的load事件 */
      image.addEventListener('load', function() {
        let [sizeRatio, maxWidth, maxHeight] = [0, 1024, 1024] // 图片压缩宽高比例和最大宽高
        const [imageWidth, imageHeight] = [this.naturalWidth, this.naturalHeight] // 图片实际宽高
        let compressFlag = false // 图片是否需要压缩
        // 如果图片宽度大于最大宽度就等比压缩图片的高度
        if (imageWidth > maxWidth) {
          compressFlag = true
          sizeRatio = imageWidth / maxWidth
          maxHeight = imageHeight / sizeRatio
        }
        // 如果图片高度大于最大高度就等比压缩图片的宽度
        if (imageHeight > maxHeight) {
          compressFlag = true
          sizeRatio = imageHeight / maxHeight
          maxWidth = imageWidth / sizeRatio
        }
        // 如果不需要压缩
        if (!compressFlag) {
          maxWidth = imageWidth
          maxHeight = imageHeight
        }
        // 使用canvas压缩图片
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.setAttribute('id', 'canvas')
        canvas.width = maxWidth
        canvas.height = maxHeight
        ctx.clearRect(0, 0, maxWidth, maxHeight) // 清除画布内所有像素
        ctx.drawImage(image, 0, 0, maxWidth, maxHeight) // canvas绘制当前图片
        const compressImage = canvas.toDataURL('image/jpeg', compressRatio) // 设置压缩类型和压缩比例获取压缩后的文件, 为 base64
        callback && callback(compressImage)
      })
    },
    isCompressImg(file, callback) {
      const maxSize = 5 * 1024 * 1024 // 最大 5M
      // 压缩图片
      if (file.size > maxSize) {
        this.imageToBase64(file, originalImage => {
          this.compressImg(originalImage, 0.5, compressImage => {
            // base64 转 blob, blob 再转 file
            const img = new File([this.base64ToBlob(compressImage)], file.name, {
              type: file.type
            })
            // 递归判断, 防止压缩后的图片仍然大于 5M
            this.isCompressImg(img, f => {
              callback(f)
            })
          })
        })
      } else {
        callback(file)
      }
    }
  }
}
